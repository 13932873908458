<div mat-dialog-title>
  <h1> {{ data.title }} </h1>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="start center" class="items-select">
    <button color="primary" mat-button
      (click)="allItemsSelected = !allItemsSelected; selectAll(allItemsSelected, items)">{{allItemsSelected ? 'Deselect All' : 'Select All'}}</button>
  </div>
  <mat-selection-list #items>
    <mat-list-option [ngClass]="{ 'mat-list-item-focus' : item.selected}" *ngFor="let item of data.items"
      [selected]="item.selected" [value]="item">
      {{item.description}}
    </mat-list-option>
  </mat-selection-list>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
  <button mat-button [mat-dialog-close]="false">{{data.cancelCaption}}</button>
  <button mat-button [mat-dialog-close]="true" color="primary" (click)="onDone(items.selectedOptions.selected)"
    cdkFocusInitial>{{data.okCaption}}</button>
</div>