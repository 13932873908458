<div fxFlexFill fxLayout="column">
  <div class="loading-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <com-contact-list [contacts]="contacts"></com-contact-list>
  <mat-nav-list>
    <ng-container *ngFor="let conversationGroup of conversations | groupBy: 'embedded.messageType.description' | pairs">
      <h3 mat-subheader (click)="conversationListItems.hidden = !conversationListItems.hidden">
        <span
          >{{ conversationGroup[1][0].dmsMessageTypeId == 0 ? 'People/Group Conversations' : conversationGroup[0] }} ({{
            conversationGroup[1].length
          }})</span
        >
      </h3>
      <div #conversationListItems>
        <com-conversations-list-item
          *ngFor="let conversation of conversationGroup[1] | orderBy: '-utcLastModified'"
          [authenticatedUser]="authenticatedUser"
          [conversation]="conversation"
          [contacts]="contacts"
          [members]="conversationMembers[conversation.id]"
          [contactsPresence]="contactsPresence$ | async"
          (conversationSelected)="selectConversation($event)"
        >
        </com-conversations-list-item>
      </div>
    </ng-container>
  </mat-nav-list>
</div>
