import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import { AuthenticatedUser, CommunicatorContact as Contact, Conversation, Member } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'com-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchContacts: boolean;
  @Input() searchConversations: boolean;
  @Input() searchArchivedConversations: boolean;
  @Input() searchString: string;
  @Input() customerAssociateId: string;
  @Output() onConversationSelected: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  authenticatedUser: AuthenticatedUser;
  contacts: Contact[] = [];
  contactsPresence$: Observable<any>;
  conversations: Conversation[] = [];
  conversationMembers: { [key: number]: Member[] } = {};
  isLoading: boolean = false;
  search: Subject<string> = new Subject();

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private cdr: ChangeDetectorRef,
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private routerStateService: RouterStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((user) => (this.authenticatedUser = user));
    this.contactsPresence$ = this.contactsStateService.selectPresences();
    this.search
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((searchString) => {
          return searchString.length > 2;
        }),
        tap((searchString) => {
          this.isLoading = true;
          this.clearSearchResults();
          if (this.searchContacts) this.searchForContacts(searchString);
        }),
        switchMap((searchString: string) => {
          return merge(
            this.searchForConversations$(searchString),
            this.searchForArchivedConversations$(searchString)
          ).pipe(
            tap((conversations) => {
              this.updateConversationsSearchResults(conversations);
            })
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.search.unsubscribe();
  }

  clearFilter() {
    this.searchString = null;
    this.clearSearchResults();
  }

  clearSearchResults() {
    this.contacts = [];
    this.conversations = [];
    this.cdr.detectChanges();
  }

  clearContacts() {
    this.contacts = [];
    this.cdr.detectChanges();
  }

  clearConversations() {
    this.conversations = [];
    this.cdr.detectChanges();
  }

  removeConversations() {
    [...Object.values(this.conversationMembers)]
      .reduce((acc, m) => acc.concat(m), [])
      .filter((m) => m.userId === this.authenticatedUser.user.id.toString() && !m.isArchived)
      .map((m) => {
        this.conversations = this.conversations.filter((c) => c.id !== m.conversationId);
        delete this.conversationMembers[m.conversationId];
      });
  }

  removeArchivedConversations() {
    [...Object.values(this.conversationMembers)]
      .reduce((acc, m) => acc.concat(m), [])
      .filter((m) => m.userId === this.authenticatedUser.user.id.toString() && m.isArchived)
      .map((m) => {
        this.conversations = this.conversations.filter((c) => c.id !== m.conversationId);
        delete this.conversationMembers[m.conversationId];
      });
  }

  searchForContacts(searchString: string) {
    this.clearContacts();
    // Filter in-state contacts as all contacts are in state, no need to make api call.
    this.contactsStateService
      .selectContacts()
      .pipe(
        take(1),
        flatMap((contacts) => contacts),
        filter(
          (contact, idx) =>
            (contact.nickName && contact.nickName.toLowerCase().includes(this.searchString.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(this.searchString.toLowerCase())) ||
            (contact.firstName &&
              contact.lastName &&
              `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(searchString.toLowerCase())) ||
            (contact.email && contact.email.toLowerCase().includes(this.searchString.toLowerCase()))
        ),
        map((contact: Contact) => {
          this.contacts = [...this.contacts, contact];
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  searchForConversations$(searchString: string): Observable<Conversation[]> {
    if (!this.searchConversations) return new Observable();

    const params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      searchString: searchString,
      embed: 'messageType,lastEvent',
    });

    if (this.customerAssociateId) {
      params.customerId = this.customerAssociateId;
    }

    return this.apiService.get<Conversation[]>('communicator/conversations', {
      params,
    });
  }

  searchForArchivedConversations$(searchString: string): Observable<Conversation[]> {
    if (!this.searchArchivedConversations) return new Observable();

    const params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: true,
      isExcluded: false,
      searchString: searchString,
      embed: 'messageType,lastEvent',
    });

    if (this.customerAssociateId) {
      params.customerId = this.customerAssociateId;
    }

    return this.apiService.get<Conversation[]>('communicator/conversations', {
      params,
    });
  }

  getMembers(conversationId: number) {
    this.apiService
      .get<Member[]>(`communicator/conversations/${conversationId.toString()}/members`)
      .pipe(
        take(1),
        map((members) => {
          this.conversationMembers[conversationId] = members;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  selectMembersForConversations(conversations: Conversation[]) {
    conversations.forEach((conversation: Conversation) => {
      this.membersStateService
        .selectMembers(conversation.id)
        .pipe(
          take(1),
          mergeMap((members: Member[]) => {
            if (members.length > 0) {
              this.conversationMembers[conversation.id] = members;
              this.cdr.detectChanges();
            } else {
              this.getMembers(conversation.id);
            }
            return members;
          })
        )
        .subscribe();
    });
  }

  updateConversationsSearchResults(conversations: Conversation[]) {
    this.conversations = [...this.conversations, ...conversations];
    this.selectMembersForConversations(conversations);
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  onKey(searchString: string) {
    this.search.next(searchString);
  }

  selectConversation(conversation: Conversation) {
    this.onConversationSelected.emit(conversation);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchArchivedConversations && !changes.searchArchivedConversations.firstChange) {
      if (changes.searchArchivedConversations.currentValue && this.searchString.length > 0) {
        this.searchForArchivedConversations$(this.searchString).subscribe((conversations) =>
          this.updateConversationsSearchResults(conversations)
        );
      } else {
        this.removeArchivedConversations();
      }
    }

    if (changes.searchConversations && !changes.searchConversations.firstChange) {
      if (changes.searchConversations.currentValue && this.searchString.length > 0) {
        this.searchForConversations$(this.searchString).subscribe((conversations) =>
          this.updateConversationsSearchResults(conversations)
        );
      } else {
        this.removeConversations();
      }
    }

    if (changes.searchContacts && !changes.searchContacts.firstChange) {
      changes.searchContacts.currentValue && this.searchString.length > 0
        ? this.searchForContacts(this.searchString)
        : this.clearContacts();
    }

    if (changes.searchString) {
      this.onKey(changes.searchString.currentValue);
    }
  }
}
