import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticationStatus } from '@quorum/authentication/state';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { environment } from '@quorum/environments';
import { SocketStateService } from '@quorum/sockets/services';

export function checkAuthentication(authenticationStateService: AuthenticationStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      console.log('app initializer');
      console.log(new Date());
      authenticationStateService.checkCookieForAuthentication();
      authenticationStateService.isAuthenticated().subscribe((value) => {
        if (value != null) {
          resolve({});
        }
      });
    });
  };
}

export function socketSetup(socketStateService: SocketStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      socketStateService.addSocketConnectionInfo({
        isConnected: false,
        url: environment.communicatorUrl,
        service: 'communicator',
        reconnectFailed: false,
        opts: {
          forceNew: true,
          transports: ['websocket'],
          reconnectionDelayMax: 30000,
          reconnectionDelay: 2000,
          randomizationFactor: 1,
          reconnectionAttempts: 10,
        },
      });
      resolve(true);
    });
  };
}

export function authenticationStagesSetup(authenticationStateService: AuthenticationStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      authenticationStateService.addAuthenticationStages([
        {
          id: 1,
          task: 'Authenticating User',
          authenticationStatus: AuthenticationStatus.pending,
        },
        {
          id: 2,
          task: 'Retrieving User Profile',
          authenticationStatus: AuthenticationStatus.pending,
        },
      ]);
      resolve(true);
    });
  };
}

export function clearStore(conversationStateService: ConversationStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      conversationStateService.resetState();
      resolve(true);
    });
  };
}
