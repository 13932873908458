import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EventTypes } from '@models/server-common';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { Conversation, ConversationStateService } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { MembersStateService } from '@quorum/communicator/state/members';
import {
  CommunicatorContact as Contact,
  Event,
  Member,
  MessageType,
  MessageTransactionMessagePreference,
  Template,
} from '@quorum/models/xs-resource';
import { MessageTransactionMessagePreferenceQueryParameters } from '@quorum/models/xs-query';
import { RouterStateService } from '@quorum/sha-router';
import { Observable, Subscription, zip } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
export type Attachment = { fileName: string; url: string };

@Component({
  selector: 'com-new-conversation-shell',
  templateUrl: './new-conversation-shell.component.html',
  styleUrls: ['./new-conversation-shell.component.css'],
})
export class NewConversationShellComponent implements OnInit, OnDestroy {
  messageTypes: MessageType[];
  newConversation: Conversation;
  newConversation$: Subscription;
  newConversationMembers$: Subscription;
  authenticatedUser: AuthenticatedUser;
  members: Member[];
  routeParams: any;
  attachments: Attachment[] = [];
  messagePreferenceOverride$: Observable<MessageTransactionMessagePreference>;
  showMessagePreferenceOverride = false;
  currentContact$: Observable<Contact>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private contactsStateService: ContactsStateService,
    private eventStateService: EventsStateService,
    private memberStateService: MembersStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.conversationStateService.startNewConversation(null);

    this.routeParams = this.routerStateService.getFullTreeParams(this.activatedRoute);

    this.messagePreferenceOverride$ = this.conversationStateService.selectMessagePreferenceOverride();

    if (
      this.routeParams.customerAssociateId &&
      this.routeParams.transactionTypeId &&
      this.routeParams.transactionId &&
      ![4, 8, 11, 13, 14, 21].includes(this.routeParams.transactionTypeId)
    ) {
      const messageTransactionMessagePreferenceQueryParams = new MessageTransactionMessagePreferenceQueryParameters({
        transactionId: this.routeParams.transactionId,
        transactionTypeId: this.routeParams.transactionTypeId,
      });

      this.conversationStateService.getMessagePreferenceOverride(messageTransactionMessagePreferenceQueryParams);
      this.showMessagePreferenceOverride = true;
    }

    this.routerStateService
      .selectRouterState()
      .pipe(
        take(1),
        map((state) => state.state.root.queryParams)
      )
      .subscribe((queryParams) => {
        if (queryParams.attachmentGuid) {
          const guids = queryParams.attachmentGuid.split(',');
          guids.forEach((guid: string) => {
            this.apiService
              .get(`/communicator/attachment-check/${guid}`)
              .pipe(map((result) => result.url))
              .subscribe((url) => {
                this.attachments.push({
                  fileName: url.split('/[#?]/')[0].split('/').pop().trim(),
                  url: url,
                });
              });
          });
        }
      });

    zip(
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.conversationStateService.selectMessageTypes().pipe(take(1))
    ).subscribe(([authenticatedUser, messageTypes]) => {
      this.authenticatedUser = authenticatedUser;
      this.messageTypes = messageTypes;
      this.currentContact$ = this.contactsStateService.selectContact(this.authenticatedUser.user.id);
    });

    this.newConversationMembers$ = this.conversationStateService.selectNewConversationMembers().subscribe((members) => {
      this.newConversation = null;
      this.members = null;
    });

    this.newConversation$ = this.conversationStateService
      .selectNewConversation()
      .pipe(
        filter((conversation: Conversation) => {
          return conversation !== null;
        }),
        tap((conversation: Conversation) => {
          this.newConversation = new DeepCopyPipe().transform(conversation);
          return conversation;
        }),
        switchMap((conversation: Conversation) => {
          return this.memberStateService.selectMembers(conversation.id);
        })
      )
      .subscribe((members: Member[]) => {
        this.members = new DeepCopyPipe().transform(members);
      });
  }

  ngOnDestroy() {
    this.newConversation$.unsubscribe();
    this.newConversationMembers$.unsubscribe();
  }

  createConversation(event: Event, template?: Template) {
    this.conversationStateService
      .selectNewConversationMembers()
      .pipe(take(1))
      .subscribe(([...members]: Member[]) => {
        const conversation: Conversation = new Conversation();
        conversation.dmsMessageTypeId = 18;

        const employeeMember = members.find((member: Member) => member.isEmployee);
        if (employeeMember) {
          conversation.dmsMessageTypeId = 0;
        }
        conversation.embedded = {};
        conversation.embedded.lastEvent = event;
        conversation.embedded.messageType = this.messageTypes.find((m) => conversation.dmsMessageTypeId === m.id);

        if (
          this.routeParams.customerAssociateId &&
          this.routeParams.transactionTypeId &&
          this.routeParams.transactionId
        ) {
          conversation.dmsTransactionId = this.routeParams.transactionId;
          conversation.dmsTransactionTypeId = this.routeParams.transactionTypeId;
        } else if (members.length == 1 && !members[0].isEmployee) {
          conversation.dmsTransactionTypeId = 10;
          conversation.dmsTransactionId = members[0].userId;
        }

        const member: Member = new Member();
        member.userId = this.authenticatedUser.user.id.toString();
        member.avatarUrl = this.authenticatedUser.user.avatarUrl;
        member.firstName = this.authenticatedUser.user.firstName;
        member.lastName = this.authenticatedUser.user.lastName;
        member.nickname = this.authenticatedUser.user.firstName;
        member.isEmployee = true;
        member.isRead = true;
        member.isArchived = false;
        member.isExcluded = false;
        member.isPinnedToUi = false;
        members.push(member);
        this.conversationStateService.createConversation(conversation, members, event, template);
      });
  }

  sendMessage(sentMessage: { message: string; attachments: Attachment[] }) {
    zip(
      this.conversationStateService.selectNewConversation().pipe(take(1)),
      this.memberStateService.selectMembers().pipe(take(1)),
      this.conversationStateService.selectNewConversationMembers().pipe(take(1))
    ).subscribe(([conversation, members, newMembers]) => {
      let sendToCustomer = false;
      if (members && members.length > 0) {
        sendToCustomer = members.find((member) => !member.isEmployee) ? true : false;
      } else if (newMembers && newMembers.length > 0) {
        sendToCustomer = newMembers.find((member) => !member.isEmployee) ? true : false;
      }

      const event: Event = this.eventStateService.createEvent(
        sentMessage.message,
        conversation ? conversation.id : null,
        EventTypes.Message,
        this.authenticatedUser.user,
        sendToCustomer,
        sentMessage.attachments.map((a) => a.url).join(',')
      );

      if (conversation && conversation.id) {
        this.routerStateService.go([{ outlets: { detail: `c/${conversation.id}` } }], {
          relativeTo: this.route.parent,
        });

        this.eventStateService.addEvent(event);

        // If current member has this conversation archived then unarchive it.
        const currentMember: Member = members.find((m) => m.userId === this.authenticatedUser.user.id.toString());
        if (currentMember && currentMember.isArchived) {
          currentMember.isArchived = false;
          this.memberStateService.updateIsArchivedForConversation(currentMember);
        }
      } else {
        this.createConversation(event);
      }
    });
  }

  sendFile(message: string) {
    zip(
      this.conversationStateService.selectNewConversation().pipe(take(1)),
      this.memberStateService.selectMembers().pipe(take(1)),
      this.conversationStateService.selectNewConversationMembers().pipe(take(1))
    ).subscribe(([conversation, members, newMembers]) => {
      let activeMembers = [];

      const event: Event = this.eventStateService.createEvent(
        message,
        conversation ? conversation.id : null,
        EventTypes.File,
        this.authenticatedUser.user,
        members ? (members.find((member) => !member.isEmployee) ? true : false) : null
      );

      if (conversation && conversation.id) {
        activeMembers = members.filter((member) => member.conversationId === conversation.id);
      }

      if (conversation && conversation.id && activeMembers.length == newMembers.length) {
        this.routerStateService.go([{ outlets: { detail: `c/${conversation.id}` } }], {
          relativeTo: this.route.parent,
        });

        this.eventStateService.addEvent(event);

        // If current member has this conversation archived then unarchive it.
        const currentMember: Member = members.find((m) => m.userId === this.authenticatedUser.user.id.toString());
        if (currentMember && currentMember.isArchived) {
          currentMember.isArchived = false;
          this.memberStateService.updateIsArchivedForConversation(currentMember);
        }
      } else {
        this.createConversation(event);
      }
    });
  }

  sendTemplate(template: { attachments: Attachment[]; template: Template }) {
    zip(
      this.conversationStateService.selectNewConversation().pipe(take(1)),
      this.memberStateService.selectMembers().pipe(take(1)),
      this.conversationStateService.selectNewConversationMembers().pipe(take(1))
    ).subscribe(([conversation, members, newMembers]) => {
      let activeMembers = [];
      let sendToCustomer = false;

      if (members && members.length > 0) {
        sendToCustomer = members.find((member) => !member.isEmployee) ? true : false;
      } else if (newMembers && newMembers.length > 0) {
        sendToCustomer = newMembers.find((member) => !member.isEmployee) ? true : false;
      }

      const event: Event = this.eventStateService.createEvent(
        'Generating email...',
        conversation ? conversation.id : null,
        EventTypes.Message,
        this.authenticatedUser.user,
        sendToCustomer,
        template.attachments.map((a) => a.url).join(',')
      );

      if (conversation && conversation.id) {
        activeMembers = members.filter((member) => member.conversationId === conversation.id);
      }

      if (conversation && conversation.id && activeMembers.length == newMembers.length) {
        this.routerStateService.go(
          [
            {
              outlets: {
                detail: `c/${conversation.id}`,
              },
            },
          ],
          {
            relativeTo: this.route.parent,
          }
        );

        const headers = new HttpHeaders({
          'template-id': template.template.id,
        });

        this.eventStateService.addEvent(event, headers);

        // If current member has this conversation archived then unarchive it.
        const currentMember: Member = members.find((m) => m.userId === this.authenticatedUser.user.id.toString());
        if (currentMember && currentMember.isArchived) {
          currentMember.isArchived = false;
          this.memberStateService.updateIsArchivedForConversation(currentMember);
        }
      } else {
        this.createConversation(event, template.template);
      }
    });
  }

  setMessagePreferenceOverride(messagePreferenceOverride: MessageTransactionMessagePreference) {
    if (messagePreferenceOverride.id) {
      this.conversationStateService.updateMessagePreferenceOverride(messagePreferenceOverride);
    } else {
      this.conversationStateService.createMessagePreferenceOverride({
        ...messagePreferenceOverride,
        transactionTypeId: this.routeParams.transactionTypeId,
        transactionId: this.routeParams.transactionId,
      });
    }
  }
}
