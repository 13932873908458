import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompareObjectsPipe } from './compare-objects.pipe';
import { DeepCopyPipe } from './deep-copy.pipe';
import { EmojiParserPipe } from './emoji-parser.pipe';
import { LinkerPipe } from './linker.pipe';
import { MapSelectionsPipe } from './map-selections.pipe';
import { MomentPipe } from './moment.pipe';
import { NameSafetyPipe } from './name-safety.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { RelativeDatePipe } from './relative-date.pipe';
import { ReversePipe } from './reverse.pipe';
import { InputErrorPipe } from './input-error.pipe';
import { DeepEqualsPipe } from './deep-equals.pipe';
import { UnitOfMeasurePipe } from './unit-of-measure.pipe';
import { AddFreeformMatchingScoreToVehiclesPipe } from './add-freeform-matching-score-to-vehicles.pipe';
import { CodecPipe } from './codec.pipe';
import { SortNamesByPipe } from './sort-names-by.pipe';
import { FilterServiceMediaPipe } from './filter-service-media.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [
    CodecPipe,
    EmojiParserPipe,
    RelativeDatePipe,
    ReversePipe,
    DeepCopyPipe,
    MomentPipe,
    MapSelectionsPipe,
    LinkerPipe,
    PhoneNumberPipe,
    NameSafetyPipe,
    InputErrorPipe,
    DeepEqualsPipe,
    UnitOfMeasurePipe,
    AddFreeformMatchingScoreToVehiclesPipe,
    SortNamesByPipe,
    FilterServiceMediaPipe,
  ],
  declarations: [
    EmojiParserPipe,
    RelativeDatePipe,
    ReversePipe,
    DeepCopyPipe,
    MomentPipe,
    MapSelectionsPipe,
    LinkerPipe,
    CompareObjectsPipe,
    PhoneNumberPipe,
    NameSafetyPipe,
    InputErrorPipe,
    DeepEqualsPipe,
    UnitOfMeasurePipe,
    AddFreeformMatchingScoreToVehiclesPipe,
    CodecPipe,
    SortNamesByPipe,
    FilterServiceMediaPipe,
  ],
})
export class CommonPipesModule {}
