import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventsEffects } from './+state/events.effects';
import { eventsReducer, metaReducers } from './+state/events.reducer';
import { EventsStateService } from './events-state.service';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('events', eventsReducer, {
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([EventsEffects]),
  ],
  providers: [EventsEffects, EventsStateService],
})
export class CommunicatorStateRootEventsModule {}
