import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationStateService, ConversationUi } from '@quorum/communicator/state/conversations';
import { environment } from '@quorum/environments';
import { PresignedS3UrlParameters } from '@quorum/models/xs-misc';
import { CommunicatorContact as Contact, DirectMessageTemplate, Template } from '@quorum/models/xs-resource';
import { CommunicatorDialogEditorComponent } from '@quorum/xsr-template-editor-ng-ui';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line max-len
import { DirectMessageTemplatesDialogComponent } from '../direct-message-templates/direct-message-templates-dialog/direct-message-templates-dialog.component';

export type Attachment = { fileName: string; url: string };

@Component({
  selector: 'com-conversation-editor',
  templateUrl: './conversation-editor.component.html',
  styleUrls: ['./conversation-editor.component.css'],
})
export class ConversationEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Output() keyStrokesEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendMessageEvent: EventEmitter<{ message: string; attachments: Attachment[] }> = new EventEmitter<{
    message: string;
    attachments: Attachment[];
  }>();
  @Output() sendTemplateEvent: EventEmitter<{ attachments: any[]; template: Template }> = new EventEmitter<{
    attachments: Attachment[];
    template: Template;
  }>();
  @Output() isTypingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() currentContact: Contact;
  @Input() placeHolder: string;
  @Input() presetMessage: string;
  @Input() attachments: Attachment[] = [];

  directMessageTemplates: DirectMessageTemplate[];
  directMessageTemplates$: Observable<DirectMessageTemplate[]>;
  files: FileList;
  isTyping = false;
  isTypingTimeout = 5000;
  keyboardEvent$: Subject<void> = new Subject<void>();
  message = '';
  ui$: Observable<ConversationUi>;
  processingAttachments = false;

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private dialog: MatDialog,
    private http: HttpClient,
    private renderer2: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.keyboardEvent$.unsubscribe();
  }

  ngOnInit() {
    this.placeHolder = this.placeHolder || 'Type a message';
    this.ui$ = this.conversationStateService.selectUiState();

    this.directMessageTemplates$ = this.conversationStateService.selectDirectMessageTemplates().pipe(
      tap((templates: DirectMessageTemplate[]) => {
        this.directMessageTemplates = templates;
      })
    );

    this.keyboardEvent$
      .pipe(
        map(() => {
          if (!this.isTyping) {
            this.isTyping = true;
            this.isTypingEmitter.emit(true);
          }
        }),
        debounceTime(5000)
      )
      .subscribe(() => {
        if (!this.isTyping) return;
        this.isTyping = false;
        this.isTypingEmitter.emit(false);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeHolder && !changes.presetMessage) {
      this.message = '';
      this.attachments = [];
      const onElement = this.renderer2.selectRootElement('#textInput');
      onElement.focus();
    }

    if (changes.presetMessage) this.message = changes.presetMessage.currentValue;
  }

  inputBlurEvent() {
    this.isTyping = false;
    this.isTypingEmitter.emit(false);
  }

  keyDownEvent(event: KeyboardEvent) {
    event.keyCode === 13 && !event.shiftKey ? event.preventDefault() : this.keyboardEvent$.next();
  }

  keyUpEvent(event: KeyboardEvent) {
    this.keyStrokesEmitter.emit(this.message);
  }

  selectedEmoji(emojiUnicode: string) {
    this.message = `${this.message} ${emojiUnicode} `;
    this.conversationStateService.toggleEmojiPanel(true);
  }

  sendMessage(message: string, attachments?: Attachment[]) {
    this.isTypingEmitter.emit(false);
    if (message.trim()) this.sendMessageEvent.emit({ message: message, attachments: attachments ? attachments : [] });
    this.message = '';
    this.isTyping = false;
    this.attachments = [];
  }

  showEmojiPanel() {
    this.conversationStateService.toggleEmojiPanel(false);
  }

  hideEmojiPanel() {
    this.conversationStateService.toggleEmojiPanel(true);
  }

  openDialog() {
    this.dialog.open(DirectMessageTemplatesDialogComponent, {
      width: '900px',
      data: { templates: this.directMessageTemplates, currentContact: this.currentContact },
    });
  }

  async uploadFiles(files: FileList): Promise<Attachment[]> {
    if (!files) return;
    this.processingAttachments = true;
    for (let i = 0; i < files.length; i++) {
      const presignedUrl: any = await this.createPresignedUrl(files[i]);
      const url: string = await this.uploadFileToS3(files[i], presignedUrl);
      this.attachments.push({ fileName: files[i].name, url });
    }

    this.processingAttachments = false;
    this.cdr.detectChanges();
    const onElement = this.renderer2.selectRootElement('#textInput');
    onElement.focus();
    return this.attachments;
  }

  async createPresignedUrl(file: File): Promise<any> {
    const fileName = `${uuid()}/${file.name}`;
    const presignedUrlParams: PresignedS3UrlParameters = {
      bucket: environment.s3AttachmentsBucket,
      filename: fileName,
      expires: 300,
      metadata: { userId: this.currentContact.id.toString(), storeId: this.currentContact.storeId.toString() },
      tags: {
        'xs:user-id': this.currentContact.id.toString(),
        'xs:store-id': this.currentContact.storeId.toString(),
        'xs:application': 'communicator',
      },
    };

    return this.http
      .post(environment.s3CreatePresignedUrl, presignedUrlParams)
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async uploadFileToS3(file: File, presignedUrl: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.entries(presignedUrl.fields).forEach(([k, v]: any) => {
        formData.append(k, v);
      });
      formData.append('file', file, presignedUrl.fields.key); // must be the last item in formData object or AWS won't accept it.
      this.http
        .post(presignedUrl.url, formData)
        .toPromise()
        .then((data) => {
          resolve(`${presignedUrl.fields.key}`.replace(' ', '+'));
        }) // do nothing
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeFile(attachment: Attachment) {
    const attachmentIndex: number = this.attachments.findIndex((a) => a.fileName == attachment.fileName);
    if (attachmentIndex >= 0) this.attachments.splice(attachmentIndex, 1);
  }

  openEmailTemplateDialog() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        map((authUser) => authUser.user)
      )
      .subscribe((currentUser) => {
        this.apiService.get(`/quorum/stores/${currentUser.storeId}`).subscribe((response) => {
          if (response.useCommunicatorCloudServices) {
            this.apiService
              .get(`v/1/quorum/templates/store-gallery/${currentUser.destination}`, {
                params: { messageTypeId: 18 },
              })
              .subscribe((templates) => {
                const dialogRef = this.dialog.open(CommunicatorDialogEditorComponent, {
                  data: {
                    attachments: this.attachments,
                    currentUser: currentUser,
                    messageType: 'email',
                    templates: templates,
                  },
                  height: '100%',
                  width: '100%',
                });

                dialogRef.afterClosed().subscribe((dialogResult) => {
                  if (dialogResult) {
                    const payload = {
                      ...dialogResult.template,
                      selectedLanguage: dialogResult.template.selectedLanguage,
                    };
                    this.apiService
                      .post(`v/1/quorum/templates/item-specific/${currentUser.destination}`, payload)
                      .subscribe((itemSpecificTemplate: Template) => {
                        this.sendTemplateEvent.emit({
                          attachments: dialogResult.attachments ? dialogResult.attachments : null,
                          template: itemSpecificTemplate,
                        });
                        this.attachments = [];
                      });
                  }
                });
              });
          }
        });
      });
  }
}
