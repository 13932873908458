<div fxFlexFill fxLayout="column">
  <com-new-conversation-header [preselectedCustomerId]="routeParams.customerAssociateId"></com-new-conversation-header>
  <div fxFlex="row">
    <div fxFlex fxLayout="column">
      <ng-container *ngIf="newConversation; then newConversationTemplate; else emptyConversation"> </ng-container>
      <ng-template #newConversationTemplate>
        <com-conversation-body [conversation]="newConversation" [members]="members" fxFlex></com-conversation-body>
      </ng-template>
      <ng-template #emptyConversation>
        <div fxLayout="column" fxFlex>
          <ng-container *ngIf="showMessagePreferenceOverride">
            <div fxLayout="column" *ngIf="messagePreferenceOverride$ | async as messagePreferenceOverride">
              <h3
                fxLayout="row"
                fxLayoutAlign="start center"
                style="color: rgba(0, 0, 0, 0.54); font-size: 14px; font-weight: 500; margin-bottom: 0"
                class="mat-subheader"
              >
                Message Preference Override
              </h3>

              <form fxLayout="column" style="padding: 0 16px 16px">
                <mat-form-field appearance="outline">
                  <mat-label>Message Preference</mat-label>
                  <mat-select
                    name="messagePreferenceId"
                    [(ngModel)]="messagePreferenceOverride.value.messagePreferenceId"
                  >
                    <mat-option [value]="1"> Email </mat-option>
                    <mat-option [value]="2"> Text </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label
                    >{{
                      messagePreferenceOverride.value.messagePreferenceId == 1
                        ? 'Email Address'
                        : 'Phone
                    Number'
                    }}
                  </mat-label>
                  <input
                    name="messagePreferenceContactInfo"
                    [(ngModel)]="messagePreferenceOverride.value.messagePreferenceContactInfo"
                    matInput
                    placeholder=""
                  />
                </mat-form-field>
                <div fxLayoutAlign="space-around center">
                  <button
                    color="primary"
                    style="border-radius: 24px"
                    mat-raised-button
                    [disabled]="messagePreferenceOverride.isLoading"
                    (click)="setMessagePreferenceOverride(messagePreferenceOverride.value)"
                  >
                    Set
                  </button>
                </div>
              </form>
              <mat-divider></mat-divider>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <com-conversation-editor
        (sendMessageEvent)="sendMessage($event)"
        (sendTemplateEvent)="sendTemplate($event)"
        [attachments]="attachments"
        [currentContact]="currentContact"
        *ngIf="currentContact$ | async as currentContact"
      >
      </com-conversation-editor>
    </div>
  </div>
</div>
