import { ComponentPortal, DomPortalHost, DomPortalOutlet } from '@angular/cdk/portal';
import { HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EventTypes, SocketEventTypes } from '@models/server-common';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser, TokenUser } from '@quorum/authentication/state';
import { CommunicatorSocketService } from '@quorum/com-sockets/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { Conversation, ConversationStateService, ConversationUi } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { MembersStateService } from '@quorum/communicator/state/members';
import { UserTyping } from '@quorum/models/xs-misc';
import { CommunicatorContact as Contact, Event, Member, Template } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
// eslint-disable-next-line max-len
import { ConversationExtendedWarrantyComponent } from '../conversation-extended-warranty/conversation-extended-warranty.component';
// eslint-disable-next-line max-len
import { ConversationPartsRequestComponent } from '../conversation-parts-request/conversation-parts-request.component';
// eslint-disable-next-line max-len
import { ConversationServiceQuoteAdvisorReadyComponent } from '../conversation-service-quote-advisor-ready/conversation-service-quote-advisor-ready.component';
// eslint-disable-next-line max-len
import { ConversationServiceQuotePartsPricingComponent } from '../conversation-service-quote-parts-pricing/conversation-service-quote-parts-pricing.component';

export type Attachment = { fileName: string; url: string };

@Component({
  selector: 'com-conversation-shell',
  templateUrl: './conversation-shell.component.html',
  styleUrls: ['./conversation-shell.component.css'],
})
export class ConversationShellComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  contacts: Array<Contact>;
  currentContact: Contact;
  currentContact$: Observable<Contact>;
  conversation: Conversation;
  currentUser: TokenUser;
  members: Array<Member>;
  editorEnabled: boolean = false;
  presetMessage: string;
  paramsSubscription$: Subscription;
  placeHolder: string;
  ui$: Observable<ConversationUi>;
  unsentMessage: { conversationId: number; message: string } = { conversationId: 0, message: '' };
  title: string;
  transactionDescription: string;
  attachments: Attachment[] = [];

  portalHost: DomPortalHost;
  portal: ComponentPortal<
    | ConversationPartsRequestComponent
    | ConversationExtendedWarrantyComponent
    | ConversationServiceQuotePartsPricingComponent
    | ConversationServiceQuoteAdvisorReadyComponent
  >;
  componentRef: ComponentRef<
    | ConversationPartsRequestComponent
    | ConversationExtendedWarrantyComponent
    | ConversationServiceQuoteAdvisorReadyComponent
    | ConversationServiceQuotePartsPricingComponent
  >;

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private communicatorSocketService: CommunicatorSocketService,
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private eventStateService: EventsStateService,
    private membersStateService: MembersStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.ui$ = this.conversationStateService.selectUiState();

    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((authenticatedUser: AuthenticatedUser) => {
          this.currentUser = authenticatedUser.user;
        })
      )
      .subscribe();

    this.routerStateService
      .selectRouterState()
      .pipe(
        take(1),
        map((state) => state.state.root.queryParams)
      )
      .subscribe((queryParams) => {
        if (queryParams.attachmentGuid) {
          const guids = queryParams.attachmentGuid.split(',');
          guids.forEach((guid: string) => {
            this.apiService
              .get(`/communicator/attachment-check/${guid}`)
              .pipe(map((result) => result.url))
              .subscribe((url) => {
                this.attachments.push({
                  fileName: url.split('/[#?]/')[0].split('/').pop().trim(),
                  url: url,
                });
              });
          });
        }
      });

    this.paramsSubscription$ = this.route.params
      .pipe(
        switchMap((params: Params) => {
          return combineLatest(
            this.contactsStateService.selectContacts(),
            this.membersStateService.selectMembers(+params['id']),
            this.conversationStateService.selectConversation(+params['id']),
            this.conversationStateService.selectUnsentMessages().pipe(take(1))
          ).pipe(
            map(([contacts, members, conversation, unsentMessages]) => {
              this.contacts = contacts;
              this.conversation = conversation;
              this.addUnsentMessageToState(+params['id']);
              this.currentContact = this.contacts.find((c) => c.id === this.currentUser.id);
              this.members = members;
              members.filter((m: Member) => {
                if (m.userId === this.currentUser.id.toString() && !m.isRead) {
                  this.membersStateService.updateIsReadForConversation(new Member({ ...m, isRead: !m.isRead }));
                }
              });

              let membershipCheck = members.find(
                (member) => member.userId === this.currentUser.id.toString() && !member.isExcluded
              );
              this.editorEnabled = membershipCheck ? true : false;

              this.title = this.generateTitle(conversation, this.members, this.currentUser.id.toString());
              this.transactionDescription = this.generateTransactionDescription(conversation, this.members);
              this.placeHolder = this.generatePlaceHolder(conversation, this.members, this.currentUser.id.toString());
              this.getUnsentMessageForConversation(unsentMessages, +params['id']);
              this.createActionsComponentPortal();
              if (this.componentRef) {
                this.componentRef.instance.conversation = this.conversation;
              }
            })
          );
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.createActionsComponentPortal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.members && changes.members.currentValue && changes.members.currentValue.length > 0) {
      this.title = this.generateTitle(this.conversation, this.members, this.currentUser.id.toString());
      this.transactionDescription = this.generateTransactionDescription(this.conversation, this.members);
      this.placeHolder = this.generatePlaceHolder(this.conversation, this.members, this.currentUser.id.toString());
    }
  }

  addUnsentMessageToState(paramsConversationId: number) {
    if (this.unsentMessage && !this.unsentMessage.message && this.unsentMessage.conversationId === paramsConversationId)
      return;
    if (this.unsentMessage.message.length !== 0)
      this.conversationStateService.addUnsentMessage(this.unsentMessage.conversationId, this.unsentMessage.message);
    else this.conversationStateService.deleteUnsentMessage(this.unsentMessage.conversationId);

    this.presetMessage = this.unsentMessage.message = '';
  }

  clearUnsentMessage(conversationId: number) {
    this.conversationStateService.deleteUnsentMessage(conversationId);
    this.unsentMessage.message = '';
    this.presetMessage = '';
  }

  getUnsentMessageForConversation(unsentMessages: { [conversationId: number]: string }, conversationId: number) {
    if (this.unsentMessage && this.unsentMessage.conversationId === conversationId) return;
    this.presetMessage = unsentMessages && unsentMessages[conversationId] ? unsentMessages[conversationId] : '';
    this.unsentMessage = {
      conversationId,
      message: this.presetMessage,
    };
  }

  generateTitle(conversation: Conversation, members: Array<Member>, currentUserId: string) {
    if (!conversation) return;
    const customer: Member = members.find((m: Member) => !m.isExcluded && !m.isEmployee);
    if (customer) {
      return customer.lastName ? `${customer.firstName} ${customer.lastName}` : `${customer.firstName}`;
    } else if (conversation.title) {
      return conversation.title;
    } else {
      return members
        .filter((m: Member) => m.firstName.toLowerCase() !== 'communicator')
        .filter((m: Member) => !m.isExcluded)
        .filter((m: Member, index: number, arr: Member[]) => {
          return arr.length === 1 ? m : m.userId !== currentUserId.toString();
        })
        .map((m: Member) => m.firstName)
        .join(', ');
    }
  }

  generateTransactionDescription(conversation: Conversation, members: Array<Member>) {
    if (!conversation) return;
    const customer: Member = members.find((m: Member) => !m.isExcluded && !m.isEmployee);
    if (customer && conversation.dmsTransactionTypeId !== 10) return conversation.title;
    else return null;
  }

  generatePlaceHolder(conversation: Conversation, members: Array<Member>, currentUserId: string) {
    if (!conversation) return;

    const customer: Member = members.find((m: Member) => !m.isExcluded && !m.isEmployee);

    if (customer) {
      return `customer ${customer.firstName ? customer.firstName : ''} ${customer.lastName ? customer.lastName : ''}`;
    } else {
      return conversation.title
        ? conversation.title
        : members
            .filter((m: Member) => m.userId !== currentUserId.toString() && !m.isExcluded)
            .filter((m: Member) => m.firstName.toLowerCase() !== 'communicator')
            .map((m: Member) => `${m.firstName} ${m.lastName}`)
            .join(', ');
    }
  }

  keyStrokes(message: string) {
    this.unsentMessage = { conversationId: this.conversation.id, message };
  }

  sendMessage(sentMessage: { message: string; attachments: Attachment[] }, conversation: Conversation) {
    const event: Event = this.eventStateService.createEvent(
      sentMessage.message,
      conversation.id,
      EventTypes.Message,
      this.currentUser,
      this.members.find((member) => !member.isEmployee) ? true : false,
      sentMessage.attachments.map((a) => a.url).join(',')
    );
    this.eventStateService.addEvent(event);
    this.clearUnsentMessage(conversation.id);
  }

  sendTemplate(template: { attachments: Attachment[]; template: Template }, conversation: Conversation) {
    const event: Event = this.eventStateService.createEvent(
      'Generating email...',
      conversation.id,
      EventTypes.Message,
      this.currentUser,
      this.members.find((member) => !member.isEmployee) ? true : false,
      template.attachments.map((a) => a.url).join(',')
    );

    const headers = new HttpHeaders({
      'template-id': template.template.id,
    });
    this.eventStateService.addEvent(event, headers);
    this.clearUnsentMessage(conversation.id);
  }

  typing(isTyping: boolean, conversation: Conversation, members: Member[]) {
    const userTyping: UserTyping = {
      conversationId: conversation.id,
      isTyping: isTyping,
      member: members.find((m) => m.userId === this.currentUser.id.toString()),
      userIds: this.members
        .filter((m: Member) => !m.isArchived)
        .filter((m: Member) => !m.isExcluded)
        .filter((m: Member) => {
          if (m.isEmployee && m.userId !== this.currentUser.id.toString()) return m;
        })
        .map((m: Member) => Number(m.userId)),
    };
    this.communicatorSocketService.emit(SocketEventTypes.ISTYPING, JSON.stringify(userTyping));
  }

  ngOnDestroy() {
    this.paramsSubscription$.unsubscribe();
  }

  createActionsComponentPortal() {
    let actionsComponent: any;
    if (this.componentRef) this.componentRef.destroy();

    if (this.conversation && [8, 11, 13, 14].includes(this.conversation.dmsMessageTypeId)) {
      switch (this.conversation.dmsMessageTypeId) {
        case 8: // Parts Request Message
          actionsComponent = ConversationPartsRequestComponent;
          break;
        case 11: // Extended Warranty Notification Message
          actionsComponent = ConversationExtendedWarrantyComponent;
          break;
        case 13: // Service Quote Parts Pricing Required Message
          actionsComponent = ConversationServiceQuotePartsPricingComponent;
          break;
        case 14: // Service Quote Ready For Advisor Message
          actionsComponent = ConversationServiceQuoteAdvisorReadyComponent;
          break;
        default:
          break;
      }
      // Create a portalHost from a DOM element
      this.portalHost = new DomPortalOutlet(
        document.querySelector('#conversationActions'),
        this.componentFactoryResolver,
        this.appRef,
        this.injector
      );

      if (!this.portalHost.outletElement) return;

      // Locate the component factory for the HeaderComponent
      this.portal = new ComponentPortal(actionsComponent);

      // Attach portal to host
      this.componentRef = this.portalHost.attach(this.portal);
      this.componentRef.instance.conversation = this.conversation;

      this.componentRef.instance.send.subscribe((event: Event) => {
        this.eventStateService.addEvent(event);
      });

      this.componentRef.changeDetectorRef.detectChanges();
    }
  }
}
