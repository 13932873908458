import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromRouter from './+state/router.actions';
import { routerFeatureSelector, routerStateSelector } from './+state/router.interface';

@Injectable()
export class RouterStateService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(private store: Store<any>, private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  go(path: any, extras: any = null) {
    this.store.dispatch(
      new fromRouter.Go({
        path: path,
        query: {},
        extras: extras,
      })
    );
  }

  getFullTreeParams(route: ActivatedRoute, params = {}): any {
    if (route) {
      for (const paramName of route.snapshot.paramMap.keys) {
        params = { ...params, ...{ [paramName]: route.snapshot.paramMap.get(paramName) } };
      }
    }
    return route.children.length !== 0 ? this.getFullTreeParams(route.children[0], params) : params;
  }

  selectRouterFeatureState() {
    return this.store.pipe(select(routerFeatureSelector));
  }

  selectRouterState() {
    return this.store.pipe(select(routerStateSelector));
  }
}
