import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) { }

  showSnackbar(snackbarMessage: string, snackbarButtonText: string, snackbarConfig: MatSnackBarConfig) {
    this.snackBar.open(snackbarMessage, snackbarButtonText, snackbarConfig);
  }

  showCustomSnackbar(customSnackbarComponent: ComponentType<any>, snackbarConfig: MatSnackBarConfig) {
    this.snackBar.openFromComponent(
      customSnackbarComponent,
      snackbarConfig
    );
  }
}
