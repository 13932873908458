export class Store {
  apiKey: string = null;
  apiUrl?: string = null;
  communicatorUrl?: string = null;
  createdById: number = null;
  databaseName: string = null;
  databaseServer: string = null;
  dataTerminalId: number = null;
  dealerCode: string = null;
  embedded?: any;
  id: number = null;
  name: string = null;
  utcCreated: Date = null;
  version: string = null;
  xselleratorIdentifier: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export const storeMock = new Store({
  apiKey: '1234Secret',
  createdById: 1000,
  databaseName: 'dealer',
  databaseServer: 'xsw2k12ds',
  dataTerminalId: 1,
  dealerCode: '84620',
  embedded: null,
  id: 1000,
  name: 'xyzCorp',
  utcCreated: new Date(),
  version: 'beta',
  xselleratorIdentifier: 'ZZZ123',
});
