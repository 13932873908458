import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { CommunicatorContact as Contact, DirectMessageTemplate } from '@quorum/models/xs-resource';
import { TwoActionDialogComponent, TwoActionDialogData } from '@quorum/sha-components-ng-ui';

@Component({
  selector: 'com-direct-message-templates-list',
  templateUrl: './direct-message-templates-list.component.html',
  styleUrls: ['./direct-message-templates-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectMessageTemplatesListComponent implements OnInit {
  @Input() templates: DirectMessageTemplate[];
  @Input() currentContact: Contact;

  isDisabled: boolean = true;
  templatesEditable: DirectMessageTemplate[];

  constructor(
    private conversationStateService: ConversationStateService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {}

  @ViewChild('message') autosize: CdkTextareaAutosize;

  ngOnInit() {
    this.templatesEditable = new DeepCopyPipe().transform(this.templates);
  }

  delete(template: DirectMessageTemplate) {
    const dialogData: TwoActionDialogData = {
      actionTwoCaption: 'Delete',
      actionOneCaption: 'Cancel',
      content: 'This template will be permanently removed from your list.',
      title: 'Delete Template?',
    };

    const dialogRef = this.dialog.open(TwoActionDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.templatesEditable.splice(
          this.templatesEditable.findIndex((t) => t.id === template.id),
          1
        );
        this.ref.detectChanges();
      }

      if (template.id) this.conversationStateService.deleteDirectMessageTemplate(template);
    });
  }

  edit(isDisabled: boolean) {
    this.isDisabled = !isDisabled;
    if (!isDisabled) this.update(this.templatesEditable);
    else this.templatesEditable = new DeepCopyPipe().transform(this.templates);
  }

  update(templates: DirectMessageTemplate[]) {
    this.templatesEditable.forEach((template) => {
      if (!template.id) {
        this.add(template);
      } else if (Object.is(template.templateName, this.templates.find((t) => t.id === template.id).templateName)) {
        // update if changed.  Object.is doesn't appear to be working as intended....TODO: fix
        this.conversationStateService.updateDirectMessageTemplate(template);
      }
    });
  }

  add(template: DirectMessageTemplate) {
    this.conversationStateService.postDirectMessageTemplate(template);
  }

  new() {
    //TODO: fix reference to department, will be embedded.employee.departmentId when PR 1191 is merged.
    this.templatesEditable.push(
      new DirectMessageTemplate({
        employeeId: this.currentContact.xselleratorEmployeeId,
        //departmentId: this.currentContact.department
      })
    );
  }

  trackByFn(index: number, group: any) {
    return group[0];
  }

  trackByTemplateFn(index: number, template: DirectMessageTemplate) {
    return template.id;
  }
}
