import { ActionReducer, MetaReducer } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ContactPresence } from '@quorum/models/xs-misc';
import { CommunicatorContact as Contact } from '@quorum/models/xs-resource';
import * as fromContacts from './contacts.actions';
import { ContactsAction } from './contacts.actions';
import { contactsInitialState } from './contacts.init';
import { ContactsState } from './contacts.interfaces';

export function contactsReducer(state: ContactsState, action: ContactsAction): ContactsState {
  switch (action.type) {
    case fromContacts.DELETE_CONTACT_PRESENCE: {
      const presences: any = Object.assign({}, state.presences);
      delete presences[action.payload.id];
      return { ...state, presences: presences };
    }
    case fromContacts.LOAD_CONTACTS: {
      return { ...state, isLoading: true };
    }
    case fromContacts.LOAD_CONTACTS_SUCCESS: {
      const contacts: Contact[] = new DeepCopyPipe().transform(state.contacts);
      action.payload.forEach((contact: Contact) => {
        if (!contacts.find((c) => c.id == contact.id)) contacts.push(contact);
      });
      return { ...state, contacts: contacts, isLoading: false };
    }
    case fromContacts.LOAD_CONTACTS_FAILURE: {
      return { ...state, errors: action.payload, isLoading: false };
    }
    case fromContacts.LOAD_CONTACTS_PRESENCE: {
      let presences: any = {};
      action.payload.forEach((presence: ContactPresence) => {
        presences = { ...presences, [presence.id]: presence };
      });
      return { ...state, presences: presences };
    }
    case fromContacts.RESET_CONTACTS_STATE: {
      return contactsInitialState;
    }
    case fromContacts.UPDATE_CONTACT_PRESENCE: {
      return {
        ...state,
        presences: { ...state.presences, [action.payload.id]: action.payload },
      };
    }
    case fromContacts.UPDATE_CONTACT_GROUP_CONFIGURATION: {
      return {
        ...state,
        groupConfiguration: { ...state.groupConfiguration, [action.payload.id]: action.payload },
      };
    }
    default: {
      return state;
    }
  }
}

export function contactstateHydration(reducer: ActionReducer<ContactsState>): ActionReducer<ContactsState> {
  return function (state: ContactsState, action: any, metaReducer: string = 'stateHydration'): ContactsState {
    if (state === undefined) {
      let contactsState: any = localStorage.getItem('contacts-state');
      state = contactsState ? JSON.parse(contactsState) : contactsInitialState;
    }

    const newState = reducer(state, action);
    if (action.feature === 'contacts' && action.triggerStorageSync) {
      localStorage.setItem('contacts-state', JSON.stringify(newState));
    }

    return newState;
  };
}

export const metaReducers: MetaReducer<ContactsState>[] = [contactstateHydration];
