import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Member } from '@quorum/models/xs-resource';

@Component({
  selector: 'com-typing-indicator',
  templateUrl: './typing-indicator.component.html',
  styleUrls: ['./typing-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypingIndicatorComponent implements OnInit {
  @Input() members: Member[] = null;

  constructor() {}

  ngOnInit() {}
}
