import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { filter, take } from 'rxjs/operators';
import { environment } from '@quorum/environments';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  authenticatedUser: AuthenticatedUser;

  constructor(private authenticationService: AuthenticationStateService, private router: Router) {
    this.authenticationService
      .selectAuthenticatedUser()
      .pipe(
        filter((authenticatedUser) => authenticatedUser != null),
        take(1)
      )
      .subscribe((authenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
        if (environment.environment !== 'development') {
          datadogRum.init({
            applicationId: 'fd8cb2df-8c80-42ad-afac-514adba4ae85',
            clientToken: 'pubd91ea400d432fadde89b5bb992a43618',
            site: 'datadoghq.com',
            service: 'communicator',
            env: `${environment.environment}`,
            version: `${environment.version}`,
            sampleRate: 100,
            trackInteractions: true,
            allowedTracingOrigins: [`${environment.apiBase}`],
          });

          datadogRum.setUser({
            id: authenticatedUser.user.id.toString(),
            name: `${authenticatedUser.user.firstName} ${authenticatedUser.user.lastName}`,
            xselleratorAssociateId: authenticatedUser.user.xselleratorEmployeeId,
            destinationId: authenticatedUser.user.destination,
            storeId: authenticatedUser.user.storeId,
            storeName: authenticatedUser.user.storeName,
          });
        }
      });
  }

  ngOnInit() {}
}
