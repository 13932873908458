import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommunicatorSettingsComponent } from './communicator-settings/communicator-settings.component';

@NgModule({
  imports: [CommonModule, CommonMaterialComponentsModule, FlexLayoutModule],
  declarations: [CommunicatorSettingsComponent],
})
export class CommunicatorUiNgSettingsModule {}
