export class XselleratorConversation {
  oemRooftopId: number;
  customerId: string;
  transactionTypeId: number;
  transactionId: string;
  automatedMessageTypeId: number;
  attachmentsPathList?: string[];
  message?: string;
  cloudConversationId?: number;
  senderId: string;
  emailTemplateId?: number;
  emailTemplateLanguageId?: string;
  subject?: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

export const mockXselleratorConversation = new XselleratorConversation({
  oemRooftopId: 1,
  customerId: '13',
  transactionTypeId: 1,
  transactionId: '11',
  automatedMessageTypeId: 1,
  attachmentsPathList: null,
  message: 'message',
  cloudConversationId: 0,
  senderId: '14',
  emailTemplateId: 0,
  emailTemplateLanguageId: 'EN',
  subject: 'Test subject',
  sourceId: 1,
});

export function getXselleratorConversationMock() {
  return new XselleratorConversation({
    oemRooftopId: 1,
    customerId: '13',
    transactionTypeId: 1,
    transactionId: '11',
    automatedMessageTypeId: 1,
    attachmentsPathList: null,
    message: 'message',
    cloudConversationId: 0,
    senderId: '14',
    emailTemplateId: 0,
    emailTemplateLanguageId: 'EN',
    subject: 'Test subject',
  });
}

export const xselleratorConversationsMock: XselleratorConversation[] = [
  mockXselleratorConversation,
  new XselleratorConversation({
    oemRooftopId: 1,
    customerId: '13',
    transactionTypeId: 1,
    transactionId: '11',
    automatedMessageTypeId: 1,
    attachmentsPathList: null,
    message: 'message',
    cloudConversationId: 0,
    senderId: '14',
    emailTemplateId: 0,
    emailTemplateLanguageId: 'EN',
    subject: 'Test subject',
  }),
];
