import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { TemplateDetailQueryParameters } from '@quorum/models/xs-query';
import { Template, TemplateDetail } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { AuthenticatedUser } from 'libs/authentication/src/lib/+state/authentication.interfaces';
import { take } from 'rxjs/operators';

@Component({
  selector: 'quorum-core-body-editor',
  templateUrl: './core-body-editor.component.html',
  styleUrls: ['./core-body-editor.component.scss'],
})
export class CoreBodyEditorComponent implements OnInit {
  originalTemplate: Template;
  generatedTemplate: Template;
  sourceTemplateId: number;
  sourceTemplateTaskId: number;
  action: string;
  taskType: string;
  keys: string;
  user: AuthenticatedUser;

  constructor(
    private route: ActivatedRoute,
    private authenticationStateService: AuthenticationStateService,
    private apiService: ApiService,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit(): void {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.route.queryParams.pipe(take(1)).subscribe((queryParams) => {
          this.action = queryParams.action;
          this.taskType = queryParams.taskType;
          this.keys = queryParams.keys;
          this.user = user;
          this.sourceTemplateId = queryParams.sourceTemplateId;
          this.sourceTemplateTaskId = queryParams.sourceTemplateTaskId;

          if (this.action === 'create') {
            this.apiService
              .get(
                `v/1/quorum/templates/store-gallery/${user.user.destination}/${
                  this.routerStateService.getFullTreeParams(this.route).templateId
                }`
              )
              .subscribe((template) => {
                this.originalTemplate = template;
              });
          } else {
            this.apiService
              .get(
                `v/1/quorum/templates/item-specific/${user.user.destination}/${
                  this.routerStateService.getFullTreeParams(this.route).templateId
                }`
              )
              .subscribe((template) => {
                this.originalTemplate = template;
              });
          }
        });
      });
  }

  save(
    user: AuthenticatedUser,
    template: Template,
    action: string,
    taskType: string,
    keys: string,
    sourceTemplateId: number,
    sourceTemplateTaskId: number
  ) {
    if (this.action === 'create') {
      this.apiService
        .post(`v/1/quorum/templates/item-specific/${user.user.destination}`, template)
        .subscribe((itemSpecificTemplate: Template) => {
          this.apiService
            .get(`v/1/crm/template-headers/${sourceTemplateId}/template-details/${sourceTemplateTaskId}`)
            .subscribe((templateDetail: TemplateDetail) => {
              templateDetail.communicatorTaskSpecificTemplate = itemSpecificTemplate.id;

              let params: TemplateDetailQueryParameters = new TemplateDetailQueryParameters({
                syncExistingTasksAsEmployeeId: user.user.xselleratorEmployeeId,
              });
              this.apiService
                .put(
                  `v/1/crm/template-headers/${sourceTemplateId}/template-details/${sourceTemplateTaskId}`,
                  templateDetail,
                  { params: params }
                )
                .subscribe((templateDetail) => {
                  console.log(templateDetail);
                });
            });
        });
    } else {
      this.apiService
        .put(
          `v/1/quorum/templates/item-specific/${user.user.destination}/${
            this.routerStateService.getFullTreeParams(this.route).templateId
          }`,
          template
        )
        .subscribe((response) => {
          console.log(response);
        });
    }
  }
}
