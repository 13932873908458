import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticationState, AuthenticationStatus, fromAuthentication } from '@quorum/authentication/state';
import { CommunicatorSocketService } from '@quorum/com-sockets/services';
import { fromContacts } from '@quorum/communicator/state/contacts';
import { fromConversations } from '@quorum/communicator/state/conversations';
import { fromEvents } from '@quorum/communicator/state/events';
import { fromMembers } from '@quorum/communicator/state/members';
import { ShellComponent, TransactionSummaryComponent } from '@quorum/communicator/ui/ng/root';
import { ConversationQueryParameters, DirectMessageTemplateQueryParameters } from '@quorum/models/xs-query';
import { Employee } from '@quorum/models/xs-resource';
import { fromRouter, Go } from '@quorum/sha-router';
import { SocketStateService } from '@quorum/sockets/services';
import { fromSockets, SocketConnectionInfo } from '@quorum/sockets/state';
import { forkJoin, from, of, race } from 'rxjs';
import { delay, filter, map, mergeAll, switchMap, take } from 'rxjs/operators';
@Injectable()
export class CommunicatorEffects {
  currentCustomerSummaryAssociateId: string = null;

  go = createEffect(
    () =>
      this.d.pessimisticUpdate(fromRouter.NAVIGATE, {
        run: (m: Go, state: any) => {
          this.router.navigate(m.payload.path, m.payload.extras);
        },
        onError: (action: Go, err) => {}
      }),
    { dispatch: false }
  );

  goToRoot = createEffect(
    () =>
      this.d.pessimisticUpdate(fromRouter.NAVIGATE_TO_ROOT, {
        run: (m: Go, state: any) => {
          this.router.navigate(['']);
        },
        onError: (action: Go, err) => {}
      }),
    { dispatch: false }
  );

  logout = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.LOGOUT, {
      run: (a: fromAuthentication.Logout, state: AuthenticationState) => {
        this.communicatorSocketService.disconnect();
        return from([
          new fromEvents.ResetEventState({}),
          new fromMembers.ResetMemberState({}),
          new fromConversations.ResetConversationState({}),
          new fromContacts.ResetContactsState({})
        ]);
      },
      onError: (a: fromAuthentication.Logout, error) => {}
    })
  );

  loadShellComponent$ = createEffect(() =>
    this.d.navigation(ShellComponent, {
      run: (a: ActivatedRouteSnapshot, state: any) => {
        return race(
          this.router.events.pipe(
            filter((events) => {
              return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
            }),
            map((event) => {
              return { action: 'finished', event };
            })
          ),
          this.router.events.pipe(
            filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
            map((event) => {
              return { action: 'cancelled', event };
            })
          )
        ).pipe(
          switchMap((event: any) => {
            if (event.action === 'cancelled') {
              return from([]);
            } else {
              const actions: any = [];

              const socketInfo = state?.sockets?.socketConnectionInfos?.find(
                (s: SocketConnectionInfo) => s.service === 'communicator'
              );

              if (socketInfo && !socketInfo.isConnected) {
                actions.push(new fromSockets.CreateSocketConnection({}));
              }

              return of(actions).pipe(mergeAll());
            }
          })
        );
      },
      onError: (a: ActivatedRouteSnapshot, e: any) => {
        console.log(e);
        // we can log and error here and return null
        // we can also navigate back
        return null;
      }
    })
  );

  loadCustomerSummaryComponent$ = createEffect(() =>
    this.d.navigation(TransactionSummaryComponent, {
      run: (a: ActivatedRouteSnapshot, state: any) => {
        return race(
          this.router.events.pipe(
            filter((events) => {
              return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
            }),
            map((event) => {
              return { action: 'finished', event };
            })
          ),
          this.router.events.pipe(
            filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
            map((event) => {
              return { action: 'cancelled', event };
            })
          )
        ).pipe(
          switchMap((event: any) => {
            if (event.action === 'cancelled') {
              return from([]);
            } else {
              return forkJoin([
                this.authenticationStateService.selectAuthenticatedEmployee().pipe(take(1)),
                this.authenticationStateService.selectAuthenticatedUser().pipe(take(1))
              ]).pipe(
                switchMap(([authenticatedEmployee, authenticatedUser]) => {
                  const actions: any = [];
                  const socketInfo = state?.sockets?.socketConnectionInfos?.find(
                    (s: SocketConnectionInfo) => s.service === 'communicator'
                  );

                  if (socketInfo && !socketInfo.isConnected) {
                    actions.push(new fromSockets.CreateSocketConnection({}));
                  }

                  if (authenticatedEmployee) {
                    const directMessageParams: DirectMessageTemplateQueryParameters =
                      new DirectMessageTemplateQueryParameters({
                        departmentId: authenticatedEmployee.departmentId,
                        employeeId: authenticatedUser.user.xselleratorEmployeeId
                      });
                    actions.push(new fromConversations.GetDirectMessageTemplatesFromServer(directMessageParams));
                  }

                  if (this.currentCustomerSummaryAssociateId != a.params.customerAssociateId) {
                    this.currentCustomerSummaryAssociateId = a.params.customerAssociateId;
                    const params: ConversationQueryParameters = new ConversationQueryParameters({
                      customerId: a.params.customerAssociateId,
                      embed: 'lastEvent, messageType',
                      page: 0,
                      pageSize: 250
                    });
                    actions.push(
                      new fromConversations.GetConversationsFromServer({
                        queryParameters: params,
                        displayNotification: false
                      })
                    );
                  }

                  return from(actions);
                })
              );
            }
          })
        );
      },
      onError: (a: ActivatedRouteSnapshot, e: any) => {
        console.log(e);
        // we can log and error here and return null
        // we can also navigate back
        return null;
      }
    })
  );

  logoutError = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.LOGOUT_ERROR, {
      run: (a: fromAuthentication.Logout, state: AuthenticationState) => {
        this.communicatorSocketService.disconnect();
        return from([
          new fromEvents.ResetEventState({}),
          new fromMembers.ResetMemberState({}),
          new fromConversations.ResetConversationState({}),
          new fromContacts.ResetContactsState({})
        ]);
      },
      onError: (a: fromAuthentication.Logout, error) => {}
    })
  );

  userAuthenticated = createEffect(() =>
    this.d.fetch(fromAuthentication.USER_AUTHENTICATED, {
      run: (a: fromAuthentication.UserAuthenticated, state: AuthenticationState) => {
        return from([
          new fromAuthentication.UpdateAuthenticationStages({
            id: 1,
            changes: { authenticationStatus: AuthenticationStatus.completed }
          }),
          new fromAuthentication.GetEmployeeFromServer(a.payload.user)
        ]).pipe(delay(500));
      },
      onError: (a: fromAuthentication.UserAuthenticated, error) => {
        return new fromAuthentication.UserAuthenticatedError(error);
      }
    })
  );

  userAuthenticatedError = createEffect(() =>
    this.d.fetch(fromAuthentication.USER_AUTHENTICATED_ERROR, {
      run: (a: fromAuthentication.UserAuthenticatedError, state: AuthenticationState) => {
        this.communicatorSocketService.disconnect();
        return from([
          new fromEvents.ResetEventState({}),
          new fromMembers.ResetMemberState({}),
          new fromConversations.ResetConversationState({}),
          new fromContacts.ResetContactsState({})
        ]);
      },
      onError: (a: fromAuthentication.UserAuthenticatedError, error) => {
        console.log('Error during user authenticated error effect.');
      }
    })
  );

  getEmployeeFromServer = createEffect(() =>
    this.d.fetch(fromAuthentication.GET_EMPLOYEE_FROM_SERVER, {
      run: (action: fromAuthentication.GetEmployeeFromServer, state: AuthenticationState) => {
        return this.apiService.get<Employee>(`v/1/associates/employees/${action.payload.xselleratorEmployeeId}`).pipe(
          map((employee) => {
            return new fromAuthentication.GetEmployeeFromServerSuccess(employee);
          })
        );
      },
      onError: (a: fromAuthentication.GetEmployeeFromServer, error) => {
        return new fromAuthentication.GetEmployeeFromServerFailure({
          message: 'Error retrieving user profile.',
          error: error.reason
        });
      }
    })
  );

  getEmployeeFromServerSuccess = createEffect(() =>
    this.d.fetch(fromAuthentication.GET_EMPLOYEE_FROM_SERVER_SUCCESS, {
      run: (action: fromAuthentication.GetEmployeeFromServerSuccess, state: AuthenticationState) => {
        const directMessageParams: DirectMessageTemplateQueryParameters = new DirectMessageTemplateQueryParameters({
          departmentId: action.payload.departmentId,
          employeeId: action.payload.associateId
        });

        return from([
          new fromAuthentication.UpdateAuthenticationStages({
            id: 2,
            changes: { authenticationStatus: AuthenticationStatus.completed }
          }),
          new fromConversations.GetDirectMessageTemplatesFromServer(directMessageParams)
        ]).pipe(delay(500));
      },
      onError: (a: fromAuthentication.GetEmployeeFromServerSuccess, error) => {
        return new fromAuthentication.GetEmployeeFromServerFailure(error);
      }
    })
  );

  socketConnected = createEffect(() =>
    this.d.fetch(fromSockets.SOCKET_CONNECTED, {
      run: (a: fromSockets.SocketConnected, state: AuthenticationState) => {
        return from([
          new fromAuthentication.UpdateAuthenticationStages({
            id: 3,
            changes: { authenticationStatus: AuthenticationStatus.completed }
          })
        ]).pipe(delay(500));
      },
      onError: (a: fromSockets.SocketConnected, error) => {
        return new fromSockets.SocketConnectedError(error);
      }
    })
  );

  logoutSuccess = createEffect(() =>
    this.d.fetch(fromAuthentication.LOGOUT_SUCCESS, {
      run: (a: fromAuthentication.LogoutSuccess, state: AuthenticationState) => {
        return from([
          new fromAuthentication.AddAuthenticationStages([
            {
              id: 1,
              task: 'Authenticating User',
              authenticationStatus: AuthenticationStatus.pending
            },
            {
              id: 2,
              task: 'Retrieving User Profile',
              authenticationStatus: AuthenticationStatus.pending
            }
          ])
        ]);
      },
      onError: (a: fromAuthentication.LogoutSuccess, error) => {
        return new fromAuthentication.LogoutError(error);
      }
    })
  );

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private communicatorSocketService: CommunicatorSocketService,
    private d: DataPersistence<any>,
    private router: Router,
    private socketStateService: SocketStateService
  ) {}
}
