import { Component, EventEmitter, OnInit, Output } from '@angular/core';
const EmojiPanel = require('emoji-panel');
const twemoji = require('twemoji');

@Component({
  selector: 'com-emoji-panel',
  templateUrl: './emoji-panel.component.html',
  styleUrls: ['./emoji-panel.component.css'],
})
export class EmojiPanelComponent implements OnInit {
  @Output() emojiUnicode: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    const panel = new EmojiPanel(document.getElementById('emojiPanel'), {
      onClick: (emoji: any) => {
        const emojiUnicode = twemoji.convert.fromCodePoint(emoji.unified);
        this.emojiUnicode.emit(emojiUnicode);
      },
    });
  }
}
