import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { ContactPresence } from '@quorum/models/xs-misc';
import { CommunicatorContactQueryParameters as ContactQueryParameters } from '@quorum/models/xs-query';
import { CommunicatorContact as Contact, Store as QuorumStore } from '@quorum/models/xs-resource';
import { map, take } from 'rxjs/operators';
import * as fromContacts from './+state/contacts.actions';
import {
  contactsErrorsSelector,
  contactsGroupConfigurationSelector,
  contactsIsLoadingSelector,
  contactsPresenceSelector,
  contactsSelector,
  ContactsState,
} from './+state/contacts.interfaces';

@Injectable()
export class ContactsStateService {
  constructor(private authenticationStateService: AuthenticationStateService, private store: Store<ContactsState>) {}

  deleteContactPresence(contactPresence: ContactPresence) {
    this.store.dispatch(new fromContacts.DeleteContactPresence(contactPresence));
  }

  getContacts(parameters: ContactQueryParameters) {
    this.store.dispatch(new fromContacts.LoadContacts(parameters));
  }

  loadContactsPresence(contactsPresence: ContactPresence[]) {
    this.store.dispatch(new fromContacts.LoadContactsPresence(contactsPresence));
  }

  selectContact(id: number) {
    return this.store.pipe(
      select(contactsSelector),
      map((contacts: Contact[]) => {
        return contacts.find((contact: Contact) => contact.id === id);
      })
    );
  }

  selectContacts() {
    this.getContactsIfNotInState();
    return this.store.pipe(select(contactsSelector));
  }

  selectErrors() {
    return this.store.pipe(select(contactsErrorsSelector));
  }

  selectIsLoading() {
    return this.store.pipe(select(contactsIsLoadingSelector));
  }

  selectPresences() {
    return this.store.pipe(select(contactsPresenceSelector));
  }

  selectGroupConfiguration() {
    this.getContactsIfNotInState();
    return this.store.pipe(select(contactsGroupConfigurationSelector));
  }

  updateGroupConfiguration(id: number, collapsed: boolean) {
    this.store.dispatch(new fromContacts.UpdateContactGroupConfiguration({ id, collapsed }));
  }

  updateContactPresence(contactPresence: ContactPresence) {
    this.store.dispatch(new fromContacts.UpdateContactPresence(contactPresence));
  }

  private getContactsIfNotInState() {
    this.store
      .pipe(
        select(contactsSelector),
        take(1),
        map((contacts: Contact[]) => {
          if (contacts.length === 0) {
            this.authenticationStateService
              .selectAuthenticatedUser()
              .pipe(
                take(1),
                map((authenticatedUser: AuthenticatedUser) => {
                  this.getContacts(
                    new ContactQueryParameters({
                      storeId: authenticatedUser.user.storeId,
                      employeeId: authenticatedUser.user.xselleratorEmployeeId,
                      embed: 'Store,Employee',
                      isActive: true,
                      isDealershipUser: true,
                    })
                  );
                  if (authenticatedUser.user.federatedStores) {
                    authenticatedUser.user.federatedStores.forEach((federatedStore: QuorumStore) => {
                      this.getContacts(
                        new ContactQueryParameters({
                          storeId: federatedStore.id,
                          employeeId: authenticatedUser.user.xselleratorEmployeeId,
                          embed: 'Store,Employee',
                          isActive: true,
                          isDealershipUser: true,
                        })
                      );
                    });
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }
}
