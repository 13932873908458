<div class="event-container" fxLayout="row" fxLayoutAlign="end end">
  <ng-container *ngIf="![1, 2, 3, 4, 5, 6].includes(event.eventTypeId); else eventAction">
    <sha-avatar *ngIf="!ownEvent && index == total; else spacer" class="event-avatar-container" [size]="'medium'"
      [avatarUrl]="sender?.avatarUrl"
      [initials]="[sender?.firstName?.charAt(0) + (sender?.lastName ? sender?.lastName?.charAt(0) : '')]"
      [displayPresence]="false">
    </sha-avatar>
    <ng-template #spacer>
      <div class="spacer"></div>
    </ng-template>
    <div fxFlex fxLayout="column">
      <span *ngIf="displayNickname(total, index, ownEvent, members)" class="mat-caption name-container">{{
        sender?.nickname
        }}</span>
      <div fxLayout="row" [matTooltip]="event.sentDate | relativeDate: true" matTooltipPosition="below">
        <div class="message-container" [ngClass]="{ sent: ownEvent, received: !ownEvent }"
          [ngStyle]="buildStyle(index, total, ownEvent)" fxLayoutAlign="center center">

          <ng-container *ngIf="event.eventTypeId !== 19; else fileContainer">
            <div comImagesLoaded [innerHTML]="event.content | emojiParser | linker | async">
            </div>
          </ng-container>
          <ng-template #fileContainer>
            <com-file [url]="event.content"></com-file>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #eventAction>
    <ng-container *ngIf="[1, 2, 3, 4, 5, 6].includes(event.eventTypeId)">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" [matTooltip]="event.sentDate | relativeDate: true" matTooltipPosition="below">
          <div class="secondary-text" fxLayoutAlign="center center">
            <span class="event-content" [innerHTML]="event.content"></span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
