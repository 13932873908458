import { WorkorderDeclinedMpviCode } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface WorkOrderDeclinedOperationsState extends EntityState<WorkorderDeclinedMpviCode> {}

export const WorkOrderDeclinedOperationsAdapter: EntityAdapter<WorkorderDeclinedMpviCode> =
  createEntityAdapter<WorkorderDeclinedMpviCode>({
    selectId: (WorkOrderDeclinedOperation: WorkorderDeclinedMpviCode) => WorkOrderDeclinedOperation.id,
  });
// eslint-disable-next-line max-len
export const initialWorkOrderDeclinedOperationsState: WorkOrderDeclinedOperationsState =
  WorkOrderDeclinedOperationsAdapter.getInitialState({});
