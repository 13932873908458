export class ContactQueryParameters {
  email: string;
  embed?: string;
  id: string;
  isActive: boolean;
  isAdministrator: boolean;
  isDealershipUser: boolean;
  firstName: string;
  lastName: string;
  nickName: string;
  storeId: string;
  username: string;
  xselleratorEmployeeId: string;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}
