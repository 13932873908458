import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { EventTypes } from '@models/server-common';

import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ContactSelectDialogComponent } from '@quorum/communicator/ui/ng/contacts';
import { environment } from '@quorum/environments';
import {
  CommunicatorContactQueryParameters as ContactQueryParameters,
  MessageTransactionMessagePreferenceQueryParameters,
} from '@quorum/models/xs-query';
import {
  CommunicatorContact as Contact,
  Conversation,
  Member,
  MessageTransactionMessagePreference,
  Store,
  TokenUser,
  Event,
} from '@quorum/models/xs-resource';
import { TwoActionDialogComponent, TwoActionDialogData } from '@quorum/sha-components-ng-ui';
import { RouterStateService } from '@quorum/sha-router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
@Component({
  selector: 'com-conversation-info-shell',
  templateUrl: './conversation-info-shell.component.html',
  styleUrls: ['./conversation-info-shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationInfoShellComponent implements OnDestroy, OnInit, OnChanges {
  @Input() conversation: Conversation;
  @Input() currentUser: TokenUser;

  contactsPresence: any;
  contactsPresenceSubscription: Subscription;
  member: Member;
  members$: Observable<any>;
  membersSubject: BehaviorSubject<any>;
  isGroupSubject: BehaviorSubject<any>;
  isGroupConversation$: Observable<any>;
  titleInputEdit: boolean = false;
  titleUpdating: boolean = false;
  messagePreferenceOverride$: Observable<MessageTransactionMessagePreference>;
  @ViewChild('convoMenu') convoMenuTrigger: MatMenuTrigger;
  @ViewChild('memberMenu') memberMenuTrigger: MatMenuTrigger;
  @ViewChild('titleInput') inputEl: ElementRef;
  membersToAdd: Member[] = [];

  constructor(
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private routerStateService: RouterStateService,
    private route: ActivatedRoute,
    private eventStateService: EventsStateService
  ) {}

  ngOnInit() {
    this.membersSubject = new BehaviorSubject<any>(this.conversation.id);
    this.isGroupSubject = new BehaviorSubject<any>(this.conversation.id);
    this.messagePreferenceOverride$ = this.conversationStateService.selectMessagePreferenceOverride();
    this.members$ = this.membersSubject.pipe(
      mergeMap((id: number) => {
        return this.membersStateService.selectMembers().pipe(
          filter((members: any) => members != null),
          map((members: any) => {
            this.member = members
              .filter((member: Member) => member.conversationId === id)
              .find((m: Member) => m.userId === this.currentUser.id.toString());
            return members
              .filter((member: Member) => member.conversationId === id && !member.isExcluded)
              .filter((member: Member) => member.firstName.toLowerCase() !== 'communicator');
          })
        );
      })
    );

    this.isGroupConversation$ = this.isGroupSubject.pipe(
      mergeMap((id: number) => {
        return this.membersStateService.isGroupConversation(id);
      })
    );

    this.contactsPresenceSubscription = this.contactsStateService
      .selectPresences()
      .subscribe((contactsPresence: any) => {
        this.contactsPresence = contactsPresence;
        this.ref.detectChanges();
      });

    this.contactsStateService.getContacts(
      new ContactQueryParameters({
        storeId: this.currentUser.storeId,
        employeeId: this.currentUser.xselleratorEmployeeId,
        embed: 'Store,Employee',
        isActive: true,
        isDealershipUser: true,
      })
    );

    if (this.currentUser.federatedStores) {
      this.currentUser.federatedStores.forEach((federatedStore: Store) => {
        this.contactsStateService.getContacts(
          new ContactQueryParameters({
            storeId: federatedStore.id,
            employeeId: this.currentUser.xselleratorEmployeeId,
            embed: 'Store,Employee',
            isActive: true,
            isDealershipUser: true,
          })
        );
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.conversation.isFirstChange()) {
      this.membersSubject.next(changes.conversation.currentValue.id);
      this.isGroupSubject.next(changes.conversation.currentValue.id);
    }

    if (changes.conversation?.currentValue?.id !== changes.conversation?.previousValue?.id) {
      let messageTransactionMessagePreferenceQueryParams = new MessageTransactionMessagePreferenceQueryParameters({
        transactionId: this.conversation.dmsTransactionId,
        transactionTypeId: this.conversation.dmsTransactionTypeId?.toString(),
      });

      this.conversationStateService.getMessagePreferenceOverride(messageTransactionMessagePreferenceQueryParams);
    }
  }

  ngOnDestroy() {
    this.contactsPresenceSubscription.unsubscribe();
  }

  getMemberInitials(member: Member): string[] {
    return [`${member.firstName ? member.firstName.charAt(0) : ''}${member.lastName ? member.lastName.charAt(0) : ''}`];
  }

  openDialog(members: Member[]): void {
    let preselectedContacts: Contact[] = [];
    this.contactsStateService
      .selectContacts()
      .pipe(take(1))
      .subscribe((contacts) => {
        contacts.map((contact) => {
          if (members.find((member) => member.userId === contact.id.toString())) preselectedContacts.push(contact);
        });

        const dialogRef = this.dialog.open(ContactSelectDialogComponent, {
          width: '600px',
          data: { preselectedContacts: preselectedContacts, title: 'Add Members' },
        });

        const params = this.routerStateService.getFullTreeParams(this.route);

        dialogRef.afterClosed().subscribe((contacts: Contact[]) => {
          if (contacts) {
            this.membersToAdd = [];

            contacts.forEach((contact: Contact, index: number) => {
              const contactAlreadyExist: boolean = preselectedContacts
                .map((preselectedContact) => preselectedContact.id)
                .includes(contact.id);

              if (!contactAlreadyExist) {
                const member = new Member({
                  avatarUrl: contact.avatarUrl,
                  conversationId: params['id'],
                  firstName: contact.firstName,
                  lastName: contact.lastName,
                  nickName: contact.nickName,
                  userId: contact.id,
                  isArchived: false,
                  isEmployee: true,
                  isExcluded: false,
                  isPinnedToUi: false,
                  isRead: false,
                });
                this.membersToAdd.push(member);
              }
            });
            this.membersStateService.addMembersToConversation(this.membersToAdd);
          }
        });
      });
  }

  openLeaveGroupDialog(): void {
    const dialogData: TwoActionDialogData = {
      actionTwoCaption: 'Leave',
      actionOneCaption: 'Cancel',
      content: 'This conversation will be archived, and you will not receive any new messages.',
      title: 'Leave Group?',
    };

    const dialogRef = this.dialog.open(TwoActionDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.membersStateService.removeSelfFromConversation(this.member);
    });
  }

  removeMemberFromConversation(member: Member) {
    const dialogData: TwoActionDialogData = {
      actionTwoCaption: 'Remove',
      actionOneCaption: 'Cancel',
      content: 'They will no longer see this conversation and will not be able to keep chatting with this group.',
      title: 'Remove This Person?',
    };

    const dialogRef = this.dialog.open(TwoActionDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.membersStateService.removeMemberFromConversation(member, this.currentUser.id);
      }
    });
  }

  saveTitle(title: string): void {
    if (title.trim()) {
      this.conversationStateService.updateConversationTitle(this.conversation.id, title);
    } else {
      title = this.conversation.title;
      this.titleInputEdit = false;
    }
  }

  selectMember(member: Member) {
    //  Implement display of the member/user info.  Whether it's a customer or an employee.
  }

  discardTitle(): void {
    this.titleInputEdit = false;
  }

  setMessagePreferenceOverride(
    messagePreferenceOverride: MessageTransactionMessagePreference,
    conversation: Conversation
  ) {
    if (messagePreferenceOverride.id) {
      this.conversationStateService.updateMessagePreferenceOverride(messagePreferenceOverride);
    } else {
      this.conversationStateService.createMessagePreferenceOverride({
        ...messagePreferenceOverride,
        transactionTypeId: conversation.dmsTransactionTypeId,
        transactionId: conversation.dmsTransactionId,
      });
    }
  }

  resetMessagePreferenceOverride(messagePreferenceOverride: MessageTransactionMessagePreference) {
    this.conversationStateService.deleteMessagePreferenceOverride(messagePreferenceOverride);
  }

  sendSubscriptionLink() {
    this.membersStateService
      .selectMembers()
      .pipe(
        filter((members: Member[]) => members != null),
        map((members: Member[]) => members.find((m: Member) => m.isEmployee == false)),
        take(1)
      )
      .subscribe((member) => {
        let domain = '';
        switch (environment.environment) {
          case 'development':
            domain = 'https://localhost:4200';
            break;
          case 'staging':
            domain = 'https://staging.qcommunicator.com';
            break;
          case 'production':
            domain = 'https://app.qcommunicator.com';
            break;
        }

        const event: Event = this.eventStateService.createEvent(
          `${domain}/subscriptions/${this.currentUser.destination}/${
            member.embedded.associate.messagePreferenceId == 1
              ? member.embedded.associate.emailAddress
              : member.embedded.addresses[0].cellPhoneNumber
          }`,
          this.conversation.id,
          EventTypes.Message,
          this.currentUser,
          !member.isEmployee ? true : false
        );
        this.eventStateService.addEvent(event);
      });
  }
}
