import { eventMock } from './event.model';

export class Conversation {
  avatarUrl: string;
  createdById: string;
  createdDate: Date;
  dmsTransactionTypeId: number;
  dmsTransactionId: string;
  dmsMessageTypeId: number;
  dmsExchangeConversationId: string;
  embedded?: any;
  id: number;
  lastEventId: number;
  title: string;
  unknownCustomerContact: string;
  utcLastModified: Date;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

export const conversationMock = new Conversation({
  avatarUrl: 'localhost/sha-avatar.png',
  createdById: '1003',
  createdDate: new Date(),
  dmsTransactionTypeId: 1,
  dmsTransactionId: '100',
  dmsMessageTypeId: 7,
  dmsExchangeConversationId: '819493421',
  id: 1,
  lastEventId: 2,
  unknownCustomerContact: null,
  utcLastModified: new Date(),
  title: 'new conversation',
  embedded: { lastEvent: eventMock, messageType: { description: 'test' } },
});

export function getConversationMock() {
  return new Conversation({
    avatarUrl: 'localhost/sha-avatar.png',
    createdById: '1003',
    createdDate: new Date(),
    dmsTransactionTypeId: 1,
    dmsTransactionId: '100',
    dmsMessageTypeId: 7,
    dmsExchangeConversationId: '819493421',
    id: 1,
    lastEventId: 2,
    unknownCustomerContact: null,
    utcLastModified: new Date(),
    title: 'new conversation',
    embedded: { lastEvent: eventMock, messageType: { description: 'test' } },
  });
}

export const conversationsMock: Conversation[] = [
  conversationMock,
  new Conversation({
    avatarUrl: 'localhost/sha-avatar.png',
    createdById: '1003',
    createdDate: new Date(),
    dmsTransactionTypeId: 1,
    dmsTransactionId: '100',
    dmsMessageTypeId: 7,
    dmsExchangeConversationId: '819493421',
    id: 2,
    lastEventId: 2,
    unknownCustomerContact: null,
    utcLastModified: new Date(),
    title: 'new conversation',
  }),
];
