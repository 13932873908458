<div class="flex flex-col h-full">
  <ng-container [ngSwitch]="data.messageType">
    <div *ngSwitchCase="'email'">
      <h1>Communicator Email</h1>
    </div>
    <div *ngSwitchCase="'text'" class="pb-4">
      <h1>Communicator Text Message</h1>
      <mat-slide-toggle labelPosition="after" [(ngModel)]="useQTemplates"
        [disabled]="data.communicatorTaskSpecificTemplate">
        Use QTemplates
      </mat-slide-toggle>
    </div>
    <div *ngSwitchDefault>
      <h1>Communicator Message</h1>
    </div>
  </ng-container>

  <div class="flex-1" *ngIf="data.messageType === 'email' || useQTemplates; else basicSMS">
    <div class="h-full flex flex-col">
      <ng-container *ngIf="languages.length <= 1;else multiLanguage">
        <mat-form-field appearance="outline">
          <mat-label>Select A Freeform Template</mat-label>
          <mat-select required [(ngModel)]="selectedTemplate" (selectionChange)="modifiedTemplate = selectedTemplate"
            [disabled]="data.communicatorTaskSpecificTemplate">
            <mat-option *ngFor="let template of data.templates" [value]="template">
              {{template.languageDefinitionMap[selectedLanguage].name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-template #multiLanguage>
        <mat-form-field appearance="outline">
          <mat-label>Select A Language</mat-label>
          <mat-select required [(ngModel)]="selectedLanguage" (selectionChange)="languageSelected(selectedLanguage)">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{language}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Select A Freeform Template</mat-label>
          <mat-select required [(ngModel)]="selectedTemplate" (selectionChange)="modifiedTemplate = selectedTemplate"
            [disabled]="data.communicatorTaskSpecificTemplate || (!data.communicatorTaskSpecificTemplate && !selectedLanguage)">
            <ng-container *ngIf="selectedLanguage">
              <mat-option *ngFor="let template of data.templates" [value]="template">
                {{template.languageDefinitionMap[selectedLanguage].name}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-template>

      <mat-form-field appearance="outline">
        <input required matInput [(ngModel)]="subject" placeholder="Subject">
      </mat-form-field>

      <quorum-slim-editor class="flex-1" [originalTemplate]="selectedTemplate" [language]="selectedLanguage"
        (onTemplateChanged)="modifiedTemplate = $event;">
      </quorum-slim-editor>

      <div class="flex flex-row place-content-start items-center mt-5" *ngIf="data.messageType === 'email'">
        <button mat-icon-button class="mr-2" [disabled]="uploading" type="button" (click)="uploader.click()">
          <mat-icon class="secondary-text">attach_file</mat-icon>
        </button>
        <input hidden multiple type="file" id="fileInput" #uploader (change)="prepareFiles($event.target.files)">

        <div class="flex flex-col place-content-center items-center p-1 rounded mr-1 relative"
          style="border:1px solid #dadce0;" *ngFor="let attachment of attachments">
          <mat-icon class="mb-1">description</mat-icon>
          <span>{{attachment.fileName}}</span>
          <button mat-icon-button color="warn" type="button" class="absolute -top-5 -right-5 z-10"
            (click)="removeAttachment(attachment)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #basicSMS>
    <mat-form-field appearance="outline">
      <mat-label>Message Content</mat-label>
      <textarea matInput cdkTextareaAutosize [(ngModel)]="messageContent" #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </ng-template>

  <div class="flex flex-row justify-end pt-4">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <ng-container [ngSwitch]="data.messageType">
      <ng-container *ngSwitchCase="'email'">
        <button [disabled]="!selectedTemplate || !subject" color="primary" mat-button
          (click)="sendEmail(attachments, modifiedTemplate, subject, selectedLanguage)">Send</button>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <button *ngIf="useQTemplates; else sendBasicSMS" [disabled]="!selectedTemplate || !subject" color="primary"
          mat-button (click)="sendTemplateTextMessage(modifiedTemplate, subject, selectedLanguage)">Send</button>
        <ng-template #sendBasicSMS>
          <button [disabled]="!messageContent" color="primary" mat-button
            (click)="sendBasicTextMessage(messageContent)">Send
          </button>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>