import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationStateService, ConversationUi, ConversationView } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import { AuthenticatedUser, Contact, Conversation, Member } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'com-master-body',
  templateUrl: './master-body.component.html',
  styleUrls: ['./master-body.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterBodyComponent implements OnInit, OnDestroy {
  activitiesConversations: Array<Conversation> = [];
  authenticatedUser: AuthenticatedUser;
  conversations: Array<Conversation>;
  contacts: Array<Contact>;
  filterApplied: boolean = false;
  filteredConversations: Array<Conversation> = [];
  filterLoadingConversations: boolean = false;
  filterPageNumber: number = 1;
  members: Array<Member>;
  membersSubscription: Subscription;
  ui$: Observable<ConversationUi>;

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private cdr: ChangeDetectorRef,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.ui$ = this.conversationStateService.selectUiState();
    this.getAuthenticatedUser();
    this.selectResources();
  }

  ngOnDestroy() {
    if (this.membersSubscription) this.membersSubscription.unsubscribe();
  }

  getAuthenticatedUser() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((authenticatedUser: AuthenticatedUser) => {
          this.authenticatedUser = authenticatedUser;
        })
      )
      .subscribe();
  }

  applyMessageFilter() {
    this.filterLoadingConversations = true;
    let eventTypeIds: number[] = this.conversationStateService.getConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isRead: false,
      eventTypeId: eventTypeIds.join(','),
      embed: 'lastEvent,messageType',
      pageNumber: this.filterPageNumber,
      pageSize: 100,
    });

    this.apiService
      .get<Conversation[]>('communicator/conversations', {
        params,
      })
      .pipe(
        take(1),
        tap((conversations: Conversation[]) => {
          this.filterLoadingConversations = false;
          this.filteredConversations = this.filterApplied ? conversations : [];
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.addUnreadSocketMessagesToFilteredConversations(this.authenticatedUser);
  }

  addUnreadSocketMessagesToFilteredConversations(authenticatedUser: AuthenticatedUser) {
    this.membersSubscription = this.membersStateService.selectMembers().subscribe((members: Member[]) => {
      members.forEach((m: Member) => {
        if (this.addConversationToFilteredConversationsConditionCheck(authenticatedUser, m)) {
          this.filteredConversations = [
            ...this.filteredConversations,
            this.conversations.find((c: Conversation) => c.id === m.conversationId),
          ];
          this.cdr.detectChanges();
        }
      });
    });
  }

  addConversationToFilteredConversationsConditionCheck(authenticatedUser: AuthenticatedUser, member: Member): boolean {
    if (member.userId !== authenticatedUser.user.id.toString() || member.isRead) return false;
    if (this.filteredConversations.map((c) => c.id).includes(member.conversationId)) return false;
    if (!this.conversations.find((c: Conversation) => c.id === member.conversationId)) return false;
    return true;
  }

  removeMessageFilter() {
    this.filterLoadingConversations = false;
    this.filteredConversations = [];
  }

  newConversation() {
    this.routerStateService.go([{ outlets: { detail: `new` } }], { relativeTo: this.route.parent });
  }

  selectResources() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((authenticatedUser) => {
        combineLatest(
          this.membersStateService.selectMembers(),
          this.conversationStateService.selectConversations(authenticatedUser),
          this.conversationStateService.selectMyActivitiesConversations(authenticatedUser)
        ).subscribe(([members, conversations, activitiesConversations]) => {
          this.members = members != null ? members : [];
          this.conversations = conversations;
          this.activitiesConversations = activitiesConversations;

          if (!(this.ref as ViewRef).destroyed) {
            this.ref.detectChanges();
          }
        });
      });
  }

  onScrollActivities() {
    forkJoin([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.conversationStateService.selectLastQueryPageNumber(ConversationView.activities).pipe(take(1)),
    ]).subscribe(([authenticatedUser, pageNumber]) => {
      this.conversationStateService.dispatchUnpinnedXselleratorConversationsFromSelfAction(
        authenticatedUser,
        null,
        pageNumber
      );
    });
  }

  onScrollConversations() {
    forkJoin([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.conversationStateService.selectLastQueryPageNumber(ConversationView.conversations).pipe(take(1)),
    ]).subscribe(([authenticatedUser, pageNumber]) => {
      this.conversationStateService.dispatchUnpinnedConversationsAction(null, pageNumber);
      this.conversationStateService.dispatchUnpinnedXselleratorConversationsFromOthersAction(
        authenticatedUser,
        null,
        pageNumber
      );
    });
  }
}
