<div fxLayout="column" fxLayoutAlign="start center">
  <mat-toolbar class="mat-elevation-z1">
    <button mat-icon-button class="secondary-text" (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="toolbar-title">Settings</span>
  </mat-toolbar>

  <div fxLayout="column" fxLayoutAlign="center center" class="detail">
    <div fxLayout="column" fxLayoutAlign="center center" class="settings-area-header">
      <h1>Personal</h1>
      <span class="mat-body-2 secondary-text">Change your personal Communicator settings</span>
    </div>
    <div fxLayout="column" class="settings-area-content">
      <div class="settings-area-header">
        <h2>Area Name</h2>
        <span class="secondary-text">A description of the area</span>
      </div>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Toggle</span>
        <mat-slide-toggle> </mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Select</span>
        <mat-form-field>
          <mat-select>
            <mat-option>1</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Input</span>
        <div fxLayoutAlign="start center">
          <button mat-icon-button>
            <mat-icon class="secondary-text">create</mat-icon>
          </button>
          <mat-form-field>
            <input autocomplete="off" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="settings-area-container">
      <div class="settings-area-header">
        <h2>Another Area Name</h2>
        <span class="secondary-text">A description of the area</span>
      </div>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Toggle</span>
        <mat-slide-toggle> </mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Select</span>
        <mat-form-field>
          <mat-select>
            <mat-option>1</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="setting-container" fxLayoutAlign="space-between center">
        <span class="secondary-text">Option Input</span>
        <div fxLayoutAlign="start center">
          <button mat-icon-button>
            <mat-icon class="secondary-text">create</mat-icon>
          </button>
          <mat-form-field>
            <input autocomplete="off" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
