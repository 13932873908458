import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList, MatListOption } from '@angular/material/list';

@Component({
  selector: 'sha-two-item-selection-list-dialog',
  templateUrl: './two-item-selection-list-dialog.component.html',
  styleUrls: ['./two-item-selection-list-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoItemSelectionListDialogComponent implements OnInit {
  allItemsSelected: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<TwoItemSelectionListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  selectAll(selectAll: boolean, items: MatSelectionList) {
    items.options.forEach((item) => (item.selected = selectAll));
  }

  onDone(items: MatListOption[]) {
    let selectedItems: any[] = [];
    items.map((item: MatListOption) => selectedItems.push(item.value));
    this.dialogRef.close(selectedItems);
  }
}
