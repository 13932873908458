import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { Conversation, Member } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'com-groups-shell',
  templateUrl: './groups-shell.component.html',
  styleUrls: ['./groups-shell.component.css'],
})
export class GroupsShellComponent implements OnInit {
  groups$: Observable<Conversation[]>;
  constructor(
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((authenticatedUser) => {
        this.groups$ = combineLatest(
          this.membersStateService.selectMembers(),
          this.conversationStateService.selectConversations(authenticatedUser).pipe(
            map((conversations: Conversation[]) => {
              return conversations.filter((conversation) => conversation.dmsMessageTypeId === 0);
            })
          )
        ).pipe(
          map(([members, conversations]) =>
            conversations.filter(
              (conversation) =>
                members.filter((member: Member) => {
                  return (
                    member.conversationId === conversation.id &&
                    member.isExcluded === false &&
                    member.firstName.toLowerCase() !== 'communicator'
                  );
                }).length > 2
            )
          )
        );
      });
  }

  goBack() {
    this.routerStateService.go([{ outlets: { master: 'master' } }], { relativeTo: this.route.parent });
  }
}
