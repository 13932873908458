<div class="shell-container" fxLayout="column">
  <div class="title-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="title-avatar-container" fxLayoutAlign="center center">
      <img *ngIf="conversation.avatarUrl; else defaultAvatar" class="title-avatar" [src]="conversation.avatarUrl" />
      <ng-template #defaultAvatar>
        <mat-icon class="person-icon">person</mat-icon>
      </ng-template>
    </div>

    <form class="title-form-container" fxLayout="row" fxLayoutAlign="end end">
      <mat-form-field (onContainerClick)="titleInputEdit = true">
        <input id="conversation-title" autocomplete="false" matInput class="conversation-title-input" #titleInput
          value="{{ conversation.dmsTransactionTypeId !== 10 ? conversation.title : null }}"
          (focus)="titleInputEdit = true" (shaClickOutside)="discardTitle()" [excludeBeforeClick]="true" />
      </mat-form-field>
    </form>

    <div class="title-menu-container" fxLayoutAlign="center center">
      <button id="save-title-button" mat-icon-button *ngIf="titleInputEdit; else settings">
        <mat-icon id="save-title-icon" class="secondary-text save-title-icon" (click)="saveTitle(titleInput.value)">
          check</mat-icon>
      </button>
      <ng-template #settings>
        <button id="settings-button" mat-icon-button [matMenuTriggerFor]="menu" #convoMenu="matMenuTrigger"
          [disabled]="(isGroupConversation$ | async) === false">
          <mat-icon class="secondary-text settings-icon">settings</mat-icon>
        </button>
      </ng-template>
    </div>

    <mat-menu #menu="matMenu">
      <button id="open-leave-group-dialog-button" mat-menu-item (click)="openLeaveGroupDialog()">
        <span>Leave Group</span>
      </button>
    </mat-menu>
  </div>

  <ng-container
    *ngIf=" conversation.dmsMessageTypeId && conversation.dmsTransactionId && ![3, 4, 8, 11, 13, 14, 21, 32].includes(conversation.dmsMessageTypeId)">
    <div fxLayout="column" *ngIf="messagePreferenceOverride$ | async as messagePreferenceOverride">
      <h3 fxLayout="row" fxLayoutAlign="start center"
        style="color: rgba(0, 0, 0, 0.54); font-size: 14px; font-weight: 500; margin-bottom: 0" class="mat-subheader">
        Message Preference Override
      </h3>
      <form fxLayout="column" style="padding: 0 16px 16px">
        <mat-form-field appearance="outline">
          <mat-label>Message Preference</mat-label>
          <mat-select name="messagePreferenceId" [(ngModel)]="messagePreferenceOverride.value.messagePreferenceId">
            <mat-option [value]="1"> Email </mat-option>
            <mat-option [value]="2"> Text </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ messagePreferenceOverride.value.messagePreferenceId == 1 ? 'Email Address' : 'Phone Number' }}
          </mat-label>
          <input name="messagePreferenceContactInfo"
            [(ngModel)]="messagePreferenceOverride.value.messagePreferenceContactInfo" matInput placeholder="" />
        </mat-form-field>
        <div fxLayoutAlign="space-around center">
          <button style="border-radius: 24px" mat-raised-button
            (click)="resetMessagePreferenceOverride(messagePreferenceOverride.value)"
            [disabled]="!messagePreferenceOverride.value.id || messagePreferenceOverride.isLoading">
            Reset
          </button>
          <button color="primary" style="border-radius: 24px" mat-raised-button
            [disabled]="messagePreferenceOverride.isLoading"
            (click)="setMessagePreferenceOverride(messagePreferenceOverride.value, conversation)">
            Update
          </button>
        </div>
      </form>
      <mat-divider></mat-divider>
    </div>
  </ng-container>

  <div
    *ngIf=" conversation.dmsMessageTypeId && conversation.dmsTransactionId && ![3, 4, 8, 11, 13, 14, 21, 32].includes(conversation.dmsMessageTypeId)">
    <h3 fxLayout="row" fxLayoutAlign="start center"
      style="color: rgba(0, 0, 0, 0.54); font-size: 14px; font-weight: 500; margin-bottom: 0" class="mat-subheader">
      Subscription Options
    </h3>
    <div fxLayoutAlign="space-around center" style="padding: 0 16px 16px">
      <button color="primary" style="border-radius: 24px" mat-raised-button (click)="sendSubscriptionLink()">
        Send Link
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="members-container">
    <mat-nav-list>
      <div id="members-list" *ngIf="members$ | async as members">
        <h3 fxLayout="row" fxLayoutAlign="start center" mat-subheader>
          <span>Members</span>
        </h3>
        <mat-list-item (click)="openDialog(members)">
          <div mat-list-icon fxLayoutAlign="center center" class="initials-circle">
            <mat-icon class="add-members-icon">group_add</mat-icon>
          </div>
          <h4 mat-line class="primary-text">Add Members</h4>
        </mat-list-item>
        <mat-list-item *ngFor="let member of members | orderBy: ['firstName', 'lastName']">
          <sha-avatar mat-list-icon [avatarUrl]="member.avatarUrl"
            [presence]="member.isEmployee ? contactsPresence[member.userId] : null"
            [initials]="member.isEmployee ? getMemberInitials(member) : null">
          </sha-avatar>
          <h3 id="member-name-h3" mat-line (click)="selectMember(member)">
            {{ member.firstName }} {{ member.lastName }}
          </h3>
          <p mat-line *ngIf="!member.isEmployee" class="secondary-text">
            <ng-container [ngSwitch]="member.embedded?.associate?.messagePreferenceId">
              <span *ngSwitchCase="null">No message preference</span>
              <span *ngSwitchCase="1">Prefers {{ member.embedded?.associate?.emailAddress }}</span>
              <span *ngSwitchCase="2">Prefers {{ member.embedded?.addresses[0]?.cellPhoneNumber | phoneNumber }}</span>
            </ng-container>
          </p>
          <button id="group-convo-menu-button" mat-icon-button [matMenuTriggerFor]="menu" #memberMenu="matMenuTrigger"
            *ngIf="isGroupConversation$ | async">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button id="remove-from-convo-button" *ngIf="currentUser.id.toString() !== member.userId; else leaveGroup"
              mat-menu-item (click)="removeMemberFromConversation(member)">
              <span>Remove from Group</span>
            </button>
            <ng-template #leaveGroup>
              <button id="leave-group-button" *ngIf="currentUser.id.toString() === member.userId" mat-menu-item
                (click)="openLeaveGroupDialog()">
                <span>Leave Group</span>
              </button>
            </ng-template>
          </mat-menu>
        </mat-list-item>
      </div>
    </mat-nav-list>
  </div>

  <div class="files-container">
    <com-files-list [conversationId]="conversation.id"></com-files-list>
  </div>
</div>