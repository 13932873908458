import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { AuthenticatedUser, Conversation, Event } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
@Component({
  selector: 'com-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionSummaryComponent implements OnInit, OnDestroy {
  authenticatedUser$: Observable<AuthenticatedUser>;
  conversationGroups$: Observable<any>;
  contacts$: Observable<any>;
  contactsPresence$: Observable<any>;
  conversationMembers: any;
  members$: Observable<any>;
  selectedConversation: Conversation;
  transactionTypeId: string;
  transactionId: string;
  customerAssociateId: string;
  conversations: any;
  searchContacts: boolean = true;
  searchConversations: boolean = true;
  searchArchivedConversations: boolean = false;
  searchString: string = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationStateService: AuthenticationStateService,
    private route: ActivatedRoute,
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private routerStateService: RouterStateService
  ) {}

  transactionEvents: Event[] = [];
  ngOnInit(): void {
    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser();
    this.contacts$ = this.contactsStateService.selectContacts();
    this.contactsPresence$ = this.contactsStateService.selectPresences();
    this.transactionTypeId = this.routerStateService.getFullTreeParams(this.activatedRoute).transactionTypeId;
    this.transactionId = this.routerStateService.getFullTreeParams(this.activatedRoute).transactionId;
    this.customerAssociateId = this.routerStateService.getFullTreeParams(this.activatedRoute).customerAssociateId;

    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((authenticatedUser) => {
        this.conversationGroups$ = this.conversationStateService.selectAllConversations(authenticatedUser).pipe(
          map((conversations) => {
            this.conversations = conversations;
            let conversationGroups = conversations.reduce((acc, obj) => {
              const property = obj.dmsTransactionTypeId;
              acc[property] = acc[property] || [];
              acc[property].push(obj);
              return acc;
            }, {});

            return conversationGroups;
          })
        );

        if (this.transactionTypeId && this.transactionId) {
          this.conversationStateService
            .selectIsLoading()
            .pipe(
              map((isLoading) => {
                if (!isLoading) {
                  this.conversationStateService
                    .selectAllConversations(authenticatedUser)
                    .pipe(
                      take(1),
                      map((conversations) =>
                        conversations.filter(
                          (conversation) =>
                            conversation.dmsTransactionTypeId &&
                            conversation.dmsTransactionTypeId.toString() === this.transactionTypeId &&
                            conversation.dmsTransactionId === this.transactionId &&
                            conversation.dmsMessageTypeId === 18 &&
                            conversation.createdById === authenticatedUser.user.id.toString()
                        )
                      )
                    )
                    .subscribe((conversations) => {
                      if (conversations.length <= 0) {
                        this.routerStateService.go(['new-conversation'], {
                          relativeTo: this.route,
                          queryParamsHandling: 'merge',
                        });
                      } else {
                        this.selectConversation(conversations[0]);
                        this.selectedConversation = conversations[0];
                        this.routerStateService.go([`c/${conversations[0].id}`], {
                          relativeTo: this.route,
                          queryParamsHandling: 'merge',
                        });
                      }
                    });
                }
              })
            )
            .subscribe();
        }
      });

    this.members$ = this.membersStateService.selectMembers().pipe(
      map((members) => {
        let conversationMembers = {};
        members.forEach((member) => {
          conversationMembers[member.conversationId] = [...(conversationMembers[member.conversationId] || []), member];
        });
        return conversationMembers;
      })
    );
  }

  selectConversation(selectedConversation: Conversation) {
    this.selectedConversation = selectedConversation;
    this.routerStateService.go([`c/${selectedConversation.id}`], { relativeTo: this.route });
  }

  mapTransactionType(transactionTypeId: string) {
    switch (transactionTypeId) {
      case '2':
        return 'Work Order';
      case '3':
        return 'Part Sale';
      case '4':
        return 'Vehicle Sale';
      case '5':
        return 'Customer Prospect';
      case '6':
        return 'Service Appointment';
      case '7':
        return 'Special Order';
      case '8':
        return 'Service Quote';
      case '9':
        return 'Vehicle';
      case '10':
        return 'Associate';
      case '11':
        return 'Vehicle Invoice';
      case '12':
        return 'Adjustments';
      case '13':
        return 'Cash Transaction';
      case '14':
        return 'Inventory Order';
      case '15':
        return 'Journal';
      case '16':
        return 'Purchase Order';
      case '17':
        return 'Part Return';
      case '18':
        return 'Reconciliation';
      case '19':
        return 'Parts Request';
      default:
        return '';
    }
  }

  ngOnDestroy() {}

  sortConversationGroups(a: any, b: any) {
    if (a.key == this.transactionTypeId) return -1;
    if (b.key == this.transactionTypeId) return 1;
    return 0;
  }

  sortTransactionGroups(a: any, b: any) {
    if (a.key == this.transactionId) return -1;
    if (b.key == this.transactionTypeId) return 1;
    if (a.value[0].utcLastModified > b.value[0].utcLastModified) return -1;
    if (b.value[0].utcLastModified > a.value[0].utcLastModified) return 1;

    return 0;
  }

  startNewConversation() {
    let associateConversationGroup = this.conversations.find(
      (conversation: any) =>
        conversation.dmsTransactionId == this.customerAssociateId && conversation.dmsTransactionTypeId == 10
    );

    if (this.transactionTypeId && this.transactionId && associateConversationGroup) {
      this.routerStateService.go([`c/${associateConversationGroup.id}`], {
        relativeTo: this.route,
      });
    } else {
      if (associateConversationGroup) {
        this.routerStateService.go([`c/${associateConversationGroup.id}`], {
          relativeTo: this.route,
        });
      } else {
        this.routerStateService.go(['new-conversation'], { relativeTo: this.route });
      }
    }
  }

  closeSearch() {
    this.searchString = '';
    this.searchContacts = true;
    this.searchConversations = true;
    this.searchArchivedConversations = false;
  }

  onSearchArchivedConversationsChange() {
    if (!this.searchConversations) this.searchConversations = true;
  }

  onSearchConversationsChange() {
    if (!this.searchConversations) this.searchArchivedConversations = false;
  }

  navigateToSelectedConversation(conversation: Conversation) {
    this.routerStateService.go([`c/${conversation.id}`], {
      relativeTo: this.route,
    });
  }
}
