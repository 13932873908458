import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromAuthentication from './authentication.actions';
import { AuthenticatedUserAction } from './authentication.actions';
import { authenticationInitialState } from './authentication.init';
import { authenticationStagesAdapter, AuthenticationState } from './authentication.interfaces';

export function authenticatedUserReducer(
  state: AuthenticationState,
  action: AuthenticatedUserAction
): AuthenticationState {
  switch (action.type) {
    case fromAuthentication.AUTHENTICATE_USER: {
      return { ...state };
    }
    case fromAuthentication.USER_AUTHENTICATED: {
      return {
        ...state,
        authenticatedUser: action.payload,
        authenticated: true,
        errors: null,
      };
    }
    case fromAuthentication.EMPLOYEE_AUTHENTICATION_SUCCESSFUL: {
      return {
        ...state,
        quorumApiKey: action.payload.apiKey,
        quorumDestinationId: action.payload.destinationId,
      };
    }
    case fromAuthentication.USER_AUTHENTICATED_ERROR: {
      return { ...state, authenticated: false, authenticating: false, errors: action.payload };
    }
    case fromAuthentication.LOGOUT: {
      return state;
    }
    case fromAuthentication.LOGOUT_SUCCESS: {
      return authenticationInitialState;
    }
    case fromAuthentication.LOGOUT_ERROR: {
      return authenticationInitialState;
    }
    case fromAuthentication.MONITOR_AUTHENTICATION_STAGES: {
      return { ...state, authenticating: true };
    }
    case fromAuthentication.ADD_AUTHENTICATION_STAGES: {
      return {
        ...state,
        authenticationStages: authenticationStagesAdapter.addMany(action.payload, state.authenticationStages),
      };
    }
    case fromAuthentication.UPDATE_AUTHENTICATION_STAGES: {
      return {
        ...state,
        authenticationStages: authenticationStagesAdapter.updateOne(action.payload, state.authenticationStages),
      };
    }
    case fromAuthentication.GET_EMPLOYEE_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case fromAuthentication.GET_EMPLOYEE_FROM_SERVER_FAILURE: {
      return { ...state, authenticated: false, authenticating: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
}

export function logger(reducer: ActionReducer<AuthenticationState>): ActionReducer<AuthenticationState> {
  return function (state, action: any): AuthenticationState {
    if (state === undefined) {
      console.log('store is intializing authentication state');
      state = authenticationInitialState;
    }

    return reducer(state, action);
  };
}

export const reducer = logger(authenticatedUserReducer);
export const metaReducers: MetaReducer<AuthenticationState>[] = [logger];
