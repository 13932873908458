import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocketEventTypes } from '@models/server-common';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { Conversation, ConversationStateService } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { FilesFacade } from '@quorum/communicator/state/files';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ContactPresence, UserTyping } from '@quorum/models/xs-misc';
import { Event, Member } from '@quorum/models/xs-resource';
import { SocketsService } from '@quorum/sockets/services';
import { CommunicatorSocketService } from './communicator-socket.service';

@NgModule({
  imports: [CommonModule],
  providers: [AuthenticationStateService, SocketsService, CommunicatorSocketService, FilesFacade],
})
export class ComSocketsModule {
  constructor(
    private communicatorSocketService: CommunicatorSocketService,
    private eventStateService: EventsStateService,
    private conversationStateService: ConversationStateService,
    private contactStateService: ContactsStateService,
    private filesService: FilesFacade,
    private memberStateService: MembersStateService
  ) {
    communicatorSocketService.bindEvent(SocketEventTypes.CONVERSATIONTITLE, (data: Conversation) => {
      this.conversationStateService.updateConversationTitleFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.CONVERSATIONPINNEDTOUI, (data: Member) => {
      this.memberStateService.updateIsPinnedToUiForConversationFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.EVENTADD, (data: Event) => {
      this.eventStateService.addEventFromSocket(data.conversationId, data);
      if (data.eventTypeId === 19)
        this.filesService.addFile({ converstionId: data.conversationId, id: data.id, url: data.content });
    });
    communicatorSocketService.bindEvent(SocketEventTypes.MEMBEREXCLUDE, (data: Member) => {
      this.memberStateService.removeMemberFromConversationFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.ISTYPING, (data: UserTyping) => {
      this.conversationStateService.isTyping(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.MEMBERADD, (data: Member) => {
      this.memberStateService.addMemberToConversationFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.MEMBERARCHIVE, (data: Member) => {
      this.memberStateService.updateIsArchivedForConversationFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.MEMBERREAD, (data: Member) => {
      this.memberStateService.updateIsReadForConversationFromSocketEvent(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.OFFLINE, (data: ContactPresence) => {
      this.contactStateService.deleteContactPresence(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.ONLINE, (data: ContactPresence) => {
      this.contactStateService.updateContactPresence(data);
    });
    communicatorSocketService.bindEvent(SocketEventTypes.ONLINEALLCONTACTS, (data: ContactPresence[]) => {
      this.contactStateService.loadContactsPresence(data);
    });
  }
}
