<div fxLayout="column">
  <div id="com-reconnect-div" [@connectionStatus]="connectionStatus">
    <ng-container *ngIf="socketInfo?.reconnectFailed; then disconnected; else reconnecting"></ng-container>
    <ng-template #disconnected>
      <div fxLayoutAlign="space-between center">
        <span>Disconnected</span>
        <button id="com-reconnect-button" mat-button (click)="reconnect()">Reconnect</button>
      </div>
    </ng-template>

    <ng-template #reconnecting>
      <div fxLayoutAlign="space-between center">
        <span>Reconnecting...</span>
        <button mat-button id="com-retry-button" (click)="reconnect()">Reconnect</button>
      </div>
    </ng-template>
  </div>
</div>
<div fxFlexFill fxLayout="row">
  <mat-sidenav-container fxFlexFill class="example-container" (backdropClick)="closeSidenav()">
    <mat-sidenav class="sidenav-menu" [(opened)]="sidenav" *ngIf="authenticatedUser$ | async as authenticatedUser">
      <mat-toolbar>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
          <button class="avatar-button-container" mat-icon-button>
            <sha-avatar
              [avatarUrl]="authenticatedUser.user.avatarUrl"
              [presence]="contactsPresence[authenticatedUser.user.id]"
              [initials]="[authenticatedUser.user.firstName.charAt(0) + authenticatedUser.user.lastName.charAt(0)]"
            >
            </sha-avatar>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayout="column" fxLayoutAlign="start start">
          <span fxFlex class="mat-title"
            >{{ authenticatedUser.user.firstName }} {{ authenticatedUser.user.lastName }}</span
          >
          <div fxLayout="row" fxLayoutAlign="space-between center" class="user-details-container">
            <span fxFlex class="mat-body-1 secondary-text">Version: {{ authenticatedUser.user.version }}</span>
            <button mat-button id="crm-logout-button" (click)="logout()">
              <span>Logout</span>
            </button>
            <!--  Will use the below once we have another option to add in addtition to Logout. -->
            <!-- <button mat-icon-button id="dropdown" [matMenuTriggerFor]="menu" class="secondary-text">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="crm-logout-button" (click)="logout()">
                <span>Logout</span>
              </button>
            </mat-menu> -->
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-divider></mat-divider>

      <div fxLayout="column" class="nav-link-container">
        <div
          class="nav-link"
          id="com-communicator-link"
          [ngClass]="{ selected: primaryRouteUrl === 'home' }"
          (click)="navigateMaster('master')"
        >
          <span class="nav-link-text">Communicator</span>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div id="crm-settings-link" class="nav-link" [routerLink]="'/settings'" (click)="closeSidenav()">
        <span class="nav-link-text">Settings</span>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
