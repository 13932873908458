import { ServiceGroup } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const SERVICEGROUPS_FEATURE_KEY = 'serviceLaneDropdowns';

//#region message-types
export interface ServiceGroupsState extends EntityState<ServiceGroup> {}
export const serviceGroupsAdapter: EntityAdapter<ServiceGroup> = createEntityAdapter<ServiceGroup>({
  selectId: (sg: ServiceGroup) => sg.id
});
export const initialServiceGroupsState: ServiceGroupsState = serviceGroupsAdapter.getInitialState({});
//#endregion
