import { Injectable } from '@angular/core';
declare var dataLayer: any;
declare var google_tag_manager: any;

@Injectable()
export class DataLayerService {
  containerId = 'GTM-WKB89SF';

  constructor() {}

  initializeUser(userProps: any) {
    this.setMultipleVariables(userProps);
    this.sendEvent('Initialize User');
  }

  sendEvent(eventName: string, variables?: any) {
    this.setMultipleVariables({ event: eventName, ...variables });
  }

  setMultipleVariables(pushedDataLayer: any) {
    dataLayer.push(pushedDataLayer);
  }

  setOneVariable(pushedPropertyKey: string, pushedPropertyValue: any) {
    google_tag_manager[this.containerId].dataLayer.set(pushedPropertyKey, pushedPropertyValue);
  }

  getOneVariable(propertyKey: string) {
    return google_tag_manager[this.containerId].dataLayer.get(propertyKey);
  }

  getFeatureFlag(featureName: string) {
    return google_tag_manager[this.containerId].dataLayer.get(`features.${featureName}`) == 'true';
  }
}
