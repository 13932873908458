import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public _imagesLoading = new Subject<number>();
  private images: Map<HTMLElement, boolean> = new Map();
  imagesLoading = 0;

  /* emit the counter for the subscribing component to know when all images finished loading */
  imagesLoading$ = this._imagesLoading.asObservable();

  /* keep track of the total number of attached images */
  imageLoading(img: HTMLElement) {
    if (!this.images.has(img) || this.images.get(img)) {
      this.images.set(img, false);
      this.imagesLoading++;

      this._imagesLoading.next(this.imagesLoading);
    }
  }

  /* keep track of the loaded number of attached images */
  imageLoadedOrError(img: HTMLElement) {
    if (this.images.has(img) && !this.images.get(img)) {
      this.images.set(img, true);
      this.imagesLoading--;
      this._imagesLoading.next(this.imagesLoading);
    }
  }
}
