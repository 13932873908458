<mat-toolbar>
  <mat-form-field fxFlex>
    <mat-chip-list *ngIf="selectedMembers$ | async as selectedMembers" #chipList>
      <mat-chip
        style="height: 53px !important"
        *ngFor="let member of selectedMembers"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(member)"
      >
        <div fxLayout="column">
          <span>{{ member.firstName }} {{ member.lastName }} </span>

          <ng-container *ngIf="!member.isEmployee" [ngSwitch]="member.embedded?.associate?.messagePreferenceId">
            <span *ngSwitchCase="1" class="mat-caption">{{ member.embedded?.associate?.emailAddress }}</span>
            <span *ngSwitchCase="2" class="mat-caption">{{
              member.embedded?.addresses[0]?.cellPhoneNumber | phoneNumber
            }}</span>
          </ng-container>
        </div>

        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        [disabled]="editorDisabled"
        id="contactSelectorInput"
        placeholder="To:"
        matInput
        #contactInput
        [formControl]="contactCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event, selectedMembers)"
      autoActiveFirstOption
    >
      <ng-container *ngIf="filteredContacts$ | async as filteredContacts">
        <mat-option style="display: none"></mat-option>
        <nav class="flex space-x-4 p-2" aria-label="Tabs">
          <span
            (click)="autoCompleteSelectedTab = 'contacts'"
            class="px-3 py-2 font-medium text-sm rounded-md"
            [ngClass]="{
              'cursor-pointer': autoCompleteSelectedTab != 'contacts',
              'hover:text-grey-700': autoCompleteSelectedTab != 'contacts',
              'text-grey-500': autoCompleteSelectedTab != 'contacts',
              'text-quorum-blue': autoCompleteSelectedTab == 'contacts',
              'bg-blue-50': autoCompleteSelectedTab == 'contacts'
            }"
          >
            Contacts
          </span>

          <span
            (click)="autoCompleteSelectedTab = 'customers'"
            *ngIf="!customerDisabled"
            class="px-3 py-2 font-medium text-sm rounded-md"
            [ngClass]="{
              'cursor-pointer': autoCompleteSelectedTab != 'customers',
              'hover:text-grey-700': autoCompleteSelectedTab != 'customers',
              'text-grey-500': autoCompleteSelectedTab != 'customers',
              'text-quorum-blue': autoCompleteSelectedTab == 'customers',
              'bg-blue-50': autoCompleteSelectedTab == 'customers'
            }"
          >
            Customers
          </span>
        </nav>

        <div *ngIf="autoCompleteSelectedTab == 'customers'; else contacts">
          <cdk-virtual-scroll-viewport itemSize="60" style="height: 188px">
            <mat-option
              fxLayout="row"
              fxLayoutAlign="start center"
              [disabled]="![1, 2].includes(contact.associate.messagePreferenceId)"
              *cdkVirtualFor="
                let contact of filteredContacts.customerContacts | orderBy: ['contact.firstName'];
                index as i;
                trackBy: contactTrackByFunction
              "
              [value]="contact"
            >
              <sha-avatar [avatarUrl]=""> </sha-avatar>
              <div class="contact-name" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <span> {{ contact.contact.firstName + ' ' + contact.contact.lastName }}</span>
                <div class="secondary-text text-sm pl-2">
                  <ng-container [ngSwitch]="contact.associate.messagePreferenceId">
                    <span *ngSwitchDefault>- Customer is missing contact information</span>
                    <span *ngSwitchCase="1">- Prefers {{ contact.associate.emailAddress }}</span>
                    <span *ngSwitchCase="2">- Prefers {{ contact.associate.cellPhoneNumber | phoneNumber }}</span>
                  </ng-container>
                </div>
              </div>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </div>
        <ng-template #contacts>
          <cdk-virtual-scroll-viewport itemSize="60" style="height: 204px">
            <mat-option
              fxLayout="row"
              *cdkVirtualFor="
                let contact of filteredContacts.employeeContacts | orderBy: ['firstName'];
                index as i;
                trackBy: contactTrackByFunction
              "
              [value]="contact"
            >
              <sha-avatar
                [avatarUrl]="contact.avatarUrl"
                [presence]="contactsPresence[contact.id]"
                [initials]="[contact.firstName.charAt(0) + contact.lastName.charAt(0)]"
              >
              </sha-avatar>
              <span class="contact-name" fxFlex> {{ contact.firstName + ' ' + contact.lastName }}</span>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <button
    [disabled]="editorDisabled"
    mat-icon-button
    color="primary"
    (click)="openContactSelectionDialog(selectedMembers)"
  >
    <mat-icon>add_circle_outline</mat-icon>
  </button>
</mat-toolbar>
