import { Store } from '../quorum/store.model';

export class Contact {
  avatarUrl: string;
  email: string;
  id: number;
  isActive: boolean;
  isAdministrator: boolean;
  isDealershipUser: boolean;
  firstName: string;
  lastName: string;
  nickName: string;
  store: Store;
  storeId: number;
  username: string;
  xselleratorEmployeeId: string;
  embedded?: any;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}

export const contactMock = new Contact({
  avatarUrl: 'localhost/sha-avatar.png',
  email: 'test@email.com',
  id: 1000,
  isActive: true,
  isAdministrator: false,
  isDealershipUser: true,
  firstName: 'John',
  lastName: 'Doe',
  nickName: 'John',
  store: null,
  storeId: 1000,
  username: 'doej',
  xselleratorEmployeeId: '14',
});

export const contactsMock: Contact[] = [
  contactMock,
  new Contact({
    avatarUrl: 'localhost/sha-avatar.png',
    email: 'test@email.com',
    id: 1001,
    isActive: true,
    isAdministrator: false,
    isDealershipUser: true,
    firstName: 'Jane',
    lastName: 'Doe',
    nickName: 'Jane',
    store: null,
    storeId: 1000,
    username: 'doeja',
    xselleratorEmployeeId: '15',
  }),
];
