import { CommunicatorContact as Contact } from '@quorum/models/xs-resource';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ContactsState {
  readonly contacts: Contact[];
  readonly errors: any;
  readonly isLoading: boolean;
  readonly presences: {};
  readonly groupConfiguration: { [id: number]: { id: number; collapsed: boolean } };
}

export const contactsFeatureSelector = createFeatureSelector<ContactsState>('contacts');
export const contactsErrorsSelector = createSelector(contactsFeatureSelector, (state: ContactsState) => state.errors);
export const contactsIsLoadingSelector = createSelector(
  contactsFeatureSelector,
  (state: ContactsState) => state.isLoading
);
export const contactsPresenceSelector = createSelector(
  contactsFeatureSelector,
  (state: ContactsState) => state.presences
);
export const contactsGroupConfigurationSelector = createSelector(
  contactsFeatureSelector,
  (state: ContactsState) => state.groupConfiguration
);
export const contactsSelector = createSelector(contactsFeatureSelector, (state: ContactsState) => state.contacts);
