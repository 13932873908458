import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromEvents from './events.actions';
import { eventsInitialState } from './events.init';
import { eventsAdapter, EventsState } from './events.interfaces';

export function eventsReducer(state: EventsState, action: fromEvents.EventsAction): EventsState {
  switch (action.type) {
    case fromEvents.POST_EVENT_TO_SERVER_SUCCESS: {
      return {
        ...eventsAdapter.updateOne({ id: action.payload.oldEvent.id, changes: action.payload.newEvent }, state),
      };
    }
    case fromEvents.ADD_EVENT_FROM_SOCKET_TO_STATE: {
      return {
        ...eventsAdapter.upsertOne(action.payload.event, state),
      };
    }
    case fromEvents.ADD_EVENT_TO_STATE: {
      return {
        ...eventsAdapter.upsertOne(action.payload.event, state),
      };
    }
    case fromEvents.DELETE_EVENTS_IN_STATE: {
      return { ...eventsAdapter.removeMany(action.payload, state) };
    }
    case fromEvents.GENERATE_PRESIGNED_URL_FOR_EVENT_SUCCESS: {
      return {
        ...eventsAdapter.upsertOne(action.payload, state),
        isLoading: false,
      };
    }
    case fromEvents.RESET_EVENT_STATE: {
      return eventsInitialState;
    }
    case fromEvents.UPDATE_IS_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case fromEvents.DISPLAY_NOTIFICATION_FOR_NEW_EVENT:
    case fromEvents.GET_EVENTS_FROM_SERVER:
    case fromEvents.GET_EVENTS_FROM_SERVER_FAILURE:
    case fromEvents.POST_EVENT_TO_SERVER:
    default: {
      return state;
    }
  }
}

export function eventsStateHydration(reducer: ActionReducer<EventsState>): ActionReducer<EventsState> {
  return function (state: EventsState, action: any, metaReducer: string = 'stateHydration'): EventsState {
    if (state === undefined) {
      let eventsState: any = localStorage.getItem('events-state');
      state = eventsState ? JSON.parse(eventsState) : eventsInitialState;
    }
    const newState = reducer(state, action);
    if (action.feature === 'events' && action.triggerStorageSync) {
      localStorage.setItem('events-state', JSON.stringify(newState));
    }
    return newState;
  };
}

export const metaReducers: MetaReducer<EventsState>[] = [eventsStateHydration];
