import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromActions from './files.actions';
import * as fromFiles from './files.reducer';
import * as FilesSelectors from './files.selectors';
import { FileEntity } from './files.models';

@Injectable()
export class FilesFacade {
  loaded$ = this.store.pipe(select(FilesSelectors.getFilesLoaded));
  allFiles$ = this.store.pipe(select(FilesSelectors.getAllFiles));

  constructor(private store: Store<fromFiles.FilesPartialState>) {}

  addFile = (file: FileEntity) => {
    this.store.dispatch(fromActions.addFile({ file }));
  };
  loadFiles = (conversationId: number) => {
    this.store.dispatch(fromActions.loadFiles({ conversationId }));
  };
}
