import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Template } from '@quorum/models/xs-resource';
//@ts-ignore
import * as grapesjs from 'grapesjs';
import { DocParser } from '../../../../../server/api/doc-parser.class';
import messageTypeDefinitions from '../../../../../server/serverless/aws/stand-alone/communicator/resource-definition/definition.json';

@Component({
  selector: 'quorum-slim-editor',
  templateUrl: './slim-editor.component.html',
  styleUrls: ['./slim-editor.component.scss'],
  providers: [DocParser],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlimEditorComponent implements OnInit, OnChanges {
  @Input() originalTemplate: Template;
  @Input() language: string = 'en';
  @Output()
  onTemplateChanged: EventEmitter<Template> = new EventEmitter<Template>();
  constructor(private docParser: DocParser) {}

  ngOnInit() {}

  ngOnChanges(changes: any) {
    if (changes.originalTemplate && changes.originalTemplate.currentValue) {
      this.initializeControl();
    }
  }

  initializeControl(): void {
    let that = this;

    const editor: any = grapesjs.init({
      container: '#gjs',
      plugins: ['@quorum/grapesjs-mjml'],
      pluginsOpts: {
        ['@quorum/grapesjs-mjml']: {},
      },
      style: '',
      height: '100%',
      showDevices: false,
      blockManager: {},
      styleManager: {},
      traitManager: {},
      deviceManager: [],
      panels: {
        defaults: [],
      },
      storageManager: {
        type: 'quorum',
        autosave: false,
        autoload: false,
      },
    });

    editor.Panels.removePanel('devices-c');

    editor.setComponents(this.originalTemplate.languageDefinitionMap[this.language].mjml);

    editor.on('update', () => {
      let emittedTemplate = {
        ...this.originalTemplate,
        languageDefinitionMap: {
          ...this.originalTemplate.languageDefinitionMap,
          [this.language]: {
            ...this.originalTemplate.languageDefinitionMap[this.language],
            html: editor.runCommand('mjml-get-code').html,
            mjml: editor.getHtml(),
          },
        },
      };

      that.onTemplateChanged.emit(emittedTemplate);
    });
    editor.on('component:selected', () => {
      editor.getSelected().set({ toolbar: [] });
    });
    editor.on('run:open-assets', function () {
      editor.Modal.close();
    });
    editor.on('storage:load', (response: any) => {
      const messageTypeId = this.originalTemplate.messageTypeId;
      response = {};
      let rteOptions = {
        icon: '',
        attributes: {
          title: 'Placeholders',
        },
        event: 'change',
        result: (rte: any, action: any) => {
          return rte.insertHTML(action.btn.firstChild.nextSibling.value);
        },
        update: (rte: any, action: any) => {
          action.btn.firstChild.nextSibling.value = '';
        },
      };

      rteOptions.icon = `
      <select name="placeholders" id="placeholders" style="color:#FFF;" class="gjs-field">
        <option value="">--Placeholders--<option>
      `;

      let source = Object.keys(messageTypeDefinitions[messageTypeId].resources.source)[0];
      let apiLookup1 =
        messageTypeDefinitions[messageTypeId].resources.source[
          Object.keys(messageTypeDefinitions[messageTypeId].resources.source)[0]
        ].apiResource.split('/')[0];

      let apiLookup2 =
        messageTypeDefinitions[messageTypeId].resources.source[
          Object.keys(messageTypeDefinitions[messageTypeId].resources.source)[0]
        ].apiResource.split('/')[1];

      const camel2title = (camelCase: string) =>
        camelCase.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());

      let def = this.docParser.getPropAndDescriptions(apiLookup1, `/${apiLookup2}`, 2);

      rteOptions.icon += `<optgroup label="${camel2title(source)}">`;

      Object.keys(def.primaryResource)
        .filter((key) => def.primaryResource[key]['x-template-placeholder'])
        .forEach((key) => {
          if (def.primaryResource[key].format == 'date-time') {
            rteOptions.icon += `<option value="{{${source}.${key}?format=time}}" title="${
              def.primaryResource[key].description
            }">${camel2title(key)
              .replace(/([A-Z]+)/g, ' $1')
              .replace(/([A-Z][a-z])/g, ' $1')} - Time (eg. '5:30 PM')</option>`;

            rteOptions.icon += `<option value="{{${source}.${key}?format=day}}" title="${
              def.primaryResource[key].description
            }">${camel2title(key)
              .replace(/([A-Z]+)/g, ' $1')
              .replace(/([A-Z][a-z])/g, ' $1')} - Day (eg. 'Monday')</option>`;

            rteOptions.icon += `<option value="{{${source}.${key}?format=fullDate}}" title="${
              def.primaryResource[key].description
            }">${camel2title(key)
              .replace(/([A-Z]+)/g, ' $1')
              .replace(/([A-Z][a-z])/g, ' $1')} - Full Date (eg. 'Mar. 15th, 2021')</option>`;
          } else {
            rteOptions.icon += `<option value="{{${source}.${key}}}" title="${
              def.primaryResource[key].description
            }">${camel2title(key)
              .replace(/([A-Z]+)/g, ' $1')
              .replace(/([A-Z][a-z])/g, ' $1')}</option>`;
          }
        });

      if (
        def.embeds &&
        messageTypeDefinitions[messageTypeId].resources.source[
          Object.keys(messageTypeDefinitions[messageTypeId].resources.source)[0]
        ].embedded
      ) {
        Object.keys(def.embeds).forEach((embed) => {
          rteOptions.icon += `<optgroup label="${camel2title(apiLookup2)} - ${camel2title(embed)}">`;

          Object.keys(def.embeds[embed]).forEach((key) => {
            rteOptions.icon += `<option value={{${apiLookup2}.embedded.${embed}.${key}}} title="${
              def.embeds[embed][key].description
            }">${camel2title(key)
              .replace(/([A-Z]+)/g, ' $1')
              .replace(/([A-Z][a-z])/g, ' $1')}</option>`;
          });
        });
      }

      Object.keys(messageTypeDefinitions[messageTypeId].resources.dependencies).forEach((dep) => {
        let nestedApiLookup1 =
          messageTypeDefinitions[messageTypeId].resources.dependencies[dep].apiResource.split('/')[0];

        let nestedApiLookup2 =
          messageTypeDefinitions[messageTypeId].resources.dependencies[dep].apiResource.split('/')[1];

        let def = this.docParser.getPropAndDescriptions(nestedApiLookup1, `/${nestedApiLookup2}`, 2);

        rteOptions.icon += `<optgroup label="${camel2title(dep)}">`;
        Object.keys(def.primaryResource)
          .filter((key) => def.primaryResource[key]['x-template-placeholder'])
          .forEach((key) => {
            if (def.primaryResource[key].format == 'date-time') {
              rteOptions.icon += `<option value="{{${dep}.${key}?format=time}}" title="${
                def.primaryResource[key].description
              }">${camel2title(key)
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')} - Time (eg. '5:30 PM')</option>`;

              rteOptions.icon += `<option value="{{${dep}.${key}?format=day}}" title="${
                def.primaryResource[key].description
              }">${camel2title(key)
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')} - Day (eg. 'Monday')</option>`;

              rteOptions.icon += `<option value="{{${dep}.${key}?format=fullDate}}" title="${
                def.primaryResource[key].description
              }">${camel2title(key)
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')} - Full Date (eg. 'Mar. 15th, 2021')</option>`;
            } else {
              rteOptions.icon += `<option value="{{${dep}.${key}}}" title="${
                def.primaryResource[key].description
              }">${camel2title(key)
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')}</option>`;
            }
          });

        rteOptions.icon += `</optgroup>`;
      });

      rteOptions.icon += `</select> `;
      editor.RichTextEditor.add('placeholders', rteOptions);
      editor.RichTextEditor.remove('link');
    });

    editor.load();
  }
}
