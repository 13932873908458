import { HttpHeaders } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { EventQueryParameters } from '@quorum/models/xs-query';
import { Event, Template } from '@quorum/models/xs-resource';

export const ADD_EVENT_TO_STATE = '[EVENTS] Add Event To State';
export class AddEventToState implements Action {
  readonly type = ADD_EVENT_TO_STATE;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: { event: Event; headers?: HttpHeaders }) {}
}

export const ADD_EVENT_FROM_SOCKET_TO_STATE = '[EVENTS] Add Event From Socket To State';
export class AddEventFromSocketToState implements Action {
  readonly type = ADD_EVENT_FROM_SOCKET_TO_STATE;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: { event: Event }) {}
}

export const DELETE_EVENTS_IN_STATE = '[EVENTS] Delete Events In State';
export class DeleteEventsInState implements Action {
  readonly type = DELETE_EVENTS_IN_STATE;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: number[]) {}
}

export const DISPLAY_NOTIFICATION_FOR_NEW_EVENT = '[EVENTS] Display Notification';
export class DisplayNotificationForNewEvent implements Action {
  readonly type = DISPLAY_NOTIFICATION_FOR_NEW_EVENT;
  readonly feature = 'events';
  constructor(public payload: Event) {}
}

export const GET_EVENTS_FROM_SERVER = '[EVENTS] Get Events From Server';
export class GetEventsFromServer implements Action {
  readonly type = GET_EVENTS_FROM_SERVER;
  readonly feature = 'events';
  constructor(public payload: EventQueryParameters) {}
}

export const GET_EVENTS_FROM_SERVER_SUCCESS = '[EVENTS] Get Events From Server Success';
export class GetEventsFromServerSuccess implements Action {
  readonly type = GET_EVENTS_FROM_SERVER_SUCCESS;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: { events: Event[] }) {}
}

export const GET_EVENTS_FROM_SERVER_FAILURE = '[EVENTS] Get Events From Server Failure';
export class GetEventsFromServerFailure implements Action {
  readonly type = GET_EVENTS_FROM_SERVER_FAILURE;
  readonly feature = 'events';
  constructor(public payload: { queryParameters: EventQueryParameters; error: any }) {}
}

export const GENERATE_PRESIGNED_URL_FOR_EVENT = '[EVENTS] Presign Event From Server';
export class GeneratePresignedUrlForEvent implements Action {
  readonly type = GENERATE_PRESIGNED_URL_FOR_EVENT;
  readonly feature = 'events';
  constructor(public payload: Event) {}
}

export const GENERATE_PRESIGNED_URL_FOR_EVENT_SUCCESS = '[EVENTS] Presign Event From Server Success';
export class GeneratePresignedUrlForEventSuccess implements Action {
  readonly type = GENERATE_PRESIGNED_URL_FOR_EVENT_SUCCESS;
  readonly feature = 'events';
  //readonly triggerStorageSync = true;
  constructor(public payload: Event) {}
}

export const GENERATE_PRESIGNED_URL_FOR_EVENT_FAILURE = '[EVENTS] Presign Event From Server Failure';
export class GeneratePresignedUrlForEventFailure implements Action {
  readonly type = GENERATE_PRESIGNED_URL_FOR_EVENT_FAILURE;
  readonly feature = 'events';
  constructor(public payload: { event: Event; error: any }) {}
}

export const POST_EVENT_TO_SERVER = '[EVENTS] Post Event To Server';
export class PostEventToServer implements Action {
  readonly type = POST_EVENT_TO_SERVER;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: { event: Event; template?: Template }) {}
}

export const POST_EVENT_TO_SERVER_FAILURE = '[EVENTS] Post Event To Server Failure';
export class PostEventToServerFailure implements Action {
  readonly type = POST_EVENT_TO_SERVER_FAILURE;
  readonly feature = 'events';
  constructor(public payload: { event: Event; error: any }) {}
}

export const POST_EVENT_TO_SERVER_SUCCESS = '[EVENTS] Post Event To Server Success';
export class PostEventToServerSuccess implements Action {
  readonly type = POST_EVENT_TO_SERVER_SUCCESS;
  readonly feature = 'events';
  readonly triggerStorageSync = true;
  constructor(public payload: { newEvent: Event; oldEvent: Event }) {}
}

export const RESET_EVENT_STATE = '[EVENTS] Reset Event State';
export class ResetEventState implements Action {
  readonly type = RESET_EVENT_STATE;
  readonly feature = 'events';
  constructor(public payload: {}) {}
}

export const UPDATE_IS_LOADING = '[EVENTS] Update Is Loading';
export class UpdateIsLoading implements Action {
  readonly type = UPDATE_IS_LOADING;
  readonly feature = 'events';
  constructor(public payload: boolean) {}
}

export type EventsAction =
  | AddEventFromSocketToState
  | AddEventToState
  | DeleteEventsInState
  | DisplayNotificationForNewEvent
  | GetEventsFromServer
  | GetEventsFromServerFailure
  | GetEventsFromServerSuccess
  | PostEventToServer
  | PostEventToServerFailure
  | PostEventToServerSuccess
  | ResetEventState
  | UpdateIsLoading
  | GeneratePresignedUrlForEvent
  | GeneratePresignedUrlForEventFailure
  | GeneratePresignedUrlForEventSuccess;
