import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserControlCommunicationService, DataLayerService } from '@applications/sha-services';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { fromRouter } from '@quorum/sha-router';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';
import { concatMap, delay, map, flatMap, switchMap, mergeMap } from 'rxjs/operators';
import { AuthenticationStateService } from '../authentication-state.service';
import * as fromAuthentication from './authentication.actions';
import { AuthenticatedUser, AuthenticationState } from './authentication.interfaces';

@Injectable()
export class AuthenticatedUserEffects {
  authenticateUser = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.AUTHENTICATE_USER, {
      run: (a: fromAuthentication.AuthenticateUser, state: AuthenticationState) => {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', 'Basic ' + btoa('12345' + ':' + 'secret'));
        const body =
          'grant_type=password&username=' + a.payload.username + '&password=' + encodeURIComponent(a.payload.password);
        return this.apiService
          .post<string>('oauth/token', body, {
            headers: headers,
            observe: 'response',
            withCredentials: true,
          })
          .pipe(
            concatMap((token: any) => {
              let newHeaders: HttpHeaders = new HttpHeaders();
              newHeaders = newHeaders.set('Authorization', 'Bearer ' + token.access_token);
              return this.apiService.get<string>('oauth/user-from-token', { headers: newHeaders }).pipe(
                mergeMap((user: any) => {
                  let domain = '';

                  if (window.document.domain.indexOf('xsellerator.dev') > -1) {
                    if (window.document.domain.indexOf('gm2') > -1) {
                      domain = 'gm2.xsellerator.dev';
                    } else {
                      domain = 'gm.xsellerator.dev';
                    }
                  } else if (window.document.domain.indexOf('xsellerator') > -1) {
                    domain = '.xsellerator.com';
                  } else if (window.document.domain.indexOf('qcommunicator') > -1) {
                    domain = '.qcommunicator.com';
                  } else if (window.document.domain.includes('192.168')) {
                    domain = '192.168';
                  } else if (window.document.domain.indexOf('halcyontek') > -1) {
                    domain = '.halcyontek.com';
                  } else if (window.document.domain.indexOf('xsellerator.dev') > -1) {
                    if (window.document.domain.indexOf('gm2') > -1) {
                      domain = 'gm2.xsellerator.dev';
                    } else {
                      domain = 'gm.xsellerator.dev';
                    }
                  } else {
                    domain = 'localhost';
                  }
                  console.log(domain);
                  this.cookieService.set('quorum', token.access_token, 1209600, '/', domain);
                  return from([new fromAuthentication.UserAuthenticated({ token: token.access_token, user: user })]);
                })
              );
            })
          );
      },
      onError: (a: fromAuthentication.AuthenticateUser, error) => {
        return new fromAuthentication.UserAuthenticatedError({
          message: 'Invalid username or password.',
          error: error.reason,
        });
      },
    })
  );

  checkCookieForAuthentication = createEffect(() =>
    this.d.fetch(fromAuthentication.CHECK_COOKIE_FOR_AUTHENTICATION, {
      run: (a: fromAuthentication.CheckCookieForAuthentication, state: AuthenticationState) => {
        if (window.location.pathname != '/login') {
          let token = this.cookieService.get('quorum');
          const urlParams = new URLSearchParams(window.location.search);
          //token = token.length == 0 ? urlParams.get('token') : token;
          token = urlParams.get('token') ? urlParams.get('token') : token;
          if (token) {
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + token);
            return this.apiService.get<string>('oauth/user-from-token', { headers: headers }).pipe(
              map((user: any) => {
                if (
                  this.cookieService.get('X-Source') &&
                  this.cookieService.get('X-Source').toLowerCase() !== user.version.toLowerCase()
                ) {
                  this.cookieService.set('X-Source', user.version);
                  location.reload();
                  return new fromAuthentication.UserAuthenticated({ token: token, user: user });
                } else {
                  return new fromAuthentication.UserAuthenticated({ token: token, user: user });
                }
              })
            );
          } else {
            if (this.browserCommunicationService.embeddedInXsellerator) {
              const silentToken = this.browserCommunicationService.getSilentLoginToken();
              return this.apiService.post<string>('oauth/silent-login', { token: silentToken }).pipe(
                switchMap((tokenGeneratedFromSilent: any) => {
                  let headers: HttpHeaders = new HttpHeaders();
                  headers = headers.set('Authorization', 'Bearer ' + tokenGeneratedFromSilent);
                  return this.apiService.get<string>('oauth/user-from-token', { headers: headers }).pipe(
                    map((user: any) => {
                      const currentSource = this.cookieService.get('X-Source');
                      if (
                        this.cookieService.get('X-Source') &&
                        currentSource.toLowerCase() !== user.version.toLowerCase()
                      ) {
                        this.cookieService.set('X-Source', user.version);
                        location.reload();
                      }
                      return new fromAuthentication.UserAuthenticated({
                        token: tokenGeneratedFromSilent,
                        user: user,
                      });
                    })
                  );
                })
              );
            } else {
              localStorage.clear();
              return new fromAuthentication.UserAuthenticatedError(null);
            }
          }
        } else {
          //localStorage.clear();
          return new fromAuthentication.UserAuthenticatedError(null);
        }
      },
      onError: (a: fromAuthentication.CheckCookieForAuthentication, error) => {
        if (this.browserCommunicationService.embeddedInXsellerator) {
          return new fromAuthentication.AttemptSilentLogin({});
        } else {
          return new fromAuthentication.UserAuthenticatedError(null);
        }
      },
    })
  );

  attemptSilentLogin = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.ATTEMPT_SILENT_LOGIN, {
      run: (a: fromAuthentication.AttemptSilentLogin, state: AuthenticationState) => {
        const silentToken = this.browserCommunicationService.getSilentLoginToken();
        return this.apiService.post<string>('oauth/silent-login', { token: silentToken }).pipe(
          switchMap((tokenGeneratedFromSilent: any) => {
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + tokenGeneratedFromSilent);
            return this.apiService.get<string>('oauth/user-from-token', { headers: headers }).pipe(
              map((user: any) => {
                const currentSource = this.cookieService.get('X-Source');
                if (this.cookieService.get('X-Source') && currentSource.toLowerCase() !== user.version.toLowerCase()) {
                  this.cookieService.set('X-Source', user.version);
                  location.reload();
                }
                return new fromAuthentication.UserAuthenticated({ token: tokenGeneratedFromSilent, user: user });
              })
            );
            //return new fromAuthentication.UserAuthenticated({ token: token, user: user });
          })
        );
      },
      onError: (a: fromAuthentication.AttemptSilentLogin, error) => {
        return new fromAuthentication.UserAuthenticatedError({
          message: 'Error authenticating user.',
          error: error.reason,
        });
      },
    })
  );

  logout = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.LOGOUT, {
      run: (a: fromAuthentication.Logout, state: AuthenticationState) => {
        return this.apiService.post<AuthenticatedUser>('oauth/logout', state.authenticatedUser).pipe(
          flatMap((payload) => {
            this.cookieService.delete('quorum', '/', 'localhost');
            localStorage.clear();
            return from([new fromAuthentication.LogoutSuccess({}), new fromRouter.Go({ path: ['login'] })]);
          })
        );
      },
      onError: (a: fromAuthentication.Logout, error) => {
        this.cookieService.delete('quorum', '/', 'localhost');
        localStorage.clear();
        return new fromAuthentication.LogoutError(error);
      },
    })
  );

  getApiCredentials = createEffect(() =>
    this.d.pessimisticUpdate(fromAuthentication.GET_API_CREDENTIALS_FOR_EMPLOYEE, {
      run: (a: fromAuthentication.GetApiCredentialsForEmployee, state: AuthenticationState) => {
        this.apiService.post('oauth/test', { token: a.payload.token }).subscribe((key) => {
          console.log(key);
          if (!key.error) {
            this.authenticationStateService.quorumEmployeeLoginSuccessful(
              key.apiKey,
              key.destinationId,
              a.payload.redirectUrl
            );
          } else {
            this.authenticationStateService.quorumEmployeeLoginFailure();
          }
        });
      },
      onError: (a: fromAuthentication.GetApiCredentialsForEmployee, error) => {},
    })
  );

  userAuthenticated = createEffect(() =>
    this.d.fetch(fromAuthentication.USER_AUTHENTICATED, {
      run: (a: fromAuthentication.UserAuthenticated, state: any) => {
        // this.dataLayerService.initializeUser({
        //   xselleratorEmployeeId: state.authentication.authenticatedUser.user.xselleratorEmployeeId,
        //   destinationId: state.authentication.authenticatedUser.user.destination,
        //   userId: state.authentication.authenticatedUser.user.id,
        // });
      },
      onError: (a: fromAuthentication.UserAuthenticated, error) => {
        return new fromAuthentication.UserAuthenticatedError(error);
      },
    })
  );

  userAuthenticatedError = createEffect(() =>
    this.d.fetch(fromAuthentication.USER_AUTHENTICATED_ERROR, {
      run: (a: fromAuthentication.UserAuthenticatedError, state: AuthenticationState) => {
        this.cookieService.delete('quorum', '/', 'localhost');
        localStorage.clear();
      },
      onError: (a: fromAuthentication.UserAuthenticatedError, error) => {
        console.log('Error during user authenticated error effect.');
      },
    })
  );

  monitorAuthenticationStages = createEffect(() =>
    this.d.fetch(fromAuthentication.MONITOR_AUTHENTICATION_STAGES, {
      run: (a: fromAuthentication.MonitorAuthenticationStages, state: AuthenticationState) => {
        return this.authenticationStateService.authenticationStagesComplete().pipe(
          delay(500),
          map((complete) => {
            return new fromRouter.GoToRoot();
          })
        );
      },
      onError: (a: fromAuthentication.MonitorAuthenticationStages, error) => {
        return new fromAuthentication.MonitorAuthenticationStagesError(error);
      },
    })
  );

  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private cookieService: CookieService,
    private d: DataPersistence<AuthenticationState>,
    private dataLayerService: DataLayerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationStateService: AuthenticationStateService,
    private browserCommunicationService: BrowserControlCommunicationService
  ) {}
}
