import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { of } from 'rxjs';
import { SocketConnectionInfoState } from './sockets.interfaces';
import { SocketStateService } from '../socket-state.service';
import { CommunicatorSocketService } from '@quorum/com-sockets/services';
import * as fromSockets from './sockets.actions';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SocketsEffects {
  
  createSocketConnection = createEffect(() => this.d.fetch(fromSockets.CREATE_SOCKET_CONNECTION, {
    run: (c: fromSockets.CreateSocketConnection, state: SocketConnectionInfoState) => {
      this.socketStateService
        .selectSocketConnectionInfos()
        .pipe(
          take(1),
          map((socketConnectionInfos: any) => {
            socketConnectionInfos.forEach((connectionInfo: any) => {
              if (connectionInfo.service === 'communicator') {
                const communicatorSocketService: CommunicatorSocketService = this.injector.get(
                  CommunicatorSocketService
                );
                communicatorSocketService.initSocket(connectionInfo.url, { ...connectionInfo.opts });
              }
            });
          })
        )
        .subscribe();
    },
    onError: (a: fromSockets.CreateSocketConnection, error) => {
      console.error('Error', error);
      return new fromSockets.CreateSocketConnectionFailure({
        message: 'Error establishing connection.',
        error: error.reason
      });
    }
  }));

  constructor(
    private actions: Actions,
    private d: DataPersistence<SocketConnectionInfoState>,
    private socketStateService: SocketStateService,
    private injector: Injector
  ) { }
}
