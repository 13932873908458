import { ProvinceState } from './province-state.model';
import { Associate } from './associate.model';
import { County } from './county.model';
import { Location } from './location.model';

export class Address {
  addressLine1: string = null;
  addressLine2: string = null;
  addressLine3: string = null;
  associateId: string = null;
  cellPhoneNumber: string = null;
  cellPhoneNumberExtension: string = null;
  countryId: string = null;
  countyId: number = null;
  embedded?: AddressEmbed = null;
  homePhoneNumber: string = null;
  homePhoneNumberExtension: string = null;
  locationId: string = null;
  postalZipCode: string = null;
  provStateId: string = null;
  sequence: number = null;
  workPhoneNumber: string = null;
  workPhoneNumberExtension: string = null;
  [key: string]: string | number | any;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class AddressEmbed {
  associate?: Associate = null;
  county?: County = null;
  location?: Location = null;
  provState?: ProvinceState = null;
}
