import { createAction, props } from '@ngrx/store';
import { MessageTypeQueryParameters } from '@quorum/models/xs-query';
import { CommunicatorMessageType as MessageType, Template } from '@quorum/models/xs-resource';

export const loadMessageTypesFromServer = createAction(
  '[Templates] Load Message Types From Server',
  props<MessageTypeQueryParameters>()
);
export const loadMessageTypesFromServerSuccess = createAction(
  '[Templates] Load Message Types From Server Success',
  props<{ messageTypes: MessageType[] }>()
);
export const loadMessageTypesFromServerFailure = createAction(
  '[Templates] Load Message Types From Server Failure',
  props<{ error: any }>()
);

export const setSelectedTemplate = createAction(
  '[Templates] Set Selected Template In State',
  props<{ etag: string; template: Template }>()
);

export const setSelectedLanguage = createAction(
  '[Templates] Set Selected Language In State',
  props<{ language: string }>()
);

export const resetState = createAction('[Templates] Reset State');
