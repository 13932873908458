import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Member } from '@quorum/models/xs-resource';

export interface MembersState extends EntityState<Member> {
  readonly ids: number[];
  readonly entities: { [id: number]: Member };
  readonly errors: any;
  readonly isLoading: boolean;
}

export const membersAdapter: EntityAdapter<Member> = createEntityAdapter<Member>();

export const membersFeatureSelector = createFeatureSelector<MembersState>('members');
export const { selectIds, selectEntities, selectAll, selectTotal } =
  membersAdapter.getSelectors(membersFeatureSelector);

export const memberErrorsSelector = createSelector(membersFeatureSelector, (state: MembersState) => state.errors);
export const membersIsLoadingSelector = createSelector(
  membersFeatureSelector,
  (state: MembersState) => state.isLoading
);
