import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommunicatorUiNgFilesModule } from '@quorum/communicator/ui/ng/files';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { SharedDirectivesModule } from '@quorum/sha-directives';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgPipesModule } from 'ngx-pipes';
import { ConversationBodyComponent } from './conversation-body/conversation-body.component';
import { ConversationEditorComponent } from './conversation-editor/conversation-editor.component';
// eslint-disable-next-line max-len
import { ConversationExtendedWarrantyComponent } from './conversation-extended-warranty/conversation-extended-warranty.component';
import { ConversationHeaderComponent } from './conversation-header/conversation-header.component';
import { ConversationInfoShellComponent } from './conversation-info-shell/conversation-info-shell.component';
import { ConversationPartsRequestComponent } from './conversation-parts-request/conversation-parts-request.component';
// eslint-disable-next-line max-len
import { ConversationServiceQuoteAdvisorReadyComponent } from './conversation-service-quote-advisor-ready/conversation-service-quote-advisor-ready.component';
// eslint-disable-next-line max-len
import { ConversationServiceQuotePartsPricingComponent } from './conversation-service-quote-parts-pricing/conversation-service-quote-parts-pricing.component';
import { ConversationShellComponent } from './conversation-shell/conversation-shell.component';
import { ConversationsListItemComponent } from './conversations-list-item/conversations-list-item.component';
import { ConversationsListComponent } from './conversations-list/conversations-list.component';
// eslint-disable-next-line max-len
import { DirectMessageTemplatesDialogComponent } from './direct-message-templates/direct-message-templates-dialog/direct-message-templates-dialog.component';
// eslint-disable-next-line max-len
import { DirectMessageTemplatesListComponent } from './direct-message-templates/direct-message-templates-list/direct-message-templates-list.component';
import { EmojiPanelComponent } from './emoji-panel/emoji-panel.component';
import { GroupsShellComponent } from './groups-shell/groups-shell.component';
import { NewConversationHeaderComponent } from './new-conversation-header/new-conversation-header.component';
import { NewConversationShellComponent } from './new-conversation-shell/new-conversation-shell.component';
import { TypingIndicatorComponent } from './typing-indicator/typing-indicator.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommunicatorUiNgEventsModule } from '@quorum/communicator/ui/ng/events';
import { NewConversationDialogComponent } from './new-conversation-dialog/new-conversation-dialog.component';
@NgModule({
    imports: [
        CommunicatorUiNgFilesModule,
        CommunicatorUiNgEventsModule,
        CommonMaterialComponentsModule,
        CommonModule,
        CommonPipesModule,
        FlexLayoutModule,
        FormsModule,
        InfiniteScrollModule,
        NgPipesModule,
        ReactiveFormsModule,
        ShaComponentsNgUiModule,
        SharedDirectivesModule,
        MatProgressSpinnerModule,
        PortalModule,
        ScrollingModule,
    ],
    exports: [
        ConversationBodyComponent,
        ConversationEditorComponent,
        ConversationHeaderComponent,
        ConversationInfoShellComponent,
        ConversationShellComponent,
        ConversationsListComponent,
        ConversationsListItemComponent,
        GroupsShellComponent,
        EmojiPanelComponent,
        NewConversationHeaderComponent,
        NewConversationShellComponent,
        TypingIndicatorComponent,
    ],
    declarations: [
        ConversationBodyComponent,
        ConversationEditorComponent,
        ConversationHeaderComponent,
        ConversationInfoShellComponent,
        ConversationsListComponent,
        ConversationShellComponent,
        ConversationsListItemComponent,
        EmojiPanelComponent,
        NewConversationHeaderComponent,
        NewConversationShellComponent,
        TypingIndicatorComponent,
        ConversationPartsRequestComponent,
        ConversationExtendedWarrantyComponent,
        ConversationServiceQuotePartsPricingComponent,
        ConversationServiceQuoteAdvisorReadyComponent,
        DirectMessageTemplatesListComponent,
        DirectMessageTemplatesDialogComponent,
        GroupsShellComponent,
        NewConversationDialogComponent,
    ]
})
export class CommunicatorUiNgConversationsModule {}
