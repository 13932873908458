<div mat-dialog-title>
  <h1>{{ data.title }}</h1>
  <mat-divider></mat-divider>
</div>
<div fxLayout="column">
  <mat-form-field color="white">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let member of selectedMembers | orderBy: ['firstName', 'lastName']"
        [selectable]="selectable"
        [removable]="removable"
        (remove)="remove(member)"
      >
        {{ member.firstName }} {{ member.lastName }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Selected contacts:"
        matInput
        #contactInput
        [formControl]="contactCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autoCompleteSelected($event)">
      <mat-option
        class="contact-container"
        fxLayout="row"
        *ngFor="let contact of filteredContacts$ | async | orderBy: ['firstName', 'lastName']"
        [value]="contact"
      >
        <div class="avatar-container">
          <sha-avatar
            matListIcon
            [avatarUrl]="contact.avatarUrl"
            [presence]="contactsPresence[contact.id]"
            [initials]="[contact.firstName.charAt(0) + contact.lastName.charAt(0)]"
          >
          </sha-avatar>
        </div>

        <span fxFlex> {{ contact.firstName + ' ' + contact.lastName }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div mat-dialog-content>
    <mat-selection-list #people>
      <mat-list-option
        [ngClass]="{ 'mat-list-item-focus': contact.selected }"
        *ngFor="let contact of contactsList | orderBy: ['contact.firstName', 'contact.lastName']"
        [selected]="contact.selected"
        [disabled]="contact.preselected"
        [value]="contact"
        (click)="onListControlChanged(people)"
      >
        <sha-avatar
          matListIcon
          [avatarUrl]="contact.contact.avatarUrl"
          [presence]="contactsPresence[contact.contact.id]"
          [initials]="[contact.contact.firstName.charAt(0) + contact.contact.lastName.charAt(0)]"
        >
        </sha-avatar>
        <h4 matLine class="primary-text">{{ contact.contact.firstName }} {{ contact.contact.lastName }}</h4>
        <p matLine class="secondary-text">
          {{ contact.contact.embedded?.employee?.embedded?.jobTitle?.description }}
          {{
            contact.contact.embedded?.employee?.embedded?.jobTitle?.description &&
            contact.contact.embedded?.employee?.embedded?.department?.description
              ? '-'
              : ''
          }}
          {{ contact.contact.embedded?.employee?.embedded?.department?.description }}
        </p>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button color="primary" (click)="onDone(people.selectedOptions.selected)" cdkFocusInitial>Add</button>
</div>
