import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiStateService } from '@quorum/communicator/state/root';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'com-communicator-settings',
  templateUrl: './communicator-settings.component.html',
  providers: [UiStateService],
  styleUrls: ['./communicator-settings.component.scss'],
})
export class CommunicatorSettingsComponent implements OnInit, OnDestroy {
  sidenav: Boolean;
  sidenavStatus$: Subscription;
  constructor(private uiStateService: UiStateService) {}

  ngOnInit() {
    this.sidenavStatus$ = this.uiStateService.selectSidenavVisible().subscribe((res) => (this.sidenav = res));
  }

  ngOnDestroy() {
    this.sidenavStatus$.unsubscribe();
  }

  toggleSidenav() {
    this.uiStateService.toggleSidenav(!this.sidenav);
  }
}
