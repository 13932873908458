import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Media, MediaParams } from '@quorum/models/xs-resource';
import {
  mediaAdapter,
  mediaParamsAdapter,
  MediaParamsState,
  MediaPartialState,
  MediaState,
  MEDIA_FEATURE_KEY,
  State,
  MediaUploadingState,
  mediaUploadingAdapter,
} from './media.reducer';

// Lookup the 'Media' feature state managed by NgRx
export const getMediaFeatureState = createFeatureSelector<State>(MEDIA_FEATURE_KEY);
export const getMediaState = createSelector(getMediaFeatureState, (state: State) => state.media);
export const getMediaParamsState = createSelector(getMediaFeatureState, (state: State) => state.mediaParams);
export const getMediaUploadingState = createSelector(getMediaFeatureState, (state: State) => state.mediaUploading);

const { selectAll, selectEntities, selectIds } = mediaAdapter.getSelectors();

export const selectMediaIds = mediaAdapter.getSelectors().selectIds;

//const { selectAll, selectEntities, selectIds } = mediaParamsAdapter.getSelectors();
const selectAllMediaParams = mediaParamsAdapter.getSelectors().selectAll;
const selectMediaParamEntities = mediaParamsAdapter.getSelectors().selectEntities;
const selectMediaParamIds = mediaParamsAdapter.getSelectors().selectIds;
const selectMediaUploadingEntities = mediaUploadingAdapter.getSelectors().selectEntities;
const selectAllMediaUploading = mediaUploadingAdapter.getSelectors().selectAll;

export const getMediaLoaded = createSelector(getMediaState, (state: MediaState) => state.loaded);

export const getMediaError = createSelector(getMediaState, (state: MediaState) => state.error);

export const getAllMedia = createSelector(getMediaState, (state: MediaState) => selectAll(state));

export const getMediaEntities = createSelector(getMediaState, (state: MediaState) => selectEntities(state));

export const getMediaParamEntities = createSelector(getMediaParamsState, (state: MediaParamsState) => {
  return selectMediaParamEntities(state);
});

export const getMediaUploadingEntities = createSelector(getMediaUploadingState, (state: MediaUploadingState) => {
  return selectMediaUploadingEntities(state);
});

export const getAllMediaUploading = createSelector(getMediaUploadingState, (state: MediaUploadingState) => {
  return selectAllMediaUploading(state);
});

export const getMediaIds = selectIds;
//export const getMediaIds = createSelector(getMediaState, (state: MediaState) => selectIds(state));
export const getSelectedId = createSelector(getMediaState, (state: MediaState) => state.selectedId);

export const getVehicleId = createSelector(getMediaState, (state: MediaState) => state.vehicleId);

export const getSelected = createSelector(
  getMediaEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getAppointmentMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { appointmentId: number }) => {
    return entities.filter((e) => {
      return e.tags.appointmentId && e.tags.appointmentId == props.appointmentId;
    });
  }
);

export const getAppointmentCaseMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { caseSequence: number; appointmentId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.appointmentId &&
        e.tags.appointmentId == props.appointmentId &&
        e.tags.caseSequence &&
        e.tags.caseSequence == props.caseSequence
      );
    });
  }
);

export const getWorkOrderMedia = createSelector(getAllMedia, (entities: Media[], props: { workOrderId: number }) => {
  return entities.filter((e) => {
    return e.tags.workOrderId && e.tags.workOrderId == props.workOrderId;
  });
});

export const getWorkOrderCaseMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { caseSequence: number; workOrderId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.workOrderId &&
        e.tags.workOrderId == props.workOrderId &&
        e.tags.caseSequence &&
        e.tags.caseSequence == props.caseSequence
      );
    });
  }
);

export const getInspectionMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { inspectionId: number; workOrderId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.inspectionId &&
        e.tags.inspectionId == props.inspectionId &&
        e.tags.workOrderId &&
        e.tags.workOrderId == props.workOrderId
      );
    });
  }
);

export const getInspectionTaskMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { inspectionTaskId: number; workOrderId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.inspectionTaskId &&
        e.tags.inspectionTaskId == props.inspectionTaskId &&
        e.tags.workOrderId &&
        e.tags.workOrderId == props.workOrderId
      );
    });
  }
);

export const getAllInspectionTaskMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { inspectionTypeId: number; workOrderId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.inspectionId &&
        e.tags.inspectionId == props.inspectionTypeId &&
        e.tags.workOrderId &&
        e.tags.workOrderId == props.workOrderId &&
        e.tags.inspectionTaskId
      );
    });
  }
);

export const getBodyReportMedia = createSelector(
  getAllMedia,
  (entities: Media[], props: { damageId: string; workOrderId: number }) => {
    return entities.filter((e) => {
      return (
        e.tags.damageId &&
        e.tags.damageId == props.damageId &&
        e.tags.workOrderId &&
        e.tags.workOrderId == props.workOrderId
      );
    });
  }
);

export const getLastMediaInState = createSelector(selectMediaIds, (entities: any) => {
  return entities?.length > 0 ? entities[0] : null;
});

export const getDeltaMediaData = createSelector(getVehicleId, getLastMediaInState, (vehicleId, lastMedia) => {
  return { vehicleId, lastMedia };
});

export const getMediaParamsForKey = createSelector(
  getMediaParamsState,
  getMediaParamEntities,
  (state: MediaParamsState, entities: Dictionary<MediaParams>, props: { key: string }) => {
    return entities[props.key];
  }
);

export const getFilteredVehicleMedia = createSelector(getAllMedia, (entities: Media[], props: any) => {
  return entities.filter((e) => {
    return (
      e.tags.inspectionTaskId &&
      e.tags.inspectionTaskId == props.inspectionTaskId &&
      e.tags.workOrderId &&
      e.tags.workOrderId == props.workOrderId
    );
  });
});

export const getSpecificFileMedia = createSelector(getAllMedia, (entities: Media[], props: { fileName: string }) => {
  return entities.filter((e) => {
    return e.fileName == props.fileName;
  });
});
