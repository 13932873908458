import { Overlay } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Media } from '@quorum/models/xs-resource';
import { TwoActionDialogComponent, TwoActionDialogData } from '@quorum/sha-components-ng-ui';
import { BehaviorSubject } from 'rxjs';
import { MediaGalleryEditMediaDialogComponent } from '../media-gallery-edit-media-dialog/media-gallery-edit-media-dialog.component';

@Component({
  selector: 'sha-media-gallery-media-card',
  templateUrl: './media-gallery-media-card.component.html',
  styleUrls: ['./media-gallery-media-card.component.scss'],
})
export class MediaGalleryMediaCardComponent implements OnInit {
  @Input() media: any;
  @Output() mediaUpdated = new EventEmitter<any>();
  @Output() mediaDeleted = new EventEmitter<any>();
  selectedImageSk: '';
  hidePlayButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private dialog: MatDialog, private overlay: Overlay, private http: HttpClient) {}

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  fullScreen(ev: any) {
    if (document.fullscreenElement === null || !document['webkitFullscreenElement']) {
      ev.target.children[0].pause();
      this.hidePlayButton$.next(false);
    }
  }

  ngOnInit(): void {}

  editMedia(media: Media) {
    const dialogRef = this.dialog.open(MediaGalleryEditMediaDialogComponent, {
      width: '80%',
      maxWidth: '450px',
      disableClose: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: { media: media },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.mediaUpdated.emit({
          ...media,
          caption: result.caption,
          customerFacing: result.customerFacing,
        });
      }
    });
  }

  downloadMedia(media: any) {
    this.http.get(media.mediaUrl, { responseType: 'blob' }).subscribe((val) => {
      const url = URL.createObjectURL(val);

      const a: any = document.createElement('a');
      a.href = url;
      a.download = media.caption;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    });
  }

  clickImage(imageSk: any) {
    if (this.selectedImageSk === imageSk) {
      this.selectedImageSk = '';
    } else {
      this.selectedImageSk = imageSk;
    }
  }

  openVideoFullscreen(videoDiv: any, media: any, video: any) {
    if (videoDiv.requestFullscreen) {
      videoDiv.requestFullscreen();
    } else if (videoDiv.webkitRequestFullscreen) {
      videoDiv.webkitRequestFullscreen();
    } else if (video.webkitEnterFullscreen) {
      video.webkitEnterFullscreen();
      video.play();
    } else {
      videoDiv.enterFullScreen();
    }
  }

  playPauseVideo(videoElement: any) {
    if (videoElement.paused) {
      videoElement.play();
      this.hidePlayButton$.next(true);
    } else {
      videoElement.pause();
      this.hidePlayButton$.next(false);
    }
  }

  videoEnded(videoElement: any) {
    if (document.fullscreenElement !== null) {
      if (document['exitFullscreen']) {
        document['exitFullscreen']();
      } else if (document['webkitCancelFullScreen']) {
        document['webkitCancelFullScreen']();
      }
    }
    videoElement.load();
  }

  deleteMedia(media: Media) {
    const dialogData: TwoActionDialogData = {
      actionTwoCaption: 'Yes',
      actionOneCaption: 'No',
      content: 'Are you sure you would like to delete this media item?',
      title: 'Delete Media',
    };

    const dialogRef = this.dialog.open(TwoActionDialogComponent, {
      width: '350px',
      disableClose: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.mediaDeleted.emit(media);
      }
    });
  }
}
