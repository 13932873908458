<div fxLayout="column" fxFlexFill>
  <div fxFlexFill>
    <mat-tab-group id="masterTabs">
      <mat-tab>
        <ng-template mat-tab-label> Conversations </ng-template>
        <div
          id="com-conversations-div"
          *ngIf="conversations"
          class="messages-tab-body"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="150"
          (scrolled)="onScrollConversations()"
          [scrollWindow]="false"
        >
          <mat-nav-list>
            <mat-list-item id="newConversation" (click)="newConversation()">
              <div mat-list-icon fxLayoutAlign="center center" class="initials-circle">
                <mat-icon class="message-icon">message</mat-icon>
              </div>
              <h4 mat-line class="primary-text">New Conversation</h4>
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                [matTooltip]="!filterApplied ? 'See unread messages' : 'See all messages'"
                matTooltipPosition="above"
                class="filter-button-container"
              >
                <button
                  mat-icon-button
                  class="secondary-text"
                  onClick="event.stopPropagation()"
                  (click)="filterApplied = !filterApplied; filterApplied ? applyMessageFilter() : removeMessageFilter()"
                >
                  <mat-icon [class.mat-primary]="filterApplied">filter_list</mat-icon>
                </button>
              </div>
            </mat-list-item>
          </mat-nav-list>
          <com-conversations-list *ngIf="!filterApplied; else filterView" [conversations]="conversations">
          </com-conversations-list>
          <ng-template #filterView>
            <div class="loading-container" *ngIf="filterLoadingConversations">
              <mat-spinner></mat-spinner>
            </div>
            <com-conversations-list [conversations]="filteredConversations"></com-conversations-list>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab id="activitiesTab" label="Activities">
        <div
          id="com-activities-div"
          *ngIf="activitiesConversations"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="150"
          (scrolled)="onScrollActivities()"
          [scrollWindow]="false"
        >
          <com-conversations-list [conversations]="activitiesConversations"></com-conversations-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
