import { MessageTransactionMessagePreference } from '@quorum/models/xs-resource';
import { conversationsAdapter, ConversationsState } from './conversations.interfaces';

export const conversationsInitialState: ConversationsState = conversationsAdapter.getInitialState({
  directMessageTemplates: [],
  errors: null,
  eventsLastQueryPage: {},
  isLoading: true,
  lastQueryPageNumber: { conversations: 0, activities: 0 },
  messageTypes: [],
  newConversation: { members: null, conversation: null },
  ui: {
    emojiPanelVisible: false,
    infoPanelVisible: false,
  },
  unsentMessages: {},
  utcLastModified: null,
  messagePreferenceOverride: { isLoading: false, value: new MessageTransactionMessagePreference() },
});
