import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TwoActionDialogData } from './two-action-dialog-data.model';

@Component({
  selector: 'sha-two-action-dialog',
  templateUrl: './two-action-dialog.component.html',
  styleUrls: ['./two-action-dialog.component.css'],
})
export class TwoActionDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: TwoActionDialogData) {}

  ngOnInit() {}
}
