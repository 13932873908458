export const environment = {
  apiBase: 'https://api.xsellerator.com',
  quorumApiBase: '.quorum-api.com',
  communicatorUrl: 'https://server.qcommunicator.com',
  hqRedirectUrl: `https://hq.xsellerator.com`,
  s3AttachmentsBucket: 'prod-communicator-attachments',
  s3CreatePresignedUrl: 'https://6s2lsrmja2.execute-api.us-east-1.amazonaws.com/prod/s3-create-presigned-url',
  legacyApiBase: 'https://quorumapi3.azurewebsites.net',
  legacyAuthBase: 'https://quorumauthentication3.azurewebsites.net',
  mediaPresignUrl: 'https://jeskzj8gf8.execute-api.us-east-1.amazonaws.com/prod/request-url',
  multiPartMediaUploadUrl: 'https://x0httflc3k.execute-api.us-east-1.amazonaws.com/prod',
  metadataScraperUrl: 'https://0l7ovxdz72.execute-api.us-east-1.amazonaws.com/',
  htmlConversionUrl: 'https://1km88x2dth.execute-api.us-east-1.amazonaws.com',
  qTemplatesPlatformUrl: 'https://qtemplates.quorum-api.com',
  googleCloudApiKey: 'AIzaSyCLlPkj8_SZC5PEXgNPYAOgaXcKChY_LAk',
  production: true,
  environment: 'production',
  version: '1.1.1',
  noticeable: {
    iconWidgetIdPowerLane: 'jXjT7rbeqS3551MytW28',
    iconWidgetIdSalesPlanner: 'XqZ7z6Zmaepz2LKlBCNa',
    iconWidgetIdCommunicator: 'RGpGZyqSM4E9w8PzJYCU',
    sharedProjectId: 'ZUegJ9JmBeCIdB1tgQOD',
  },
  jwtTokenGenerationUrl: 'https://o3iijtm5c9.execute-api.us-east-1.amazonaws.com/production/generateToken',
  createSSMParamUrl: 'https://gyelbvi0zf.execute-api.us-east-1.amazonaws.com/production/createSSMParam',
};
