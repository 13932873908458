import { Action } from '@ngrx/store';

export const TOGGLE_SIDENAV_IN_STATE = '[COMMUNICATOR] Toggle Sidenav in State';
export class ToggleSidenavInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_SIDENAV_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export type UISettingsAction = ToggleSidenavInState;
