import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromSockets from './+state/sockets.actions';
import {
  SocketConnectionInfo,
  socketConnectionInfosSelector,
  SocketConnectionInfoState,
} from './+state/sockets.interfaces';

@Injectable()
export class SocketStateService {
  constructor(
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private store: Store<SocketConnectionInfoState>
  ) { }

  addSocketConnectionInfo(connectionInfo: SocketConnectionInfo) {
    this.store.dispatch(new fromSockets.AddSocketConnectionInfo(connectionInfo));
  }

  selectSocketConnectionInfos() {
    return this.store.pipe(select(socketConnectionInfosSelector));
  }

  reconnectFailed() {
    this.store.dispatch(new fromSockets.SocketReconnectFailed({}));
  }

  socketConnected() {
    this.store.dispatch(new fromSockets.SocketConnected({ isConnected: true }));
  }

  socketReconnected() {
    this.store.dispatch(new fromSockets.SocketConnected({ isConnected: true }));

    forkJoin(
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.conversationStateService.selectLastModified().pipe(take(1))
    ).subscribe(([authenticatedUser, utcLastModified]) => {
      this.conversationStateService.dispatchConversationsRefreshActions(authenticatedUser, utcLastModified);
    });
  }

  socketDisconnected() {
    this.store.dispatch(new fromSockets.SocketDisconnected({ isConnected: false }));
  }
}
