import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DirectMessageTemplate, Member, MessageType, MessageTransactionMessagePreference } from '@quorum/models/xs-resource';
import { ConversationView } from '../conversation-view.enum';
import { Conversation } from '../conversation.model';

export interface ConversationUi {
  [id: number]: ConversationEntityUi;
  emojiPanelVisible: boolean;
  infoPanelVisible: boolean;
}

export interface ConversationEntityUi {
  titleUpdate?: boolean;
}

export interface ConversationsState extends EntityState<Conversation> {
  errors: any;
  eventsLastQueryPage: { [conversationId: number]: number };
  isLoading: boolean;
  lastQueryPageNumber: { [key in ConversationView]: number };
  directMessageTemplates: DirectMessageTemplate[];
  messageTypes: MessageType[];
  newConversation: NewConversation;
  ui: ConversationUi;
  unsentMessages: { [conversationId: number]: string };
  utcLastModified: Date;
  messagePreferenceOverride: { isLoading: boolean; value: MessageTransactionMessagePreference };
}

export const conversationsAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
  sortComparer: (a: Conversation, b: Conversation) => {
    return +new Date(b.utcLastModified) - +new Date(a.utcLastModified);
  },
});

export interface NewConversation {
  readonly members: Array<Member>;
  readonly conversation: Conversation;
}

export const conversationsFeatureSelector = createFeatureSelector<ConversationsState>('conversations');

export const { selectIds, selectEntities, selectAll, selectTotal } =
  conversationsAdapter.getSelectors(conversationsFeatureSelector);

export const conversationsIsLoadingSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.isLoading
);
export const conversationsLastModifiedSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.utcLastModified
);
export const conversationsLastQueryPageNumberSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.lastQueryPageNumber
);
export const conversationUiSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.ui
);
export const eventsLastQueryPageSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.eventsLastQueryPage
);
export const unsentMessagesSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.unsentMessages
);
export const directMessageTemplatesSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.directMessageTemplates
);
export const messageTypesSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.messageTypes
);
export const newConversationSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.newConversation
);
export const newConversationConversationSelector = createSelector(
  newConversationSelector,
  (state: NewConversation) => state.conversation
);
export const newConversationMembersSelector = createSelector(
  newConversationSelector,
  (state: NewConversation) => state.members
);
export const messagePreferenceOverrideSelector = createSelector(
  conversationsFeatureSelector,
  (state: ConversationsState) => state.messagePreferenceOverride
);
