<ng-container *ngIf="members && members.length > 1">
  <mat-list-item [ngClass]="{ focused: selected }" *ngIf="members" (click)="selectConversation(conversation)">
    <sha-avatar
      mat-list-icon
      [avatarUrl]="conversation.avatarUrl"
      [avatarUrls]="avatarUrls"
      [initials]="membersAvatarInitials"
      [presence]="presence"
    >
    </sha-avatar>
    <div mat-line fxLayout="row" fxLayoutAlign="space-between center">
      <h4
        style="margin: 0; max-width: 180px; overflow: hidden; text-overflow: ellipsis"
        [ngClass]="{ 'bold-text': !currentMember?.isRead }"
      >
        {{ title }}
      </h4>

      <span class="mat-caption secondary-text"> {{ conversation.utcLastModified | relativeDate: false }}</span>
    </div>

    <p
      mat-line
      style="font-weight: 300"
      [ngClass]="{ 'bold-text': !currentMember?.isRead, 'secondary-text': currentMember?.isRead }"
    >
      <ng-container *ngIf="conversation.typingMembers?.length > 0; then typingIndicator; else lastMessage">
      </ng-container>
      <ng-template #lastMessage>
        <span>{{
          currentMember?.userId == lastEventMember?.userId ? 'You: ' : lastEventMember?.firstName + ': '
        }}</span>
        <span
          *ngIf="!(conversation.typingMembers?.length > 0)"
          [innerHTML]="conversation.embedded.lastEvent?.content"
        ></span>
      </ng-template>
      <ng-template #typingIndicator>
        <com-typing-indicator></com-typing-indicator>
      </ng-template>
    </p>

    <div fxLayout="column" fxLayoutAlign="center center">
      <button mat-icon-button [matMenuTriggerFor]="menu" class="secondary-text" onclick="event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="toggleIsArchived(currentMember)" mat-menu-item>
          <span>{{ currentMember?.isArchived ? 'UnArchive' : 'Archive' }}</span>
        </button>
        <button (click)="toggleIsPinnedToUi(currentMember)" mat-menu-item>
          <span>{{ currentMember?.isPinnedToUi ? 'Unpin' : 'Pin' }}</span>
        </button>
        <button (click)="toggleIsRead(currentMember)" mat-menu-item>
          <span>Mark as {{ currentMember?.isRead ? 'Unread' : 'Read' }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-list-item>
</ng-container>
