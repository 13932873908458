import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CommunicatorMessageType as MessageType,
  MessageTypeToTemplateMapping,
  Template,
} from '@quorum/models/xs-resource';
import {
  messageTypeAdapter,
  messageTypeToTemplateMappingsStateAdapter,
  quorumGalleryAdapter,
  State,
  storeGalleryAdapter,
  quorumLibraryAdapter,
  TemplatesPartialState,
  TEMPLATES_FEATURE_KEY,
} from './templates.reducer';
// Lookup the 'Templates' feature state managed by NgRx
export const getTemplatesState = createFeatureSelector<State>(TEMPLATES_FEATURE_KEY);

const storeGalleryState = createSelector(getTemplatesState, (state: State) => state.storeGallery);
const isStoreGalleryLoading = createSelector(getTemplatesState, (state: State) => state.storeGallery.loading);
const storeGallerySelectors = storeGalleryAdapter.getSelectors(storeGalleryState);

const quorumGalleryState = createSelector(getTemplatesState, (state: State) => state.quorumGallery);
const isQuorumGalleryLoading = createSelector(getTemplatesState, (state: State) => state.quorumGallery.loading);
const quorumGallerySelectors = quorumGalleryAdapter.getSelectors(quorumGalleryState);

const quorumLibraryState = createSelector(getTemplatesState, (state: State) => state.quorumLibrary);
const isQuorumLibraryLoading = createSelector(getTemplatesState, (state: State) => state.quorumLibrary.loading);
const quorumLibrarySelectors = quorumLibraryAdapter.getSelectors(quorumLibraryState);

const MessageTypeState = createSelector(getTemplatesState, (state: State) => state.messageTypes);
const isMessageTypesLoading = createSelector(getTemplatesState, (state: State) => state.messageTypes.loading);
const messageTypesSelectors = messageTypeAdapter.getSelectors(MessageTypeState);

const MessageTypeToTemplateMappingsState = createSelector(
  getTemplatesState,
  (state: State) => state.messageTypeToTemplateMappings
);
const isMessageTypeToTemplateMappingsLoading = createSelector(
  getTemplatesState,
  (state: State) => state.messageTypeToTemplateMappings.loading
);
const messageTypeToTemplateMappingsSelectors = messageTypeToTemplateMappingsStateAdapter.getSelectors(
  MessageTypeToTemplateMappingsState
);

const selectedTemplate = createSelector(getTemplatesState, (state: State) => state.selectedTemplate);
const selectedLanguage = createSelector(getTemplatesState, (state: State) => state.selectedLanguage);
const languages = createSelector(getTemplatesState, (state: State) => state.languages);

export const isLoadingComplete = createSelector(
  isStoreGalleryLoading,
  isQuorumGalleryLoading,
  isQuorumLibraryLoading,
  isMessageTypesLoading,
  isMessageTypeToTemplateMappingsLoading,
  (
    isStoreGalleryLoading: boolean,
    isQuorumGalleryLoading: boolean,
    isQuorumLibraryLoading: boolean,
    isMessageTypesLoading: boolean,
    isMessageTypeToTemplateMappingsLoading: boolean
  ): boolean => {
    return !isStoreGalleryLoading &&
      !isQuorumGalleryLoading &&
      !isQuorumLibraryLoading &&
      !isMessageTypesLoading &&
      !isMessageTypeToTemplateMappingsLoading
      ? true
      : false;
  }
);

export const galleryByMessageType = createSelector(
  storeGallerySelectors.selectAll,
  messageTypesSelectors.selectAll,
  (gallery: Template[], messageTypes: MessageType[]): any => {
    let galleryByMessageType: any = {};
    messageTypes.forEach((messageType) => {
      let filteredGallery = gallery.filter((template) => {
        return template.messageTypeId == messageType.id;
      });
      galleryByMessageType[messageType.id] = filteredGallery;
    });
    return galleryByMessageType;
  }
);

export const galleryByMessageTypeWithDefaults = createSelector(
  storeGallerySelectors.selectAll,
  messageTypesSelectors.selectAll,
  messageTypeToTemplateMappingsSelectors.selectAll,
  (
    gallery: Template[],
    messageTypes: MessageType[],
    messageTypeToTemplateMappings: MessageTypeToTemplateMapping[]
  ): any => {
    let galleryByMessageType: any = {};

    messageTypes.forEach((messageType) => {
      let filteredGallery = gallery.filter((template) => {
        return template.messageTypeId == messageType.id;
      });

      let messageTypeToTemplateMapping = messageTypeToTemplateMappings.find(
        (messageTypeToTemplateMapping) => messageTypeToTemplateMapping.messageTypeId === messageType.id
      );

      galleryByMessageType[messageType.id] = {
        templates: filteredGallery,
        messageTypeDescription: messageType.description,
        defaultTemplateRequired: messageType.requiresTemplate,
        defaultTemplateId: messageTypeToTemplateMapping ? messageTypeToTemplateMapping.templateId : null,
      };
    });
    return galleryByMessageType;
  }
);

// export const getTemplatesLoaded = createSelector(getTemplatesState, (state: State) => state.loaded);

// export const getTemplatesError = createSelector(getTemplatesState, (state: State) => state.error);

// export const getAllTemplates = createSelector(getTemplatesState, (state: State) => selectAll(state));

// export const getTemplatesEntities = createSelector(getTemplatesState, (state: State) => selectEntities(state));

// export const getSelectedId = createSelector(getTemplatesState, (state: State) => state.selectedId);

export const templatesQuery = {
  storeGalleryTemplates: storeGallerySelectors.selectAll,
  quorumGalleryTemplates: quorumGallerySelectors.selectAll,
  quorumLibraryTemplates: quorumLibrarySelectors.selectAll,
  messageTypes: messageTypesSelectors.selectAll,
  messageTypeToTemplateMappings: messageTypeToTemplateMappingsSelectors.selectAll,
  isLoadingComplete,
  languages,
  selectedTemplate,
  selectedLanguage,
  galleryByMessageType,
  galleryByMessageTypeWithDefaults,
};
