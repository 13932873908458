export class AssociateQueryParameters {
  classId?: string;
  contactMethodUpdatedDateFrom?: Date;
  contactMethodUpdatedDateTo?: Date;
  contactPreferenceId?: string;
  conversionId?: string;
  emailAddress?: string;
  emailConsent?: string;
  embed?: Array<string> = [];
  id?: string;
  initialContactMethod?: string;
  messagePreferenceId?: number;
  page?: number;
  pageSize?: number;
  privacyLevelId?: number;
  search?: string;
  showInactive?: boolean;
  subTypeId?: string;
  textConsent?: string;

  constructor(obj?: AssociateQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
