import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';

export const NAVIGATE = '[ROUTER] Go';
export class Go implements Action {
  readonly type = NAVIGATE;
  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export const NAVIGATE_TO_ROOT = '[ROUTER] Go to root';
export class GoToRoot implements Action {
  readonly type = NAVIGATE_TO_ROOT;
  constructor() {}
}

export const BACK = '[ROUTER] Back';
export class Back implements Action {
  readonly type = BACK;
  constructor(public payload: any) {}
}
// export interface Back {
//   type: 'BACK';
//   payload: any;
// }

export const FORWARD = '[ROUTER] forward';
export class Forward implements Action {
  readonly type = FORWARD;
  constructor(public payload: any) {}
}
// export interface Forward {
//   type: 'FORWARD';
//   payload: any;
// }

export type Actions = Go | Back | Forward;
