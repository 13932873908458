export enum EventTypes {
  ArchivedConversation = 6,
  ExtendedWarrantyGotIt = 12,
  File = 19,
  MemberAddedToConversation = 3,
  Message = 7,
  MessageForwardMemberAdded = 4,
  PartsRequestGotIt = 8,
  PartsRequestIsReady = 9,
  PartsRequestOrdered = 13,
  RemoveOtherFromGroup = 2,
  RemoveSelfFromGroup = 1,
  ServiceQuoteAdvisorGotIt = 17,
  ServiceQuotePartsGotIt = 16,
  ServiceQuotePartsOrdered = 18,
  ServiceQuoteDeclined = 15,
  ServiceQuoteSold = 14,
  XselleratorAutomatedExternalOutbound = 10,
  XselleratorAutomatedInternalOutbound = 11,
  XselleratorEventMemberAdded = 5,
}
