import * as fromUISettings from './ui.actions';
import { initialUIState } from './ui.init';
import { CommunicatorUIState } from './ui.interfaces';

export function uiSettingsReducer(
  state = initialUIState,
  action: fromUISettings.UISettingsAction
): CommunicatorUIState {
  switch (action.type) {
    case fromUISettings.TOGGLE_SIDENAV_IN_STATE: {
      return {
        ...state,
        sidenavVisible: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export function reducer(state: CommunicatorUIState, action: fromUISettings.UISettingsAction): CommunicatorUIState {
  let newState: CommunicatorUIState;
  if (state) {
    newState = uiSettingsReducer(state, action);
    localStorage.setItem('communicatorUI', JSON.stringify(newState));
  } else {
    let localState = localStorage.getItem('communicatorUI');
    let initialState: CommunicatorUIState;
    if (localState) {
      initialState = JSON.parse(localState);
    } else {
      initialState = initialUIState;
    }
    newState = uiSettingsReducer(initialState, action);
    localStorage.setItem('communicatorUI', JSON.stringify(newState));
  }
  return newState;
}
