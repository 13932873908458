import * as fromSockets from './sockets.actions';
import { SocketsAction } from './sockets.actions';
import { socketsInitialState } from './sockets.init';
import { SocketConnectionInfoState } from './sockets.interfaces';

export function socketsReducer(state: SocketConnectionInfoState, action: SocketsAction): SocketConnectionInfoState {
  switch (action.type) {
    case fromSockets.ADD_SOCKET_CONNECTION_INFO: {
      return {
        ...state,
        socketConnectionInfos: [...state.socketConnectionInfos, action.payload]
      };
    }
    case fromSockets.CREATE_SOCKET_CONNECTION: {
      return state;
    }
    case fromSockets.RESET_SOCKET_STATE: {
      return socketsInitialState;
    }
    case fromSockets.SOCKET_CONNECTED:
    case fromSockets.SOCKET_DISCONNECTED: {
      const index: number = state.socketConnectionInfos.findIndex(c => c.service === 'communicator');

      return {
        ...state,
        socketConnectionInfos: [
          ...state.socketConnectionInfos.slice(0, index),
          {
            ...state.socketConnectionInfos[index],
            isConnected: action.payload.isConnected,
            reconnectFailed: false
          },
          ...state.socketConnectionInfos.slice(index + 1)
        ]
      };
    }
    case fromSockets.SOCKET_RECONNECT_FAILED: {
      console.log('SOCKET_RECONNECT_FAILED');
      const index: number = state.socketConnectionInfos.findIndex(c => c.service === 'communicator');

      return {
        ...state,
        socketConnectionInfos: [
          ...state.socketConnectionInfos.slice(0, index),
          {
            ...state.socketConnectionInfos[index],
            isConnected: false,
            reconnectFailed: true
          },
          ...state.socketConnectionInfos.slice(index + 1)
        ]
      };
    }
    default: {
      return state;
    }
  }
}
