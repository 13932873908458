import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTypes } from '@models/server-common';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ConversationStateService, fromConversations } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ConversationQueryParameters, EventQueryParameters } from '@quorum/models/xs-query';
import { Event, Member, TokenUser } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromEvents from './+state/events.actions';
import { eventsIsLoadingSelector, EventsState, selectAll } from './+state/events.interfaces';

@Injectable()
export class EventsStateService {
  constructor(
    private route: ActivatedRoute,
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private memberStateService: MembersStateService,
    private routerStateService: RouterStateService,
    private store: Store<EventsState>,
    private actions$: Actions
  ) {}

  addEvent(event: Event, headers?: HttpHeaders) {
    this.store.dispatch(new fromEvents.AddEventToState({ event: event, headers: headers }));
  }

  addEventFromSocket(conversationId: number, event: Event) {
    forkJoin([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.selectEventsForConversation(conversationId).pipe(take(1)),
      this.conversationStateService.selectConversation(conversationId).pipe(take(1)),
    ]).subscribe(([authenticatedUser, events, conversation]) => {
      if (events && events.length > 0) {
        // if (
        //   !events.find((e: Event) => {
        //     if ((event.guid && e.guid === event.guid) || e.id === event.id) return e;
        //   })
        // )
        this.addEventFromSocketToConversation(conversationId, event);
      } else if (conversation) {
        this.store.dispatch(
          new fromConversations.UpdateConversationLastEventInState({
            id: conversationId,
            changes: {
              lastEventId: event.id,
              utcLastModified: event.sentDate,
              embedded: {
                ...conversation.embedded,
                lastEvent: event,
              },
            },
          })
        );
        this.store.dispatch(new fromEvents.DisplayNotificationForNewEvent(event));
        this.updateIsReadForConversation(conversationId, event);
      } else {
        this.dispatchQueryForConversation(authenticatedUser, conversationId, event);
      }
    });
  }

  createEvent(
    content: string,
    conversationId: number,
    eventType: EventTypes,
    currentUser: TokenUser,
    sendToCustomer: boolean = false,
    attachments: any = null
  ): Event {
    const event: Event = new Event();
    event.attachments = attachments;
    event.conversationId = conversationId;
    event.memberId = currentUser.id.toString();
    event.eventTypeId = eventType;
    event.content = content;
    event.guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    event.sentDate = new Date();
    event.sent = false;
    event.sendToCustomer = sendToCustomer;
    event.id = Math.floor(Math.random() * (9999999999999 - 999999999999)) + 999999999999;
    return event;
  }

  getEvents(queryParameters: EventQueryParameters) {
    if (!queryParameters.pageSize) queryParameters.pageSize = 25;

    this.store.dispatch(new fromEvents.GetEventsFromServer(queryParameters));
    if (queryParameters.pageNumber === 1) this.store.dispatch(new fromEvents.UpdateIsLoading(true));
  }

  selectEventsForConversation(conversationId: number): Observable<Event[]> {
    return this.store.pipe(
      select(selectAll),
      map((events) => {
        return new DeepCopyPipe().transform(
          events.filter((event) => event !== undefined && event.conversationId === conversationId)
        );
      })
    );
  }
  private addEventFromSocketToConversation(conversationId: number, event: Event) {
    this.store.dispatch(new fromEvents.AddEventFromSocketToState({ event: event }));
    this.updateIsReadForConversation(conversationId, event);
  }

  private dispatchQueryForConversation(authenticatedUser: AuthenticatedUser, conversationId: number, event: Event) {
    console.log('dispatchQueryForConversation');
    if (
      event.eventTypeId !== EventTypes.ArchivedConversation &&
      event.eventTypeId !== EventTypes.MemberAddedToConversation &&
      event.eventTypeId !== EventTypes.MessageForwardMemberAdded &&
      event.eventTypeId !== EventTypes.RemoveOtherFromGroup &&
      event.eventTypeId !== EventTypes.RemoveSelfFromGroup &&
      event.eventTypeId !== EventTypes.XselleratorEventMemberAdded
    ) {
      return this.routerStateService
        .selectRouterState()
        .pipe(
          take(1),
          map((routerState) => {
            if (!routerState.state.url.includes('customer-summary')) {
              const queryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                id: conversationId,
                embed: 'lastEvent,messageType',
              });
              this.store.dispatch(
                new fromConversations.GetConversationsFromServer({ queryParameters, displayNotification: true })
              );
            }
          })
        )
        .subscribe();
    }
  }

  selectIsLoading() {
    return this.store.pipe(select(eventsIsLoadingSelector));
  }

  private updateIsReadForConversation(conversationId: number, event: Event) {
    forkJoin([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.memberStateService.selectMembers().pipe(take(1)),
    ]).subscribe(([authenticatedUser, membersEntity]) => {
      const params = this.routerStateService.getFullTreeParams(this.route);
      const me: Member = membersEntity
        .filter((member: Member) => member.conversationId === conversationId)
        .find((member: Member) => member.userId === authenticatedUser.user.id.toString());

      const auditEvents: Array<EventTypes> = [
        EventTypes.ArchivedConversation,
        EventTypes.MemberAddedToConversation,
        EventTypes.RemoveOtherFromGroup,
        EventTypes.RemoveSelfFromGroup,
        EventTypes.XselleratorEventMemberAdded,
      ];
      if (
        conversationId.toString() !== params['id'] &&
        event.memberId !== authenticatedUser.user.id.toString() &&
        me.isRead === true &&
        !auditEvents.includes(<EventTypes>event.eventTypeId)
      ) {
        this.memberStateService.updateIsReadForConversation({ ...me, isRead: false });
      }
    });
  }

  listenForUpdateLastEventInState$ = this.actions$.pipe(
    ofType(fromConversations.UPDATE_CONVERSATION_LAST_EVENT_IN_STATE),
    map((action) => {
      return action;
    })
  );
}
