export class ConversationQueryParameters {
  customerId: string;
  dmsExchangeConversationId: string;
  eventTypeId: string;
  embed: string;
  employeeId: string;
  id: number;
  isArchived: boolean;
  isExcluded: boolean;
  isPinnedToUi: boolean;
  isRead: any;
  lastEventCreatedById: string;
  lastEventCreatedByIdNotEqual: string;
  lastModifiedSearchEndDate: Date;
  lastModifiedSearchStartDate: Date;
  messageTypeId: string;
  pageNumber: number;
  pageSize: number;
  searchString: string;
  transactionId: string;
  transactionTypeId: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
