import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationStateService } from './authentication-state.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  private isAuthenticated: boolean;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private router: Router,
    private routerStateService: RouterStateService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // return true;//this.isAuthenticated;
    // you can return an observable, so you just need that stream to give you
    //  the current value of authenticated from store and then map that to true/false with
    //  your side effect of router navigate if false.
    return this.authenticationStateService.isAuthenticated().pipe(
      map((value: any) => {
        if (value) {
          return true;
        } else {
          this.routerStateService.go(['/login'], {});
          return false;
        }
      })
    );
    // .take(1) // this should allow you to grab the current value each time canActivate is run
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
