import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@quorum/environments';
import { PresignedS3UrlParameters } from '@quorum/models/xs-misc';
import { Template } from '@quorum/models/xs-resource';
import { TokenUser } from 'libs/authentication/src/lib/+state/authentication.interfaces';
import { v4 as uuid } from 'uuid';

export type Attachment = { fileName: string; url: string };

@Component({
  selector: 'quorum-communicator-dialog-editor',
  templateUrl: './communicator-dialog-editor.component.html',
  styleUrls: ['./communicator-dialog-editor.component.scss'],
})
export class CommunicatorDialogEditorComponent implements OnInit {
  attachments: Attachment[] = [];
  modifiedTemplate: Template;
  selectedTemplate: Template;
  subject: string;
  uploading = false;
  useQTemplates = false;
  languages: string[];
  selectedLanguage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      attachments: Attachment[];
      communicatorTaskSpecificTemplate: Template;
      currentUser: TokenUser;
      messageType: string;
      templates: Template[];
    },
    public dialogRef: MatDialogRef<CommunicatorDialogEditorComponent>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.useQTemplates = this.data.communicatorTaskSpecificTemplate ? true : false;
    this.attachments = this.data.attachments ? this.data.attachments : [];
    this.languages = this.data.templates
      .map((template) => Object.keys(template.languageDefinitionMap))
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    if (this.languages.length === 1) {
      this.selectedLanguage = this.languages[0];
      this.languageSelected(this.selectedLanguage);
    }
  }

  sendEmail(attachments: Attachment[], template: Template, subject: string, language: string) {
    template.languageDefinitionMap[language].subject = subject;

    template.selectedLanguage = language;
    this.dialogRef.close({ attachments, template });
  }

  sendTemplateTextMessage(template: Template, subject: string, language: string) {
    template.languageDefinitionMap[language].subject = subject;
    template.selectedLanguage = language;

    this.dialogRef.close({
      template: template,
    });
  }

  sendBasicTextMessage(messageContent: string) {
    this.dialogRef.close({
      messageContent: messageContent,
    });
  }

  removeAttachment(attachment: Attachment) {
    this.attachments = this.attachments.filter((arrayAttachment) => arrayAttachment.fileName != attachment.fileName);
  }

  languageSelected(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;

    if (this.data.communicatorTaskSpecificTemplate) {
      this.data.templates.push(this.data.communicatorTaskSpecificTemplate);
      this.selectedTemplate = this.data.communicatorTaskSpecificTemplate;
      this.subject = this.data.communicatorTaskSpecificTemplate.languageDefinitionMap[selectedLanguage].subject;
    }
  }
  async prepareFiles(files: FileList) {
    await this.uploadFiles(files);
  }

  async uploadFiles(files: FileList): Promise<boolean> {
    if (!files) return;
    this.uploading = !this.uploading;
    for (let i = 0; i < files.length; i++) {
      const presignedUrl: any = await this.createPresignedUrl(files[i]);
      this.attachments.push({
        fileName: files[i].name,
        url: await this.uploadFileToS3(files[i], presignedUrl),
      });
    }
    this.uploading = !this.uploading;
  }

  async createPresignedUrl(file: File): Promise<any> {
    const fileName = `${uuid()}/${file.name}`;
    const presignedUrlParams: PresignedS3UrlParameters = {
      bucket: environment.s3AttachmentsBucket,
      filename: fileName,
      expires: 300,
      metadata: { userId: this.data.currentUser.id.toString(), storeId: this.data.currentUser.storeId.toString() },
      tags: {
        'xs:user-id': this.data.currentUser.id.toString(),
        'xs:store-id': this.data.currentUser.storeId.toString(),
        'xs:application': 'communicator',
      },
    };

    return this.http
      .post(environment.s3CreatePresignedUrl, presignedUrlParams)
      .toPromise()
      .then((data: any) => {
        data.fields.key = fileName;
        return data;
      })
      .catch((err: any) => {
        throw err;
      });
  }

  async uploadFileToS3(file: File, presignedUrl: any): Promise<string> {
    const formData = new FormData();
    Object.entries(presignedUrl.fields).forEach(([k, v]: any) => {
      formData.append(k, v);
    });
    formData.append('file', file, presignedUrl.fields.key);

    return this.http
      .post(presignedUrl.url, formData)
      .toPromise()
      .then((data) => {
        return `${presignedUrl.fields.key.replace(/\s/g, '+')}`;
      })
      .catch((err) => {
        throw err;
      });
  }

}
