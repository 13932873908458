import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Conversation } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';

@Component({
  selector: 'com-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
})
export class MasterComponent implements OnInit {
  searchContacts: boolean = true;
  searchConversations: boolean = true;
  searchArchivedConversations: boolean = false;
  searchString: string = '';
  constructor(private routerStateService: RouterStateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {}

  closeSearch() {
    this.searchString = '';
    this.searchContacts = true;
    this.searchConversations = true;
    this.searchArchivedConversations = false;
  }

  onSearchArchivedConversationsChange() {
    if (!this.searchConversations) this.searchConversations = true;
  }

  onSearchConversationsChange() {
    if (!this.searchConversations) this.searchArchivedConversations = false;
  }

  navigateToSelectedConversation(conversation: Conversation) {
    this.routerStateService.go([{ outlets: { detail: `c/${conversation.id}` } }], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
