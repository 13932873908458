export class MemberQueryParameters {
  conversationId?: string;
  embed?: string;
  id: number;
  isArchived: boolean;
  isEmployee: boolean;
  isPinnedToUi: boolean;
  isRead: boolean;
  searchString: string;
  userId: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
