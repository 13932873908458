import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ContactPresence } from '@quorum/models/xs-misc';

@Component({
  selector: 'sha-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {
  @Input() avatarUrl: string;
  @Input() avatarUrls: Array<string>;
  @Input() displayPresence = true;
  @Input() presence: ContactPresence;
  @Input() initials: Array<string>;
  @Input() size = 'large';

  constructor() {}

  ngOnInit() {}
}
