<div mat-dialog-title>
  <h1>Direct Message Templates</h1>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-content>
  <com-direct-message-templates-list
    [templates]="data.templates"
    [currentContact]="data.currentContact"
  ></com-direct-message-templates-list>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <!-- <button mat-button [mat-dialog-close]="true" color="primary"  cdkFocusInitial>Save</button> -->
</div>
