<mat-nav-list>
  <ng-container *ngIf="contactsPresence$ | async as presences">
    <ng-container *ngFor="let contactGroup of contacts | groupBy: 'embedded.store.name' | pairs">
      <h3 mat-subheader (click)="collapseGroup(contactGroup[1][0].storeId)">
        <span>{{ contactGroup[0] }} ({{ contactGroup[1].length }})</span>
      </h3>
      <div [hidden]="groupConfiguration[contactGroup[1][0].storeId]?.collapsed">
        <com-contacts-list-item
          *ngFor="let contact of contactGroup[1] | orderBy: ['firstName', 'lastName']"
          [contact]="contact"
          [contactPresence]="presences[contact.id]"
        ></com-contacts-list-item>
      </div>
    </ng-container>
  </ng-container>
</mat-nav-list>
