import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationStateService, ConversationUi } from '@quorum/communicator/state/conversations';
import { UiStateService } from '@quorum/communicator/state/root';
import { environment } from '@quorum/environments';
import { AuthenticatedUser, MessageType } from '@quorum/models/xs-resource';
import { TwoItemSelectionListData, TwoItemSelectionListDialogComponent } from '@quorum/sha-components-ng-ui';
import { RouterStateService } from '@quorum/sha-router';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
declare const noticeable: any;

@Component({
  selector: 'com-master-header',
  templateUrl: './master-header.component.html',
  styleUrls: ['./master-header.component.css'],
  providers: [UiStateService],
  animations: [
    trigger('connectionStatus', [
      state('connected', style({ display: 'none' })),
      state('disconnected', style({ display: 'block' })),
      transition('disconnected => connected', animate('.5s')),
      transition('connected => disconnected', animate('.5s')),
    ]),
  ],
})
export class MasterHeaderComponent implements OnInit, OnDestroy {
  authenticatedUser$: Observable<AuthenticatedUser>;
  conversationUi: ConversationUi;
  ui$: Subscription;
  sidenav: boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private conversationStateService: ConversationStateService,
    private authenticationStateService: AuthenticationStateService,
    private dialog: MatDialog,
    private uiStateService: UiStateService,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser();
    this.ui$ = this.conversationStateService
      .selectUiState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((ui) => {
        this.conversationUi = ui;
      });
  }

  ngAfterViewInit() {
    this.authenticatedUser$
      .pipe(
        switchMap((authenticatedUser) => {
          return this.authenticationStateService.generateJWTTokenForNoticeable(authenticatedUser);
        }),
        take(1)
      )
      .subscribe((ssoToken: any) => {
        noticeable.identify(environment.noticeable.sharedProjectId, ssoToken.endUserSsoToken);
        noticeable.render('widget', environment.noticeable.iconWidgetIdPowerLane, { selector: '#noticeable-icon' });
      });
  }

  ngOnDestroy() {
    noticeable.destroy('widget', environment.noticeable.iconWidgetIdCommunicator);
    this.destroy$.next();
    this.destroy$.complete();
  }

  archiveMessages() {
    forkJoin([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.conversationStateService.selectMessageTypes().pipe(take(1)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentUser, messageTypes]) => {
        const data: TwoItemSelectionListData = {
          title: 'Archive Messages',
          cancelCaption: 'Cancel',
          okCaption: 'Archive',
          items: messageTypes,
        };

        const dialogRef = this.dialog.open(TwoItemSelectionListDialogComponent, {
          width: '600px',
          data,
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe((messageTypes: MessageType[]) => {
            if (messageTypes) {
              messageTypes.forEach((messageType: MessageType) => {
                this.conversationStateService.archiveMessagesOfType(currentUser.user.id, messageType.id);
              });
            }
          });
      });
  }

  navigateMaster(path: string) {
    this.routerStateService.go(['/home', { outlets: { master: path } }], { relativeTo: this.route });
  }

  toggleSidenav() {
    this.uiStateService.toggleSidenav(!this.sidenav);
  }
}
