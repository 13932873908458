import { Injectable } from '@angular/core';
import { ApiService } from '@quorum/api';
import { Event } from '@quorum/models/xs-resource';
import { map } from 'rxjs/internal/operators/map';
import { FileEntity } from './+state/files.models';

@Injectable()
export class FilesStateService {
  constructor(private apiService: ApiService) {}

  getFiles = (conversationId: number) => {
    return this.apiService
      .get<Event[]>(`communicator/conversations/${conversationId}/events`, {
        params: { convesationId: conversationId, eventTypeId: 19, pageSize: 250 },
      })
      .pipe(
        map((events) => {
          let files: FileEntity[] = [];
          for (let index = 0; index < events.length; index++) {
            files.push({ converstionId: conversationId, id: events[index].id, url: events[index].content });
          }
          return files;
        })
      );
  };
}
