import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { FilesListComponent } from './files-list/files-list.component';
import { FilesComponent } from './files/files.component';
import { CommunicatorStateFilesModule } from '@quorum/communicator/state/files';
@NgModule({
  imports: [CommonMaterialComponentsModule, CommonModule, CommunicatorStateFilesModule],
  declarations: [FilesComponent, FilesListComponent],
  exports: [FilesComponent, FilesListComponent],
})
export class CommunicatorUiNgFilesModule {}
