<div fxFlex class="loading-container" *ngIf="isLoading$ | async; else events">
  <mat-spinner></mat-spinner>
</div>
<ng-template #events>
  <div fxFlex #eventsContainer id="eventsContainer" class="events-container"
    *ngIf="members?.length > 0 && eventGroups$ | async as eventGroups" infiniteScroll [infiniteScrollUpDistance]="2"
    [infiniteScrollThrottle]="150" (scrolledUp)="onScrollUp()" [scrollWindow]="false">
    <div #eventGroupsContainer id="eventGroupsContainer" fxLayout="column">
      <ng-template #eventGroupsList ngFor let-group let-i="index" [ngForOf]="eventGroups" [ngForTrackBy]="trackByFn">
        <div class="secondary-text" *ngIf="displayTimeStamp(eventGroups, i)" fxLayoutAlign="center center">
          <span class="timestamp">{{ group[0].sentDate | relativeDate: true }} </span>
        </div>
        <com-event *ngFor="let event of group; let i = index; trackBy: trackByEventFn" [event]="event" [index]="i + 1"
          [ownEvent]="authenticatedUser.user.id == event.memberId" [sender]="getEventSender(members, event.memberId)"
          [total]="group.length" [members]="members" [fxFlexAlign]="
            ![1, 2, 3, 4, 5, 6].includes(event.eventTypeId)
              ? event.memberId == authenticatedUser.user.id
                ? 'end'
                : 'start'
              : 'center'
          ">
        </com-event>
        <div *ngIf="group[group.length - 1].channelId" [fxFlexAlign]="
            ![1, 2, 3, 4, 5, 6].includes(group[group.length - 1].eventTypeId)
              ? group[group.length - 1].memberId == authenticatedUser.user.id
                ? 'end'
                : 'start'
              : 'center'
          ">
          <span style="padding-right: 22px; font-size: 11px; color: grey">Sent via {{ group[group.length -
            1].channelId.toLowerCase() }}</span>
        </div>
      </ng-template>
    </div>
    <com-typing-indicator *ngIf="conversation.typingMembers?.length > 0" [members]="conversation.typingMembers">
    </com-typing-indicator>
  </div>
</ng-template>
