<div class="container" fxLayout="column">
  <mat-toolbar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button class="secondary-text" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span fxFlex class="toolbar-title">Contacts</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div fxFlex class="contact-list-container" *ngIf="contacts$ | async as contacts">
    <com-contact-list [contacts]="contacts"></com-contact-list>
  </div>
</div>
