import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Employee } from '@quorum/models/xs-resource';
import { AuthenticatedUser, AuthenticationErrors, AuthenticationStages, TokenUser } from './authentication.interfaces';

export const ATTEMPT_SILENT_LOGIN = '[AUTHENTICATION] Attempt Silent Login';
export class AttemptSilentLogin implements Action {
  readonly type = ATTEMPT_SILENT_LOGIN;
  constructor(public payload: {}) {}
}

export const AUTHENTICATE_USER = '[AUTHENTICATION] Authenticate User';
export class AuthenticateUser implements Action {
  readonly type = AUTHENTICATE_USER;
  constructor(public payload: { username: string; password: string }) {}
}

export const CHECK_COOKIE_FOR_AUTHENTICATION = '[AUTHENTICATION] Check Cookie For Authentication';
export class CheckCookieForAuthentication implements Action {
  readonly type = CHECK_COOKIE_FOR_AUTHENTICATION;
  constructor(public payload: {}) {}
}

export const EMPLOYEE_AUTHENTICATION_SUCCESSFUL = '[AUTHENTICATION] Employee Authentication Successful';
export class EmployeeAuthenticationSuccessful implements Action {
  readonly type = EMPLOYEE_AUTHENTICATION_SUCCESSFUL;
  constructor(public payload: { apiKey: string; destinationId: string; redirectUrl: string }) {}
}

export const EMPLOYEE_AUTHENTICATION_FAILURE = '[AUTHENTICATION] Employee Authentication Failure';
export class EmployeeAuthenticationFailure implements Action {
  readonly type = EMPLOYEE_AUTHENTICATION_FAILURE;
  constructor(public payload: any) {}
}

export const GET_API_CREDENTIALS_FOR_EMPLOYEE = '[AUTHENTICATION] Get Api Credentials For Employee';
export class GetApiCredentialsForEmployee implements Action {
  readonly type = GET_API_CREDENTIALS_FOR_EMPLOYEE;
  constructor(public payload: { token: string; redirectUrl: string }) {}
}

export const LOGOUT = '[AUTHENTICATION] Logout';
export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(public payload: {}) {}
}

export const LOGOUT_SUCCESS = '[AUTHENTICATION] Logout Success';
export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
  constructor(public payload: {}) {}
}

export const LOGOUT_ERROR = '[AUTHENTICATION] Logout Error';
export class LogoutError implements Action {
  readonly type = LOGOUT_ERROR;
  constructor(public payload: { message: string }) {}
}

export const USER_AUTHENTICATED = '[AUTHENTICATION] User Authenticated';
export class UserAuthenticated implements Action {
  readonly type = USER_AUTHENTICATED;
  constructor(public payload: AuthenticatedUser) {}
}

export const USER_AUTHENTICATED_ERROR = '[AUTHENTICATION] User Authenticated Error';
export class UserAuthenticatedError implements Action {
  readonly type = USER_AUTHENTICATED_ERROR;
  constructor(public payload: AuthenticationErrors) {}
}

export const ADD_AUTHENTICATION_STAGES = '[AUTHENTICATION] Add Authentication Stages';
export class AddAuthenticationStages implements Action {
  readonly type = ADD_AUTHENTICATION_STAGES;
  constructor(public payload: AuthenticationStages[]) {}
}

export const UPDATE_AUTHENTICATION_STAGES = '[AUTHENTICATION] Update Authentication Stages';
export class UpdateAuthenticationStages implements Action {
  readonly type = UPDATE_AUTHENTICATION_STAGES;
  constructor(public payload: Update<AuthenticationStages>) {}
}

export const MONITOR_AUTHENTICATION_STAGES = '[AUTHENTICATION] Monitor Authentication Stages';
export class MonitorAuthenticationStages implements Action {
  readonly type = MONITOR_AUTHENTICATION_STAGES;
}

export const MONITOR_AUTHENTICATION_STAGES_ERROR = '[AUTHENTICATION] Monitor Authentication Stages Error';
export class MonitorAuthenticationStagesError implements Action {
  readonly type = MONITOR_AUTHENTICATION_STAGES_ERROR;
  constructor(public payload: { message: string }) {}
}

export const GET_EMPLOYEE_FROM_SERVER = '[AUTHENTICATION] Get Employee From Server';
export class GetEmployeeFromServer implements Action {
  readonly type = GET_EMPLOYEE_FROM_SERVER;
  constructor(public payload: TokenUser) {}
}

export const GET_EMPLOYEE_FROM_SERVER_FAILURE = '[AUTHENTICATION] Get Employee From Server Failure';
export class GetEmployeeFromServerFailure implements Action {
  readonly type = GET_EMPLOYEE_FROM_SERVER_FAILURE;
  constructor(public payload: AuthenticationErrors) {}
}

export const GET_EMPLOYEE_FROM_SERVER_SUCCESS = '[AUTHENTICATION] Get Employee From Server Success';
export class GetEmployeeFromServerSuccess implements Action {
  readonly type = GET_EMPLOYEE_FROM_SERVER_SUCCESS;
  constructor(public payload: Employee) {}
}

export type AuthenticatedUserAction =
  | AttemptSilentLogin
  | AuthenticateUser
  | UserAuthenticated
  | UserAuthenticatedError
  | EmployeeAuthenticationSuccessful
  | EmployeeAuthenticationFailure
  | GetApiCredentialsForEmployee
  | Logout
  | LogoutSuccess
  | LogoutError
  | CheckCookieForAuthentication
  | AddAuthenticationStages
  | UpdateAuthenticationStages
  | MonitorAuthenticationStages
  | MonitorAuthenticationStagesError
  | GetEmployeeFromServer
  | GetEmployeeFromServerFailure
  | GetEmployeeFromServerSuccess;
