import { Injectable } from '@angular/core';

@Injectable()
export class BrowserControlCommunicationService {
  drilldownEnabled = false;
  embeddedInXsellerator = false;
  disableRippleEffect = false;
  hasError = false;
  constructor() {
    if ((<any>window).DrilldownManager) {
      this.drilldownEnabled = true;
      this.embeddedInXsellerator = true;
      this.disableRippleEffect = true;
    }
  }

  drilldown(name: string, pbWindow: string, params: string[]) {
    if (this.drilldownEnabled) {
      const paramsString: string = params.join('+');
      (<any>window).DrilldownManager.drilldown(name, pbWindow, paramsString);
    } else {
      return;
    }
  }

  getSilentLoginToken() {
    return (<any>window).ConnectionInfo.getToken();
  }

  receivePowerBuilderMessage() {
    let promise = new Promise((resolve, reject) => {
      (<any>window).injectIntoAngular = (messageObject: any) => {
        resolve(messageObject);
      };
    });

    return promise;
  }
}
