import { createAction, props } from '@ngrx/store';
import { MessageTypeToTemplateMappingQueryParameters } from '@quorum/models/xs-query';
import { MessageTypeToTemplateMapping } from '@quorum/models/xs-resource';

export const GetMessageTypeToTemplateMappingsFromServer = createAction(
  '[Quorum] Get Message Type To Template Mappings From Server',
  props<{ custId: string; queryParams?: MessageTypeToTemplateMappingQueryParameters }>()
);
export const GetMessageTypeToTemplateMappingsFromServerSuccess = createAction(
  '[Quorum] Get Message Type To Template Mappings From Server Success',
  props<{ messageTypeToTemplateMappings: MessageTypeToTemplateMapping[] }>()
);
export const GetMessageTypeToTemplateMappingsFromServerFailure = createAction(
  '[Quorum] Get Message Type To Template Mappings From Server Error',
  props<{ error: any }>()
);

export const GetMessageTypeToTemplateMappingFromServer = createAction(
  '[Quorum] Get Message Type To Template Mapping From Server',
  props<{ custId: string; messageTypeId: number }>()
);
export const GetMessageTypeToTemplateMappingFromServerSuccess = createAction(
  '[Quorum] Get Message Type To Template Mapping From Server Success',
  props<{ messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const GetMessageTypeToTemplateMappingFromServerFailure = createAction(
  '[Quorum] Get Message Type To Template Mapping From Server Error',
  props<{ error: any }>()
);

export const UpdateMessageTypeToTemplateMappingOnServer = createAction(
  '[Quorum] Update Message Type To Template Mapping On Server',
  props<{ custId: string; messageTypeId: number; messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const UpdateMessageTypeToTemplateMappingOnServerSuccess = createAction(
  '[Quorum] Update Message Type To Template Mapping On Server Success',
  props<{ messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const UpdateMessageTypeToTemplateMappingOnServerError = createAction(
  '[Quorum] Update Message Type To Template Mapping On Server Error',
  props<{ error: any }>()
);

export const PatchMessageTypeToTemplateMappingOnServer = createAction(
  '[Quorum] Patch Message Type To Template Mapping On Server',
  props<{ custId: string; messageTypeId: number; messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const PatchMessageTypeToTemplateMappingOnServerSuccess = createAction(
  '[Quorum] Patch Message Type To Template Mapping On Server Success',
  props<{ messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const PatchMessageTypeToTemplateMappingOnServerError = createAction(
  '[Quorum] Patch Message Type To Template Mapping On Server Error',
  props<{ error: any }>()
);

export const CreateMessageTypeToTemplateMappingOnServer = createAction(
  '[Quorum] Create Message Type To Template Mapping From Server Error',
  props<{ custId: string; messageTypeId: number; messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const CreateMessageTypeToTemplateMappingOnServerSuccess = createAction(
  '[Quorum] Create Message Type To Template Mapping From Server Success',
  props<{ messageTypeToTemplateMapping: MessageTypeToTemplateMapping }>()
);
export const CreateMessageTypeToTemplateMappingOnServerError = createAction(
  '[Quorum] Create Message Type To Template Mapping From Server Error',
  props<{ error: any }>()
);
