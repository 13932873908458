import { Injectable } from '@angular/core';
import { ApiService } from '@quorum/api';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { Conversation, Member, Event } from '@quorum/models/xs-resource';
@Injectable()
export class CommunicatorService {
  constructor(private apiService: ApiService) {}

  createConversation(conversation: Conversation, members: Member[], event: Event) {
    this.apiService.post<Conversation>('communicator/conversations', conversation).subscribe((newConversation) => {
      const updatedEvent: Event = new DeepCopyPipe().transform(event);
      updatedEvent.conversationId = newConversation.id;
      const updatedMembers: Member[] = new DeepCopyPipe().transform(members);
      updatedMembers.forEach((m) => (m.conversationId = newConversation.id));

      this.apiService
        .post<Member[]>(
          `communicator/conversations/${newConversation.id.toString()}/members`,
          this.prepareMultipleMembersBody(updatedMembers)
        )
        .subscribe((members) => {
          this.apiService
            .post<Event>(`communicator/conversations/${newConversation.id}/events`, updatedEvent)
            .subscribe();
        });
    });
  }

  prepareMultipleMembersBody(members: Member[]) {
    const response: any[] = [];
    members.forEach((member: Member, index: number) => {
      response.push({ id: index, body: member });
    });

    return response;
  }
}
