import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromMembers from './members.actions';
import { membersInitialState } from './members.init';
import { membersAdapter, MembersState } from './members.interfaces';

export function membersReducer(state: MembersState, action: fromMembers.MembersAction): MembersState {
  switch (action.type) {
    case fromMembers.ADD_MEMBER_TO_CONVERSATION_FROM_SOCKET_TO_STATE: {
      // if member is for newly created conversation there will be no conversation in state, so ignore.
      // The full conversation will be loaded on the first event that comes in on the socket for the new conversation.
      return membersAdapter.upsertOne(action.payload.member, state);
    }
    case fromMembers.ADD_MEMBERS_TO_CONVERSATION_SUCCESS:
    case fromMembers.POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_SUCCESS:
    case fromMembers.GET_MEMBERS_FROM_SERVER_SUCCESS:
    case fromMembers.GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_SUCCESS: {
      return membersAdapter.upsertMany(action.payload.members, state);
    }

    case fromMembers.POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_FAILURE:
    case fromMembers.GET_MEMBERS_FROM_SERVER_FAILURE:
    case fromMembers.GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_FAILURE: {
      return { ...state, errors: action.payload };
    }
    case fromMembers.POST_MEMBER_TO_SERVER_SUCCESS: {
      return state;
    }
    case fromMembers.DELETE_MEMBERS_IN_STATE: {
      return membersAdapter.removeMany(action.payload.ids, state);
    }

    case fromMembers.REMOVE_SELF_FROM_CONVERSATION_FAILURE: {
      return state;
    }
    case fromMembers.RESET_MEMBER_STATE: {
      return membersInitialState;
    }
    case fromMembers.START_MEMBERS_SPINNER_UI:
    case fromMembers.STOP_MEMBERS_SPINNER_UI: {
      return { ...state, isLoading: action.payload };
    }
    case fromMembers.UPDATE_IS_ARCHIVED_CONVERSATION_IN_STATE:
    case fromMembers.UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER: {
      return membersAdapter.updateOne(
        { id: action.payload.member.id, changes: { isArchived: action.payload.member.isArchived } },
        state
      );
    }
    case fromMembers.UPDATE_IS_PINNED_TO_UI_CONVERSATION_IN_STATE:
    case fromMembers.UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER: {
      return membersAdapter.updateOne(
        { id: action.payload.id, changes: { isPinnedToUi: action.payload.isPinnedToUi } },
        state
      );
    }
    case fromMembers.UPDATE_IS_READ_CONVERSATION_IN_STATE:
    case fromMembers.UPDATE_IS_READ_CONVERSATION_ON_SERVER: {
      return membersAdapter.updateOne({ id: action.payload.id, changes: { isRead: action.payload.isRead } }, state);
    }
    case fromMembers.REMOVE_MEMBER_FROM_CONVERSATION_IN_STATE: {
      return membersAdapter.updateOne({ id: action.payload.id, changes: { isExcluded: true } }, state);
    }
    case fromMembers.REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER: {
      return membersAdapter.updateOne({ id: action.payload.member.id, changes: { isExcluded: true } }, state);
    }
    case fromMembers.REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_FAILURE: {
      return membersAdapter.updateOne({ id: action.payload.member.id, changes: { isExcluded: false } }, state);
    }
    case fromMembers.POST_MEMBER_TO_SERVER_FAILURE:
    case fromMembers.UPDATE_IS_READ_CONVERSATION_ON_SERVER_FAILURE:
    case fromMembers.REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_SUCCESS:
    case fromMembers.UPDATE_IS_READ_CONVERSATION_ON_SERVER_FAILURE:
    case fromMembers.UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER_FAILIURE:
    case fromMembers.UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER_FAILIURE: {
      return state;
    }
    case fromMembers.ADD_MEMBERS_TO_CONVERSATION:
    case fromMembers.GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER:
    case fromMembers.POST_MEMBER_TO_SERVER:
    case fromMembers.POST_NEW_CONVERSATION_MEMBERS_TO_SERVER:
    case fromMembers.REMOVE_SELF_FROM_CONVERSATION:
    default: {
      return state;
    }
  }
}

export function membersStateHydration(reducer: ActionReducer<MembersState>): ActionReducer<MembersState> {
  return function (state: MembersState, action: any, metaReducer: string = 'stateHydration'): MembersState {
    if (state === undefined) {
      let membersState: any = localStorage.getItem('members-state');
      state = membersState ? JSON.parse(membersState) : membersInitialState;
    }

    const newState = reducer(state, action);
    if (action.feature === 'members' && action.triggerStorageSync) {
      localStorage.setItem('members-state', JSON.stringify(newState));
    }

    return newState;
  };
}

export const metaReducers: MetaReducer<MembersState>[] = [membersStateHydration];
