import { Conversation as Base, eventMock, Member, memberMock } from '@quorum/models/xs-resource';

export class Conversation extends Base {
  typingMembers: Member[] = null;
  isPinnedToUi: boolean = false;

  constructor(obj?: any) {
    super();
    Object.assign(this, obj);
  }
}

export const conversationMock = new Conversation({
  avatarUrl: 'localhost/sha-avatar.png',
  createdBy: memberMock,
  createdDate: new Date(),
  dmsTransactionTypeId: 1,
  dmsTransactionId: 100,
  dmsMessageTypeId: 7,
  dmsReferenceId: '14',
  dmsExchangeConversationId: '819493421',
  id: 1,
  lastEventId: 2,
  utcLastModified: new Date(),
  title: 'new conversation',
  embedded: { lastEvent: eventMock, messageType: { description: 'test' } },
  typingMembers: null,
  isPinnedToUi: false,
});
