import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FILES_FEATURE_KEY, State, FilesPartialState, filesAdapter } from './files.reducer';

export const getFilesState = createFeatureSelector< State>(FILES_FEATURE_KEY);

const { selectAll } = filesAdapter.getSelectors();

export const getFilesLoaded = createSelector(getFilesState, (state: State) => state.loaded);

export const getFilesError = createSelector(getFilesState, (state: State) => state.error);

export const getAllFiles = createSelector(getFilesState, (state: State) => selectAll(state));
