export enum SocketEventTypes {
  CONVERSATIONAVATARURL = 'CONVERSATIONAVATARURL',
  CONVERSATIONPINNEDTOUI = 'CONVERSATIONPINNEDTOUI',
  CONVERSATIONTITLE = 'CONVERSATIONTITLE',
  EVENTADD = 'EVENTADD',
  ISACTIVE = 'ISACTIVE',
  ISTYPING = 'ISTYPING',
  MEMBERADD = 'MEMBERADD',
  MEMBERARCHIVE = 'MEMBERARCHIVE',
  MEMBEREXCLUDE = 'MEMBEREXCLUDE',
  MEMBERREAD = 'MEMBERREAD',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  ONLINEALLCONTACTS = 'ONLINEALLCONTACTS'
}
