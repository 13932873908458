import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConversationStateService } from 'libs/communicator/state/conversations/src/lib/conversation-state.service';
import { SocketsEffects } from './+state/sockets.effects';
import { socketsInitialState } from './+state/sockets.init';
import { socketsReducer } from './+state/sockets.reducer';
import { SocketStateService } from './socket-state.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sockets', socketsReducer, {
      initialState: socketsInitialState,
    }),
    EffectsModule.forFeature([SocketsEffects, ConversationStateService]),
  ],
  providers: [SocketsEffects, SocketStateService],
})
export class SocketsModule {}
