<div *ngIf="{media: mediaSubject$ | async, mediaLoaded: vehicleMediaLoaded$ | async} as data;" class="flex-col">
  <div class="flex-col" *ngIf="data.mediaLoaded; else mediaLoading">
    <div *ngIf="!isEmpty(data.media); else noMedia">
      <div *ngFor="let mediaWorkOrderGroup of data.media  | pairs | reverse; trackBy: trackByFunction">
        <div class="flex flex-row w-full mb-4 font-medium">
          <div class="pr-3">Work Order {{ mediaWorkOrderGroup[1][0].tags.workOrderId }}</div>
          <div class="text-black text-opacity-50" *ngIf="mediaWorkOrderGroup[1][0].tags.appointmentId">(Appointment
            {{mediaWorkOrderGroup[1][0].tags.appointmentId}})</div>
        </div>
        <div class="w-full grid grid-cols-1 gap-4 sm:grid-cols-2">
          <sha-media-gallery-media-card *ngFor="let media of mediaWorkOrderGroup[1]; trackBy: trackByFunction"
            [media]="media" (mediaUpdated)="mediaUpdated($event)" (mediaDeleted)="mediaDeleted($event)"
            class="border-grey-300 border-solid border rounded-lg flex-col">
          </sha-media-gallery-media-card>
        </div>
      </div>
    </div>
    <ng-template #noMedia>
      <div class="font-normal text-center">No media was found.</div>
    </ng-template>
  </div>

  <ng-template #mediaLoading>
    <div>
      <div class="flex flex-row w-1/2 h-6 animated-background">
        <h2 class="font-normal pr-3 animate-pulse"></h2>
      </div>
      <div class="flex flex-row flex-wrap pt-6">
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
      </div>

      <div class="flex flex-row w-1/2 h-6 mt-2 animated-background">
        <h2 class="font-normal pr-3 "></h2>
      </div>
      <div class="flex flex-row flex-wrap pt-6">
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>
        <div fxFlex="47" class="border-grey-300 border-solid border m-2 rounded-lg flex-col  animated-background">
          <div class="flex">
            <div class="">
              <div>
                <img />
              </div>
            </div>

            <div class="flex flex-col h-32 w-auto pt-3 pl-3 pr-3 whitespace-nowrap overflow-hidden">
              <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis"></span>
              <span class="font-medium text-black text-opacity-50">
              </span>
              <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"></span>
              <span class="text-black text-opacity-50"></span>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="h-10"></div>
        </div>


      </div>
    </div>
  </ng-template>
</div>