import { Address } from './address.model';
import { Class } from './class.model';
import { Contact } from './contact.model';
import { Customer } from './customer.model';
import { Employee } from './employee.model';
import { MessageType } from './message-type.model';

export class Associate {
  active: string = null;
  classId: string = null;
  contactMethodUpdatedDate?: Date = null;
  contactPreferenceId: string = null;
  conversionControlNumber: string = null;
  conversionId: string = null;
  createdDate?: Date = null;
  dcpSkipFlag: boolean = null;
  embedded?: AssociateEmbed = null;
  electricCardReader: string = null;
  emailAddress: string = null;
  emailConsent: string = null;
  firstName: string = null;
  fullName?: string = null;
  id?: string = null;
  initialContactMethod: string = null;
  lastName: string = null;
  legalFirstName: string = null;
  legalLastName: string = null;
  legalNameFlag: string = null;
  loyaltyNumber: string = null;
  mailingAddressSequence: number = null;
  messagePreferenceId: number = null;
  oneTimeFlag: string = null;
  privacyLevelId: number = null;
  subTypeId: string = null;
  textConsent: string = null;
  webAddress: string = null;
  [key: string]: string | object | number | Date | boolean;

  constructor(obj?: Associate) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
class AssociateEmbed {
  addresses?: Address[] = null;
  class?: Class = null;
  contacts?: Contact[] = null;
  customer?: Customer = null;
  employee?: Employee = null;
  messageType?: MessageType = null;
}
