
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { Conversation } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { ContactPresence } from '@quorum/models/xs-misc';
import { Contact, Member } from '@quorum/models/xs-resource';

@Component({
  selector: 'com-conversations-list-item',
  templateUrl: './conversations-list-item.component.html',
  styleUrls: ['./conversations-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsListItemComponent implements OnInit, OnChanges {
  @Input() authenticatedUser: AuthenticatedUser;
  @Input() conversation: Conversation;
  @Input() selected: boolean;
  @Input() members: Member[] = [];
  @Input() contacts: Array<Contact> = [];
  @Input() contactsPresence: Array<ContactPresence> = [];
  @Output() conversationSelected: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  avatarUrls: Array<string> = [];
  currentMember: Member = new Member();
  lastEventMember: Member;
  membersAvatarInitials: string[] = [];
  presence: ContactPresence;
  title: string;

  constructor(private membersStateService: MembersStateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversation && changes.conversation.currentValue) {
      this.title = this.generateTitle(this.conversation, this.members, this.authenticatedUser.user.id);
    }
    if (changes.contactsPresence && changes.contactsPresence.currentValue) {
      this.updatePresence(changes.contactsPresence.currentValue);
    }

    if (changes.members && changes.members.currentValue && changes.members.currentValue.length > 0) {
      this.members = changes.members.currentValue;
      this.updateForMemberChanges(this.members);
      this.updatePresence(this.contactsPresence);
      this.title = this.generateTitle(this.conversation, this.members, this.authenticatedUser.user.id);
      this.currentMember = this.members.find((member: Member) => {
        return member.userId == this.authenticatedUser.user.id.toString();
      });
    }
    //this.ref.detectChanges();
  }

  ngOnInit() {}

  updatePresence(contactsPresence: ContactPresence[]) {
    if (!this.members) return;
    const contactsPresenceKeys: Array<string> = Object.keys(contactsPresence);

    this.members
      .filter((member: Member) => {
        if (member.isEmployee && Number(member.userId) != this.authenticatedUser.user.id) return member;
      })
      .map((member: Member) => {
        if (contactsPresenceKeys.includes(member.userId)) return contactsPresence[parseInt(member.userId, 10)];
      })
      .every((contactPresence: ContactPresence) => {
        if (!contactPresence) {
          this.presence = null;
          return false;
        } else {
          this.presence = contactPresence;
          if (this.presence.idleTimeUtc === 0) return false;
        }
      });
  }

  updateForMemberChanges(members: Member[]) {
    this.currentMember = this.members.find(
      (member: Member) => Number(member.userId) === this.authenticatedUser.user.id
    );
    this.lastEventMember = this.getLastEventMember(members, this.conversation.embedded.lastEvent.memberId);
    this.avatarUrls = this.getMemberAvatarUrls(members, this.conversation.embedded.lastEvent.memberId);
    this.membersAvatarInitials = this.getMemberAvatarInitials(members, this.conversation.embedded.lastEvent.memberId);
  }

  generateTitle(conversation: Conversation, members: Array<Member>, currentUserId: number) {
    if (!conversation || !members) return;
    const customer: Member = members.find((m: Member) => !m.isExcluded && !m.isEmployee);
    if (customer) {
      return customer.lastName ? `${customer.firstName} ${customer.lastName}` : `${customer.firstName}`;
    } else if (conversation.title) {
      return conversation.title;
    } else {
      return members
        .filter((m: Member) => m.firstName.toLowerCase() !== 'communicator')
        .filter((m: Member) => !m.isExcluded)
        .filter((m: Member, index: number, arr: Member[]) => {
          return arr.length === 1 ? m : m.userId !== currentUserId.toString();
        })
        .map((m: Member) => m.firstName)
        .join(', ');
    }
  }

  getLastEventMember(members: Member[], lastEventMemberId: String) {
    return members.find((m) => m.userId === lastEventMemberId);
  }

  getMemberAvatarInitials(members: Member[], lastEventMemberId: String) {
    if (this.currentMember && lastEventMemberId === this.currentMember.userId)
      return members
        .filter((member) => member.userId !== this.currentMember.userId)
        .filter((member) => member.firstName?.toLowerCase() !== 'communicator')
        .map((member) => {
          return `${member.firstName ? member.firstName.charAt(0) : ''}${
            member.lastName ? member.lastName.charAt(0) : ''
          }`;
        });
    else
      return members
        .filter((member) => member.userId === lastEventMemberId)
        .map((member) => {
          return `${member.firstName ? member.firstName.charAt(0) : ''}${
            member.lastName ? member.lastName.charAt(0) : ''
          }`;
        });
  }

  getMemberAvatarUrls(members: Member[], lastEventMemberId: String) {
    let avatarUrls: Array<string> = [];
    // Add last event member first as the first url in the array will be used as the main url in the avatar component.
    if (this.currentMember && lastEventMemberId === this.currentMember.userId)
      members
        .filter((member) => member.avatarUrl !== null && member.avatarUrl !== '')
        .filter((member) => member.userId !== this.currentMember.userId)
        .filter((member) => member.firstName.toLowerCase() !== 'communicator')
        .map((member) => avatarUrls.push(member.avatarUrl));
    else
      members
        .filter((member) => member.avatarUrl !== null && member.avatarUrl !== '')
        .filter((member) => member.userId === lastEventMemberId)
        .map((member) => avatarUrls.push(member.avatarUrl));

    return avatarUrls;
  }

  toggleIsArchived(member: Member) {
    this.membersStateService.updateIsArchivedForConversation({ ...member, isArchived: !member.isArchived });
  }

  toggleIsPinnedToUi(member: Member) {
    this.membersStateService.updateIsPinnedToUiForConversation({ ...member, isPinnedToUi: !member.isPinnedToUi });
  }

  toggleIsRead(member: Member) {
    this.membersStateService.updateIsReadForConversation({ ...member, isRead: !member.isRead });
  }

  selectConversation(conversation: Conversation) {
    this.conversationSelected.emit(conversation);
  }
}