import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFiles from './+state/files.reducer';
import { FilesEffects } from './+state/files.effects';
import { FilesFacade } from './+state/files.facade';
import { FilesStateService } from './files-state.service';

export const comFilesRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(fromFiles.FILES_FEATURE_KEY, fromFiles.reducer),
    EffectsModule.forFeature([FilesEffects]),
  ],
  providers: [FilesFacade, FilesStateService],
})
export class CommunicatorStateFilesModule {}
