import { Store } from './store.model';

export class User {
  apiKey: string = null;
  avatarUrl: string = null;
  createdById: number = null;
  createdBy: User;
  department: string = null;
  etag?: string = null;
  email: string = null;
  id: number = null;
  integrationPartnerId: number = null;
  isActive: boolean = null;
  isActiveDirectoryLogin: boolean = null;
  isAdministrator: boolean = null;
  isDealershipUser: boolean = null;
  isQuorumUser: boolean = null;
  firstName: string = null;
  lastName: string = null;
  nickName: string = null;
  jobTitle: string = null;
  store: Store;
  storeId: number = null;
  username: string = null;
  utcActive: Date = null;
  utcCreated: Date = null;
  utcLastModified: Date = null;
  xselleratorEmployeeId: string = null;
  externalPictureUrl: string = null;
  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export const userMock = new User({
  apiKey: '1234Secret',
  avatarUrl: 'localhost/sha-avatar.png',
  createdById: 1000,
  createdBy: null,
  department: 'corporate',
  email: 'test@email.com',
  id: 1000,
  isActive: true,
  isActiveDirectoryLogin: false,
  isAdministrator: false,
  isDealershipUser: true,
  isQuorumUser: true,
  firstName: 'John',
  lastName: 'Doe',
  nickName: 'John',
  jobTitle: 'CEO',
  store: null,
  storeId: 1000,
  username: 'doej',
  utcActive: new Date(),
  utcCreated: new Date(),
  utcLastModified: new Date(),
  xselleratorEmployeeId: '14',
});



