import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Params, UrlSegment } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

export interface RouterStateUrl {
  root: RouterStateUrlRoot;
  url: string;
}

export interface RouterStateUrlRoot {
  params: Params;
  data: any;
  url: UrlSegment[];
  outlet: string;
  routeConfig: any;
  children: RouterStateUrlRoot[];
}

export const routerFeatureSelector = createFeatureSelector<RouterReducerState>('router');
export const routerStateSelector = createSelector(
  routerFeatureSelector,
  (state: RouterReducerState) => state
);
