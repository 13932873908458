export class EventQueryParameters {
  conversationId: number;
  embed: string;
  id: string;
  isViewable: boolean;
  memberId: string;
  pageNumber: number;
  pageSize: number;
  eventTypeId: number;
  searchEndDate: Date;
  searchStartDate: Date;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
