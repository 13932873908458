import { createAction, props } from '@ngrx/store';
import { Media, MediaParams } from '@quorum/models/xs-resource';

export const loadMedia = createAction('[Media] Load Media', props<{ vehicleId: number }>());
export const loadDeltaMedia = createAction(
  '[Media] Load Delta Media',
  props<{ vehicleId: number; lastMedia: string }>()
);
export const loadMediaSuccess = createAction(
  '[Media] Load Media Success',
  props<{ media: Media[]; vehicleId: number }>()
);

export const loadDeltaMediaSuccess = createAction(
  '[Media] Load Delta Media Success',
  props<{ media: Media[]; vehicleId: number }>()
);

export const deleteMediaFromServer = createAction('[Media] Delete Media From Server', props<{ media: Media }>());

export const deleteMediaFromServerSuccess = createAction(
  '[Media] Delete Media From Server Success',
  props<{ media: Media }>()
);

export const updateMediaOnServer = createAction('[Media] Update Media On Server', props<{ media: Media }>());
export const updateMediaOnServerSuccess = createAction(
  '[Media] Update Media On Server Success',
  props<{ media: Media }>()
);
export const updateMediaOnServerFailure = createAction('[Media] Update Media Failed', props<{ error: any }>());

export const setMediaModelKey = createAction('[Media] Set Media Model Key', props<{ mediaParams: MediaParams }>());

export const loadMediaFailure = createAction('[Media] Load Media Failure', props<{ error: any }>());

export const uploadMedia = createAction(
  '[Media] Upload Media',
  props<{ file: { fileName: string; fileType: string; fileStatus: string; fileNameToDisplay: string } }>()
);

export const mediaUploaded = createAction(
  '[Media] Media Uploaded',
  props<{ file: { fileName: string; fileType: string; fileStatus: string } }>()
);

export const clearMediaState = createAction('[Media] Clear Media State');

export const clearUploadingMediaState = createAction('[Media] Clear Uploading Media State');
