import { WorkOrderCase } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface SelectedWorkOrderCasesState extends EntityState<WorkOrderCase> {}

export const workOrderCaseAdapter: EntityAdapter<WorkOrderCase> = createEntityAdapter<WorkOrderCase>({
  selectId: (workOrderCase: WorkOrderCase) => workOrderCase.sequence,
});
// eslint-disable-next-line max-len
export const initialSelectedWorkOrderCaseState: SelectedWorkOrderCasesState = workOrderCaseAdapter.getInitialState({});
