<mat-list-item [ngClass]="{ 'mat-list-item-focus': selected }" (click)="selectConversationForContacts([contact])">
  <sha-avatar mat-list-icon [avatarUrl]="contact.avatarUrl" [presence]="contactPresence" [initials]="contactInitials">
  </sha-avatar>
  <h4 mat-line class="primary-text">{{ contact.firstName }} {{ contact.lastName }}</h4>
  <p mat-line class="secondary-text">
    {{ contact.embedded?.employee?.embedded?.jobTitle?.description }}
    {{
      contact.embedded?.employee?.embedded?.jobTitle?.description &&
      contact.embedded?.employee?.embedded?.department?.description
        ? '-'
        : ''
    }}
    {{ contact.embedded?.employee?.embedded?.department?.description }}
  </p>
</mat-list-item>
