import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { Conversation, ConversationStateService } from '@quorum/communicator/state/conversations';
import { MembersStateService } from '@quorum/communicator/state/members';
import { Member } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'com-conversations-list',
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() conversations: Array<Conversation>;

  authenticatedUser$: Observable<any>;
  contacts$: Observable<any>;
  isLoading$: Observable<boolean>;
  members$: Observable<any>;
  membersSelectSubscription: Subscription;
  contactsPresence$: Observable<any>;
  conversationMembers: { [key: number]: Member[] } = {};

  defaultPinnedMessagesToDisplay: number = 4;
  pinnedMessagesToDisplay: number = this.defaultPinnedMessagesToDisplay;
  viewAllPinnedMessages: boolean = false;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private membersStateService: MembersStateService,
    private routerStateService: RouterStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser();
    this.contacts$ = this.contactsStateService.selectContacts();
    this.contactsPresence$ = this.contactsStateService.selectPresences();
    this.isLoading$ = this.conversationStateService.selectIsLoading();
    this.members$ = this.membersStateService.selectMembers().pipe(delay(0));
    this.membersSelectSubscription = this.membersStateService.selectMembers().subscribe((members: Member[]) => {
      this.conversationMembers = {};
      members.forEach((member) => {
        this.conversationMembers[member.conversationId] = [
          ...(this.conversationMembers[member.conversationId] || []),
          member,
        ];
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {
    this.membersSelectSubscription.unsubscribe();
  }

  trackByFn(index: number, group: any) {
    return group[0];
  }

  trackByConversationFn(index: number, conversation: Conversation) {
    return conversation.id;
  }

  selectConversation(conversation: Conversation) {
    this.routerStateService.go([{ outlets: { detail: `c/${conversation.id}` } }], { relativeTo: this.route.parent });
  }
}