import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommunicatorUiNgContactsModule } from '@quorum/communicator/ui/ng/contacts';
import { CommunicatorUiNgConversationsModule } from '@quorum/communicator/ui/ng/conversations';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { NgPipesModule } from 'ngx-pipes';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonMaterialComponentsModule,
    CommonModule,
    CommunicatorUiNgContactsModule,
    CommunicatorUiNgConversationsModule,
    FlexLayoutModule,
    NgPipesModule,
  ],
  exports: [SearchPanelComponent],
  declarations: [SearchPanelComponent],
})
export class CommunicatorUiNgSearchModule {}
