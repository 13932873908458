import { Injectable } from '@angular/core';
import { SocketEventTypes } from '@models/server-common';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { SocketUser } from '@quorum/models/xs-misc';
import { map, take } from 'rxjs/operators';
import { SocketStateService } from './socket-state.service';

@Injectable()
export class SocketsService {
  private eventsToBind: any = [];
  protected socket: SocketIOClient.Socket;

  constructor(
    protected authenticationStateServiceBase: AuthenticationStateService,
    protected socketStateService: SocketStateService
  ) {}

  public initSocket(url: string, opts: SocketIOClient.ConnectOpts) {
    this.authenticationStateServiceBase
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        map((authenticatedUser: AuthenticatedUser) => {
          const socketUser: SocketUser = {
            apiKey: authenticatedUser.user.apiKey,
            destination: authenticatedUser.user.destination,
            federatedStores: authenticatedUser.user.federatedStores,
            firstName: authenticatedUser.user.firstName,
            id: authenticatedUser.user.id,
            isAdministrator: authenticatedUser.user.isAdministrator,
            isQuorumUser: authenticatedUser.user.isQuorumUser,
            lastName: authenticatedUser.user.lastName,
            storeId: authenticatedUser.user.storeId,
            storeName: authenticatedUser.user.storeName,
            username: authenticatedUser.user.username,
            version: authenticatedUser.user.username,
          };
          opts.query = { user: JSON.stringify(socketUser), upgrade: false, transports: ['websocket'] };
          const ioFunc = (io as any).default ? (io as any).default : io;
          this.socket = ioFunc(url, opts);
        })
      )
      .subscribe();
    this.socket.on('connect', () => {
      this.socketStateService.socketConnected();
      this.bindEvents();
    });
    this.socket.on('disconnect', () => {
      this.socketStateService.socketDisconnected();
    });
    this.socket.on('reconnect_failed', () => {
      this.socketStateService.reconnectFailed();
    });
    this.socket.on('reconnect', () => {
      this.socketStateService.socketReconnected();
    });
  }

  public bindEvent(name: string, handler: any) {
    this.eventsToBind.push({ event: name, handler: handler });
  }

  public bindEvents() {
    this.eventsToBind.forEach((event: any) => {
      this.socket.on(event.event, event.handler);
    });
  }

  public disconnect() {
    if (this.socket) this.socket.disconnect();
  }

  public emit(event: SocketEventTypes, payload: string): void {
    this.socket.emit(event, payload);
  }

  public reconnect(): boolean {
    if (!this.socket) return false;
    this.socket.connect();
    return true;
  }
}
