import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Media, MediaParams } from '@quorum/models/xs-resource';
import * as MediaActions from './media.actions';

export const MEDIA_FEATURE_KEY = 'media';

export interface MediaState extends EntityState<Media> {
  selectedId?: string; // which Media record has been selected
  loaded: boolean; // has the Media list been loaded
  error?: string | null; // last none error (if any)
  vehicleId: number;
}

interface MediaUploadState {
  [id: string]: string | number;
  fileName: string;
  fileNameToDisplay: string;
  fileType: string;
  fileStatus: string;
}

export interface MediaUploadingState extends EntityState<MediaUploadState> {}

export function selectMediaUploadingId(m: any): string {
  //In this case this would be optional since primary key is id
  return m.fileName;
}

export const mediaUploadingAdapter: EntityAdapter<any> = createEntityAdapter<MediaUploadingState>({
  selectId: selectMediaUploadingId,
});

export const initialMediaUploadingState: MediaUploadingState = mediaUploadingAdapter.getInitialState({});

export interface MediaParamsState extends EntityState<MediaParams> {}

export interface MediaPartialState {
  readonly [MEDIA_FEATURE_KEY]: MediaState;
}

export interface MediaParamsPartialState {
  readonly [MEDIA_FEATURE_KEY]: MediaParamsState;
}

export function selectMediaId(m: Media): string {
  //In this case this would be optional since primary key is id
  return m.SK;
}

export function selectMediaParamsId(m: MediaParams): string {
  console.log(m);
  //In this case this would be optional since primary key is id
  return m.key;
}

export const mediaAdapter: EntityAdapter<Media> = createEntityAdapter<Media>({ selectId: selectMediaId });
export const mediaParamsAdapter: EntityAdapter<MediaParams> = createEntityAdapter<MediaParams>({
  selectId: selectMediaParamsId,
});

export const initialMediaState: MediaState = mediaAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  vehicleId: null,
});

export const initialMediaParamsState: MediaParamsState = mediaParamsAdapter.getInitialState({});

export interface State {
  media: MediaState;
  mediaParams: MediaParamsState;
  mediaUploading: MediaUploadingState;
}

export const initialState: State = {
  media: initialMediaState,
  mediaParams: initialMediaParamsState,
  mediaUploading: initialMediaUploadingState,
};

const mediaReducer = createReducer(
  initialState,
  on(MediaActions.loadMediaSuccess, (state, { media, vehicleId }) => {
    return { ...state, media: mediaAdapter.setAll(media, { ...state.media, loaded: true, vehicleId }) };
  }),
  on(MediaActions.loadDeltaMediaSuccess, (state, { media, vehicleId }) => {
    return { ...state, media: mediaAdapter.upsertMany(media, { ...state.media, loaded: true, vehicleId }) };
  }),
  on(MediaActions.loadMediaFailure, (state, { error }) => ({ ...state, error })),
  on(MediaActions.setMediaModelKey, (state, { mediaParams }) => {
    return { ...state, mediaParams: mediaParamsAdapter.setOne(mediaParams, state.mediaParams) };
  }),
  on(MediaActions.deleteMediaFromServerSuccess, (state, { media }) => {
    return { ...state, media: mediaAdapter.removeOne(media.SK, { ...state.media }) };
  }),
  on(MediaActions.updateMediaOnServerSuccess, (state, { media }) => {
    return { ...state, media: mediaAdapter.upsertOne(media, { ...state.media }) };
  }),
  on(MediaActions.uploadMedia, (state, { file }) => {
    return { ...state, mediaUploading: mediaUploadingAdapter.setOne(file, { ...state.mediaUploading }) };
  }),
  on(MediaActions.mediaUploaded, (state, { file }) => {
    return { ...state, mediaUploading: mediaUploadingAdapter.upsertOne(file, { ...state.mediaUploading }) };
  }),
  on(MediaActions.clearMediaState, (state) => {
    return {
      ...state,
      media: initialMediaState,
      mediaParams: initialMediaParamsState,
      mediaUploading: initialMediaUploadingState,
    };
  }),

  on(MediaActions.clearUploadingMediaState, (state) => {
    return {
      ...state,
      mediaUploading: initialMediaUploadingState,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return mediaReducer(state, action);
}
