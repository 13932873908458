<div fxLayout="row">
  <sha-avatar
    *ngIf="members"
    class="avatar-container"
    [size]="'medium'"
    [avatarUrl]="members[members?.length - 1].avatarUrl"
    [displayPresence]="false"
    [initials]="[members[members.length - 1].firstName.charAt(0) + members[members.length - 1].lastName.charAt(0)]"
  >
  </sha-avatar>
  <div fxFlex fxLayout="column">
    <div fxLayout="row">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div id="wave" [ngClass]="members ? 'event-background' : ''">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  </div>
</div>
