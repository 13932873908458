export class VehicleSearch {
  availableDate: Date = null;
  categoryDescription: string = null;
  categoryId: string = null;
  currentMileage: number = null;
  days: number = null;
  description: string = null;
  genericModelId: number = null;
  invoiceNumber: string = null;
  lastMileageDate: Date = null;
  make: string = null;
  modelCode: string = null;
  orderNumber: string = null;
  pegCode: string = null;
  price1: number = null;
  price1Description: string = null;
  price2: number = null;
  price2Description: string = null;
  price3: number = null;
  price3Description: string = null;
  receivedDate: Date = null;
  rooftopDescription: string = null;
  rooftopId: number = null;
  saleCount: number = null;
  saleStatusDescription: string = null;
  saleStatusId: string = null;
  subCategoryDescription: string = null;
  subCategoryId: number = null;
  subTypeId: number = null;
  unitNumber: string = null;
  vehicleCode: string = null;
  vehicleCodeColorDescription: string = null;
  vehicleColorId: number = null;
  vehicleDetailColorDescription: string = null;
  vehicleId: number = null;
  vehicleInteriorColorDescription: string = null;
  vehicleProspectColorDescription: string = null;
  vehicleStatusDescription: string = null;
  vehicleStatusId: string = null;
  vehicleYear: number = null;
  vin: string = null;

  constructor(obj?: VehicleSearch) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export const mockVehicleSearch = new VehicleSearch({
  availableDate: new Date(),
  categoryDescription: 'Used',
  categoryId: '3',
  currentMileage: 12345,
  days: 5,
  description: 'SUV',
  genericModelId: 321,
  invoiceNumber: 'invocie 123',
  lastMileageDate: new Date(),
  make: 'Ford',
  modelCode: 'model code',
  orderNumber: '112233',
  pegCode: 'string',
  price1: 15000,
  price1Description: null,
  price2: 17000,
  price2Description: null,
  price3: 19000,
  price3Description: null,
  receivedDate: new Date(),
  rooftopDescription: null,
  rooftopId: 1,
  saleCount: 2,
  saleStatusDescription: 'Sold',
  saleStatusId: 'S',
  subCategoryDescription: null,
  subCategoryId: 3,
  subTypeId: 4,
  unitNumber: '4321',
  vehicleCode: null,
  vehicleCodeColorDescription: 'black',
  vehicleColorId: 1,
  vehicleDetailColorDescription: 'silver',
  vehicleId: 6,
  vehicleInteriorColorDescription: null,
  vehicleProspectColorDescription: null,
  vehicleStatusDescription: null,
  vehicleStatusId: '10',
  vehicleYear: 5,
  vin: '1G1ZC5EB0AF149974',
});
