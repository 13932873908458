<div class="container" fxLayout="column">
  <mat-toolbar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button class="secondary-text" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span fxFlex class="toolbar-title">Groups</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div fxFlex class="conversastion-list-container" *ngIf="groups$ | async as groups">
    <com-conversations-list [conversations]="groups"></com-conversations-list>
  </div>
</div>
