import { Injectable } from '@angular/core';
import { EventTypes } from '@models/server-common';
import { select, Store } from '@ngrx/store';
import { selectAuthenticationAuthenticatedUserUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { fromEvents } from '@quorum/communicator/state/events';
import {
  fromMembers,
  MembersStateService,
  selectEntities as selectMemberEntities,
} from '@quorum/communicator/state/members';
import { ConversationQueryParameters, MessageTransactionMessagePreferenceQueryParameters } from '@quorum/models/xs-query';
import { UserTyping } from '@quorum/models/xs-misc';

import {
  AuthenticatedUser,
  CommunicatorContact as Contact,
  DirectMessageTemplate,
  Event,
  Member,
  MessageTransactionMessagePreference,
  Template,
} from '@quorum/models/xs-resource';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromConversations from './+state/conversations.actions';
import {
  conversationsIsLoadingSelector,
  conversationsLastModifiedSelector,
  conversationsLastQueryPageNumberSelector,
  conversationUiSelector,
  directMessageTemplatesSelector,
  eventsLastQueryPageSelector,
  messagePreferenceOverrideSelector,
  messageTypesSelector,
  newConversationConversationSelector,
  newConversationMembersSelector,
  selectAll,
  selectEntities,
  unsentMessagesSelector,
} from './+state/conversations.interfaces';
import { ConversationView } from './conversation-view.enum';
import { Conversation } from './conversation.model';

@Injectable()
export class ConversationStateService {
  constructor(private store: Store<any>, private memberStateService: MembersStateService) {}

  addMemberToNewConversation(member: Member) {
    this.store.dispatch(new fromConversations.AddMemberToNewConversationInState(member));
  }

  addUnsentMessage(conversationId: number, message: string) {
    this.store.dispatch(new fromConversations.AddUnsentMessageToState({ conversationId, message }));
  }

  archiveMessagesOfType(currentUserId: number, messageTypeId: number) {
    this.store.dispatch(new fromConversations.PostArchiveMessagesOfTypeToServer({ currentUserId, messageTypeId }));
  }

  createConversation(conversation: Conversation, members: Member[], event: Event, template?: Template) {
    this.store.dispatch(new fromConversations.PostConversationToServer({ conversation, members, event, template }));
  }

  deleteDirectMessageTemplate(template: DirectMessageTemplate) {
    this.store.dispatch(new fromConversations.DeleteDirectMessageTemplateFromServer(template));
  }

  deleteUnsentMessage(conversationId: number) {
    this.store.dispatch(new fromConversations.DeleteUnsentMessageFromState({ conversationId }));
  }

  createPinnedConversationsAction(utcLastModified?: Date): any {
    const queryParameters = this.createPinnedConversationsParams(utcLastModified);
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createPinnedConversationsParams(utcLastModified?: Date): ConversationQueryParameters {
    let eventTypeIds: number[] = this.getConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: true,
      pageSize: 100,
      eventTypeId: eventTypeIds.join(','),
      embed: 'lastEvent,messageType'
    });
    if (utcLastModified) params.lastModifiedSearchStartDate = utcLastModified;
    return params;
  }

  createPinnedXselleratorConversationsFromOthersAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date
  ): any {
    const queryParameters = this.createPinnedXselleratorConversationsFromOthersParams(
      authenticatedUser,
      utcLastModified
    );
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createPinnedXselleratorConversationsFromOthersParams(authenticatedUser: AuthenticatedUser, utcLastModified: Date) {
    const eventTypeIds: number[] = this.getActivitiesConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: true,
      pageSize: 100,
      eventTypeId: eventTypeIds.join(','),
      lastEventCreatedByIdNotEqual: authenticatedUser.user.id.toString(),
      embed: 'lastEvent,messageType'
    });
    if (utcLastModified) params.lastModifiedSearchStartDate = utcLastModified;
    return params;
  }

  createPinnedXselleratorConversationsFromSelfAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date
  ): any {
    const queryParameters = this.createPinnedXselleratorConversationsFromSelfParams(authenticatedUser, utcLastModified);
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createPinnedXselleratorConversationsFromSelfParams(authenticatedUser: AuthenticatedUser, utcLastModified?: Date) {
    const eventTypeIds: number[] = this.getActivitiesConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: true,
      pageSize: 100,
      eventTypeId: eventTypeIds.join(','),
      lastEventCreatedById: authenticatedUser.user.id,
      embed: 'lastEvent,messageType'
    });
    if (utcLastModified) params.lastModifiedSearchStartDate = utcLastModified;
    return params;
  }

  dispatchUnpinnedConversationsAction(utcLastModified?: Date, pageNumber?: number) {
    this.store.dispatch(this.createUnpinnedConversationsAction(utcLastModified, pageNumber));
  }

  createUnpinnedConversationsAction(utcLastModified?: Date, pageNumber?: number): any {
    const queryParameters = this.createUnpinnedConversationsParams(utcLastModified, pageNumber);
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createUnpinnedConversationsParams(utcLastModified?: Date, pageNumber?: number) {
    let eventTypeIds: number[] = this.getConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: false,
      eventTypeId: eventTypeIds.join(','),
      embed: 'lastEvent,messageType'
    });

    if (utcLastModified) params.lastModifiedSearchStartDate = utcLastModified;
    else {
      params.pageNumber = pageNumber ? pageNumber : 1;
      params.pageSize = 25;
    }
    return params;
  }

  dispatchUnpinnedXselleratorConversationsFromOthersAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ) {
    this.store.dispatch(
      this.createUnpinnedXselleratorConversationsFromOthersAction(authenticatedUser, utcLastModified, pageNumber)
    );
  }

  createUnpinnedXselleratorConversationsFromOthersAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ): any {
    const queryParameters = this.createUnpinnedXselleratorConversationsFromOthersParams(
      authenticatedUser,
      utcLastModified,
      pageNumber
    );
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createUnpinnedXselleratorConversationsFromOthersParams(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ) {
    const eventTypeIds: number[] = this.getActivitiesConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: false,
      eventTypeId: eventTypeIds.join(','),
      lastEventCreatedByIdNotEqual: authenticatedUser.user.id.toString(),
      embed: 'lastEvent,messageType'
    });

    if (utcLastModified) {
      params.lastModifiedSearchStartDate = utcLastModified;
    } else {
      params.pageNumber = pageNumber ? pageNumber : 1;
      params.pageSize = 25;
    }
    return params;
  }

  createUnpinnedXselleratorConversationsFromSelfAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ): any {
    const queryParameters = this.createUnPinnedXselleratorConversationsFromSelfParams(
      authenticatedUser,
      utcLastModified,
      pageNumber
    );
    return new fromConversations.GetConversationsFromServer({ queryParameters });
  }

  createUnPinnedXselleratorConversationsFromSelfParams(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ) {
    const eventTypeIds: number[] = this.getActivitiesConversationsQueryEventTypeIds();
    let params: ConversationQueryParameters = new ConversationQueryParameters({
      isArchived: false,
      isExcluded: false,
      isPinnedToUi: false,
      eventTypeId: eventTypeIds.join(','),
      lastEventCreatedById: authenticatedUser.user.id,
      embed: 'lastEvent,messageType'
    });

    if (utcLastModified) {
      params.lastModifiedSearchStartDate = utcLastModified;
    } else {
      params.pageNumber = pageNumber ? pageNumber : 1;
      params.pageSize = 25;
    }
    return params;
  }

  dispatchUnpinnedXselleratorConversationsFromSelfAction(
    authenticatedUser: AuthenticatedUser,
    utcLastModified?: Date,
    pageNumber?: number
  ) {
    this.store.dispatch(
      this.createUnpinnedXselleratorConversationsFromSelfAction(authenticatedUser, utcLastModified, pageNumber)
    );
  }

  dispatchConversationsRefreshActions(authenticatedUser: AuthenticatedUser, utcLastModified: Date) {
    const actions: any = this.getConversationsRefreshActions(authenticatedUser, utcLastModified);
    actions.forEach((action: any) => {
      this.store.dispatch(action);
    });
  }

  getConversationsRefreshActions(authenticatedUser: AuthenticatedUser, utcLastModified: Date): any {
    let actions: any = [];
    actions.push(this.createUnpinnedConversationsAction(utcLastModified));
    actions.push(this.createPinnedConversationsAction(utcLastModified));
    actions.push(this.createUnpinnedXselleratorConversationsFromOthersAction(authenticatedUser, utcLastModified));
    actions.push(this.createUnpinnedXselleratorConversationsFromSelfAction(authenticatedUser, utcLastModified));
    actions.push(this.createPinnedXselleratorConversationsFromSelfAction(authenticatedUser, utcLastModified));
    actions.push(this.createPinnedXselleratorConversationsFromOthersAction(authenticatedUser, utcLastModified));

    if (utcLastModified) {
      actions.push(this.createArchivedConversationsToRemoveFromStateAction(utcLastModified));
      actions.push(this.createExcludedConversationsToRemoveFromStateAction(utcLastModified));
    }
    return actions;
  }

  getActivitiesConversationsQueryEventTypeIds(): number[] {
    let eventTypeIds: number[] = new Array();
    for (let eventType in EventTypes) {
      if (!isNaN(Number(eventType))) {
        if (
          eventType === EventTypes.XselleratorAutomatedExternalOutbound.toString() ||
          eventType === EventTypes.XselleratorAutomatedInternalOutbound.toString()
        )
          eventTypeIds.push(parseInt(eventType));
      }
    }
    return eventTypeIds;
  }

  getConversationsQueryEventTypeIds(): number[] {
    let eventTypeIds: number[] = new Array();
    for (let eventType in EventTypes) {
      if (!isNaN(Number(eventType))) {
        if (
          eventType !== EventTypes.XselleratorAutomatedExternalOutbound.toString() &&
          eventType !== EventTypes.XselleratorAutomatedInternalOutbound.toString()
        )
          eventTypeIds.push(parseInt(eventType));
      }
    }
    return eventTypeIds;
  }

  isTyping(userTyping: UserTyping) {
    let typingMembers: Member[] = [];

    this.selectConversation(userTyping.conversationId)
      .pipe(
        take(1),
        map((conversation: Conversation) => {
          if (!conversation) return;
          if (conversation.typingMembers) typingMembers = conversation.typingMembers;

          if (userTyping.isTyping) {
            if (typingMembers.findIndex(i => i.id === userTyping.member.id) === -1) {
              typingMembers.push(userTyping.member);
              this.store.dispatch(
                new fromConversations.AddUserIsTypingToState({
                  id: userTyping.conversationId,
                  changes: { typingMembers: typingMembers }
                })
              );
            }
          } else {
            if (typingMembers.findIndex(i => i.id === userTyping.member.id) !== -1) {
              typingMembers.splice(typingMembers.indexOf(userTyping.member), 1);
              this.store.dispatch(
                new fromConversations.AddUserIsTypingToState({
                  id: userTyping.conversationId,
                  changes: { typingMembers: typingMembers }
                })
              );
            }
          }
        })
      )
      .subscribe();
  }

  leaveConversation(member: Member) {
    this.memberStateService.removeSelfFromConversation(member);
  }

  postDirectMessageTemplate(template: DirectMessageTemplate) {
    this.store.dispatch(new fromConversations.PostDirectMessageTemplateToServer(template));
  }

  removeMemberFromNewConversation(member: Member) {
    this.store.dispatch(new fromConversations.DeleteMemberFromNewConversationInState(member));
  }

  resetState() {
    this.store.dispatch(new fromConversations.ResetConversationState({}));
    this.store.dispatch(new fromMembers.ResetMemberState({}));
    this.store.dispatch(new fromEvents.ResetEventState({}));
  }

  createArchivedConversationsToRemoveFromStateAction(utcLastModified: Date) {
    if (utcLastModified) {
      const params: ConversationQueryParameters = new ConversationQueryParameters({
        isArchived: true,
        lastModifiedSearchStartDate: utcLastModified
      });

      return new fromConversations.GetConversationsToRemoveFromState(params);
    }
  }

  createExcludedConversationsToRemoveFromStateAction(utcLastModified: Date) {
    if (utcLastModified) {
      const params: ConversationQueryParameters = new ConversationQueryParameters({
        isExcluded: true,
        lastModifiedSearchStartDate: utcLastModified
      });

      return new fromConversations.GetConversationsToRemoveFromState(params);
    }
  }

  selectConversation(id: number): Observable<Conversation> {
    return this.store.pipe(
      select(selectEntities),
      map((conversations: any) => {
        return new DeepCopyPipe().transform(conversations)[id];
      })
    );
  }

  selectConversationsForContacts(contacts: Contact[]) {
    return new Observable(observer => {
      forkJoin([
        this.store.pipe(select(selectAuthenticationAuthenticatedUserUser)).pipe(take(1)),
        this.store.pipe(select(selectMemberEntities)).pipe(take(1)),
        this.store.pipe(select(selectEntities)).pipe(take(1))
      ]).subscribe(([authenticatedUser, members, conversations]) => {
        var groupBy = (items: Array<any>, key: string) =>
          items.reduce(
            (result, item) => ({
              ...result,
              [item[key]]: [...(result[item[key]] || []), item]
            }),
            {}
          );

        // Create dictionary of members using conversationId
        const conversationMembers: { [id: number]: Member[] } = groupBy(
          Object.entries(members).map(([k, v]) => v),
          'conversationId'
        );

        const contactIdsToMatch = [...contacts.map(c => c.id), authenticatedUser.id].sort().join(',');

        // find conversations that match all contacts
        const matchedConversations: Conversation[] = Object.keys(conversationMembers)
          .filter(id => {
            const membersForConversation = conversationMembers[parseInt(id)]
              .map(m => {
                if (m.isEmployee) return parseInt(m.userId);
              })
              .sort()
              .join(',');

            if (contactIdsToMatch == membersForConversation && conversations[parseInt(id)]) return parseInt(id);
          })
          .map(id => conversations[parseInt(id)]);

        observer.next(matchedConversations);
      });
    });
  }

  selectConversations(authenticatedUser: AuthenticatedUser) {
    return this.store.pipe(
      select(selectAll),
      map(conversations => {
        let conversationsCopy: Conversation[] = new DeepCopyPipe().transform(conversations);
        return conversationsCopy
          .filter((conversation: Conversation) => conversation.embedded && conversation.embedded['lastEvent'])
          .filter((conversation: Conversation) => {
            const event: Event = conversation.embedded['lastEvent'] as Event;
            if (
              (event.eventTypeId !== EventTypes.XselleratorAutomatedExternalOutbound &&
                event.eventTypeId !== EventTypes.XselleratorAutomatedInternalOutbound) ||
              ((event.eventTypeId === EventTypes.XselleratorAutomatedExternalOutbound ||
                event.eventTypeId === EventTypes.XselleratorAutomatedInternalOutbound) &&
                event.memberId !== authenticatedUser.user.id.toString())
            ) {
              return conversation;
            }
          });
      })
    );
  }

  selectAllConversations(authenticatedUser: AuthenticatedUser) {
    return this.store.pipe(
      select(selectAll),
      map((conversations) => {
        let conversationsCopy: Conversation[] = new DeepCopyPipe().transform(conversations);
        return conversationsCopy.filter(
          (conversation: Conversation) => conversation.embedded && conversation.embedded['lastEvent']
        );
      })
    );
  }

  selectMyActivitiesConversations(authenticatedUser: AuthenticatedUser) {
    return this.store.pipe(
      select(selectAll),
      map(conversations => {
        let conversationsCopy: Conversation[] = new DeepCopyPipe().transform(conversations);
        return conversationsCopy
          .filter((conversation: Conversation) => conversation.embedded && conversation.embedded['lastEvent'])
          .filter((conversation: Conversation) => {
            const event: Event = conversation.embedded['lastEvent'] as Event;
            if (
              (event.eventTypeId === EventTypes.XselleratorAutomatedExternalOutbound ||
                event.eventTypeId === EventTypes.XselleratorAutomatedInternalOutbound) &&
              event.memberId === authenticatedUser.user.id.toString()
            ) {
              return conversation;
            }
          });
      })
    );
  }

  selectDirectMessageTemplates() {
    return this.store.pipe(select(directMessageTemplatesSelector));
  }

  selectEventsLastQueryPage(conversationId: number) {
    return this.store.pipe(select(eventsLastQueryPageSelector));
  }

  selectIsLoading() {
    return this.store.pipe(select(conversationsIsLoadingSelector));
  }

  selectLastModified() {
    return this.store.pipe(select(conversationsLastModifiedSelector));
  }

  selectLastQueryPageNumber(conversationView: ConversationView) {
    return this.store.pipe(
      select(conversationsLastQueryPageNumberSelector),
      map(d => {
        switch (conversationView) {
          case ConversationView.conversations:
            return d.conversations;
            break;
          case ConversationView.activities:
            return d.activities;
            break;
          default:
            break;
        }
      })
    );
  }

  selectMessageTypes() {
    return this.store.pipe(select(messageTypesSelector));
  }

  selectNewConversationMembers() {
    return this.store.pipe(select(newConversationMembersSelector));
  }

  selectNewConversation() {
    return this.store.pipe(select(newConversationConversationSelector));
  }

  selectUiState() {
    return this.store.pipe(select(conversationUiSelector));
  }

  selectUnsentMessages() {
    return this.store.pipe(select(unsentMessagesSelector));
  }

  startNewConversation(conversation: Conversation) {
    this.store.dispatch(new fromConversations.StartNewConversationInState({ conversation: conversation }));
  }

  toggleConversationInfoPanel(visible: boolean) {
    this.store.dispatch(new fromConversations.ToggleConversationInfoPanelUi(!visible));
  }

  toggleEmojiPanel(visible: boolean) {
    this.store.dispatch(new fromConversations.ToggleEmojiPanelUi(!visible));
  }

  updateConversationIsPinnedToUi(id: number, isPinnedToUi: boolean) {
    this.store.dispatch(new fromConversations.UpdateConversationIsPinnedToUiInState({ id, changes: { isPinnedToUi } }));
  }

  updateConversationTitle(id: number, title: string) {
    this.store.dispatch(new fromConversations.UpdateConversationTitleOnServer({ id, title }));
  }

  updateConversationTitleFromSocketEvent(conversation: Conversation) {
    this.store.dispatch(
      new fromConversations.UpdateConversationTitleFromSocketEventInState({
        id: conversation.id,
        changes: { title: conversation.title }
      })
    );
  }

  updateDirectMessageTemplate(template: DirectMessageTemplate) {
    this.store.dispatch(new fromConversations.UpdateDirectMessageTemplateToServer(template));
  }

  getConversationsForTransaction(conversationQueryParams: ConversationQueryParameters) {
    this.store.dispatch(new fromConversations.GetConversationsFromServer({ queryParameters: conversationQueryParams }));
  }

  getMessagePreferenceOverride(queryParams: MessageTransactionMessagePreferenceQueryParameters) {
    this.store.dispatch(new fromConversations.GetMessageTransactionPreferencesFromServer(queryParams));
  }

  createMessagePreferenceOverride(messageTransactionPreference: MessageTransactionMessagePreference) {
    this.store.dispatch(new fromConversations.PostMessageTransactionPreferenceToServer(messageTransactionPreference));
  }

  updateMessagePreferenceOverride(messageTransactionPreference: MessageTransactionMessagePreference) {
    this.store.dispatch(new fromConversations.PutMessageTransactionPreferenceToServer(messageTransactionPreference));
  }

  deleteMessagePreferenceOverride(messageTransactionPreference: MessageTransactionMessagePreference) {
    this.store.dispatch(
      new fromConversations.DeleteMessageTransactionPreferenceFromServer(messageTransactionPreference)
    );
  }

  selectMessagePreferenceOverride(): Observable<MessageTransactionMessagePreference> {
    return this.store.pipe(
      select(messagePreferenceOverrideSelector),
      map((messagePreferenceOverride) => {
        return new DeepCopyPipe().transform(messagePreferenceOverride);
      })
    );
  }
}
