import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact, DirectMessageTemplate } from '@quorum/models/xs-resource';

@Component({
  selector: 'com-direct-message-templates-dialog',
  templateUrl: './direct-message-templates-dialog.component.html',
  styleUrls: ['./direct-message-templates-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectMessageTemplatesDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { templates: DirectMessageTemplate[]; currentContact: Contact }) {}

  ngOnInit() {}
}
