import { Conversation } from './conversation.model';
import { EventType } from './event-type.model';
import { Member } from './member.model';
import { Channel } from '@quorum/models/xs-enum';
export class Event {
  attachments?: string;
  channelId?: Channel;
  channelValue?: string;
  content: string;
  conversation?: Conversation;
  conversationId: number;
  guid?: string;
  id: number;
  member?: Member;
  memberId: string;
  sent?: boolean;
  sentDate: Date;
  eventType?: EventType;
  eventTypeId: number;
  sendToCustomer?: boolean;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

export const eventMock = new Event({
  content: '',
  conversationId: 1,
  guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  id: 1,
  memberId: '1001',
  sent: true,
  sentDate: new Date(),
  eventType: { id: 7, description: 'Message', isViewable: true },
  eventTypeId: 7,
  sendToCustomer: false,
});

export const eventsMock: Event[] = [
  eventMock,
  new Event({
    content: '',
    conversationId: 1,
    guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    id: 1,
    memberId: '1002',
    sent: true,
    sentDate: new Date(),
    eventType: { id: 7, description: 'Message', isViewable: true },
    eventTypeId: 7,
    sendToCustomer: false,
  }),
];
