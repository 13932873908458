import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sha-media-gallery-edit-media-dialog',
  templateUrl: './media-gallery-edit-media-dialog.component.html',
  styleUrls: ['./media-gallery-edit-media-dialog.component.css'],
})
export class MediaGalleryEditMediaDialogComponent implements OnInit {
  editMediaForm = this.fb.group({
    caption: [this.data.media.caption],
    customerFacing: [
      this.data.media?.customerFacing === true ||
      (typeof this.data.media?.customerFacing === 'string' && this.data.media?.customerFacing.toLowerCase() === 'true')
        ? true
        : false,
    ],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { media: any },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MediaGalleryEditMediaDialogComponent>
  ) {}

  ngOnInit() {}

  cancel() {
    this.dialogRef.close();
  }

  save(editMediaForm: { caption: string; customerFacing: boolean }) {
    this.dialogRef.close(editMediaForm);
  }

  toggleCustomerFacing(customerFacing: boolean) {
    this.editMediaForm.get('customerFacing').setValue(!customerFacing);
  }
}
