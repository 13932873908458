import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authenticationStateService: AuthenticationStateService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers: any;

    return combineLatest([
      this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
      this.authenticationStateService.selectQuorumApiKey().pipe(take(1)),
      this.authenticationStateService.selectQuorumDestinationId().pipe(take(1)),
    ]).pipe(
      map(([authenticatedUser, apiKey, destinationId]) => {
        return { authenticatedUser, apiKey, destinationId };
      }),
      mergeMap((data) => {
        const authenticatedUser = data.authenticatedUser;
        const apiKey = data.apiKey;
        const destinationId = data.destinationId;
        const reqHeaderApiKey = req.headers.get('api-key');
        const reqHeaderDestinationId = req.headers.get('destination-id');
        if (
          authenticatedUser &&
          authenticatedUser.token &&
          req.url.indexOf('oauth/token') == -1 &&
          req.url.indexOf('image-test') == -1 &&
          req.url.indexOf('89bwqps39a') == -1 &&
          req.url.indexOf('amazonaws') == -1 &&
          req.url.indexOf('quorum-api') == -1
        ) {
          headers = {
            'api-key': authenticatedUser.user.apiKey,
            'destination-id': authenticatedUser.user.destination,
            'origin-id': authenticatedUser.user.id ? authenticatedUser.user.id.toString() : '',
          };
          if (req.url.indexOf('azure') == -1) {
            headers['Authorization'] = 'Bearer ' + authenticatedUser.token;
          }
          const modifiedReq = req.clone({ setHeaders: headers });
          return next.handle(modifiedReq);
        } else if (
          apiKey != null &&
          destinationId != null &&
          req.url.indexOf('oauth/token') == -1 &&
          req.url.indexOf('image-test') == -1 &&
          req.url.indexOf('amazonaws') == -1
        ) {
          if (reqHeaderApiKey && reqHeaderDestinationId) {
            headers = {
              'api-key': reqHeaderApiKey,
              'destination-id': reqHeaderDestinationId,
              'origin-id': '0',
            };
          } else {
            headers = {
              'api-key': data.apiKey,
              'destination-id': data.destinationId,
              'origin-id': '0',
            };
          }

          try {
            const modifiedReq = req.clone({ setHeaders: headers });

            return next.handle(modifiedReq);
          } catch (e) {
            return next.handle(e);
          }
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
