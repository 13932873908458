<mat-toolbar *ngIf="ui$ | async as conversationUI">
  <div fxFlex fxFlexOffset="28px" fxLayout="column" fxLayoutAlign="center center">
    <span class="toolbar-title">{{ title }}</span>
    <span class="mat-caption secondary-text"> {{ transactionDescription }}</span>
  </div>
  <button id="com-header-info" mat-icon-button (click)="toggleConversationInfoPanel(conversationUI.infoPanelVisible)">
    <mat-icon color="primary" id="info-icon" class="secondary-text">
      {{ conversationUI.infoPanelVisible ? 'info' : 'info_outline' }}</mat-icon
    >
  </button>
</mat-toolbar>
