import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { Contact } from '@quorum/models/xs-resource';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'com-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactListComponent implements OnInit, OnDestroy {
  @Input() contacts: Contact[];

  errors$: Observable<any>;
  isLoading$: Observable<boolean>;
  contactsPresence$: Observable<any>;
  groupConfiguration$: Subscription;
  groupConfiguration: { [id: number]: { collapsed: boolean } };

  constructor(private contactsStateService: ContactsStateService) {}

  ngOnInit() {
    this.errors$ = this.contactsStateService.selectErrors();
    this.isLoading$ = this.contactsStateService.selectIsLoading();
    this.contactsPresence$ = this.contactsStateService.selectPresences();
    this.groupConfiguration$ = this.contactsStateService
      .selectGroupConfiguration()
      .pipe(
        map((config: any) => {
          this.groupConfiguration = config;
        })
      )
      .subscribe();
  }

  collapseGroup(id: number) {
    this.contactsStateService.updateGroupConfiguration(
      id,
      this.groupConfiguration[id] ? !this.groupConfiguration[id].collapsed : true
    );
  }

  ngOnDestroy() {
    this.groupConfiguration$.unsubscribe();
  }
}
