import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CommunicatorMessageType as MessageType,
  MessageTypeToTemplateMapping,
  Template,
} from '@quorum/models/xs-resource';
import { messageTypeToTemplateMappingActions, templateActions } from '@quorum/sha-entities/quorum';
import * as TemplatesActions from './templates.actions';
import { resetState, setSelectedLanguage } from './templates.actions';
import { TemplatesEntity } from './templates.models';
export const TEMPLATES_FEATURE_KEY = 'templates';

//#region storeGallery
export interface StoreGalleryState extends EntityState<Template> {
  loading: boolean;
}

export const storeGalleryAdapter: EntityAdapter<Template> = createEntityAdapter<Template>({});
export const initialStoreGalleryState: StoreGalleryState = storeGalleryAdapter.getInitialState({ loading: true });
//#endregion

//#region quorumGallery
export interface QuorumGalleryState extends EntityState<Template> {
  loading: boolean;
}

export const quorumGalleryAdapter: EntityAdapter<Template> = createEntityAdapter<Template>({});
export const initialQuorumGalleryState: QuorumGalleryState = quorumGalleryAdapter.getInitialState({ loading: true });
//#endregion

//#region quorumLibrary
export interface QuorumLibraryState extends EntityState<Template> {
  loading: boolean;
}

export const quorumLibraryAdapter: EntityAdapter<Template> = createEntityAdapter<Template>({});
export const initialQuorumLibraryState: QuorumLibraryState = quorumLibraryAdapter.getInitialState({ loading: true });
//#endregion

export interface MessageTypeState extends EntityState<MessageType> {
  loading: boolean;
}

export const messageTypeAdapter: EntityAdapter<MessageType> = createEntityAdapter<MessageType>({});
export const initialMessageTypeState: MessageTypeState = messageTypeAdapter.getInitialState({ loading: true });
//#endregion

//#region quorumGallery
export interface MessageTypeToTemplateMappingsState extends EntityState<MessageTypeToTemplateMapping> {
  loading: boolean;
}

export const messageTypeToTemplateMappingsStateAdapter: EntityAdapter<MessageTypeToTemplateMapping> =
  createEntityAdapter<MessageTypeToTemplateMapping>({
    selectId: (messageTypeToTemplateMapping) => messageTypeToTemplateMapping.messageTypeId,
  });

export const initialMessageTypeToTemplateMappingsState: MessageTypeToTemplateMappingsState =
  messageTypeToTemplateMappingsStateAdapter.getInitialState({
    loading: true,
  });
//#endregion

export interface State {
  storeGallery: StoreGalleryState;
  quorumGallery: QuorumGalleryState;
  quorumLibrary: QuorumLibraryState;
  languages: string[];
  messageTypes: MessageTypeState;
  messageTypeToTemplateMappings: MessageTypeToTemplateMappingsState;
  selectedTemplate: { etag: string; template: Template };
  selectedLanguage: string;
}

export interface TemplatesPartialState {
  readonly [TEMPLATES_FEATURE_KEY]: State;
}

export const templatesAdapter: EntityAdapter<TemplatesEntity> = createEntityAdapter<TemplatesEntity>();

export const initialState: State = {
  storeGallery: initialStoreGalleryState,
  quorumGallery: initialQuorumGalleryState,
  quorumLibrary: initialQuorumLibraryState,
  languages: [],
  messageTypes: initialMessageTypeState,
  messageTypeToTemplateMappings: initialMessageTypeToTemplateMappingsState,
  selectedTemplate: null,
  selectedLanguage: null,
};

const templatesReducer = createReducer(
  initialState,
  on(templateActions.GetStoreGalleryTemplatesFromServerSuccess, (state, { templates }) => ({
    ...state,
    storeGallery: storeGalleryAdapter.addMany(templates, { ...state.storeGallery, loading: false }),
    languages: templates
      .map((template) => Object.keys(template.languageDefinitionMap))
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index),
    selectedLanguage: state.selectedLanguage
      ? state.selectedLanguage
      : templates
          .map((template) => Object.keys(template.languageDefinitionMap))
          .reduce((prev, curr) => prev.concat(curr), [])
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)[0],
  })),
  on(templateActions.GetQuorumGalleryTemplatesFromServerSuccess, (state, { templates }) => ({
    ...state,
    quorumGallery: quorumGalleryAdapter.addMany(templates, { ...state.quorumGallery, loading: false }),
  })),
  on(templateActions.GetQuorumLibraryTemplatesFromServerSuccess, (state, { templates }) => ({
    ...state,
    quorumLibrary: quorumLibraryAdapter.addMany(templates, { ...state.quorumLibrary, loading: false }),
    languages: templates
      .map((template) => Object.keys(template.languageDefinitionMap))
      .reduce((prev, curr) => prev.concat(curr), [])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index),
  })),
  on(TemplatesActions.loadMessageTypesFromServerSuccess, (state, { messageTypes }) => ({
    ...state,
    messageTypes: messageTypeAdapter.addMany(messageTypes, { ...state.messageTypes, loading: false }),
  })),
  on(
    messageTypeToTemplateMappingActions.GetMessageTypeToTemplateMappingsFromServerSuccess,
    (state, { messageTypeToTemplateMappings }) => ({
      ...state,
      messageTypeToTemplateMappings: messageTypeToTemplateMappingsStateAdapter.addMany(messageTypeToTemplateMappings, {
        ...state.messageTypeToTemplateMappings,
        loading: false,
      }),
    })
  ),
  on(
    messageTypeToTemplateMappingActions.CreateMessageTypeToTemplateMappingOnServerSuccess,
    messageTypeToTemplateMappingActions.UpdateMessageTypeToTemplateMappingOnServerSuccess,
    messageTypeToTemplateMappingActions.PatchMessageTypeToTemplateMappingOnServerSuccess,
    (state, { messageTypeToTemplateMapping }) => ({
      ...state,
      messageTypeToTemplateMappings: messageTypeToTemplateMappingsStateAdapter.upsertOne(messageTypeToTemplateMapping, {
        ...state.messageTypeToTemplateMappings,
        loading: false,
      }),
    })
  ),
  on(templateActions.CreateStoreGalleryTemplateOnServerSuccess, (state, { template }) => ({
    ...state,
    storeGallery: storeGalleryAdapter.upsertOne(template, {
      ...state.storeGallery,
      loading: false,
    }),
  })),
  on(templateActions.DeleteStoreGalleryTemplateOnServerSuccess, (state, { templateId }) => ({
    ...state,
    storeGallery: storeGalleryAdapter.removeOne(templateId, {
      ...state.storeGallery,
      loading: false,
    }),
  })),
  on(
    templateActions.GetQuorumLibraryTemplateFromServerSuccess,
    templateActions.GetQuorumGalleryTemplateFromServerSuccess,
    templateActions.GetStoreGalleryTemplateFromServerSuccess,
    (state, { etag, template }) => ({
      ...state,
      selectedTemplate: { etag: etag, template: template },
    })
  ),
  on(templateActions.GetStoreGalleryTemplateFromServerSuccess, (state, { etag, template }) => ({
    ...state,
    storeGallery: storeGalleryAdapter.updateOne({ id: template.id, changes: template }, state.storeGallery),
  })),
  on(setSelectedLanguage, (state, { language }) => ({
    ...state,
    selectedLanguage: language,
  })),
  on(resetState, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return templatesReducer(state, action);
}
