<div class="media-gallery-edit-container" style="font-family: 'Open Sans';">
  <div class="text-lg font-semibold mb-6">Edit Media</div>
  <div>
    <form [formGroup]="editMediaForm">
      <div fxLayout="column">
        <div class="flex flex-col">
          <span class="font-semibold text-sm">Caption</span>
          <mat-form-field appearance="outline" class="text-sm">
            <input name="caption" autocomplete="false" matInput formControlName="caption" #caption />
            <button *ngIf="caption.value" matSuffix mat-icon-button aria-label="Clear" (click)="caption.value=''">
              <mat-icon class="my-1 pl-2">close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="flex flex-row w-full h-full items-center justify-start">
          <div class="transform scale-90">
            <button type="button" (click)="toggleCustomerFacing(editMediaForm.value?.customerFacing)"
              [ngClass]="{'bg-quorum-blue': editMediaForm.get('customerFacing').value, 'bg-grey-300': !editMediaForm.get('customerFacing').value }"
              class="relative inline-flex mt-0.5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out">
              <span [ngClass]="editMediaForm.get('customerFacing').value ? 'translate-x-5 ':'translate-x-0'"
                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
            </button>
          </div>
          <span class="font-medium text-sm ml-2">Customer Facing</span>
        </div>
      </div>

      <div class="flex flex-row place-content-end items-center mt-8">
        <span (click)="cancel()"
          class="flex place-items-center place-content-center py-2 px-5 rounded-md cursor-pointer"
          [ngStyle]="{ border: '1px solid #515251'}">Cancel</span>
        <button (click)="save(editMediaForm.value)" [disabled]="!editMediaForm.valid"
          class="flex place-items-center place-content-center py-2 px-5 rounded-md ml-3 cursor-pointer"
          [ngStyle]="{ background: 'rgba(0, 84, 166, 1)'}" type="submit">
          <span class="text-white font-medium text-sm">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>