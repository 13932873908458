import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router } from '@angular/router';

import { createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { fromEvents } from '@quorum/communicator/state/events';
import { fromMembers } from '@quorum/communicator/state/members';
import { ConversationShellComponent } from '@quorum/communicator/ui/ng/conversations';
import {
  ConversationPatchParameters,
  ConversationQueryParameters,
  DirectMessageTemplateQueryParameters,
  EventQueryParameters,
  MemberQueryParameters,
} from '@quorum/models/xs-query';
import {
  DirectMessageTemplate,
  Event,
  Member,
  MessageType,
  MessageTransactionMessagePreference,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { ShellComponent } from 'libs/communicator/ui/ng/root/src/lib/shell/shell.component';
import { forkJoin, from, of, race } from 'rxjs';
import { filter, flatMap, map, mergeAll, switchMap, take } from 'rxjs/operators';
import { ConversationStateService } from '../conversation-state.service';
import { ConversationView } from '../conversation-view.enum';
import { Conversation } from '../conversation.model';
import * as fromConversations from './conversations.actions';
import { ConversationsState } from './conversations.interfaces';

@Injectable()
export class ConversationsEffects {
  loadShell = createEffect(() =>
    this.d.navigation(ShellComponent, {
      run: (a: ActivatedRouteSnapshot, state: any) => {
        return race(
          this.router.events.pipe(
            filter((events) => {
              return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
            }),
            map((event) => {
              return { action: 'finished', event };
            })
          ),
          this.router.events.pipe(
            filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
            map((event) => {
              return { action: 'cancelled', event };
            })
          )
        ).pipe(
          switchMap((event) => {
            if (event.action === 'cancelled') {
              return from([]);
            } else {
              let actions: any = [];
              if (state.router.navigationId == 1 || this.routerStateService.getPreviousUrl() == '/login') {
                return forkJoin([
                  this.authenticationStateService.selectAuthenticatedEmployee().pipe(take(1)),
                  this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
                  this.conversationStateService.selectLastModified().pipe(take(1)),
                ]).pipe(
                  switchMap(([authenticatedEmployee, authenticatedUser, utcLastModified]) => {
                    actions = this.conversationStateService.getConversationsRefreshActions(
                      authenticatedUser,
                      utcLastModified
                    );

                    if (authenticatedEmployee) {
                      const directMessageParams: DirectMessageTemplateQueryParameters =
                        new DirectMessageTemplateQueryParameters({
                          departmentId: authenticatedEmployee.departmentId,
                          employeeId: authenticatedUser.user.xselleratorEmployeeId,
                        });

                      actions.push(new fromConversations.GetDirectMessageTemplatesFromServer(directMessageParams));
                    }
                    actions.push(new fromConversations.GetMessageTypesFromServer({}));
                    return from(actions);
                  })
                );
              } else {
                return from([]);
              }
            }
          })
        );
      },
      onError: (a: ActivatedRouteSnapshot, e: any) => {
        console.log(e);
        // we can log and error here and return null
        // we can also navigate back
        return null;
      },
    })
  );

  addMemberToNewConversation = createEffect(() =>
    this.d.fetch(fromConversations.ADD_MEMBER_TO_NEW_CONVERSATION_IN_STATE, {
      run: (a: fromConversations.AddMemberToNewConversationInState, state: any) => {
        const routeParams = this.routerStateService.getFullTreeParams(this.route);
        let newConversationMembers = state.conversations.newConversation.members.map((m: Member) => m.userId);
        let customerMember = state.conversations.newConversation.members.find((member: Member) => !member.isEmployee);

        let params = new ConversationQueryParameters();

        if (newConversationMembers.length === 1 && customerMember) {
          if (routeParams.transactionTypeId && routeParams.transactionId) {
            params.messageTypeId = '18';
            params.transactionTypeId = routeParams.transactionTypeId;
            params.transactionId = routeParams.transactionId;
          } else {
            params.messageTypeId = '18';
            params.transactionTypeId = '10';
            params.transactionId = customerMember.userId;
          }
        } else {
          params.employeeId = newConversationMembers.join(',');
        }

        return from([new fromConversations.GetNewConversationFromServer(params)]);
      },
      onError: (a: fromConversations.AddMemberToNewConversationInState, error) => {
        console.log({ error });
      },
    })
  );

  archiveMessagesOfType = createEffect(() =>
    this.d.pessimisticUpdate(fromConversations.POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER, {
      run: (a: fromConversations.PostArchiveMessagesOfTypeToServer, state: any) => {
        return this.apiService
          .post<string>(`communicator/members/${a.payload.currentUserId}/archive/${a.payload.messageTypeId}`, {})
          .pipe(
            flatMap((result: string) => {
              const actions: Action[] = [];
              let convoEvents: number[] = [];
              let convoMembers: number[] = [];
              state.conversations.ids.map((id: number) => {
                if (state.conversations.entities[id].dmsMessageTypeId === a.payload.messageTypeId) {
                  state.members.ids.map((memberId: number) => {
                    if (
                      memberId !== null &&
                      state.members.entities[memberId].conversationId === state.conversations.entities[id].id
                    ) {
                      convoMembers.push(memberId);
                    }
                  });

                  actions.push(new fromMembers.DeleteMembersInState({ ids: convoMembers }));

                  state.events.ids.map((eventId: number) => {
                    if (
                      eventId !== null &&
                      state.events.entities[eventId].conversationId === state.conversations.entities[id].id
                    ) {
                      convoEvents.push(eventId);
                    }
                  });

                  actions.push(new fromEvents.DeleteEventsInState(convoEvents));
                  actions.push(new fromConversations.DeleteConversationFromState({ id: id }));
                }
              });
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (a: fromConversations.PostArchiveMessagesOfTypeToServer, error: any) => {
        return new fromConversations.PostArchiveMessagesOfTypeToServerFailure({
          messageTypeId: a.payload.messageTypeId,
          error,
        });
      },
    })
  );

  checkIfConversationExists = createEffect(() =>
    this.d.fetch(fromConversations.GET_NEW_CONVERSATION_FROM_SERVER, {
      run: (a: fromConversations.GetNewConversationFromServer) => {
        return this.apiService.get<Conversation[]>('communicator/conversations', { params: a.payload }).pipe(
          flatMap((conversations: Conversation[]) => {
            const arrays = [];

            if (conversations.length > 0) {
              if (conversations.length > 1) {
                conversations.sort((a, b) => {
                  return a.id < b.id ? 1 : -1;
                });
              }
              arrays.push(new fromConversations.GetNewConversationFromServerSuccess(conversations[0]));
              arrays.push(
                new fromEvents.GetEventsFromServer(
                  new EventQueryParameters({ conversationId: conversations[0].id, pageNumber: 1, pageSize: 25 })
                )
              );
              arrays.push(
                new fromMembers.GetMembersForConversationFromServer({
                  queryParameters: new MemberQueryParameters({
                    conversationId: conversations[0].id,
                    embed: 'associate,addresses',
                  }),
                })
              );
            } else {
              arrays.push(new fromConversations.ResetConversationInNewConversationInState(null));
            }
            return arrays;
          })
        );
      },
      onError: (a: fromConversations.GetNewConversationFromServer, error: any) => {
        return new fromConversations.GetNewConversationFromServerFailure({ queryParameters: a.payload, error });
      },
    })
  );

  createConversation = createEffect(() =>
    this.d.pessimisticUpdate(fromConversations.POST_CONVERSATION_TO_SERVER, {
      run: (a: fromConversations.PostConversationToServer, state: any) => {
        return this.apiService.post<Conversation>('communicator/conversations', a.payload.conversation).pipe(
          flatMap((conversation: Conversation) => {
            conversation.embedded = a.payload.conversation.embedded;
            // Update members and event with new conversation id.
            const event: Event = new DeepCopyPipe().transform(a.payload.event);
            event.conversationId = conversation.id;
            const members: Member[] = new DeepCopyPipe().transform(a.payload.members);
            members.forEach((m) => (m.conversationId = conversation.id));
            return [
              new fromConversations.PostConversationToServerSuccess({ conversation: conversation }),
              new fromMembers.PostNewConversationMembersToServer({
                conversationId: conversation.id,
                members,
                event,
                template: a.payload.template,
              }),
            ];
          })
        );
      },
      onError: (a: fromConversations.PostConversationToServer, error) => {
        console.log({ error });
        return new fromConversations.PostConversationToServerFailure({ conversation: a.payload.conversation, error });
      },
    })
  );

  loadConversation = createEffect(() =>
    this.d.fetch(fromConversations.GET_CONVERSATION_FROM_STATE_SERVER, {
      run: (a: fromConversations.GetConversationFromStateServer, state: any) => {
        const conversation: Conversation = state.conversations.entities[a.payload];

        if (conversation != null) {
          const actions: Action[] = [];
          const convoEvents: any[] = [];
          state.events.ids
            .filter((eventId: number) => eventId != null)
            .map((eventId: number) => {
              if (state.events.entities[eventId].conversationId == a.payload) {
                convoEvents.push(eventId);
              }
            });

          if (convoEvents.length <= 0) {
            actions.push(
              new fromEvents.GetEventsFromServer(
                new EventQueryParameters({ conversationId: conversation.id, pageNumber: 1, pageSize: 25 })
              )
            );
            actions.push(new fromEvents.UpdateIsLoading(true));
          }
          return of(actions).pipe(mergeAll());
        } else {
          return this.apiService
            .get<Conversation>('communicator/conversations/' + a.payload + '?embed=lastEvent,messageType')
            .pipe(
              flatMap((c: Conversation) => {
                return [
                  new fromConversations.AddConversationToState({ conversation: c }),
                  new fromEvents.GetEventsFromServer(
                    new EventQueryParameters({ conversationId: c.id, pageNumber: 1, pageSize: 25 })
                  ),
                  new fromMembers.GetMembersForConversationFromServer({
                    queryParameters: new MemberQueryParameters({ conversationId: c.id, embed: 'associate,addresses' }),
                  }),
                ];
              })
            );
        }
      },
      onError: (a: fromConversations.GetConversationFromStateServer, error) => {
        return new fromConversations.GetConversationFromStateServerFailure({ conversationId: a.payload, error });
      },
    })
  );

  loadConversations = createEffect(() =>
    this.d.fetch(fromConversations.GET_CONVERSATIONS_FROM_SERVER, {
      run: (a: fromConversations.GetConversationsFromServer, state: any) => {
        return this.apiService
          .get<Conversation[]>('communicator/conversations', { params: a.payload.queryParameters })
          .pipe(
            flatMap((conversations: Conversation[]) => {
              const actionsToReturn: any[] = [];
              actionsToReturn.push(new fromConversations.GetConversationsFromServerSuccess(conversations));
              if (a.payload.queryParameters.pageNumber) {
                const conversationView: ConversationView = a.payload.queryParameters.lastEventCreatedById
                  ? ConversationView.activities
                  : ConversationView.conversations;

                actionsToReturn.push(new fromConversations.UpdateLastQueryPageNumberInState({ conversationView }));
              }

              let conversationIds: string[] = [];

              conversations.forEach((conversation: Conversation) => {
                conversationIds.push(conversation.id.toString());

                if (Object.keys(state.events.entities).length) {
                  const eventsForConversation: Event[] = new DeepCopyPipe().transform(
                    Object.values(state.events.entities).filter(
                      (event: Event) => event !== undefined && event.conversationId === conversation.id
                    )
                  );

                  if (eventsForConversation.length !== 0) {
                    const maxEventId: number = Math.max(...eventsForConversation.map((event) => event.id));
                    if (maxEventId !== conversation.lastEventId) {
                      actionsToReturn.push(
                        new fromEvents.GetEventsFromServer(
                          new EventQueryParameters({
                            conversationId: conversation.id,
                            searchStartDate: eventsForConversation.find((e) => e.id === maxEventId).sentDate,
                          })
                        )
                      );
                    }
                  }
                }
              });

              if (conversationIds.length > 0) {
                actionsToReturn.push(
                  new fromMembers.GetMembersFromServer({
                    queryParameters: new MemberQueryParameters({
                      conversationId: conversationIds.join(','),
                      embed: 'associate,addresses',
                    }),
                    displayNotification: a.payload.displayNotification,
                  })
                );
              }

              return from(actionsToReturn);
            })
          );
      },
      onError: (a: fromConversations.GetConversationsFromServer, error: any) => {
        console.log(error);
        return new fromConversations.GetConversationsFromServerFailure({
          queryParameters: a.payload.queryParameters,
          error,
        });
      },
    })
  );

  deleteMessageTemplates = createEffect(() =>
    this.d.fetch(fromConversations.DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER, {
      run: (a: fromConversations.DeleteDirectMessageTemplateFromServer, state: any) => {
        return this.apiService
          .delete<DirectMessageTemplate[]>(`v/1/communicator/message-templates/direct/${a.payload.id}`, {})
          .pipe(
            map((messageTemplate: DirectMessageTemplate) => {
              console.log(messageTemplate);
              return new fromConversations.DeleteDirectMessageTemplateFromServerSuccess(a.payload);
            })
          );
      },
      onError: (a: fromConversations.DeleteDirectMessageTemplateFromServer, error: any) => {
        console.log(error);
        return new fromConversations.DeleteDirectMessageTemplateFromServerFailure(error);
      },
    })
  );
  getExcludedConversationsSinceLastModified = this.d.fetch(fromConversations.GET_CONVERSATIONS_TO_REMOVE_FROM_STATE, {
    run: (a: fromConversations.GetConversationsToRemoveFromState, state: any) => {
      return this.apiService.get<Conversation[]>('communicator/conversations', { params: a.payload }).pipe(
        map((conversations: Conversation[]) => {
          return new fromConversations.GetConversationsToRemoveFromStateSuccess({ conversations });
        })
      );
    },
    onError: (a: fromConversations.GetConversationsToRemoveFromState, error: any) => {
      return new fromConversations.GetConversationsToRemoveFromStateFailure(error);
    },
  });

  loadMessageTemplates = createEffect(() =>
    this.d.fetch(fromConversations.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER, {
      run: (a: fromConversations.GetDirectMessageTemplatesFromServer, state: any) => {
        return this.apiService
          .get<DirectMessageTemplate[]>('v/1/communicator/message-templates/direct', { params: a.payload })
          .pipe(
            map((messageTemplates: DirectMessageTemplate[]) => {
              return new fromConversations.GetDirectMessageTemplatesFromServerSuccess(messageTemplates);
            })
          );
      },
      onError: (a: fromConversations.GetDirectMessageTemplatesFromServer, error: any) => {
        return new fromConversations.GetDirectMessageTemplatesFromServerFailure(error);
      },
    })
  );

  getExcludedConversationsSinceLastModifiedSuccess = this.d.fetch(
    fromConversations.GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_SUCCESS,
    {
      run: (a: fromConversations.GetConversationsToRemoveFromStateSuccess, state: any) => {
        const actions: Action[] = [];
        a.payload.conversations.map((c) =>
          actions.push(new fromConversations.DeleteConversationFromState({ id: c.id }))
        );
        return of(actions).pipe(mergeAll());
      },
      onError: (a: fromConversations.GetConversationsToRemoveFromStateSuccess, error: any) => {
        console.error(error);
        return new fromConversations.GetConversationsToRemoveFromStateFailure(error);
      },
    }
  );

  loadMessageTypes = createEffect(() =>
    this.d.fetch(fromConversations.GET_MESSAGE_TYPES_FROM_SERVER, {
      run: (a: fromConversations.GetMessageTypesFromServer, state: any) => {
        return this.apiService.get<MessageType[]>('communicator/message-types').pipe(
          map((messageTypes: MessageType[]) => {
            return new fromConversations.GetMessageTypesFromServerSuccess(messageTypes);
          })
        );
      },
      onError: (a: fromConversations.GetMessageTypesFromServer, error: any) => {
        return new fromConversations.GetMessageTypesFromServerFailure(error);
      },
    })
  );

  deleteConversationFromState = createEffect(() =>
    this.d.fetch(fromConversations.DELETE_CONVERSATION_FROM_STATE, {
      run: (a: fromConversations.DeleteConversationFromState, state: any) => {
        const params: any = this.routerStateService.getFullTreeParams(this.route);
        if (params['id'] && params['id'] === a.payload.id.toString()) {
          this.routerStateService.go(['home'], { relativeTo: this.route });
        }

        const eventIds: number[] = Object.values(state.events.entities)
          .filter((e: Event) => e.conversationId === a.payload.id)
          .map((e: Event) => e.id);

        const memberIds: number[] = Object.values(state.members.entities)
          .filter((m: Member) => m.conversationId === a.payload.id)
          .map((m: Member) => m.id);

        return from([
          new fromMembers.DeleteMembersInState({ ids: memberIds }),
          new fromEvents.DeleteEventsInState(eventIds),
        ]);
      },
      onError: (a: fromConversations.DeleteConversationFromState, error: any) => {},
    })
  );

  postDirectMessageTemplate = createEffect(() =>
    this.d.pessimisticUpdate(fromConversations.POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER, {
      run: (a: fromConversations.PostDirectMessageTemplateToServer, state: any) => {
        return this.apiService
          .post<DirectMessageTemplate>(`/v/1/communicator/message-templates/direct`, a.payload)
          .pipe(
            map((messageTemplate: DirectMessageTemplate) => {
              return new fromConversations.PostDirectMessageTemplateToServerSuccess(messageTemplate);
            })
          );
      },
      onError: (a: fromConversations.PostDirectMessageTemplateToServer, error: any) => {
        return new fromConversations.PostDirectMessageTemplateToServerFailure({ template: a.payload, error });
      },
    })
  );

  updateDirectMessageTemplate = createEffect(() =>
    this.d.pessimisticUpdate(fromConversations.UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER, {
      run: (a: fromConversations.UpdateDirectMessageTemplateToServer, state: any) => {
        return this.apiService
          .post<DirectMessageTemplate>(`/v/1/communicator/message-templates/direct/${a.payload.id}`, a.payload)
          .pipe(
            map((messageTemplate: DirectMessageTemplate) => {
              return new fromConversations.UpdateDirectMessageTemplateToServerSuccess(messageTemplate);
            })
          );
      },
      onError: (a: fromConversations.UpdateDirectMessageTemplateToServer, error: any) => {
        return new fromConversations.UpdateDirectMessageTemplateToServerFailure({ template: a.payload, error });
      },
    })
  );

  removeMemberToNewConversation = createEffect(() =>
    this.d.fetch(fromConversations.DELETE_MEMBER_FROM_NEW_CONVERSATION_IN_STATE, {
      run: (a: fromConversations.DeleteMemberFromNewConversationInState, state: any) => {
        const params = new ConversationQueryParameters();
        params.employeeId = state.conversations.newConversation.members.map((m: Member) => m.userId).join(',');
        params.messageTypeId = '18';
        return params.employeeId
          ? from([new fromConversations.GetNewConversationFromServer(params)])
          : from([new fromConversations.ResetConversationInNewConversationInState(null)]);
      },
      onError: (a: fromConversations.DeleteMemberFromNewConversationInState, error) => {},
    })
  );

  updateConversationTitle = createEffect(() =>
    this.d.pessimisticUpdate(fromConversations.UPDATE_CONVERSATION_TITLE_ON_SERVER, {
      run: (a: fromConversations.UpdateConversationTitleOnServer, state: any) => {
        return this.apiService
          .patch<ConversationPatchParameters>(`communicator/conversations/${a.payload.id}`, {
            title: a.payload.title,
          })
          .pipe(
            map((conversation: Conversation) => {
              return new fromConversations.UpdateConversationTitleOnServerSuccess({
                id: a.payload.id,
                changes: { title: a.payload.title },
              });
            })
          );
      },
      onError: (a: fromConversations.UpdateConversationTitleOnServer, error: any) => {
        return new fromConversations.UpdateConversationTitleOnServerFailure({ conversationId: a.payload.id, error });
      },
    })
  );

  loadConversationFromRouter = createEffect(() =>
    this.d.navigation(ConversationShellComponent, {
      run: (a: ActivatedRouteSnapshot, state: any) => {
        return new fromConversations.GetConversationFromStateServer(a.params.id);
      },
      onError: (a: ActivatedRouteSnapshot, e: any) => {
        console.log(e);
        // we can log and error here and return null
        // we can also navigate back
        return null;
      },
    })
  );

  getMessageTransactionMessagePreferences = createEffect(() =>
    this.d.fetch(fromConversations.GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER, {
      run: (a: fromConversations.GetMessageTransactionPreferencesFromServer, state: any) => {
        return this.apiService
          .get<MessageTransactionMessagePreference[]>('v/1/fixed-operations/message-transaction-message-preferences', {
            params: a.payload,
          })
          .pipe(
            map((messageTransactionMessagePreferences: MessageTransactionMessagePreference[]) => {
              return new fromConversations.GetMessageTransactionPreferencesFromServerSuccess(
                messageTransactionMessagePreferences.length > 0
                  ? messageTransactionMessagePreferences[0]
                  : new MessageTransactionMessagePreference()
              );
            })
          );
      },
      onError: (a: fromConversations.GetMessageTransactionPreferencesFromServer, error: any) => {
        return new fromConversations.GetMessageTransactionPreferencesFromServerFailure(error);
      },
    })
  );

  createMessageTransactionMessagePreference = createEffect(() =>
    this.d.fetch(fromConversations.POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER, {
      run: (a: fromConversations.PostMessageTransactionPreferenceToServer, state: any) => {
        return this.apiService
          .post<MessageTransactionMessagePreference>(
            'v/1/fixed-operations/message-transaction-message-preferences',
            a.payload
          )
          .pipe(
            map((messageTransactionMessagePreference: MessageTransactionMessagePreference) => {
              return new fromConversations.PostMessageTransactionPreferenceToServerSuccess(
                messageTransactionMessagePreference
              );
            })
          );
      },
      onError: (a: fromConversations.PostMessageTransactionPreferenceToServer, error: any) => {
        return new fromConversations.PostMessageTransactionPreferenceToServerFailure(error);
      },
    })
  );

  updateMessageTransactionMessagePreference = createEffect(() =>
    this.d.fetch(fromConversations.PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER, {
      run: (a: fromConversations.PutMessageTransactionPreferenceToServer, state: any) => {
        return this.apiService
          .put<MessageTransactionMessagePreference>(
            `v/1/fixed-operations/message-transaction-message-preferences/${a.payload.id}`,
            a.payload
          )
          .pipe(
            map((messageTransactionMessagePreference: MessageTransactionMessagePreference) => {
              return new fromConversations.PutMessageTransactionPreferenceToServerSuccess(
                messageTransactionMessagePreference
              );
            })
          );
      },
      onError: (a: fromConversations.PutMessageTransactionPreferenceToServer, error: any) => {
        return new fromConversations.PutMessageTransactionPreferenceToServerFailure(error);
      },
    })
  );

  deleteMessageTransactionMessagePreference = createEffect(() =>
    this.d.fetch(fromConversations.DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER, {
      run: (a: fromConversations.DeleteMessageTransactionPreferenceFromServer, state: any) => {
        return this.apiService
          .delete<MessageTransactionMessagePreference>(
            `v/1/fixed-operations/message-transaction-message-preferences/${a.payload.id}`
          )
          .pipe(
            map((messageTransactionMessagePreference: MessageTransactionMessagePreference) => {
              return new fromConversations.DeleteMessageTransactionPreferenceFromServerSuccess();
            })
          );
      },
      onError: (a: fromConversations.DeleteMessageTransactionPreferenceFromServer, error: any) => {
        return new fromConversations.DeleteMessageTransactionPreferenceFromServerFailure(error);
      },
    })
  );
  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private conversationStateService: ConversationStateService,
    private d: DataPersistence<ConversationsState>,
    private route: ActivatedRoute,
    private router: Router,
    private routerStateService: RouterStateService
  ) {}
}
