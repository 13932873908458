import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as FilesActions from './files.actions';
import { FileEntity } from './files.models';

export const FILES_FEATURE_KEY = 'files';

export interface State extends EntityState<FileEntity> {
  loaded: boolean;
  error?: string | null;
}

export interface FilesPartialState {
  readonly [FILES_FEATURE_KEY]: State;
}

export const sortById = (a: FileEntity, b: FileEntity): number => {
  return a.id - b.id;
};

export const filesAdapter: EntityAdapter<FileEntity> = createEntityAdapter<FileEntity>({ sortComparer: sortById });

export const initialState: State = filesAdapter.getInitialState({
  loaded: false,
});

const filesReducer = createReducer(
  initialState,
  on(FilesActions.addFile, (state, { file }) => filesAdapter.addOne(file, state)),
  on(FilesActions.loadFiles, (state) => ({ ...state, loaded: false, error: null })),
  on(FilesActions.loadFilesSuccess, (state, { files }) => filesAdapter.addMany(files, { ...state, loaded: true })),
  on(FilesActions.loadFilesFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return filesReducer(state, action);
}
