import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTypes } from '@models/server-common';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { Conversation, fromConversations } from '@quorum/communicator/state/conversations';
import { EventsStateService, fromEvents } from '@quorum/communicator/state/events';
import { ConversationPatchParameters } from '@quorum/models/xs-query';
import { Event, Member, TokenUser } from '@quorum/models/xs-resource';
import { MultiResourceResponse } from '@quorum/models/xs-misc';
import { RouterStateService } from '@quorum/sha-router';
import { from, of } from 'rxjs';
import { flatMap, map, mergeAll, switchMap } from 'rxjs/operators';
import * as fromMembers from './members.actions';
import { MembersState } from './members.interfaces';

@Injectable()
export class MembersEffects {
  
  loadMembers = createEffect(() => this.d.fetch(fromMembers.GET_MEMBERS_FROM_SERVER, {
    id: (a: fromMembers.GetMembersFromServer, state: any) => {
      return a.payload.queryParameters.conversationId;
    },
    run: (a: fromMembers.GetMembersFromServer, state: any) => {
      return this.apiService
        .get<Member[]>(`communicator/members`, {
          params: a.payload.queryParameters,
        })
        .pipe(
          flatMap((members) => {
            const currentMember: Member = members.find(
              (m: Member) => m.userId === state.authentication.authenticatedUser.user.id.toString()
            );

            const conversation: Conversation = state.conversations.entities[a.payload.queryParameters.conversationId];

            let actions: Action[] = [
              new fromMembers.GetMembersFromServerSuccess({ members: members }),
              new fromMembers.StopMembersSpinnerUi(false),
            ];

            if (currentMember?.isPinnedToUi) {
              const conversationIds = a.payload.queryParameters.conversationId.split(',');
              conversationIds.map((conversationId) => {
                actions.push(
                  new fromConversations.UpdateConversationIsPinnedToUiInState({
                    id: conversationId,
                    changes: {
                      isPinnedToUi:
                        typeof currentMember?.isPinnedToUi === 'number' && currentMember.isPinnedToUi == 1
                          ? true
                          : false,
                    },
                  })
                );
              });
            }

            if (a.payload.displayNotification) {
              actions.push(new fromEvents.DisplayNotificationForNewEvent(conversation.embedded.lastEvent));
            }

            return actions;
          })
        );
    },
    onError: (a: fromMembers.GetMembersFromServer, error) => {
      console.log({ error });
      return of([]).pipe(
        flatMap((members: Member[]) => [
          new fromMembers.GetMembersFromServerFailure({
            queryParameters: a.payload.queryParameters,
            error,
          }),
          new fromMembers.StopMembersSpinnerUi(false),
        ])
      );
    },
  }));

  
  loadMembersForConversation = createEffect(() => this.d.fetch(fromMembers.GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER, {
    id: (a: fromMembers.GetMembersForConversationFromServer, state: any) => {
      return a.payload.queryParameters.conversationId;
    },
    run: (a: fromMembers.GetMembersForConversationFromServer, state: any) => {
      return this.apiService
        .get<Member[]>(`communicator/conversations/${a.payload.queryParameters.conversationId.toString()}/members`, {
          params: a.payload.queryParameters,
        })
        .pipe(
          flatMap((members) => {
            const currentMember: Member = members.find(
              (m: Member) => m.userId === state.authentication.authenticatedUser.user.id.toString()
            );

            const conversation: Conversation = state.conversations.entities[a.payload.queryParameters.conversationId];

            let actions: Action[] = [
              new fromMembers.GetMembersForConversationFromServerSuccess({ members: members }),
              new fromMembers.StopMembersSpinnerUi(false),
            ];

            if (currentMember?.isPinnedToUi) {
              new fromConversations.UpdateConversationIsPinnedToUiInState({
                id: a.payload.queryParameters.conversationId,
                changes: {
                  isPinnedToUi:
                    typeof currentMember?.isPinnedToUi === 'number' && currentMember.isPinnedToUi == 1 ? true : false,
                },
              });
            }

            if (a.payload.displayNotification) {
              actions.push(new fromEvents.DisplayNotificationForNewEvent(conversation.embedded.lastEvent));
            }

            return actions;
          })
        );
    },
    onError: (a: fromMembers.GetMembersForConversationFromServer, error) => {
      console.log({ error });
      return of([]).pipe(
        flatMap((members: Member[]) => [
          new fromMembers.GetMembersForConversationFromServerFailure({
            queryParameters: a.payload.queryParameters,
            error,
          }),
          new fromMembers.StopMembersSpinnerUi(false),
        ])
      );
    },
  }));

  
  postMemberToConversation = createEffect(() => this.d.pessimisticUpdate(fromMembers.POST_MEMBER_TO_SERVER, {
    run: (a: fromMembers.PostMemberToServer, state: any) => {
      return this.apiService
        .post<Member>(
          `communicator/conversations/${a.payload.member.conversationId}/members/${a.payload.member.id}`,
          a.payload
        )
        .pipe(
          map((member: Member) => {
            return new fromMembers.PostMemberToServerSuccess(a.payload);
          })
        );
    },
    onError: (a: fromMembers.PostMemberToServer, error: any) => {
      console.log({ error });
      return new fromMembers.PostMemberToServerFailure({ member: a.payload.member, error });
    },
  }));

  
  addMembers = createEffect(() => this.d.pessimisticUpdate(fromMembers.POST_NEW_CONVERSATION_MEMBERS_TO_SERVER, {
    run: (a: fromMembers.PostNewConversationMembersToServer, state: any) => {
      return this.apiService
        .post<Member[]>(
          `communicator/conversations/${a.payload.conversationId.toString()}/members`,
          this.prepareMultipleMembersBody(a.payload.members)
        )
        .pipe(
          flatMap((members) => {
            return this.multipleMemberPost(members, a);
          })
        );
    },
    onError: (a: fromMembers.PostNewConversationMembersToServer, error) => {
      console.log({ error });
      return new fromMembers.PostNewConversationMembersToServerFailure({
        conversationId: a.payload.conversationId,
        error,
      });
    },
  }));

  
  addMembersToConversation = createEffect(() => this.d.pessimisticUpdate(fromMembers.ADD_MEMBERS_TO_CONVERSATION, {
    run: (a: fromMembers.AddMembersToConversation, state: any) => {
      return this.apiService
        .post<Member[]>(
          `communicator/conversations/${a.payload.members[0].conversationId.toString()}/members`,
          this.prepareMultipleMembersBody(a.payload.members)
        )
        .pipe(
          switchMap((responses: MultiResourceResponse[]) => {
            const successMembers: Member[] = [];
            const errorMembers: any[] = [];
            responses.forEach((response: MultiResourceResponse) => {
              response.statusCode === 201 ? successMembers.push(response.body) : errorMembers.push(response.body);
            });
            const actions: Action[] = [];
            if (errorMembers.length > 0) {
              return [
                new fromMembers.AddMembersToConversationFailure({
                  conversationId: a.payload.conversationId,
                  error: `One or more members failed to be added to conversation ${a.payload.conversationId}.`,
                }),
              ];
            } else {
              actions.push(new fromMembers.AddMembersToConversationSuccess({ members: successMembers }));

              successMembers.forEach((member: Member) => {
                const currentUser: TokenUser = state.authentication.authenticatedUser.user;
                const content = `${currentUser.firstName} ${currentUser.lastName} added ${member.firstName} ${member.lastName} to group.`;

                const event: Event = this.eventStateService.createEvent(
                  content,
                  member.conversationId,
                  EventTypes.MemberAddedToConversation,
                  state.authentication.authenticatedUser.user,
                  false
                );
                actions.push(new fromEvents.AddEventToState({ event }));
              });

              return of(actions).pipe(mergeAll());
            }
          })
        );
    },
    onError: (a: fromMembers.AddMembersToConversation, error: any) => {
      console.log({ error });
      return new fromMembers.AddMembersToConversationFailure({
        conversationId: a.payload.members[0].conversationId,
        error,
      });
    },
  }));

  
  excludeFromConversation = createEffect(() => this.d.optimisticUpdate(fromMembers.REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER, {
    run: (a: fromMembers.RemoveMemberFromConversationOnServer, state: any) => {
      return this.apiService
        .patch<ConversationPatchParameters>(
          `communicator/conversations/${a.payload.member.conversationId}/members/${a.payload.member.id}`,
          {
            isExcluded: true,
          }
        )
        .pipe(
          flatMap(() => {
            const currentUser: TokenUser = state.authentication.authenticatedUser.user;
            const content = `${currentUser.firstName} ${currentUser.lastName} removed ${a.payload.member.firstName} ${a.payload.member.lastName} from group.`;

            const event: Event = this.eventStateService.createEvent(
              content,
              a.payload.member.conversationId,
              EventTypes.RemoveOtherFromGroup,
              state.authentication.authenticatedUser.user,
              false
            );

            return [
              new fromMembers.RemoveMemberFromConversationOnServerSuccess({ id: a.payload.member.id }),
              new fromEvents.AddEventToState({ event }),
            ];
          })
        );
    },
    undoAction: (a: fromMembers.RemoveMemberFromConversationOnServer, error: any) => {
      console.log({ error });
      return new fromMembers.RemoveMemberFromConversationOnServerFailure({ member: a.payload.member, error });
    },
  }));

  
  removeMembersOnMultipleFailure = createEffect(() => this.d.pessimisticUpdate(fromMembers.REMOVE_MEMBERS_ON_MULTIPLE_FAILURE, {
    run: (a: fromMembers.RemoveMembersOnMultipleFailure, state: any) => {
      // make api call to delete the members
    },
    onError: (a: fromMembers.RemoveMembersOnMultipleFailure, error) => {
      console.log({ error });
    },
  }));

  
  removeSelfFromConversation = createEffect(() => this.d.pessimisticUpdate(fromMembers.REMOVE_SELF_FROM_CONVERSATION, {
    run: (a: fromMembers.RemoveSelfFromConversation, state: any) => {
      return this.apiService
        .patch<ConversationPatchParameters>(
          `communicator/conversations/${a.payload.member.conversationId}/members/${a.payload.member.id}`,
          {
            isExcluded: true,
          }
        )
        .pipe(
          flatMap((conversation: Conversation) => {
            const currentUser: TokenUser = state.authentication.authenticatedUser.user;
            const content = `${currentUser.firstName} ${currentUser.lastName} left the group.`;
            const event: Event = this.eventStateService.createEvent(
              content,
              a.payload.member.conversationId,
              EventTypes.RemoveSelfFromGroup,
              state.authentication.authenticatedUser.user,
              false
            );
            return [
              new fromConversations.DeleteConversationFromState({ id: a.payload.member.conversationId }),
              new fromEvents.PostEventToServer({ event }),
            ];
          })
        );
    },
    onError: (a: fromMembers.RemoveSelfFromConversation, error: any) => {
      console.log({ error });
      return new fromMembers.RemoveSelfFromConversationFailure({ member: a.payload.member, error });
    },
  }));

  
  updateIsArchivedConversation = createEffect(() => this.d.optimisticUpdate(fromMembers.UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER, {
    run: (a: fromMembers.UpdateIsArchivedConversationToServer, state: any) => {
      return this.apiService
        .patch(
          `communicator/conversations/${a.payload.member.conversationId.toString()}/members/${a.payload.member.id}`,
          {
            isArchived: a.payload.member.isArchived,
          }
        )
        .pipe(
          flatMap(() => {
            const actions: Action[] = [];
            if (a.payload.member.isArchived) {
              const eventIds: number[] = Object.values(state.events.entities)
                .filter((e: Event) => e.conversationId === a.payload.member.conversationId)
                .map((e: Event) => e.id);
              actions.push(new fromEvents.DeleteEventsInState(eventIds));

              const memberIds: number[] = Object.values(state.members.entities)
                .filter((m: Member) => m.conversationId === a.payload.member.conversationId)
                .map((m: Member) => m.id);
              actions.push(new fromMembers.DeleteMembersInState({ ids: memberIds }));
              actions.push(new fromConversations.DeleteConversationFromState({ id: a.payload.member.conversationId }));
            } else {
              // implement added conversation. members to state.
            }
            return of(actions).pipe(mergeAll());
          })
        );
    },
    undoAction: (a: fromMembers.UpdateIsArchivedConversationToServer, error: any) => {
      console.log({ error });
      return new fromMembers.UpdateIsArchivedConversationToServerFailure({
        member: a.payload.member,
        error,
      });
    },
  }));

  
  updateIsPinnedToUiConversation = createEffect(() => this.d.optimisticUpdate(fromMembers.UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER, {
    run: (a: fromMembers.UpdateIsPinnedToUiConversationOnServer, state: any) => {
      return this.apiService
        .patch(`communicator/conversations/${a.payload.conversationId.toString()}/members/${a.payload.id}`, {
          isPinnedToUi: a.payload.isPinnedToUi,
        })
        .pipe(
          flatMap(() => {
            return from([]);
          })
        );
    },
    undoAction: (a: fromMembers.UpdateIsPinnedToUiConversationOnServer, error: any) => {
      console.log({ error });
      return new fromMembers.UpdateIsPinnedToUiConversationOnServerFailure({
        member: { ...a.payload, isPinnedToUi: !a.payload.isPinnedToUi },
        error,
      });
    },
  }));

  
  updateIsReadConversation = createEffect(() => this.d.optimisticUpdate(fromMembers.UPDATE_IS_READ_CONVERSATION_ON_SERVER, {
    run: (a: fromMembers.UpdateIsReadConversationOnServer, state: any) => {
      return this.apiService
        .patch(`communicator/conversations/${a.payload.conversationId.toString()}/members/${a.payload.id}`, {
          isRead: a.payload.isRead,
        })
        .pipe(
          flatMap(() => {
            return from([]);
          })
        );
    },
    undoAction: (a: fromMembers.UpdateIsReadConversationOnServer, error: any) => {
      console.log({ error });
      return new fromMembers.UpdateIsReadConversationOnServerFailure({
        member: { ...a.payload, isRead: !a.payload.isRead },
        error,
      });
    },
  }));

  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private d: DataPersistence<MembersState>,
    private eventStateService: EventsStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService
  ) {}

  prepareMultipleMembersBody(members: Member[]) {
    const response: any[] = [];
    members.forEach((member: Member, index: number) => {
      response.push({ id: index, body: member });
    });

    return response;
  }

  multipleMemberPost(response: any, action: any) {
    const successMembers: Member[] = [];
    const errorMembers: any[] = [];
    response.forEach((res: any) => {
      res.statusCode === 201 ? successMembers.push(res.body) : errorMembers.push(res.body);
    });
    const returnActions = [];
    if (errorMembers.length > 0) {
      returnActions.push(
        new fromMembers.PostNewConversationMembersToServerFailure({
          conversationId: action.payload.conversationId,
          error: 'One or more members failed to be added.',
        })
      );
      returnActions.push(new fromMembers.RemoveMembersOnMultipleFailure(action.payload.members));
    } else {
      if (action.payload.event) {
        returnActions.push(
          new fromMembers.PostNewConversationMembersToServerSuccess({ members: successMembers }),
          new fromEvents.PostEventToServer({ event: action.payload.event, template: action.payload.template })
        );
      } else {
        new fromMembers.AddMembersToConversationSuccess({ members: successMembers });
      }
    }

    return returnActions;
  }
}
