import { SocketConnectionInfo } from './sockets.interfaces';
import { Action } from '@ngrx/store';

export const ADD_SOCKET_CONNECTION_INFO = '[SOCKETS] Add Socket Connection Info';
export class AddSocketConnectionInfo implements Action {
  readonly type = ADD_SOCKET_CONNECTION_INFO;
  constructor(public payload: SocketConnectionInfo) {}
}

export const CREATE_SOCKET_CONNECTION = '[SOCKETS] Create Socket Connection';
export class CreateSocketConnection implements Action {
  readonly type = CREATE_SOCKET_CONNECTION;
  constructor(public payload: any) {}
}

export const CREATE_SOCKET_CONNECTION_FAILURE = '[SOCKETS] Create Socket Connection Failure';
export class CreateSocketConnectionFailure implements Action {
  readonly type = CREATE_SOCKET_CONNECTION_FAILURE;
  constructor(public payload: any) {}
}

export const RESET_SOCKET_STATE = '[SOCKETS] Reset Socket State';
export class ResetSocketState implements Action {
  readonly type = RESET_SOCKET_STATE;
  constructor(public payload: {}) {}
}

export const SOCKET_CONNECTED = '[SOCKETS] Socket Connected';
export class SocketConnected implements Action {
  readonly type = SOCKET_CONNECTED;
  constructor(public payload: { isConnected: boolean }) {}
}

export const SOCKET_CONNECTED_ERROR = '[SOCKETS] Socket Connected Error';
export class SocketConnectedError implements Action {
  readonly type = SOCKET_CONNECTED_ERROR;
  constructor(public payload: { message: string }) {}
}

export const SOCKET_DISCONNECTED = '[SOCKETS] Socket Disconnected';
export class SocketDisconnected implements Action {
  readonly type = SOCKET_DISCONNECTED;
  constructor(public payload: { isConnected: boolean }) {}
}

export const SOCKET_RECONNECT_FAILED = '[SOCKETS] Socket Reconnect Failed';
export class SocketReconnectFailed implements Action {
  readonly type = SOCKET_RECONNECT_FAILED;
  constructor(public payload: any) {}
}

export type SocketsAction =
  | AddSocketConnectionInfo
  | CreateSocketConnection
  | CreateSocketConnectionFailure
  | ResetSocketState
  | SocketConnected
  | SocketDisconnected
  | SocketReconnectFailed;
