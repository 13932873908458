import { Address } from '../associates/address.model';
import { Associate } from '../associates/associate.model';
import { Conversation } from './conversation.model';
export class Member {
  avatarUrl: string;
  conversation: Conversation;
  conversationId: number;
  firstName: string;
  id: number;
  isArchived: boolean;
  isEmployee: boolean;
  isExcluded: boolean;
  isPinnedToUi: boolean;
  isRead: boolean;
  lastName: string;
  nickname: string;
  userId: string;
  xselleratorEmployeeId?: string;
  embedded?: MemberEmbed;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

class MemberEmbed {
  associate?: Associate = null;
  addresses?: Address[] = [];
}

export const memberMockIsRead = new Member({
  avatarUrl: 'localhost/sha-avatar.png',
  conversationId: 1,
  firstName: 'John',
  lastName: 'Doe',
  id: 1,
  isArchived: false,
  isEmployee: true,
  isExcluded: false,
  isPinnedToUi: false,
  isRead: true,
  nickname: 'John',
  userId: '1000',
});

export const memberMock = new Member({
  avatarUrl: 'localhost/sha-avatar.png',
  conversationId: 1,
  firstName: 'John',
  lastName: 'Doe',
  id: 1,
  isArchived: false,
  isEmployee: true,
  isExcluded: false,
  isPinnedToUi: false,
  isRead: false,
  nickname: 'John',
  userId: '1000',
});

export const membersMock: Member[] = [
  memberMock,
  new Member({
    avatarUrl: 'localhost/sha-avatar.png',
    conversationId: 1,
    firstName: 'Jane',
    lastName: 'Doe',
    id: 2,
    isArchived: false,
    isEmployee: true,
    isExcluded: false,
    isPinnedToUi: false,
    isRead: false,
    nickname: 'Jane',
    userId: '1001',
  }),
];
