import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromUi from './ui.actions';
import { sidenavVisibleSelector } from './ui.interfaces';

@Injectable()
export class UiStateService {
  constructor(private store: Store<any>) {}

  selectSidenavVisible() {
    return this.store.pipe(select(sidenavVisibleSelector));
  }

  toggleSidenav(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleSidenavInState(toggle));
  }
}
