import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ApiModule } from '@quorum/api';
import { environment } from '@quorum/environments';
import { ContactsEffects } from './+state/contacts.effects';
import { contactsReducer, metaReducers } from './+state/contacts.reducer';
import { ContactsStateService } from './contacts-state.service';

@NgModule({
  providers: [ContactsEffects, ContactsStateService],
  imports: [
    ApiModule.withConfig({
      apiBase: environment.apiBase,
      legacyApiBase: environment.legacyApiBase,
    }),
    StoreModule.forFeature('contacts', contactsReducer, {
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([ContactsEffects]),
  ],
})
export class ContactsModule {}
