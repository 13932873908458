<div class="loading-container" *ngIf="isLoading$ | async; else conversationListTemplate">
  <mat-spinner></mat-spinner>
</div>
<ng-template #conversationListTemplate>
  <mat-nav-list fxFlex *ngIf="contacts$ | async as contacts">
    <ng-container *ngIf="members$ | async as members">
      <ng-container
        *ngFor="
          let conversationGroup of conversations
            | orderBy: ['-isPinnedToUi', '-utcLastModified']
            | groupBy: 'isPinnedToUi'
            | pairs;
          trackBy: trackByFn
        "
      >
        <ng-container *ngIf="conversationGroup[0] === 'true'">
          <h3 fxLayout="row" fxLayoutAlign="start center" mat-subheader>
            <span>Pinned Messages ({{ conversationGroup[1].length }})</span>
          </h3>
        </ng-container>
        <ng-container
          *ngFor="
            let conversation of conversationGroup[1]
              | orderBy: '-utcLastModified'
              | slice: 0:(conversationGroup[0] === 'true' ? pinnedMessagesToDisplay : conversationGroup[1].length);
            index as i;
            trackBy: trackByConversationFn
          "
        >
          <com-conversations-list-item
            [authenticatedUser]="authenticatedUser$ | async"
            [conversation]="conversation"
            [contacts]="contacts"
            [members]="conversationMembers[conversation.id]"
            [contactsPresence]="contactsPresence$ | async"
            (conversationSelected)="selectConversation($event)"
          >
          </com-conversations-list-item>
        </ng-container>
        <ng-container
          *ngIf="conversationGroup[0] === 'true' && defaultPinnedMessagesToDisplay < conversationGroup[1].length"
        >
          <div fxLayout="row" fxLayoutAlign="center center" class="view-button-container">
            <button
              mat-button
              color="primary"
              (click)="
                pinnedMessagesToDisplay = viewAllPinnedMessages
                  ? defaultPinnedMessagesToDisplay
                  : conversationGroup[1].length;
                viewAllPinnedMessages = !viewAllPinnedMessages
              "
            >
              {{ viewAllPinnedMessages ? 'View Less' : 'View All' }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="conversationGroup[0] === 'true'">
          <div class="divider-container" fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-divider fxFlex="90" [inset]="false"></mat-divider>
            <mat-divider fxFlex="90" [inset]="false"></mat-divider>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</ng-template>
