import { createAction, props } from '@ngrx/store';
import { TemplateQueryParameters } from '@quorum/models/xs-query';
import { Template } from '@quorum/models/xs-resource';

export const GetStoreGalleryTemplatesFromServer = createAction(
  '[Quorum] Get Store Gallery Templates From Server',
  props<{ custId: string; queryParams?: TemplateQueryParameters }>()
);

export const GetStoreGalleryTemplatesFromServerSuccess = createAction(
  '[Quorum] Get Store Gallery Templates From Server Success',
  props<{ templates: Template[] }>()
);
export const GetStoreGalleryTemplatesFromServerError = createAction(
  '[Quorum] Get Store Gallery Templates From Server Error',
  props<{ error: any }>()
);

export const GetStoreGalleryTemplateFromServer = createAction(
  '[Quorum] Get Store Gallery Template From Server',
  props<{ custId: string; id: string }>()
);

export const GetStoreGalleryTemplateFromServerSuccess = createAction(
  '[Quorum] Get Store Gallery Template From Server Success',
  props<{ etag: string; template: Template }>()
);
export const GetStoreGalleryTemplateFromServerError = createAction(
  '[Quorum] Get Store Gallery Template From Server Error',
  props<{ error: any }>()
);

export const UpdateStoreGalleryTemplateOnServer = createAction(
  '[Quorum] Update Store Gallery Template On Server',
  props<{ custId: string; etag: string; template: Template }>()
);

export const UpdateStoreGalleryTemplateOnServerSuccess = createAction(
  '[Quorum] Update Store Gallery Template On Server Success',
  props<{ template: Template }>()
);
export const UpdateStoreGalleryTemplateOnServerError = createAction(
  '[Quorum] Update Store Gallery Template On Server Error',
  props<{ error: any }>()
);

export const CreateStoreGalleryTemplateOnServer = createAction(
  '[Quorum] Create Store Gallery Template From Server',
  props<{ custId: string; template: Template }>()
);

export const CreateStoreGalleryTemplateOnServerSuccess = createAction(
  '[Quorum] Create Store Gallery Template From Server Success',
  props<{ template: Template }>()
);
export const CreateStoreGalleryTemplateOnServerError = createAction(
  '[Quorum] Create Store Gallery Template From Server Error',
  props<{ error: any }>()
);

export const DeleteStoreGalleryTemplateOnServer = createAction(
  '[Quorum] Delete Store Gallery Template From Server',
  props<{ custId: string; templateId: string }>()
);

export const DeleteStoreGalleryTemplateOnServerSuccess = createAction(
  '[Quorum] Delete Store Gallery Template From Server Success',
  props<{ templateId: string }>()
);
export const DeleteStoreGalleryTemplateOnServerError = createAction(
  '[Quorum] Delete Store Gallery Template From Server Error',
  props<{ error: any }>()
);

export const GetQuorumGalleryTemplatesFromServer = createAction(
  '[Quorum] Get Quorum Gallery Templates From Server',
  props<{ queryParams?: TemplateQueryParameters }>()
);

export const GetQuorumGalleryTemplatesFromServerSuccess = createAction(
  '[Quorum] Get Quorum Gallery Templates From Server Success',
  props<{ templates: Template[] }>()
);
export const GetQuorumGalleryTemplatesFromServerError = createAction(
  '[Quorum] Get Quorum Gallery Templates From Server Error',
  props<{ error: any }>()
);

export const GetQuorumGalleryTemplateFromServer = createAction(
  '[Quorum] Get Quorum Gallery Template From Server',
  props<{ id: string }>()
);

export const GetQuorumGalleryTemplateFromServerSuccess = createAction(
  '[Quorum] Get Quorum Gallery Template From Server Success',
  props<{ etag: string; template: Template }>()
);
export const GetQuorumGalleryTemplateFromServerError = createAction(
  '[Quorum] Get Quorum Gallery Template From Server Error',
  props<{ error: any }>()
);

export const UpdateQuorumGalleryTemplateOnServer = createAction(
  '[Quorum] Update Quorum Gallery Template On Server',
  props<{ etag: string; template: Template }>()
);

export const UpdateQuorumGalleryTemplateOnServerSuccess = createAction(
  '[Quorum] Update Quorum Gallery Template On Server Success',
  props<{ template: Template }>()
);
export const UpdateQuorumGalleryTemplateOnServerError = createAction(
  '[Quorum] Update Quorum Gallery Template On Server Error',
  props<{ error: any }>()
);

export const CreateQuorumGalleryTemplateOnServer = createAction(
  '[Quorum] Create Quorum Gallery Template From Server',
  props<{ template: Template }>()
);

export const CreateQuorumGalleryTemplateOnServerSuccess = createAction(
  '[Quorum] Create Quorum Gallery Template From Server Success',
  props<{ template: Template }>()
);
export const CreateQuorumGalleryTemplateOnServerError = createAction(
  '[Quorum] Create Quorum Gallery Template From Server Error',
  props<{ error: any }>()
);

export const GetQuorumLibraryTemplatesFromServer = createAction(
  '[Quorum] Get Quorum Library Templates From Server',
  props<{ queryParams?: TemplateQueryParameters }>()
);

export const GetQuorumLibraryTemplatesFromServerSuccess = createAction(
  '[Quorum] Get Quorum Library Templates From Server Success',
  props<{ templates: Template[] }>()
);
export const GetQuorumLibraryTemplatesFromServerError = createAction(
  '[Quorum] Get Quorum Library Templates From Server Error',
  props<{ error: any }>()
);

export const GetQuorumLibraryTemplateFromServer = createAction(
  '[Quorum] Get Quorum Library Template From Server',
  props<{ id: string }>()
);

export const GetQuorumLibraryTemplateFromServerSuccess = createAction(
  '[Quorum] Get Quorum Library Template From Server Success',
  props<{ etag: string; template: Template }>()
);
export const GetQuorumLibraryTemplateFromServerError = createAction(
  '[Quorum] Get Quorum Library Template From Server Error',
  props<{ error: any }>()
);

export const UpdateQuorumLibraryTemplateOnServer = createAction(
  '[Quorum] Update Quorum Library Template On Server',
  props<{ etag: string; template: Template }>()
);

export const UpdateQuorumLibraryTemplateOnServerSuccess = createAction(
  '[Quorum] Update Quorum Library Template On Server Success',
  props<{ template: Template }>()
);
export const UpdateQuorumLibraryTemplateOnServerError = createAction(
  '[Quorum] Update Quorum Library Template On Server Error',
  props<{ error: any }>()
);

export const CreateQuorumLibraryTemplateOnServer = createAction(
  '[Quorum] Create Quorum Library Template From Server',
  props<{ template: Template }>()
);

export const CreateQuorumLibraryTemplateOnServerSuccess = createAction(
  '[Quorum] Create Quorum Library Template From Server Success',
  props<{ template: Template }>()
);
export const CreateQuorumLibraryTemplateOnServerError = createAction(
  '[Quorum] Create Quorum Library Template From Server Error',
  props<{ error: any }>()
);
