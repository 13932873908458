import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocketEventTypes } from '@models/server-common';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { CommunicatorSocketService } from '@quorum/com-sockets/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ContactPresence } from '@quorum/models/xs-misc';
import { AuthenticatedUser, Conversation } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { SocketStateService } from '@quorum/sockets/services';
import { SocketConnectionInfo } from '@quorum/sockets/state';
import { UiStateService } from 'libs/communicator/state/root/src/lib/ui/ui-state-service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';

@Component({
  selector: 'com-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  animations: [
    trigger('connectionStatus', [
      state('connected', style({ display: 'none' })),
      state('disconnected', style({ display: 'block' })),
      transition('disconnected => connected', animate('.5s')),
      transition('connected => disconnected', animate('.5s')),
    ]),
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  activity$: Subject<void> = new Subject<void>();
  connectionStatus: string = 'connected';
  idleTimeout: number = 1000 * 60 * 3;
  isIdle: boolean = false;
  selectedConversation: Conversation;
  sidenavStatus$: Subscription;
  sidenav: boolean;
  socketInfo: SocketConnectionInfo;
  socketInfoSubscription: Subscription;
  authenticatedUser$: Observable<AuthenticatedUser>;
  contactsPresence$: Subscription;
  contactsPresence: { [key: number]: ContactPresence } = {};
  primaryRouteUrl: string;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private communicatorSocketService: CommunicatorSocketService,
    private contactsStateService: ContactsStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService,
    private socketStateService: SocketStateService,
    private uiStateService: UiStateService
  ) {}

  ngOnInit() {
    this.activity$
      .pipe(
        map(() => {
          if (this.isIdle) {
            this.isIdle = false;
            this.communicatorSocketService.emit(SocketEventTypes.ONLINE, '0');
          }
        }),
        debounceTime(this.idleTimeout)
      )
      .subscribe(() => {
        this.isIdle = true;
        this.communicatorSocketService.emit(SocketEventTypes.ONLINE, Date.now().toString());
      });

    this.activity$.next();
    this.notificationRequestPermission();
    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser().pipe(take(1));
    this.sidenavStatus$ = this.uiStateService.selectSidenavVisible().subscribe((res) => (this.sidenav = res));
    this.contactsPresence$ = this.contactsStateService
      .selectPresences()
      .subscribe((presences) => (this.contactsPresence = presences));
    this.routerStateService.selectRouterState().subscribe((routerState) => {
      if (routerState.state.root.firstChild.url[0]) {
        this.primaryRouteUrl = routerState.state.root.firstChild.url[0].path;
      }
    });

    setTimeout(
      () =>
        (this.socketInfoSubscription = this.socketStateService
          .selectSocketConnectionInfos()
          .pipe(
            map((sockets) => {
              this.socketInfo = sockets.find((s) => s.service === 'communicator');
              this.connectionStatus = this.socketInfo && this.socketInfo.isConnected ? 'connected' : 'disconnected';
            })
          )
          .subscribe()),
      5000
    );
  }

  ngOnDestroy() {
    this.activity$.unsubscribe();
    this.contactsPresence$.unsubscribe();
    this.sidenavStatus$.unsubscribe();
    if (this.socketInfoSubscription) this.socketInfoSubscription.unsubscribe();
  }

  notificationRequestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission(function (permission) {
        // Do nothing.
      });
    }
  }

  @HostListener('window:click', ['$event'])
  mouseMoveEvent(event: any) {
    this.activity$.next();
  }
  @HostListener('window:focus', ['$event'])
  windowFocus(event: any) {
    this.activity$.next();
  }

  logout() {
    this.authenticationStateService.logout();
    this.closeSidenav();
  }

  closeSidenav() {
    this.uiStateService.toggleSidenav(false);
  }

  navigateMaster(path: string) {
    this.routerStateService.go(['/home', { outlets: { master: path } }], { relativeTo: this.route });
    this.closeSidenav();
  }

  reconnect() {
    this.socketStateService
      .selectSocketConnectionInfos()
      .pipe(
        take(1),
        map((socketConnectionInfos: any) => {
          socketConnectionInfos.forEach((connectionInfo: any) => {
            if (connectionInfo.service === 'communicator')
              this.communicatorSocketService.initSocket(connectionInfo.url, { ...connectionInfo.opts });
          });
        })
      )
      .subscribe();
  }
}
