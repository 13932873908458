<div style="height: 100%" fxLayout="column" *ngIf="conversation" class="container">
  <com-conversation-header [title]="title" [transactionDescription]="transactionDescription"></com-conversation-header>
  <div fxFlex fxLayout="row">
    <div fxFlex fxLayout="column">
      <com-conversation-body [conversation]="conversation" [members]="members" fxFlex></com-conversation-body>
      <div id="conversationActions"></div>
      <com-conversation-editor *ngIf="editorEnabled" (sendMessageEvent)="sendMessage($event, conversation)"
        (sendTemplateEvent)="sendTemplate($event, conversation)" (keyStrokesEmitter)="keyStrokes($event)"
        [currentContact]="currentContact" [placeHolder]="'Message ' + placeHolder" [presetMessage]="presetMessage"
        [attachments]="attachments" (isTypingEmitter)="typing($event, conversation, members)">
      </com-conversation-editor>
    </div>
    <div *ngIf="(ui$ | async).infoPanelVisible">
      <com-conversation-info-shell [conversation]="conversation" [currentUser]="currentUser">
      </com-conversation-info-shell>
    </div>
  </div>
</div>