import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FilesFacade } from '@quorum/communicator/state/files';
@Component({
  selector: 'com-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesListComponent implements OnInit {
  @Input() conversationId: number;

  constructor(public filesService: FilesFacade) { }

  ngOnInit(): void {
    this.filesService.loadFiles(this.conversationId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.conversationId.isFirstChange()) this.filesService.loadFiles(changes.conversationId.currentValue);
  }
}
