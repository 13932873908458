import { Action } from '@ngrx/store';
import { MemberQueryParameters } from '@quorum/models/xs-query';
import { Event, Member, Template } from '@quorum/models/xs-resource';

export const ADD_MEMBER_TO_CONVERSATION_FROM_SOCKET_TO_STATE =
  '[MEMBERS] Add Member To Conversation From Socket To State';
export class AddMemberToConversationFromSocketToState implements Action {
  readonly feature = 'members';
  readonly type = ADD_MEMBER_TO_CONVERSATION_FROM_SOCKET_TO_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member }) {}
}

export const ADD_MEMBERS_TO_CONVERSATION = '[MEMBERS] Add Members to Conversation';
export class AddMembersToConversation implements Action {
  readonly feature = 'members';
  readonly type = ADD_MEMBERS_TO_CONVERSATION;
  constructor(public payload: { conversationId: number; members: Member[] }) {}
}

export const ADD_MEMBERS_TO_CONVERSATION_SUCCESS = '[MEMBERS] Add Members to Conversation Success';
export class AddMembersToConversationSuccess implements Action {
  readonly feature = 'members';
  readonly type = ADD_MEMBERS_TO_CONVERSATION_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { members: Member[] }) {}
}

export const ADD_MEMBERS_TO_CONVERSATION_FAILURE = '[MEMBERS] Add Members to Conversation Failure';
export class AddMembersToConversationFailure implements Action {
  readonly feature = 'members';
  readonly type = ADD_MEMBERS_TO_CONVERSATION_FAILURE;
  constructor(public payload: { conversationId: number; error: any }) {}
}

export const DELETE_MEMBERS_IN_STATE = '[MEMBERS] Delete Members In State';
export class DeleteMembersInState implements Action {
  readonly feature = 'members';
  readonly type = DELETE_MEMBERS_IN_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: { ids: number[] }) {}
}

export const POST_MEMBER_TO_SERVER = '[MEMBERS] Post Member To Server';
export class PostMemberToServer implements Action {
  readonly feature = 'members';
  readonly type = POST_MEMBER_TO_SERVER;
  constructor(public payload: { member: Member }) {}
}

export const GET_MEMBERS_FROM_SERVER = '[MEMBERS] Get Members From Server';
export class GetMembersFromServer implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FROM_SERVER;
  constructor(public payload: { queryParameters: MemberQueryParameters; displayNotification?: boolean }) {}
}

export const GET_MEMBERS_FROM_SERVER_SUCCESS = '[MEMBERS] Get Members From Server Success';
export class GetMembersFromServerSuccess implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { members: Member[] }) {}
}

export const GET_MEMBERS_FROM_SERVER_FAILURE = '[MEMBERS] Get Members From Server Failure';
export class GetMembersFromServerFailure implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FROM_SERVER_FAILURE;
  constructor(public payload: { queryParameters: MemberQueryParameters; error: any }) {}
}

export const GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER = '[MEMBERS] Get Members For Conversation From Server';
export class GetMembersForConversationFromServer implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER;
  constructor(public payload: { queryParameters: MemberQueryParameters; displayNotification?: boolean }) {}
}

export const GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_SUCCESS =
  '[MEMBERS] Get Members For Conversation From Server Success';
export class GetMembersForConversationFromServerSuccess implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { members: Member[] }) {}
}

export const GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_FAILURE =
  '[MEMBERS] Get Members For Conversation From Server Failure';
export class GetMembersForConversationFromServerFailure implements Action {
  readonly feature = 'members';
  readonly type = GET_MEMBERS_FOR_CONVERSATION_FROM_SERVER_FAILURE;
  constructor(public payload: { queryParameters: MemberQueryParameters; error: any }) {}
}

export const POST_MEMBER_TO_SERVER_FAILURE = '[MEMBERS] Post Member To Server Failure';
export class PostMemberToServerFailure implements Action {
  readonly feature = 'members';
  readonly type = POST_MEMBER_TO_SERVER_FAILURE;
  constructor(public payload: { member: Member; error: any }) {}
}

export const POST_MEMBER_TO_SERVER_SUCCESS = '[MEMBERS] Post Member To Server Success';
export class PostMemberToServerSuccess implements Action {
  readonly feature = 'members';
  readonly type = POST_MEMBER_TO_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member }) {}
}

export const POST_NEW_CONVERSATION_MEMBERS_TO_SERVER = '[MEMBERS] Post New Conversation Members To Server';
export class PostNewConversationMembersToServer implements Action {
  readonly feature = 'members';
  readonly type = POST_NEW_CONVERSATION_MEMBERS_TO_SERVER;
  constructor(public payload: { conversationId: number; members: Member[]; event?: Event; template?: Template }) {}
}

export const POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_FAILURE =
  '[MEMBERS] Post New Conversation Members To Server Failure';
export class PostNewConversationMembersToServerFailure implements Action {
  readonly feature = 'members';
  readonly type = POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_FAILURE;
  constructor(public payload: { conversationId: number; error: any }) {}
}

export const POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_SUCCESS =
  '[MEMBERS] Post New Conversation Members To Server Success';
export class PostNewConversationMembersToServerSuccess implements Action {
  readonly feature = 'members';
  readonly type = POST_NEW_CONVERSATION_MEMBERS_TO_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { members: Member[] }) {}
}

export const REMOVE_MEMBER_FROM_CONVERSATION_IN_STATE = '[MEMBERS] Remove Member From Conversation In State';
export class RemoveMemberFromConversationInState implements Action {
  readonly feature = 'members';
  readonly type = REMOVE_MEMBER_FROM_CONVERSATION_IN_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: { id: number }) {}
}

export const REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER = '[MEMBERS] Remove Member From Conversation On Server';
export class RemoveMemberFromConversationOnServer implements Action {
  readonly feature = 'members';
  readonly type = REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER;
  constructor(public payload: { member: Member; currentUserId: number }) {}
}

export const REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_FAILURE =
  '[MEMBERS] Remove Member From Conversation On Server Failure';
export class RemoveMemberFromConversationOnServerFailure implements Action {
  readonly feature = 'members';
  readonly type = REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_FAILURE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member; error: any }) {}
}

export const REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_SUCCESS =
  '[MEMBERS] Remove Member From Conversation On Server Success';
export class RemoveMemberFromConversationOnServerSuccess implements Action {
  readonly feature = 'members';
  readonly type = REMOVE_MEMBER_FROM_CONVERSATION_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { id: number }) {}
}

export const REMOVE_MEMBERS_ON_MULTIPLE_FAILURE = '[MEMBERS] Remove Members On Multiple Failure';
export class RemoveMembersOnMultipleFailure implements Action {
  readonly feature = 'members';
  readonly type = REMOVE_MEMBERS_ON_MULTIPLE_FAILURE;
  constructor(public payload: Member[]) {}
}

export const REMOVE_SELF_FROM_CONVERSATION = '[MEMBERS] Remove Self From Conversation';
export class RemoveSelfFromConversation implements Action {
  readonly type = REMOVE_SELF_FROM_CONVERSATION;
  readonly feature = 'members';
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member }) {}
}

export const REMOVE_SELF_FROM_CONVERSATION_FAILURE = '[MEMBERS] Remove Self From Conversation Failure';
export class RemoveSelfFromConversationFailure implements Action {
  readonly type = REMOVE_SELF_FROM_CONVERSATION_FAILURE;
  readonly feature = 'members';
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member; error: any }) {}
}

export const RESET_MEMBER_STATE = '[MEMBERS] Reset Member State';
export class ResetMemberState implements Action {
  readonly type = RESET_MEMBER_STATE;
  readonly feature = 'members';
  constructor(public payload: {}) {}
}

export const START_MEMBERS_SPINNER_UI = '[MEMBERS] Start Members Spinner Ui';
export class StartMembersSpinnerUi implements Action {
  readonly type = START_MEMBERS_SPINNER_UI;
  constructor(public payload: boolean) {}
}

export const STOP_MEMBERS_SPINNER_UI = '[MEMBERS] Stop Members Spinner Ui';
export class StopMembersSpinnerUi implements Action {
  readonly feature = 'members';
  readonly type = STOP_MEMBERS_SPINNER_UI;
  constructor(public payload: boolean) {}
}

export const UPDATE_IS_ARCHIVED_CONVERSATION_IN_STATE = '[MEMBERS] Update Is Archived Conversation In State';
export class UpdateIsArchivedConversationInState implements Action {
  readonly feature = 'members';
  readonly type = UPDATE_IS_ARCHIVED_CONVERSATION_IN_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member }) {}
}

export const UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER = '[MEMBERS] Update Is Archived Conversation To Server';
export class UpdateIsArchivedConversationToServer implements Action {
  readonly feature = 'members';
  readonly type = UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member }) {}
}

export const UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER_FAILIURE =
  '[MEMBERS] Update Is Archived Conversation To Server Failure';
export class UpdateIsArchivedConversationToServerFailure implements Action {
  readonly type = UPDATE_IS_ARCHIVED_CONVERSATION_TO_SERVER_FAILIURE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member; error: any }) {}
}

export const UPDATE_IS_PINNED_TO_UI_CONVERSATION_IN_STATE = '[MEMBERS] Update Is Pinned To Ui Conversation In State';
export class UpdateIsPinnedToUiConversationInState implements Action {
  readonly type = UPDATE_IS_PINNED_TO_UI_CONVERSATION_IN_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER = '[MEMBERS] Update Is Pinned To Ui Conversation On Server';
export class UpdateIsPinnedToUiConversationOnServer implements Action {
  readonly type = UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER;
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER_FAILIURE =
  '[MEMBERS] Update Is Archived Conversation On Server Failure';
export class UpdateIsPinnedToUiConversationOnServerFailure implements Action {
  readonly type = UPDATE_IS_PINNED_TO_UI_CONVERSATION_ON_SERVER_FAILIURE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member; error: any }) {}
}

export const UPDATE_IS_READ_CONVERSATION_IN_STATE = '[MEMBERS] Update Is Read Conversation In State ';
export class UpdateIsReadConversationInState implements Action {
  readonly feature = 'members';
  readonly type = UPDATE_IS_READ_CONVERSATION_IN_STATE;
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const UPDATE_IS_READ_CONVERSATION_ON_SERVER = '[MEMBERS] Update Is Read Conversation On Server ';
export class UpdateIsReadConversationOnServer implements Action {
  readonly feature = 'members';
  readonly type = UPDATE_IS_READ_CONVERSATION_ON_SERVER;
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const UPDATE_IS_READ_CONVERSATION_ON_SERVER_FAILURE = '[MEMBERS] Update Is Read Conversation On Server Failure ';
export class UpdateIsReadConversationOnServerFailure implements Action {
  readonly feature = 'members';
  readonly type = UPDATE_IS_READ_CONVERSATION_ON_SERVER_FAILURE;
  readonly triggerStorageSync = true;
  constructor(public payload: { member: Member; error: any }) {}
}

export type MembersAction =
  | AddMemberToConversationFromSocketToState
  | AddMembersToConversation
  | AddMembersToConversationSuccess
  | AddMembersToConversationFailure
  | DeleteMembersInState
  | GetMembersFromServer
  | GetMembersFromServerFailure
  | GetMembersFromServerSuccess
  | GetMembersForConversationFromServer
  | GetMembersForConversationFromServerFailure
  | GetMembersForConversationFromServerSuccess
  | PostMemberToServer
  | PostMemberToServerFailure
  | PostMemberToServerSuccess
  | PostNewConversationMembersToServer
  | PostNewConversationMembersToServerFailure
  | PostNewConversationMembersToServerSuccess
  | RemoveMemberFromConversationInState
  | RemoveMemberFromConversationOnServer
  | RemoveMemberFromConversationOnServerFailure
  | RemoveMemberFromConversationOnServerSuccess
  | RemoveMembersOnMultipleFailure
  | RemoveSelfFromConversation
  | RemoveSelfFromConversationFailure
  | ResetMemberState
  | StartMembersSpinnerUi
  | StopMembersSpinnerUi
  | UpdateIsArchivedConversationInState
  | UpdateIsArchivedConversationToServer
  | UpdateIsArchivedConversationToServerFailure
  | UpdateIsReadConversationInState
  | UpdateIsReadConversationOnServer
  | UpdateIsReadConversationOnServerFailure
  | UpdateIsPinnedToUiConversationInState
  | UpdateIsPinnedToUiConversationOnServer
  | UpdateIsPinnedToUiConversationOnServerFailure;
