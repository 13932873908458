import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: any, alwaysShowTime: boolean): any {
    if (new Date(value) instanceof Date) {
      const now = moment(new Date());
      const valueDate = moment.utc(value).local();
      const dayDiff = now.diff(valueDate, 'days');
      const thisYear = now.year() === valueDate.year();

      if (thisYear) {
        if (dayDiff === 0) {
          return valueDate.format('hh:mm A');
        } else if (dayDiff > 0 && dayDiff <= 6) {
          if (alwaysShowTime) {
            return valueDate.format('hh:mm A ddd');
          } else {
            return valueDate.format('ddd');
          }
        } else if (dayDiff > 6) {
          if (alwaysShowTime) {
            return valueDate.format('hh:mm A MMM Do');
          } else {
            return valueDate.format('MMM Do');
          }
        }
      } else {
        if (alwaysShowTime) {
          return valueDate.format('hh:mm A MMM DD, YYYY');
        } else {
          return valueDate.format('MMM DD, YYYY');
        }
      }
    }

    return value;
  }
}
