import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'com-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements OnInit, OnChanges {
  @Input() url: string;

  file: {
    name: string;
    extension: string;
    fullPath: string;
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    let fileFullName: string = this.url.includes('?')
      ? this.url.substring(this.url.lastIndexOf('/') + 1, this.url.lastIndexOf('?'))
      : this.url.substring(this.url.lastIndexOf('/') + 1);
    fileFullName = decodeURIComponent(fileFullName);
    fileFullName = fileFullName.replace(/\+/g, ' ');
    const fileExtension: string = fileFullName.split('.')[1];
    this.file = { name: fileFullName, extension: fileExtension, fullPath: this.url };
  }
}
