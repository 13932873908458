export class MessageTransactionMessagePreferenceQueryParameters {
  id?: string;
  messagePreferenceContactInfo?: string;
  messagePreferenceId?: string;
  page?: number;
  pageSize?: number;
  transactionId?: string;
  transactionTypeId?: string;

  constructor(obj?: MessageTransactionMessagePreferenceQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
