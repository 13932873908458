import { AuthenticationState, initialAuthenticationStagesState } from './authentication.interfaces';

export const authenticationInitialState: AuthenticationState = {
  // fill it initial state here
  authenticatedUser: null,
  errors: null,
  authenticated: null,
  authenticating: false,
  authenticationStages: initialAuthenticationStagesState,
  employee: null,
  quorumApiKey: null,
  quorumDestinationId: null
};
