import { Injectable } from '@angular/core';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { SocketsService } from '@quorum/sockets/services';
import { SocketStateService } from '@quorum/sockets/services';

@Injectable()
export class CommunicatorSocketService extends SocketsService {
  constructor(
    protected authenticationStateService: AuthenticationStateService,
    protected socketStateService: SocketStateService
  ) {
    super(authenticationStateService, socketStateService);
  }
}
