<div>
  <ng-container *ngIf="['png', 'jpg', 'jpeg', 'gif'].includes(file.extension); else unknownFileType">
    <a [href]="file.fullPath" target="_blank" class="file-container" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex fxLayout="column">
        <img [src]="file.fullPath" alt="file.name" class="image-container" />
      </div>
    </a>
  </ng-container>
  <ng-template #unknownFileType>
    <a [href]="file.fullPath" target="_blank" class="file-container" fxLayout="row" fxLayoutAlign="start center">
      <div class="save-icon-container" fxLayoutAlign="center center">
        <mat-icon class="save-icon">save_alt</mat-icon>
      </div>
      <div fxFlex fxLayout="column">
        <b>{{ file.name }}</b>
        <span class="mat-caption">Download</span>
      </div>
    </a>
  </ng-template>
</div>
