import { Directive, ElementRef } from '@angular/core';
import { ImageService } from './image.service';

@Directive({
  selector: '[comImagesLoaded]',
})
export class ImagesLoadedDirective {
  /* MutationObserver watches for changes being made to the DOM -  we watch for [innerHTML]='event.content' binding on the event.component */
  private observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
    mutations.forEach((mutation: MutationRecord) => {
      if (mutation.type == 'childList') {
        const imageElement = (Object.values(mutation.target.childNodes) as HTMLElement[])[1]?.firstElementChild; //<img> wrapped inside an <a> tag

        if (imageElement && imageElement.tagName === 'IMG') {
          /* register a new image with a service and increment the counter */
          this.imageService.imageLoading(imageElement as HTMLElement);

          /* decrement the counter when the image has loaded */
          imageElement.addEventListener('load', () => {
            this.imageService.imageLoadedOrError(imageElement as HTMLElement);
          });

          /* or decrement the counter when the image is broken */
          imageElement.addEventListener('error', () => {
            console.log('error when loading the image');
            this.imageService.imageLoadedOrError(imageElement as HTMLElement);
          });
        }
      }
    });
  });

  constructor(private el: ElementRef, private imageService: ImageService) {}

  ngOnInit() {
    this.observer.observe(this.el.nativeElement, {
      childList: true,
    });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
