import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@quorum/environments';
import Autolinker from 'autolinker';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/operators';
import validator from 'validator';

@Pipe({
  name: 'linker',
})
export class LinkerPipe implements PipeTransform {
  constructor(private http?: HttpClient, private sanitizer?: DomSanitizer) {}
  imageFileExtensions: string[] = ['PNG', 'JPG', 'JPEG', 'GIF'];

  transform(value: any, args?: any): Observable<any> {
    if (value) {
      if (validator.isURL(value)) {
        const fileFullName: string = value.substring(value.lastIndexOf('/') + 1);
        const fileExtension: string = fileFullName.split('.')[1];

        if (fileExtension && this.imageFileExtensions.includes(fileExtension.toUpperCase())) {
          return of(
            this.sanitizer.bypassSecurityTrustHtml(`
                    <div fxLayout="column" style="padding: 4px 0;display:flex;flex-direction:column;">
                      <a [href]="file.fullPath" target="_blank" class="linker-file-container">
                          <img src=${value} alt=${fileFullName} style="border-radius:8px;width:100%;height:auto" class="linker-image-container">
                      </a>
                      <a href=${value} target="_blank" rel="noopener noreferrer">${value}</a>
                    </div>
                  `)
          );
        } else {
          return this.http.get(environment.metadataScraperUrl, { params: { targetUrl: value } }).pipe(
            switchMap((metadata: any) => {
              if (metadata && metadata.title !== '') {
                return of(
                  this.sanitizer.bypassSecurityTrustHtml(`
                  <a href=${value} target="_blank" rel="noopener noreferrer" fxLayout="column" style="padding: 4px 0;display:flex;flex-direction:column;text-decoration:none;">
                    <img src=${metadata.image} alt=${metadata.publisher} style="border-radius:8px;width:100%;max-width:400px;height:auto;margin-bottom:4px;">
                    <span style="color:initial !important;word-break: break-word;padding:0 4px;">${metadata.title}</span>
                    <span style="font-size:12px;color: rgba(0,0,0,0.52) !important;font-weight:500;padding:0 4px;">${metadata.publisher}</span>
                  </a>
              `)
                );
              } else {
                return of(`<a href="${value}" target="_blank">${value}</a>`);
              }
            }),
            catchError((err: Response) => {
              return of(`<a href="${value}" target="_blank">${value}</a>`);
            })
          );
        }
      } else {
        return of(
          this.sanitizer.bypassSecurityTrustHtml(
            `
            <span style="white-space: pre-wrap;word-break: break-word;">${Autolinker.link(value, {
              stripPrefix: false,
            })}</span>
            `
          )
        );
      }
    } else {
      return of(value);
    }
  }
}
