import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { Conversation, ConversationStateService } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { EventQueryParameters } from '@quorum/models/xs-query';
import { Event, Member } from '@quorum/models/xs-resource';
import { ImageService } from 'libs/communicator/ui/ng/events/src/lib/image.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'com-conversation-body',
  templateUrl: './conversation-body.component.html',
  styleUrls: ['./conversation-body.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationBodyComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() conversation: Conversation;
  @Input() members: Array<Member> = [];

  @ViewChild('eventsContainer') eventsContainer: ElementRef; // scrollable div
  @ViewChildren('eventGroupsList') eventGroupsList: QueryList<any>;

  authenticatedUser$: Subscription;
  authenticatedUser: AuthenticatedUser;
  events$: Observable<any>;
  eventsSubscription$: Subscription;
  eventsSubject: BehaviorSubject<any>;
  eventGroups$: Observable<any>;
  isLoading$: Observable<boolean>;
  scrollToBottom: boolean = true;
  imagesLoadingSub$: Subscription;
  listenForUpdateLastEventInState$: Subscription;

  constructor(
    private conversationStateService: ConversationStateService,
    private eventsStateService: EventsStateService,
    private authenticationStateService: AuthenticationStateService,
    private imageService: ImageService,
    private eventStateService: EventsStateService
  ) {}

  ngOnInit() {
    this.eventsSubject = new BehaviorSubject<any>(this.conversation.id);

    this.authenticatedUser$ = this.authenticationStateService
      .selectAuthenticatedUser()
      .subscribe((authenticatedUser: AuthenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
      });
    this.isLoading$ = this.eventsStateService.selectIsLoading();

    this.eventGroups$ = this.eventsSubject.pipe(
      switchMap((id: number) => {
        return this.eventsStateService.selectEventsForConversation(id).pipe(
          filter((events: Event[]) => {
            return events != null;
          }),
          map((events: Event[]) => {
            const eventsCopy: Event[] = JSON.parse(JSON.stringify(events));
            return eventsCopy.sort((a: any, b: any) => new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime());
          })
        );
      }),
      map((events: Event[]) => this.buildEventGroups(events))
    );

    /* scroll to the bottom of chat (when conversation has images) */
    this.imagesLoadingSub$ = this.imageService.imagesLoading$
      .pipe(
        filter((imgCounter) => imgCounter === 0) // 0 means images finished loading in the chat
      )
      .subscribe((_) => {
        if (this.scrollToBottom) {
          this.autoScrollToBottom();
        }
      });
  }

  ngAfterViewInit() {
    this.autoScrollToBottom();

    /* scroll to the bottom of chat when a new message is sent or receieved while in chat */
    this.listenForUpdateLastEventInState$ = this.eventStateService.listenForUpdateLastEventInState$.subscribe(
      (action: any) => {
        if (action && action.payload?.id === this.conversation.id) {
          this.autoScrollToBottom();
        }
      }
    );
  }

  autoScrollToBottom() {
    setTimeout(() => {
      this.eventsContainer?.nativeElement.scrollTo({
        left: 0,
        top: this.eventsContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversation && !changes.conversation.isFirstChange()) {
      if (changes.conversation.currentValue.id != changes.conversation.previousValue.id) {
        this.scrollToBottom = true;
        this.eventsSubject.next(changes.conversation.currentValue.id);

        /* scroll to the bottom of chat when switching between conversations (for conversation with no images) */
        if (this.scrollToBottom) {
          this.autoScrollToBottom();
        }
      }
    }
  }

  ngOnDestroy() {
    this.authenticatedUser$.unsubscribe();
    this.imagesLoadingSub$.unsubscribe();
    this.listenForUpdateLastEventInState$.unsubscribe();
  }

  displayTimeStamp(events: any, index: number) {
    if (index === 0) {
      return true;
    } else if (index >= 1) {
      const diff: number =
        (moment(events[index][0].sentDate).toDate().getTime() -
          moment(events[index - 1][0].sentDate)
            .toDate()
            .getTime()) /
        1000;
      if (diff >= 1200) {
        return true;
      } else {
        return false;
      }
    }
  }

  buildEventGroups(events: any) {
    let groupIndex: number = 0;

    const groupedObj: any = events.reduce((prev: any, cur: Event, index: number) => {
      if (!prev[groupIndex]) {
        groupIndex++;
        prev[groupIndex] = [cur];
      } else {
        const timeDiff = moment(prev[groupIndex][0].sentDate).diff(moment(cur.sentDate), 'seconds');
        if (prev[groupIndex][0].memberId !== cur.memberId || Math.abs(timeDiff) > 60) {
          groupIndex++;
          prev[groupIndex] = [cur];
        } else {
          prev[groupIndex].push(cur);
        }
      }

      prev[groupIndex].sort((a: Event, b: Event) => +new Date(a.sentDate) - +new Date(b.sentDate));
      return prev;
    }, {});

    return Object.keys(groupedObj).map((key) => groupedObj[key]);
  }

  getEventSender(members: Array<Member>, memberId: string) {
    return members.find((member: Member) => member.userId === memberId);
  }

  onScrollUp() {
    this.scrollToBottom = false;
    this.conversationStateService
      .selectEventsLastQueryPage(this.conversation.id)
      .pipe(
        take(1),
        map((eventsLastQueryPage: { [conversationId: number]: number }) => {
          this.eventsStateService.getEvents(
            new EventQueryParameters({
              conversationId: this.conversation.id,
              pageNumber: eventsLastQueryPage[this.conversation.id] + 1,
            })
          );
        })
      )
      .subscribe();
  }

  trackByFn(index: number, group: any) {
    return group[0].sentDate;
  }

  trackByEventFn(index: number, event: Event) {
    return event.id;
  }
}
