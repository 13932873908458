import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationStateService } from './authentication-state.service';

@Injectable()
export class EmployeeAuthenticationGuard implements CanActivate, CanActivateChild {
  private isAuthenticated: boolean;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private router: Router,
    private routerStateService: RouterStateService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log(next);
    console.log(state);

    if (next.data.employeeLogin && state.url != '"/login/xsellerator-employee"') {
      console.log('we should go to emp login');
      //this.routerStateService.go(['/login/xsellerator-employee'], {});
    } else {
      //return true;
    }
    return true;
    // return this.authenticationStateService.isAuthenticated().pipe(
    //   map((value: any) => {
    //     if (value) {
    //       return true;
    //     } else {
    //       this.routerStateService.go(['/login'], {});
    //       return false;
    //     }
    //   })
    // );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
