import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MessageTypeToTemplateMapping } from '@quorum/models/xs-resource';

export interface SelectedMessageTypeToTemplateMappingState extends EntityState<MessageTypeToTemplateMapping> {}

export const messageTypeToTemplateMappingAdapter: EntityAdapter<MessageTypeToTemplateMapping> =
  createEntityAdapter<MessageTypeToTemplateMapping>({
    selectId: (messageTypeToTemplateMapping: MessageTypeToTemplateMapping) =>
      messageTypeToTemplateMapping.messageTypeId,
  });
export const initialMessageTypeToTemplateMappingState: SelectedMessageTypeToTemplateMappingState =
  messageTypeToTemplateMappingAdapter.getInitialState({});
