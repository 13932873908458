import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { ApiModule } from '@quorum/api';
import { CommunicatorAppState } from '@quorum/communicator/state/root';
import { environment } from '@quorum/environments';
import { ConversationsEffects } from './+state/conversations.effects';
import { conversationsReducer, metaReducers } from './+state/conversations.reducer';
import { ConversationStateService } from './conversation-state.service';

@NgModule({
  imports: [
    // ApiModule.withConfig({
    //   apiBase: environment.apiBase,
    //   legacyApiBase: environment.legacyApiBase,
    // }),
    CommonModule,
    StoreModule.forFeature('conversations', conversationsReducer, {
      // initialState: conversationsInitialState,
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([ConversationsEffects]),
  ],
  providers: [ConversationStateService, ConversationsEffects],
})
export class ConversationsModule {
  constructor() {
    // store.dispatch({
    //   type: 'LOAD_CONVERSATIONS',
    //   payload: {}
    // });
  }
}
