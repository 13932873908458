export class MessageType {
  description: string;
  id: number;
  isInternal: boolean;
  requiresTemplate: boolean;
  transactionTypeId: number;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}

export const messageTypeMock = new MessageType({
  description: 'type 1',
  id: 1,
  isInternal: true,
  requiresDefaultTemplate: false,
  transactionTypeId: 2,
});

export const messageTypesMock: MessageType[] = [
  messageTypeMock,
  new MessageType({
    description: 'type 2',
    id: 2,
    isInternal: false,
    requiresDefaultTemplate: true,
    transactionTypeId: 2,
  }),
];
