import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@quorum/authentication/services';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ContactsEffects } from '@quorum/communicator/state/contacts';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { NgPipesModule } from 'ngx-pipes';
import { ContactListComponent } from './contact-list/contact-list.component';
// eslint-disable-next-line max-len
import { ContactSelectDialogListItemComponent } from './contact-select-dialog-list-item/contact-select-dialog-list-item.component';
import { ContactSelectDialogComponent } from './contact-select-dialog/contact-select-dialog.component';
import { ContactsListItemComponent } from './contacts-list-item/contacts-list-item.component';
import { ContactsShellComponent } from './contacts-shell/contacts-shell.component';

@NgModule({
    imports: [
        CommonModule,
        CommonMaterialComponentsModule,
        ShaComponentsNgUiModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FormsModule,
        NgPipesModule,
        RouterModule,
    ],
    //exports: [ContactListComponent],
    exports: [ContactListComponent, ContactsListItemComponent, ContactSelectDialogComponent],
    declarations: [
        ContactListComponent,
        ContactsListItemComponent,
        ContactSelectDialogComponent,
        ContactSelectDialogListItemComponent,
        ContactsShellComponent,
    ],
    //declarations: [ContactListComponent],
    providers: [ContactsEffects, ContactsStateService, AuthenticationGuard]
})
export class CommunicatorUiNgContactsModule {
  constructor() {}
}
