import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromPowerlaneAnalytics from './powerlane-analytics.reducer';
import * as PowerlaneAnalyticsSelectors from './powerlane-analytics.selectors';
import * as PowerlaneAnalyticsActions from './powerlane-analytics.actions';
@Injectable()
export class PowerlaneAnalyticsFacade {
  loaded$ = this.store.pipe(select(PowerlaneAnalyticsSelectors.getPowerlaneAnalyticsLoaded));
  allPowerlaneAnalytics$ = this.store.pipe(select(PowerlaneAnalyticsSelectors.getAllPowerlaneAnalytics));
  selectedPowerlaneAnalytics$ = this.store.pipe(select(PowerlaneAnalyticsSelectors.getSelected));

  constructor(private store: Store<fromPowerlaneAnalytics.PowerlaneAnalyticsPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  dispatchVcrSentFromWorkOrder(workOrderId: number) {
    this.store.dispatch(PowerlaneAnalyticsActions.VcrSentFromWorkOrder({ workOrderId }));
  }

  dispatchVcrWithMediaSentFromWorkOrder(workOrderId: number) {
    this.store.dispatch(PowerlaneAnalyticsActions.VcrSentWithMediaFromWorkOrder({ workOrderId }));
  }

  dispatchWorkOrderCreatedFromPowerLane(workOrderId: number) {
    this.store.dispatch(PowerlaneAnalyticsActions.WorkOrderCreatedFromPowerLane({ workOrderId }));
  }

  dispatchMediaAddedToWorkOrderFromPowerLane(
    workOrderId: number,
    mediaAddedFromLocation: string,
    fileType: string,
    videoDuration?: number
  ) {
    this.store.dispatch(
      PowerlaneAnalyticsActions.MediaAddedToWorkOrderFromPowerLane({
        workOrderId,
        mediaAddedFromLocation,
        fileType,
        videoDuration,
      })
    );
  }
}
