import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventTypes } from '@models/server-common';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { Conversation } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { Event, TokenUser } from '@quorum/models/xs-resource';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'com-conversation-service-quote-advisor-ready',
  templateUrl: './conversation-service-quote-advisor-ready.component.html',
  styleUrls: ['./conversation-service-quote-advisor-ready.component.css'],
})
export class ConversationServiceQuoteAdvisorReadyComponent implements OnInit {
  @Input() conversation: Conversation;
  @Output() send: EventEmitter<Event> = new EventEmitter<Event>();

  currentUser: TokenUser;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private eventsStateService: EventsStateService
  ) {}

  ngOnInit() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((authenticatedUser: AuthenticatedUser) => {
          this.currentUser = authenticatedUser.user;
        })
      )
      .subscribe();
  }

  gotIt() {
    const event: Event = this.eventsStateService.createEvent(
      `I have this one!`,
      this.conversation.id,
      EventTypes.ServiceQuoteAdvisorGotIt,
      this.currentUser
    );
    this.send.emit(event);
  }

  isDeclined() {
    const event: Event = this.eventsStateService.createEvent(
      `The customer declined the work!`,
      this.conversation.id,
      EventTypes.ServiceQuoteDeclined,
      this.currentUser
    );
    this.send.emit(event);
  }

  isSold() {
    const event: Event = this.eventsStateService.createEvent(
      `Go ahead, the customer approved the work!`,
      this.conversation.id,
      EventTypes.ServiceQuoteSold,
      this.currentUser
    );
    this.send.emit(event);
  }
}
