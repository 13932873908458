import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { NgPipesModule } from 'ngx-pipes';
import { AvatarComponent } from './avatar/avatar.component';
import { EmailTemplateDialogComponent } from './com-email-template-dialog/email-template-dialog.component';
import { TextMessageDialogComponent } from './com-text-message-dialog/text-message-dialog.component';
import { MediaGalleryDialogButtonComponent } from './media-gallery-dialog-button/media-gallery-dialog-button.component';
import { MediaGalleryDialogComponent } from './media-gallery-dialog/media-gallery-dialog.component';
import { MediaGalleryEditMediaDialogComponent } from './media-gallery-edit-media-dialog/media-gallery-edit-media-dialog.component';
import { MediaGalleryFilterComponent } from './media-gallery-filter/media-gallery-filter.component';
import { MediaGalleryMediaCardComponent } from './media-gallery-media-card/media-gallery-media-card.component';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { MediaPreviewDialogComponent } from './media-preview-dialog/media-preview-dialog.component';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { MediaUploadInputComponent } from './media-upload-input/media-upload-input.component';
import { MediaUploadOptionsDialogComponent } from './media-upload-options-dialog/media-upload-options-dialog.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { TwoActionDialogComponent } from './two-action-dialog/two-action-dialog.component';
// eslint-disable-next-line max-len
import { TwoItemSelectionListDialogComponent } from './two-item-selection-list-dialog/two-item-selection-list-dialog.component';
import { SharedDirectivesModule } from '@quorum/sha-directives';
import { MediaUploadSnackbarComponent } from './media-upload-snackbar/media-upload-snackbar.component';
import { MediaRecorderUploadDialogComponent } from './media-recorder-upload-dialog/media-recorder-upload-dialog.component';

@NgModule({
  imports: [
    CommonMaterialComponentsModule,
    CommonPipesModule,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CommonPipesModule,
    NgPipesModule,
    OverlayModule,
    SharedDirectivesModule,
  ],
  declarations: [
    AvatarComponent,
    TwoActionDialogComponent,
    TwoItemSelectionListDialogComponent,
    TextMessageDialogComponent,
    EmailTemplateDialogComponent,
    MediaPreviewDialogComponent,
    MediaUploadInputComponent,
    MediaPreviewComponent,
    MediaUploadOptionsDialogComponent,
    MediaGalleryComponent,
    MediaGalleryEditMediaDialogComponent,
    MediaGalleryFilterComponent,
    MediaGalleryMediaCardComponent,
    MediaGalleryDialogComponent,
    MediaGalleryDialogButtonComponent,
    ProgressIndicatorComponent,
    MediaUploadSnackbarComponent,
    MediaRecorderUploadDialogComponent,
  ],
  exports: [
    AvatarComponent,
    TwoActionDialogComponent,
    TwoItemSelectionListDialogComponent,
    TextMessageDialogComponent,
    EmailTemplateDialogComponent,
    MediaUploadInputComponent,
    MediaPreviewComponent,
    MediaUploadOptionsDialogComponent,
    MediaGalleryEditMediaDialogComponent,
    MediaGalleryFilterComponent,
    MediaGalleryMediaCardComponent,
    MediaGalleryDialogButtonComponent,
    MediaGalleryComponent,
    ProgressIndicatorComponent,
    MediaRecorderUploadDialogComponent,
  ],
})
export class ShaComponentsNgUiModule {}
