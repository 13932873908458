import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NxModule } from '@nrwl/angular';
import { ApiInterceptor } from '@quorum/api';
import {
  AuthenticationGuard,
  AuthenticationStateService
} from '@quorum/authentication/services';
import {
  CommunicatorUiNgContactsModule,
  ContactsShellComponent
} from '@quorum/communicator/ui/ng/contacts';
import {
  ConversationShellComponent,
  GroupsShellComponent,
  NewConversationShellComponent
} from '@quorum/communicator/ui/ng/conversations';
import {
  CommunicatorUiNgRootModule,
  HomeShellComponent,
  MasterComponent,
  ShellComponent,
  TransactionSummaryComponent
} from '@quorum/communicator/ui/ng/root';
import {
  CommunicatorSettingsComponent,
  CommunicatorUiNgSettingsModule
} from '@quorum/communicator/ui/ng/settings';
import { CommunicatorStateRootModule } from '@quorum/communicator/state/root';
import { SocketStateService } from '@quorum/sockets/services';
import { AppComponent } from './app.component';
import {
  authenticationStagesSetup,
  checkAuthentication,
  clearStore,
  socketSetup
} from './app.initializer';
import { ConversationStateService } from '@quorum/communicator/state/conversations';

// Sentry.init({
//   dsn: 'https://4fe595f189df49ebbb33e201a98b609c@sentry.io/1761668',
//   release: '2020.06.11-api1',
// });

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    CommunicatorUiNgRootModule,
    //CommunicatorUiNgContactsModule,
    CommunicatorStateRootModule,
    CommunicatorUiNgSettingsModule,
    NxModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: 'login',
          //loadChildren: '@quorum/authentication-ng-ui#AuthenticationNgUiModule'
          loadChildren: () =>
            import(
              '../../../../libs/authentication-ng-ui/src/lib/authentication-ng-ui.module'
            ).then((m) => m.AuthenticationNgUiModule)
        },
        {
          path: 'subscriptions',
          loadChildren: () =>
            import(
              '../../../../libs/communicator/ui/ng/subscriptions/src/lib/communicator-ui-ng-subscriptions.module'
            ).then((m) => m.CommunicatorUiNgSubscriptionsModule)
        },
        {
          path: '',
          component: ShellComponent,
          canActivate: [AuthenticationGuard],
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: '/home/(master:master)'
            },
            {
              path: 'settings',
              component: CommunicatorSettingsComponent
            },
            {
              path: 'home',
              component: HomeShellComponent,
              children: [
                {
                  path: 'master',
                  component: MasterComponent,
                  outlet: 'master'
                },
                {
                  path: 'contacts',
                  component: ContactsShellComponent,
                  outlet: 'master'
                },
                {
                  path: 'groups',
                  component: GroupsShellComponent,
                  outlet: 'master'
                },
                {
                  path: 'c/:id',
                  component: ConversationShellComponent,
                  outlet: 'detail'
                },
                {
                  path: 'new',
                  component: NewConversationShellComponent,
                  outlet: 'detail'
                },
                {
                  path: '',
                  component: MasterComponent,
                  outlet: 'master'
                }
              ]
            }
          ]
        },
        {
          path: 'customer-summary/:customerAssociateId',
          component: TransactionSummaryComponent,
          canActivate: [AuthenticationGuard],
          children: [
            {
              path: 'c/:id',
              component: ConversationShellComponent
            },
            {
              path: 'new-conversation',
              component: NewConversationShellComponent
            }
          ]
        },
        {
          path: 'customer-summary/:customerAssociateId/:transactionTypeId/:transactionId',
          component: TransactionSummaryComponent,
          canActivate: [AuthenticationGuard],
          children: [
            {
              path: 'c/:id',
              component: ConversationShellComponent
            },
            {
              path: 'new-conversation',
              component: NewConversationShellComponent
            }
          ]
        }
      ],
      {
        enableTracing: false,
        paramsInheritanceStrategy: 'always',
        relativeLinkResolution: 'legacy'
      }
    )
  ],
  declarations: [AppComponent],
  exports: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkAuthentication,
      multi: true,
      deps: [AuthenticationStateService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: clearStore,
      multi: true,
      deps: [ConversationStateService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: socketSetup,
      multi: true,
      deps: [SocketStateService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authenticationStagesSetup,
      multi: true,
      deps: [AuthenticationStateService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // {
    //   provide: UrlSerializer,
    //   useClass: StandardUrlSerializer
    // }
  ]
})
export class AppModule {}
