import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Media } from '@quorum/models/xs-resource';
import { MediaStateService } from '@quorum/sha-media';
import { RouterStateService } from '@quorum/sha-router';
import { GroupByPipe } from 'ngx-pipes';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'sha-media-gallery',
  templateUrl: './media-gallery.component.html',
  providers: [GroupByPipe],
  styleUrls: ['./media-gallery.component.scss'],
})
export class MediaGalleryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() media: Media[];
  mediaSubject$: BehaviorSubject<Media[]> = new BehaviorSubject<Media[]>([]);
  isPopoverOpen: boolean = false;
  showClearButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  vehicleMediaLoaded$: Observable<boolean>;

  constructor(
    private mediaStateService: MediaStateService,
    private route: ActivatedRoute,
    private groupByPipe: GroupByPipe,
    private routerStateService: RouterStateService
  ) {}

  ngOnDestroy() {
    this.mediaSubject$.unsubscribe();
    this.showClearButton$.unsubscribe();
  }

  ngOnChanges(media: SimpleChanges) {
    this.mediaSubject$.next(this.groupByPipe.transform(this.media, 'tags.workOrderId'));
  }

  ngOnInit() {
    this.vehicleMediaLoaded$ = this.mediaStateService.getVehicleMediaLoaded();

    this.mediaSubject$.next(this.groupByPipe.transform(this.media, 'tags.workOrderId'));
  }

  trackByFunction(index: number, item: Media) {
    return item.SK;
  }

  isEmpty(media: any) {
    return Object.keys(media).length === 0;
  }

  mediaUpdated(media: any) {
    this.mediaStateService.updateMedia({
      ...media,
      caption: media.caption,
      customerFacing: media.customerFacing,
    });
  }

  mediaDeleted(media: any) {
    this.mediaStateService.deleteMedia(media);
  }
}
