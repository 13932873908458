import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MembersEffects } from './+state/members.effects';
import { membersReducer, metaReducers } from './+state/members.reducer';
import { MembersStateService } from './members-state.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('members', membersReducer, {
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([MembersEffects]),
  ],
  providers: [MembersEffects, MembersStateService],
})
export class CommunicatorStateRootMembersModule {}
