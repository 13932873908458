import { Action } from '@ngrx/store';
import { ContactPresence } from '@quorum/models/xs-misc';
import { ContactQueryParameters } from '@quorum/models/xs-query';
import { CommunicatorContact as Contact } from '@quorum/models/xs-resource';

export const DELETE_CONTACT_PRESENCE = '[CONTACTS] Delete Contact Presence';
export class DeleteContactPresence implements Action {
  readonly type = DELETE_CONTACT_PRESENCE;
  readonly feature = 'contacts';
  constructor(public payload: ContactPresence) {}
}

export const LOAD_CONTACTS = '[CONTACTS] Load Contacts';
export class LoadContacts implements Action {
  readonly type = LOAD_CONTACTS;
  readonly feature = 'contacts';
  constructor(public payload: ContactQueryParameters) {}
}

export const LOAD_CONTACTS_SUCCESS = '[CONTACTS] Load Contacts Success';
export class LoadContactsSuccess implements Action {
  readonly type = LOAD_CONTACTS_SUCCESS;
  readonly feature = 'contacts';
  readonly triggerStorageSync = true;
  constructor(public payload: Contact[]) {}
}

export const LOAD_CONTACTS_FAILURE = '[CONTACTS] Load Contacts Failure';
export class LoadContactsFailure implements Action {
  readonly type = LOAD_CONTACTS_FAILURE;
  readonly feature = 'contacts';
  constructor(public payload: { reason: string }) {}
}

export const LOAD_CONTACTS_PRESENCE = '[CONTACTS] Load Contacts Presence';
export class LoadContactsPresence implements Action {
  readonly type = LOAD_CONTACTS_PRESENCE;
  readonly feature = 'contacts';
  constructor(public payload: ContactPresence[]) {}
}

export const RESET_CONTACTS_STATE = '[CONTACTS] Reset Contacts State';
export class ResetContactsState implements Action {
  readonly type = RESET_CONTACTS_STATE;
  readonly feature = 'contacts';
  readonly triggerStorageSync = true;
  constructor(public payload: {}) {}
}

export const UPDATE_CONTACT_PRESENCE = '[CONTACTS] Update Contact Presence';
export class UpdateContactPresence implements Action {
  readonly type = UPDATE_CONTACT_PRESENCE;
  readonly feature = 'contacts';
  constructor(public payload: ContactPresence) {}
}

export const UPDATE_CONTACT_GROUP_CONFIGURATION = '[CONTACTS] Update Contact Group Configuration';
export class UpdateContactGroupConfiguration implements Action {
  readonly type = UPDATE_CONTACT_GROUP_CONFIGURATION;
  readonly feature = 'contacts';
  readonly triggerStorageSync = true;
  constructor(public payload: { id: number; collapsed: boolean }) {}
}

export type ContactsAction =
  | DeleteContactPresence
  | LoadContacts
  | LoadContactsFailure
  | LoadContactsPresence
  | LoadContactsSuccess
  | ResetContactsState
  | UpdateContactPresence
  | UpdateContactGroupConfiguration;
