import { Pipe, PipeTransform } from '@angular/core';
//import * as f from 'twemoji/dist/twemoji.amd.js'
declare var twemoji: any;

@Pipe({
  name: 'emojiParser',
})
export class EmojiParserPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    /* Creates links for urls in message.
     Using Autolinker rather than angular 'linker' filter because 'linker' causes issues with twemoji.parse(). */
    // message = Autolinker.link(message);
    if (value) {
      value = twemoji.parse(value);
    }
    return value;
  }
}
