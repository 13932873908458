import { MessageTransactionMessagePreference } from '@quorum/models/xs-resource';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromConversations from './conversations.actions';
import { conversationsInitialState } from './conversations.init';
import { conversationsAdapter, ConversationsState } from './conversations.interfaces';

export function conversationsReducer(
  state: ConversationsState,
  action: fromConversations.ConversationsAction
): ConversationsState {
  switch (action.type) {
    case fromConversations.ADD_MEMBER_TO_NEW_CONVERSATION_IN_STATE: {
      return {
        ...state,
        newConversation: {
          ...state.newConversation,
          members: [...state.newConversation.members, action.payload],
        },
      };
    }
    case fromConversations.ADD_UNSENT_MESSAGE_TO_STATE: {
      return {
        ...state,
        unsentMessages: {
          ...state.unsentMessages,
          [action.payload.conversationId]: action.payload.message,
        },
      };
    }
    case fromConversations.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_FAILURE: {
      return state;
    }
    case fromConversations.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS: {
      return { ...state, directMessageTemplates: action.payload };
    }
    case fromConversations.GET_MESSAGE_TYPES_FROM_SERVER_FAILURE: {
      return state;
    }
    case fromConversations.GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS: {
      return { ...state, messageTypes: action.payload };
    }
    case fromConversations.GET_NEW_CONVERSATION_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        newConversation: {
          ...state.newConversation,
          conversation: action.payload,
        },
      };
    }
    case fromConversations.GET_NEW_CONVERSATION_FROM_SERVER_FAILURE: {
      return {
        ...state,
        newConversation: { ...state.newConversation, conversation: null },
      };
    }
    case fromConversations.POST_CONVERSATION_TO_SERVER_FAILURE: {
      return state;
    }

    case fromConversations.ADD_CONVERSATION_FROM_SOCKET_TO_STATE:
    case fromConversations.ADD_CONVERSATION_TO_STATE:
    case fromConversations.POST_CONVERSATION_TO_SERVER_SUCCESS: {
      return {
        ...conversationsAdapter.addOne(action.payload.conversation, state),
      };
    }
    case fromConversations.POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE:
    case fromConversations.UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE: {
      return { ...state, errors: action.payload };
    }
    case fromConversations.POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS:
    case fromConversations.UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS: {
      return {
        ...state,
        directMessageTemplates: [...state.directMessageTemplates, action.payload],
      };
    }
    case fromConversations.DELETE_CONVERSATION_FROM_STATE: {
      return {
        ...conversationsAdapter.removeOne(action.payload.id, state),
      };
    }
    case fromConversations.GET_CONVERSATIONS_FROM_SERVER_SUCCESS: {
      return {
        ...conversationsAdapter.upsertMany(action.payload, state),
        utcLastModified: new Date(),
        isLoading: false,
      };
    }
    case fromConversations.GET_CONVERSATIONS_FROM_SERVER_FAILURE: {
      return { ...state, errors: action.payload, isLoading: false };
    }
    case fromConversations.POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER_FAILURE: {
      return state;
    }
    case fromConversations.DELETE_MEMBER_FROM_NEW_CONVERSATION_IN_STATE: {
      const members = state.newConversation.members.filter((member) => {
        return member.userId !== action.payload.userId;
      });
      return {
        ...state,
        newConversation: { ...state.newConversation, members: members },
      };
    }
    case fromConversations.DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_SUCCESS: {
      const templates = state.directMessageTemplates.filter((template) => template.id !== action.payload.id);
      return { ...state, directMessageTemplates: templates };
    }
    case fromConversations.DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_FAILURE: {
      return { ...state, errors: action.payload };
    }
    case fromConversations.DELETE_UNSENT_MESSAGE_FROM_STATE: {
      const key = action.payload.conversationId;
      const { [key]: value, ...newUnsentMessges } = state.unsentMessages;
      return { ...state, unsentMessages: newUnsentMessges };
    }
    case fromConversations.RESET_CONVERSATION_STATE: {
      return conversationsInitialState;
    }
    case fromConversations.RESET_CONVERSATION_IN_NEW_CONVERSATION_IN_STATE: {
      return { ...state, newConversation: { ...state.newConversation, conversation: null } };
    }
    case fromConversations.START_NEW_CONVERSATION_IN_STATE: {
      return { ...state, newConversation: { conversation: action.payload.conversation, members: [] } };
    }
    case fromConversations.TOGGLE_CONVERSATION_INFO_PANEL_UI: {
      return {
        ...state,
        ui: { ...state.ui, infoPanelVisible: action.payload },
      };
    }
    case fromConversations.TOGGLE_EMOJI_PANEL_UI: {
      return {
        ...state,
        ui: { ...state.ui, emojiPanelVisible: action.payload },
      };
    }
    case fromConversations.UPDATE_CONVERSATION_TITLE_ON_SERVER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.id]: state.ui[action.payload.id]
            ? { ...state.ui[action.payload.id], titleUpdate: true }
            : { titleUpdate: true },
        },
      };
    }
    case fromConversations.UPDATE_EVENTS_LAST_QUERY_PAGE_IN_STATE: {
      return {
        ...state,
        eventsLastQueryPage: {
          ...state.eventsLastQueryPage,
          [action.payload.conversationId]: state.eventsLastQueryPage[action.payload.conversationId]
            ? state.eventsLastQueryPage[action.payload.conversationId] + 1
            : 1,
        },
      };
    }
    case fromConversations.UPDATE_LAST_QUERY_PAGE_NUMBER_IN_STATE: {
      return {
        ...state,
        lastQueryPageNumber: {
          ...state.lastQueryPageNumber,
          [action.payload.conversationView]: state.lastQueryPageNumber[action.payload.conversationView] + 1,
        },
      };
    }
    case fromConversations.ADD_USER_IS_TYPING_TO_STATE:
    case fromConversations.DELETE_USER_IS_TYPING_IN_STATE:
    case fromConversations.UPDATE_CONVERSATION_IS_PINNED_TO_UI_IN_STATE:
    case fromConversations.UPDATE_CONVERSATION_TITLE_ON_SERVER_SUCCESS:
    case fromConversations.UPDATE_CONVERSATION_TITLE_FROM_SOCKET_EVENT_IN_STATE:
    case fromConversations.UPDATE_CONVERSATION_LAST_EVENT_IN_STATE:
    case fromConversations.UPDATE_CONVERSATION_IN_STATE: {
      return {
        ...conversationsAdapter.updateOne(action.payload, state),
      };
    }
    case fromConversations.DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER:
    case fromConversations.POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER:
    case fromConversations.GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER:
    case fromConversations.PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER: {
      return {
        ...state,
        messagePreferenceOverride: { ...state.messagePreferenceOverride, isLoading: true },
      };
    }
    case fromConversations.GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER_SUCCESS:
    case fromConversations.POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS:
    case fromConversations.PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS: {
      return {
        ...state,
        messagePreferenceOverride: { isLoading: false, value: action.payload },
      };
    }
    case fromConversations.DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        messagePreferenceOverride: { isLoading: false, value: new MessageTransactionMessagePreference() },
      };
    }
    case fromConversations.GET_CONVERSATIONS_FROM_SERVER:
    case fromConversations.DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER:
    case fromConversations.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER:
    case fromConversations.GET_MESSAGE_TYPES_FROM_SERVER:
    case fromConversations.GET_NEW_CONVERSATION_FROM_SERVER:
    case fromConversations.POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER:
    case fromConversations.POST_CONVERSATION_TO_SERVER:
    case fromConversations.UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER:
    case fromConversations.GET_CONVERSATIONS_TO_REMOVE_FROM_STATE:
    case fromConversations.GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_SUCCESS:
    case fromConversations.GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_FAILURE:
    default: {
      return state;
    }
  }
}

export function ConversationstateHydration(
  reducer: ActionReducer<ConversationsState>
): ActionReducer<ConversationsState> {
  return function (state: ConversationsState, action: any, metaReducer: string = 'stateHydration'): ConversationsState {
    if (state === undefined) {
      let conversationState: any = localStorage.getItem('conversations-state');
      state = conversationState ? JSON.parse(conversationState) : conversationsInitialState;
    }
    const newState = reducer(state, action);
    if (action.feature === 'conversations' && action.triggerStorageSync) {
      localStorage.setItem('conversations-state', JSON.stringify(newState));
    }

    return newState;
  };
}

export const metaReducers: MetaReducer<ConversationsState>[] = [ConversationstateHydration];
