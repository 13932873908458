import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Event } from '@quorum/models/xs-resource';

export interface EventsState extends EntityState<Event> {
  isLoading: boolean;
}

export const eventsAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  sortComparer: (a: Event, b: Event) => {
    return +new Date(b.sentDate) - +new Date(a.sentDate);
  },
});

export const eventsFeatureSelector = createFeatureSelector<EventsState>('events');

export const eventsIsLoadingSelector = createSelector(eventsFeatureSelector, (state: EventsState) => state.isLoading);
export const { selectIds, selectEntities, selectAll, selectTotal } = eventsAdapter.getSelectors(eventsFeatureSelector);
