export class AssociateReportQueryParameters {
  classId?: string;
  contactPreferenceId?: string;
  conversionControlNumber?: string;
  conversionId?: string;
  countryId?: string;
  electricCardReader?: string;
  embed?: any;
  emailAddress?: string;
  emailConsent?: string;
  freeformPhoneType?: string;
  guid?: string;
  id?: string;
  initialContactMethod?: string;
  legalName?: string;
  legalNameFlag?: string;
  loyaltyNumber?: string;
  messagePreferenceId?: number;
  page?: number;
  pageSize?: number;
  phoneExtension?: string;
  phoneNumber?: string;
  phoneType?: string;
  postalZip?: string;
  privacyLevelId?: number;
  provStateId?: string;
  search?: string;
  subTypeId?: string;
  tciCustomerNumber?: string;
  textConsent?: string;
  vendorContactCode?: number;
  vsDealerPromotionId?: number;
  webAddress?: string;
  woDealerPromtionId?: number;

  constructor(obj?: AssociateReportQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
