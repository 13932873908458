import { ServiceAppointmentDeclinedMpviCode } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface ServiceAppointmentDeclinedOperationsState extends EntityState<ServiceAppointmentDeclinedMpviCode> {}

export const serviceAppointmentDeclinedOperationsAdapter: EntityAdapter<
  ServiceAppointmentDeclinedMpviCode
> = createEntityAdapter<ServiceAppointmentDeclinedMpviCode>({
  selectId: (serviceAppointmentDeclinedOperation: ServiceAppointmentDeclinedMpviCode) =>
    serviceAppointmentDeclinedOperation.id
});
// eslint-disable-next-line max-len
export const initialServiceAppointmentDeclinedOperationsState: ServiceAppointmentDeclinedOperationsState = serviceAppointmentDeclinedOperationsAdapter.getInitialState(
  {}
);
