import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'com-home-shell',
  templateUrl: './home-shell.component.html',
  styleUrls: ['./home-shell.component.css'],
})
export class HomeShellComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
