export class ResponseInterceptorOptions {
  etag?: string;
  total?: number;
  totalPages?: number;

  constructor(obj?: ResponseInterceptorOptions) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
