import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventTypes } from '@models/server-common';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { Conversation } from '@quorum/communicator/state/conversations';
import { EventsStateService } from '@quorum/communicator/state/events';
import { Event, TokenUser } from '@quorum/models/xs-resource';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'com-conversation-parts-request',
  templateUrl: './conversation-parts-request.component.html',
  styleUrls: ['./conversation-parts-request.component.css'],
})
export class ConversationPartsRequestComponent implements OnInit {
  @Input() conversation: Conversation;
  @Output() send: EventEmitter<Event> = new EventEmitter<Event>();

  currentUser: TokenUser;

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private eventsStateService: EventsStateService
  ) {}

  ngOnInit() {
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((authenticatedUser: AuthenticatedUser) => {
          this.currentUser = authenticatedUser.user;
        })
      )
      .subscribe();
  }

  gotIt() {
    const event: Event = this.eventsStateService.createEvent(
      `I have this one!`,
      this.conversation.id,
      EventTypes.PartsRequestGotIt,
      this.currentUser
    );
    this.send.emit(event);
  }

  isOrdered() {
    const event: Event = this.eventsStateService.createEvent(
      `Parts have been ordered!`,
      this.conversation.id,
      EventTypes.PartsRequestOrdered,
      this.currentUser
    );
    this.send.emit(event);
  }

  isReady() {
    const event: Event = this.eventsStateService.createEvent(
      `The parts are ready!`,
      this.conversation.id,
      EventTypes.PartsRequestIsReady,
      this.currentUser
    );
    this.send.emit(event);
  }
}
