import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Employee, Store } from '@quorum/models/xs-resource';

export interface TokenUser {
  apiKey: string;
  avatarUrl: string;
  destination: string;
  email: string;
  errorMessage: string;
  federatedStores: Store[];
  firstName: string;
  id: number;
  isActive: boolean;
  isActiveDirectoryLogin: boolean;
  isAdministrator: boolean;
  isQuorumUser: boolean;
  lastName: string;
  storeId: number;
  storeName: string;
  terminalIdentifier: string;
  username: string;
  xselleratorDatabaseName: string;
  xselleratorDatabaseServer: string;
  xselleratorEmployeeId: string;
  version: string;
}

export interface AuthenticatedUser {
  user: TokenUser;
  token: string;
}

export enum AuthenticationStatus {
  pending = 'PENDING',
  inProgress = 'INPROGRESS',
  completed = 'COMPLETED',
  optional = 'OPTIONAL',
}

export interface AuthenticationStages {
  id: number;
  task: string;
  authenticationStatus: AuthenticationStatus;
}

export interface AuthenticationErrors {
  message: string;
  error: string;
}

export const authenticationStagesAdapter: EntityAdapter<AuthenticationStages> =
  createEntityAdapter<AuthenticationStages>({
    selectId: (authStages: AuthenticationStages) => authStages.id,
  });

export interface AuthenticationStagesState extends EntityState<AuthenticationStages> {}

export interface AuthenticationState {
  readonly authenticatedUser: AuthenticatedUser;
  readonly authenticated: boolean;
  readonly authenticating: boolean;
  readonly errors: AuthenticationErrors;
  readonly authenticationStages: AuthenticationStagesState;
  readonly employee: Employee;
  readonly quorumApiKey: string;
  readonly quorumDestinationId: string;
}

export const selectAuthentication = createFeatureSelector<AuthenticationState>('authentication');
export const selectAuthenticationErrors = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.errors
);
export const selectAuthenticationIsAuthenticated = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.authenticated
);
export const selectAuthenticationIsAuthenticating = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.authenticating
);

export const selectAuthenticationAuthenticatedUser = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.authenticatedUser
);

export const selectAuthenticationAuthenticatedUserUser = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.authenticatedUser.user
);

export const initialAuthenticationStagesState: AuthenticationStagesState = authenticationStagesAdapter.getInitialState(
  {}
);
export const selectAuthenticationAuthenticationStages = createSelector(
  selectAuthentication,
  (state: any) => state.authenticationStages
);

export const selectAuthenticationAuthenticationStageInProgress = createSelector(
  selectAuthenticationAuthenticationStages,
  (state: any) => state
);

export const { selectIds, selectEntities, selectAll, selectTotal } = authenticationStagesAdapter.getSelectors(
  selectAuthenticationAuthenticationStages
);

export const selectAuthenticationEmployee = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.employee
);

export const selectQuorumApiKey = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.quorumApiKey
);

export const selectQuorumDestinationId = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.quorumDestinationId
);
