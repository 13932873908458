import { createAction, props } from '@ngrx/store';
import { PowerLaneAppAnalytics } from '@quorum/models/xs-misc';

export const SendAnalyticsToServer = createAction(
  '[PowerlaneAnalytics] Send Analytics To Server',
  props<{ analyticsEvent: PowerLaneAppAnalytics }>()
);

export const WorkOrderCreatedFromPowerLane = createAction(
  '[PowerlaneAnalytics] Work Order Created From Power Lane',
  props<{ workOrderId: number }>()
);

export const AppointmentCreatedFromPowerLane = createAction(
  '[PowerlaneAnalytics] Appointment Created From Power Lane',
  props<{ appointmentId: number }>()
);

export const VcrSentFromWorkOrder = createAction(
  '[PowerlaneAnalytics] VCR Sent From Work Order',
  props<{ workOrderId: number }>()
);

export const VcrSentWithMediaFromWorkOrder = createAction(
  '[PowerlaneAnalytics] VCR Sent with Media From Work Order',
  props<{ workOrderId: number }>()
);

export const MediaAddedToWorkOrderFromPowerLane = createAction(
  '[PowerlaneAnalytics] Media Added To Work Order From Power Lane',
  props<{ workOrderId: number; mediaAddedFromLocation: string; fileType: string; videoDuration?: number }>()
);

export const AnalyticsError = createAction('[PowerlaneAnalytics] Analytics Error', props<{ error: string }>());
