import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { ContactSelectDialogComponent } from '@quorum/communicator/ui/ng/contacts';
import { ContactPresence } from '@quorum/models/xs-misc';
import { AssociateQueryParameters, AssociateReportQueryParameters } from '@quorum/models/xs-query';
import {
  Address,
  Associate,
  AssociateReport,
  CommunicatorContact as Contact,
  Member,
} from '@quorum/models/xs-resource';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'com-new-conversation-header',
  templateUrl: './new-conversation-header.component.html',
  styleUrls: ['./new-conversation-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewConversationHeaderComponent implements OnInit, OnDestroy {
  @Input() preselectedCustomerId: string;
  contacts$: Subscription;
  contacts: Array<Contact> = [];
  contactsPresence$: Subscription;
  contactsPresence: { [key: number]: ContactPresence } = {};
  contactCtrl = new FormControl();
  filteredContacts$: Observable<void | any>;
  selectedMembers$: Observable<Member[]>;
  selectedMembers: Array<Member>;
  selectable: boolean = false;
  removable: boolean = true;
  separatorKeysCodes = [ENTER];
  editorDisabled: boolean = false;
  customerDisabled: boolean = false;
  autoCompleteSelectedTab: string = 'contacts';
  @ViewChild('contactInput') contactInput: any;

  constructor(
    private apiService: ApiService,
    private changeDetectionRef: ChangeDetectorRef,
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService,
    private dialog: MatDialog,
    private renderer2: Renderer2
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer2.selectRootElement('#contactSelectorInput').focus();
    }, 10);
  }

  ngOnInit(): void {
    this.filteredContacts$ = this.contactCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter((contactName) => typeof contactName === 'string' && contactName.length > 2),
      switchMap((contactName: string) => {
        let params: AssociateReportQueryParameters = new AssociateReportQueryParameters({
          search: contactName,
        });

        return combineLatest([
          this.apiService.get<AssociateReport[]>('v/1/reporting/associates', { params: params }).pipe(
            startWith([]),
            map((associates: AssociateReport[]) =>
              associates.map((associate) => {
                return {
                  associate: associate,
                  contact: new Contact({
                    firstName: associate.firstName ? associate.firstName : associate.fullName,
                    lastName: associate.firstName ? associate.lastName : '',
                    id: associate.id,
                    avatarUrl: null,
                    nickname: null,
                    isDealershipUser: false,
                  }),
                };
              })
            )
          ),
          of(this.filter(contactName, this.contacts, this.selectedMembers)),
        ]);
      }),
      map(([associateContacts, employeeContacts]) => {
        return {
          employeeContacts: employeeContacts,
          customerContacts: associateContacts,
        };
      })
    );

    this.contacts$ = this.contactsStateService.selectContacts().subscribe((contacts) => (this.contacts = contacts));
    this.contactsPresence$ = this.contactsStateService
      .selectPresences()
      .subscribe((presences) => (this.contactsPresence = presences));

    this.selectedMembers$ = this.conversationStateService
      .selectNewConversationMembers()
      .pipe(tap((members) => (this.selectedMembers = members)));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preselectedCustomerId && changes.preselectedCustomerId.currentValue) {
      let params: AssociateQueryParameters = new AssociateQueryParameters({
        embed: ['addresses'],
      });

      this.apiService
        .get<AssociateReport[]>(`v/1/associates/associates/${changes.preselectedCustomerId.currentValue}`, {
          params: params,
        })
        .pipe(
          map(
            (associate: Associate) =>
              new Member({
                userId: associate.id,
                avatarUrl: null,
                firstName: associate.firstName,
                lastName: associate.lastName,
                nickname: null,
                isEmployee: false,
                embedded: {
                  associate: {
                    ...new Associate(),
                    messagePreferenceId: associate.messagePreferenceId,
                    emailAddress: associate.emailAddress,
                  },
                  addresses: [{ ...new Address(), cellPhoneNumber: associate.embedded?.addresses[0]?.cellPhoneNumber }],
                },
              })
          )
        )
        .subscribe((member) => {
          this.conversationStateService.addMemberToNewConversation(member);
          this.changeDetectionRef.detectChanges();
        });
    }
  }

  ngOnDestroy() {
    this.contacts$.unsubscribe();
    this.contactsPresence$.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    if (event.input && typeof event.value === 'object') {
      // event.input.value = '';
      // this.conversationStateService.addMemberToNewConversation(member);
    }
  }

  remove(contact: Member): void {
    this.conversationStateService.removeMemberFromNewConversation(contact);
    this.disableWhenCustomerSelected(this.selectedMembers);
  }

  filter(contactName: string, contacts: Contact[], selectedMembers: Member[]) {
    return contacts.filter((contact: Contact) => {
      return (
        (contact.firstName + ' ' + contact.lastName).toLowerCase().indexOf(contactName.toLowerCase()) > -1 &&
        !selectedMembers.some(function (selectedContact) {
          return selectedContact.id === contact.id;
        })
      );
    });
  }

  selected(event: MatAutocompleteSelectedEvent, selectedMembers: Member[]): void {
    this.contactInput.nativeElement.value = '';
    if (!selectedMembers || !selectedMembers.find((member) => member.userId == event.option.value.id)) {
      let member: Member;

      if (event.option.value.associate) {
        const associate: AssociateReport = event.option.value.associate;

        member = new Member({
          userId: associate.id,
          avatarUrl: null,
          firstName: associate.firstName,
          lastName: associate.lastName,
          nickname: null,
          isEmployee: false,
          embedded: {
            associate: {
              ...new Associate(),
              messagePreferenceId: associate.messagePreferenceId,
              emailAddress: associate.emailAddress,
            },
            addresses: [{ ...new Address(), cellPhoneNumber: event.option.value.associate.cellPhoneNumber }],
          },
        });
      } else {
        const associate: AssociateReport = event.option.value;

        member = new Member({
          userId: associate.id,
          avatarUrl: null,
          firstName: associate.firstName,
          lastName: associate.lastName,
          nickname: null,
          isEmployee: true,
        });
      }

      this.conversationStateService.addMemberToNewConversation(member);
      this.disableWhenCustomerSelected(this.selectedMembers);
      this.disableWhenEmployeeSelected();
    }
  }

  disableWhenCustomerSelected(selectedMembers: Member[]) {
    const customerMember = selectedMembers.find((selectedMember) => !selectedMember.isEmployee);
    this.editorDisabled = customerMember ? true : false;
  }

  disableWhenEmployeeSelected() {
    this.selectedMembers$.subscribe((members) => {
      const employeeMember = members.find((selectedMember) => selectedMember.isEmployee);
      this.customerDisabled = employeeMember ? true : false;
    });
  }

  openContactSelectionDialog(selectedMembers: Member[]): void {
    let preselectedContacts: Contact[] = [];
    this.contacts.map((contact) => {
      if (selectedMembers.find((selectedMember) => selectedMember.userId === contact.id.toString()))
        preselectedContacts.push(contact);
    });

    const dialogRef = this.dialog.open(ContactSelectDialogComponent, {
      width: '600px',
      data: { preselectedContacts: preselectedContacts, title: 'Add Contacts' },
    });

    dialogRef.afterClosed().subscribe((contacts: Contact[]) => {
      if (contacts) {
        contacts.forEach((contact: Contact, index: number) => {
          if (!selectedMembers.find((member) => member.userId == contact.id.toString())) {
            this.conversationStateService.addMemberToNewConversation(
              new Member({
                userId: contact.id.toString(),
                avatarUrl: contact.avatarUrl,
                firstName: contact.firstName,
                lastName: contact.lastName,
                nickname: contact.nickName,
                isEmployee: contact.isDealershipUser,
              })
            );
          }
        });
      }
    });
  }

  contactTrackByFunction(index: number, contact: Contact) {
    return contact ? contact.id : null;
  }
}
