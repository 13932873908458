<mat-toolbar *ngIf="authenticatedUser$ | async as authenticatedUser">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button class="secondary-text" (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <span fxFlex class="toolbar-title">Communicator</span>
    <div id="noticeable-icon"></div>
    <button id="com-options-menu-button" mat-icon-button [matMenuTriggerFor]="matOptionsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #matOptionsMenu="matMenu">
      <button id="com-contacts-button" mat-menu-item (click)="navigateMaster('contacts')">
        <span>Contacts</span>
      </button>
      <button id="com-groups-button" mat-menu-item (click)="navigateMaster('groups')">
        <span>Groups</span>
      </button>
      <mat-divider></mat-divider>
      <button id="com-archive-messages-button" mat-menu-item (click)="archiveMessages()">
        <span>Archive Messages</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>