import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataLayerService, ShaServicesModule } from '@applications/sha-services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '@quorum/environments';
import { CookieService } from 'ngx-cookie-service';
//import { ApiModule, ApiService } from '@quorum/api';
import { ApiModule } from '../../../api/src/lib/api.module';
import { AuthenticatedUserEffects } from './+state/authentication.effects';
import { authenticatedUserReducer, metaReducers } from './+state/authentication.reducer';
import { AuthenticationStateService } from './authentication-state.service';
import { AuthenticationGuard } from './authentication.guard';
import { EmployeeAuthenticationGuard } from './employee-authentication.guard';

@NgModule({
  imports: [
    ApiModule.withConfig({
      apiBase: environment.apiBase,
      legacyApiBase: environment.legacyApiBase,
    }),
    CommonModule,
    StoreModule.forFeature('authentication', authenticatedUserReducer, {
      metaReducers: metaReducers,
    }),
    EffectsModule.forFeature([AuthenticatedUserEffects]),
    ShaServicesModule,
  ],
  providers: [
    AuthenticatedUserEffects,
    AuthenticationGuard,
    AuthenticationStateService,
    CookieService,
    DataLayerService,
    EmployeeAuthenticationGuard,
  ],
})
export class AuthenticationModule {}
