<div fxLayout="column" fxFlexFill>
  <div fxFlexFill>
    <div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-button color="primary" (click)="new()">New</button>
        <button mat-button color="primary" (click)="edit(isDisabled)">{{ isDisabled ? 'Edit' : 'Save' }}</button>
      </div>

      <mat-list fxLayout="column" fxLayoutAlign="center stretch">
        <ng-container
          *ngFor="
            let templateGroup of templatesEditable | orderBy: '-templateGroup[0]' | groupBy: 'departmentId' | pairs;
            trackBy: trackByFn
          "
        >
          <h3 fxLayout="row" fxLayoutAlign="start center" mat-subheader>
            <span>{{ templateGroup[0] === 'null' ? 'My Templates' : 'Departmental Templates' }}</span>
          </h3>
          <ng-container
            *ngFor="let template of templateGroup[1] | orderBy: 'templateName'; index as i; trackBy: trackByTemplateFn"
          >
            <mat-list-item fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
                <form fxFill fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-form-field>
                    <input [attr.disabled]="!isDisabled" matInput value="{{ template.templateName }}" />
                  </mat-form-field>

                  <mat-form-field>
                    <textarea
                      matInput
                      readonly="true"
                      cdkTextareaAutosize
                      #message="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="5"
                      >{{ template.message }}</textarea
                    >
                  </mat-form-field>

                  <button mat-icon-button type="button" (click)="delete(template)">
                    <mat-icon matListIcon>delete</mat-icon>
                  </button>
                </form>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </mat-list>
    </div>
  </div>
</div>
