import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { CommunicatorContact as Contact } from '@quorum/models/xs-resource';
import { from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import * as fromContacts from './contacts.actions';
import { ContactsState } from './contacts.interfaces';

@Injectable()
export class ContactsEffects {
  
  loadContacts = createEffect(() => this.d.fetch(fromContacts.LOAD_CONTACTS, {
    run: (c: fromContacts.LoadContacts, state: ContactsState) => {
      return this.apiService.get<Contact[]>('v/1/quorum/contacts', { params: c.payload }).pipe(
        flatMap((contacts: Contact[]) => {
          return [new fromContacts.LoadContactsSuccess(contacts)];
        })
      );
    },
    onError: (action: fromContacts.LoadContacts, err) => {
      console.log(err);
      return from([new fromContacts.LoadContactsFailure(err)]);
    },
  }));

  constructor(private actions: Actions, private d: DataPersistence<ContactsState>, private apiService: ApiService) {}
}
