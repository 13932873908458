import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { Conversation } from '@quorum/models/xs-resource';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'com-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.css'],
})
export class ConversationHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() transactionDescription: string;
  ui$: Observable<any>;
  conversation$: Observable<Conversation>;
  paramsSubscription$: Subscription;
  constructor(private conversationStateService: ConversationStateService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.paramsSubscription$ = this.route.params.subscribe((params) => {
      //this.conversationStateService.getConversation(+params['id']);
      this.conversation$ = this.conversationStateService.selectConversation(+params['id']);
    });
    this.ui$ = this.conversationStateService.selectUiState();
  }

  toggleConversationInfoPanel(visible: boolean) {
    this.conversationStateService.toggleConversationInfoPanel(visible);
  }

  ngOnDestroy() {
    this.paramsSubscription$.unsubscribe();
  }
}
