import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiService, API_BASE, LEGACY_API_BASE, LEGACY_AUTH_BASE } from './api.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [ApiService],
})
export class ApiModule {
  static withConfig(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: API_BASE,
          useValue: options.apiBase,
        },
        {
          provide: LEGACY_API_BASE,
          useValue: options.legacyApiBase,
        },
        {
          provide: LEGACY_AUTH_BASE,
          useValue: options.legacyAuthBase,
        },
      ],
    };
  }
}

export interface ApiModuleOptions {
  apiBase: string;
  legacyApiBase: string;
  legacyAuthBase?: string;
}
