<ng-container *ngIf="avatarUrl; else multipleAvatars">
  <div class="avatar-container" [ngClass]="size" fxLayoutAlign="center center">
    <div class="dank image-container" [ngStyle]="{'background-image': 'url(' + avatarUrl + ')'}"></div>
    <div *ngIf="displayPresence" class="active-indicator"
      [ngClass]="(!presence) ? 'presence-offline' : (presence.idleTimeUtc > 0 ? 'presence-idle' : 'presence-active')">
    </div>
  </div>
</ng-container>

<ng-template #multipleAvatars>
  <ng-container *ngIf="avatarUrls?.length > 0; else initialsAvatar">
    <div class="avatar-container" [ngClass]="size" fxLayoutAlign="center center">
      <div class="dank image-container" [ngStyle]="{'background-image': 'url(' + avatarUrls[0] + ')'}"></div>
      <div *ngIf="displayPresence" class="active-indicator"
        [ngClass]="(!presence) ? 'presence-offline' : (presence.idleTimeUtc > 0 ? 'presence-idle' : 'presence-active')">
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #initialsAvatar>
  <div *ngIf="initials?.length > 0; else defaultAvatar" [ngClass]="size" class="avatar-container initials-circle"
    fxLayoutAlign="center center">
    <span fxFlexAlign="center" class="initials" [ngClass]="size">{{initials[0].toUpperCase()}}</span>
    <div *ngIf="displayPresence" class="active-indicator"
      [ngClass]="(!presence) ? 'presence-offline' : (presence.idleTimeUtc > 0 ? 'presence-idle' : 'presence-active')">
    </div>
  </div>
</ng-template>

<ng-template #defaultAvatar>
  <div class="avatar-container" fxLayoutAlign="center center">
    <mat-icon fxFlexAlign="center">person</mat-icon>
  </div>
</ng-template>