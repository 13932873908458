import { Injectable } from '@angular/core';
import { ApiService } from '@quorum/api';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { GmSltData } from '@quorum/models/xs-misc';
import { Media } from '@quorum/models/xs-resource';
import { MediaStateService } from '@quorum/sha-media';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GmSltService {
  constructor(private apiService: ApiService, private mediaStateService: MediaStateService) {}

  defaultGmSltData: GmSltData = {
    roData: {
      mobileDeviceCheckIn: {
        wasMobileCheckIn: null,
        mobileCheckInDate: null,
      },
      serviceVisitTracking: null,
      electronicEstimate: {
        wasEstimateSent: 'N',
        methodOfEstimateSent: null,
      },
      media: {
        mediaUploaded: 'N',
        numberOfVideos: '0',
        numberOfPhotos: '0',
      },
      postBooklet: {
        postBookletPresented: 'N',
        methodOfPostBookletSent: null,
      },
    },
    menuData: [],
    asrData: [],
    workOrderId: null,
    createdTimestamp: null,
  };

  getGmSltByWorkOrderId(destinationId: string, workOrderId: string): Observable<GmSltData> {
    return this.apiService
      .get(`v/1/fixed-operations/gm-slt/${destinationId}/${workOrderId}`)
      .pipe(map((gmSltData) => gmSltData[0]));
  }

  createGmSltRecord(workOrderId: number): Observable<GmSltData> {
    const isTouchscreen = this.isTouchscreenDevice();
    const updatedGmSltData = {
      ...this.defaultGmSltData,
      roData: {
        ...this.defaultGmSltData.roData,
        mobileDeviceCheckIn: {
          wasMobileCheckIn: isTouchscreen ? 'Y' : 'N',
          mobileCheckInDate: isTouchscreen ? new Date() : null,
        },
      },
      workOrderId: workOrderId,
    };

    return this.apiService.post(`v/1/fixed-operations/gm-slt`, updatedGmSltData);
  }

  /**
   * Builds/updates a GM SLT data object each time media is uploaded to a warning/failed inspection task.
   * If a task already has an asrData record it will be updated accordingly, if it does not, one will be created.
   * This ensures each failed/warning inspection task has an accurate asrData line on the GM SLT record.
   *
   * @param gmSlt Existing GM SLT Data record
   * @param file File that was uploaded
   * @returns A new GM SLT Data object to be used to update the GM SLT record
   */
  buildUpdatedGmSltRecordForMediaUpload(gmSlt: GmSltData, file: Media, params: any): GmSltData {
    const updatedGmSlt: GmSltData = new DeepCopyPipe().transform(gmSlt);

    const existingAsrDataIndex = updatedGmSlt.asrData.findIndex(
      (asrData) => asrData.media.inspectionTaskId === params?.gmInspectionId
    );

    let updatedAsrDataRecord;
    if (existingAsrDataIndex !== -1) {
      updatedGmSlt.asrData[existingAsrDataIndex] = {
        media: {
          ...updatedGmSlt.asrData[existingAsrDataIndex].media,
          mediaUploaded: 'Y',
          numberOfPhotos:
            file.mediaType === 'image'
              ? (parseInt(updatedGmSlt.asrData[existingAsrDataIndex].media.numberOfPhotos) + 1).toString()
              : parseInt(updatedGmSlt.asrData[existingAsrDataIndex].media.numberOfPhotos).toString(),
          numberOfVideos:
            file.mediaType === 'video'
              ? (parseInt(updatedGmSlt.asrData[existingAsrDataIndex].media.numberOfVideos) + 1).toString()
              : parseInt(updatedGmSlt.asrData[existingAsrDataIndex].media.numberOfVideos).toString(),
        },
      };
    } else {
      updatedAsrDataRecord = {
        media: {
          inspectionTaskId: params?.gmInspectionId,
          vehicleInspectionId: file.tags.inspectionTaskId.toString(),
          mediaUploaded: 'Y',
          numberOfPhotos: file.mediaType === 'image' ? '1' : '0',
          numberOfVideos: file.mediaType === 'video' ? '1' : '0',
        },
      };
      updatedGmSlt.asrData.push(updatedAsrDataRecord);
    }
    updatedGmSlt.roData.media.mediaUploaded = 'Y';
    updatedGmSlt.roData.media.numberOfPhotos =
      file.mediaType === 'image'
        ? updatedGmSlt.roData.media.numberOfPhotos
          ? (parseInt(updatedGmSlt.roData.media.numberOfPhotos) + 1).toString()
          : '1'
        : updatedGmSlt.roData.media.numberOfPhotos
        ? parseInt(updatedGmSlt.roData.media.numberOfPhotos).toString()
        : '0';
    updatedGmSlt.roData.media.numberOfVideos =
      file.mediaType === 'video'
        ? updatedGmSlt.roData.media.numberOfVideos
          ? (parseInt(updatedGmSlt.roData.media.numberOfVideos) + 1).toString()
          : '1'
        : updatedGmSlt.roData.media.numberOfVideos
        ? parseInt(updatedGmSlt.roData.media.numberOfVideos).toString()
        : '0';

    return updatedGmSlt;
  }

  /**
   * Builds/updates a GM SLT data object by checking the current GM SLT record and comparing it with the inspection data.
   * If a task already has an asrData record it will be updated accordingly, if it does not, one will be created.
   * This ensures each failed/warning inspection task has an accurate asrData line on the GM SLT record.
   *
   * @param gmSlt Existing GM SLT Data record
   * @param inspection Current inspection object
   * @returns A new GM SLT Data object to be used to update the GM SLT record
   */
  buildUpdatedGmSltRecordForInspectionSave(gmSlt: GmSltData, inspection: any) {
    const updatedGmSlt: GmSltData = new DeepCopyPipe().transform(gmSlt);
    this.mediaStateService
      .getAllInspectionTaskMedia(inspection.inspectionTypeId, inspection.workOrderId)
      .pipe(take(1))
      .subscribe((taskMedia) => {
        let concatCategoryArray = inspection.categories;

        if (inspection.tires) {
          concatCategoryArray = concatCategoryArray.concat(inspection.tires);
        }

        if (inspection.tireOther) {
          concatCategoryArray = concatCategoryArray.concat(inspection.tireOther);
        }

        if (inspection.tireOtherCategories) {
          concatCategoryArray = concatCategoryArray.concat(inspection.tireOtherCategories);
        }

        if (inspection.tireOtherGm) {
          concatCategoryArray = concatCategoryArray.concat(inspection.tireOtherGm);
        }

        if (inspection.windshieldCracksChips) {
          concatCategoryArray = concatCategoryArray.concat(inspection.windshieldCracksChips);
        }

        let concatTaskArray: any[] = [];
        concatCategoryArray.forEach((category: any) => {
          concatTaskArray = concatTaskArray.concat(category.tasks);
        });

        const warnFailFilteredTaskArray = concatTaskArray
          .filter((task) => task.redBox || task.yellowBox)
          .map((task) => {
            return { inspectionTaskId: task.gmInspectionId, vehicleInspectionId: task.taskId };
          });

        warnFailFilteredTaskArray.forEach((taskId) => {
          const asrDataForTask = updatedGmSlt.asrData.find(
            (asrData) => asrData.media.inspectionTaskId === taskId.inspectionTaskId.toString()
          );

          if (!asrDataForTask) {
            let updatedAsrDataRecord;
            const mediaForTaskId = taskMedia.filter(
              (media) => media.tags.inspectionTaskId === taskId.vehicleInspectionId
            );

            if (mediaForTaskId && mediaForTaskId.length > 0) {
              updatedAsrDataRecord = {
                media: {
                  inspectionTaskId: taskId.inspectionTaskId.toString(),
                  vehicleInspectionId: taskId.vehicleInspectionId.toString(),
                  mediaUploaded: 'Y',
                  numberOfPhotos: mediaForTaskId
                    .filter((taskMedia) => taskMedia.mediaType === 'image')
                    .length.toString(),
                  numberOfVideos: mediaForTaskId
                    .filter((taskMedia) => taskMedia.mediaType === 'video')
                    .length.toString(),
                },
              };

              updatedGmSlt.roData.media.mediaUploaded = 'Y';
              updatedGmSlt.roData.media.numberOfPhotos = updatedGmSlt.roData.media.numberOfPhotos
                ? (
                    parseInt(updatedGmSlt.roData.media.numberOfPhotos) +
                    mediaForTaskId.filter((taskMedia) => taskMedia.mediaType === 'image').length
                  ).toString()
                : mediaForTaskId.filter((taskMedia) => taskMedia.mediaType === 'image').length.toString();
              updatedGmSlt.roData.media.numberOfVideos = updatedGmSlt.roData.media.numberOfVideos
                ? (
                    parseInt(updatedGmSlt.roData.media.numberOfVideos) +
                    mediaForTaskId.filter((taskMedia) => taskMedia.mediaType === 'video').length
                  ).toString()
                : mediaForTaskId.filter((taskMedia) => taskMedia.mediaType === 'video').length.toString();
            } else {
              updatedAsrDataRecord = {
                media: {
                  inspectionTaskId: taskId.inspectionTaskId.toString(),
                  vehicleInspectionId: taskId.vehicleInspectionId.toString(),
                  mediaUploaded: 'N',
                  numberOfPhotos: '0',
                  numberOfVideos: '0',
                },
              };

              updatedGmSlt.roData.media.mediaUploaded = updatedGmSlt.roData.media.mediaUploaded
                ? updatedGmSlt.roData.media.mediaUploaded
                : 'N';
              updatedGmSlt.roData.media.numberOfPhotos = updatedGmSlt.roData.media.numberOfPhotos
                ? updatedGmSlt.roData.media.numberOfPhotos
                : '0';
              updatedGmSlt.roData.media.numberOfVideos = updatedGmSlt.roData.media.numberOfVideos
                ? updatedGmSlt.roData.media.numberOfVideos
                : '0';
            }

            updatedGmSlt.asrData.push(updatedAsrDataRecord);
          }
        });
      });
    return updatedGmSlt;
  }

  updateGmSltRecord(gmSlt: GmSltData): Observable<GmSltData> {
    return this.apiService.put(`v/1/fixed-operations/gm-slt/${gmSlt.SK}`, gmSlt);
  }

  /**
   * Utility function to determine if the users device is touchscreen (tablet/mobile) as recommended
   * by MDN web docs (https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent)
   */
  isTouchscreenDevice() {
    let isTouchscreen = false;

    if ('maxTouchPoints' in navigator) {
      isTouchscreen = navigator.maxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        isTouchscreen = !!mQ.matches;
      } else if ('orientation' in window) {
        isTouchscreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        isTouchscreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return isTouchscreen;
  }
}
