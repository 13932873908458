export class MessageTransactionMessagePreference {
  id?: number = null;
  messagePreferenceContactInfo: string = null;
  messagePreferenceId: number = null;
  transactionId: string = null;
  transactionTypeId: number = null;

  constructor(obj?: MessageTransactionMessagePreference) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
