export class DirectMessageTemplate {
  departmentId: string;
  employeeId: string;
  id?: number;
  message: string;
  templateName: string;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}
