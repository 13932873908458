import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';
import { PowerlaneAnalyticsEntity } from '@quorum/models/xs-misc';

export const POWERLANEANALYTICS_FEATURE_KEY = 'powerlaneAnalytics';

export interface State extends EntityState<PowerlaneAnalyticsEntity> {
  selectedId?: string | number; // which PowerlaneAnalytics record has been selected
  loaded: boolean; // has the PowerlaneAnalytics list been loaded
  error?: string | null; // last known error (if any)
}

export interface PowerlaneAnalyticsPartialState {
  readonly [POWERLANEANALYTICS_FEATURE_KEY]: State;
}

export const powerlaneAnalyticsAdapter: EntityAdapter<PowerlaneAnalyticsEntity> =
  createEntityAdapter<PowerlaneAnalyticsEntity>();

export const initialState: State = powerlaneAnalyticsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const powerlaneAnalyticsReducer = createReducer(initialState);

export function reducer(state: State | undefined, action: Action) {
  return powerlaneAnalyticsReducer(state, action);
}
