<mat-nav-list>
  <h3 fxLayout="row" fxLayoutAlign="start center" mat-subheader>
    <span>Files</span>
  </h3>
  <a mat-list-item [href]="file.url" target="_blank" *ngFor="let file of filesService.allFiles$ | async">
    <div mat-list-icon class="save-icon-container flex flex-row place-content-center place-items-center">
      <mat-icon class="save-icon">save_alt</mat-icon>
    </div>
    <h4 mat-line class="primary-text">{{ file.url.substring(file.url.lastIndexOf('/') + 1) }}</h4>
    <!-- <p mat-line class="secondary-text">Click to download</p> -->
  </a>
</mat-nav-list>
