import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { CommunicatorUiNgContactsModule } from '@quorum/communicator/ui/ng/contacts';
import { CommunicatorUiNgConversationsModule } from '@quorum/communicator/ui/ng/conversations';
import { CommunicatorUiNgSearchModule } from '@quorum/communicator/ui/ng/search';
import { ComSocketsModule } from '@quorum/com-sockets';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgPipesModule } from 'ngx-pipes';
import { HomeShellComponent } from './home-shell/home-shell.component';
import { MasterBodyComponent } from './master-body/master-body.component';
import { MasterHeaderComponent } from './master-header/master-header.component';
import { MasterComponent } from './master/master.component';
import { ShellComponent } from './shell/shell.component';
import { TransactionSummaryComponent } from './transaction-summary/transaction-summary.component';
import { CommunicatorUiNgEventsModule } from '@quorum/communicator/ui/ng/events';
@NgModule({
  imports: [
    CommonModule,
    CommonPipesModule,
    NgPipesModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
    CommonMaterialComponentsModule,
    CommunicatorUiNgConversationsModule,
    CommunicatorUiNgEventsModule,
    //CommunicatorUiNgContactsModule,
    CommunicatorUiNgSearchModule,
    ComSocketsModule,
    ShaComponentsNgUiModule,
    InfiniteScrollModule,
    MatSidenavModule,
  ],
  exports: [ShellComponent],
  declarations: [
    ShellComponent,
    MasterComponent,
    MasterHeaderComponent,
    MasterBodyComponent,
    HomeShellComponent,
    TransactionSummaryComponent,
  ],
})
export class CommunicatorUiNgRootModule {}
