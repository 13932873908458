import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { CommunicatorContact as Contact } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';

@Component({
  selector: 'com-contacts-shell',
  templateUrl: './contacts-shell.component.html',
  styleUrls: ['./contacts-shell.component.css'],
})
export class ContactsShellComponent implements OnInit {
  contacts$: Observable<Contact[]>;

  constructor(
    private route: ActivatedRoute,
    private contactsStateService: ContactsStateService,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.contacts$ = this.contactsStateService.selectContacts();
  }

  goBack() {
    this.routerStateService.go([{ outlets: { master: 'master' } }], { relativeTo: this.route.parent });
  }
}
