import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { SystemControlValue } from '@quorum/models/xs-resource';

export interface SystemControlState extends EntityState<SystemControlValue> {}

export const systemControlAdapter: EntityAdapter<SystemControlValue> = createEntityAdapter<SystemControlValue>({
  selectId: (systemControlValue: SystemControlValue) => {
    return `${systemControlValue.constantReference}-${systemControlValue.rooftopId}`;
  },
});

export const initialState: SystemControlState = systemControlAdapter.getInitialState({});
export const servicesGroupsFeatureSelector = createFeatureSelector<any>('systemControl');
export const { selectIds, selectEntities, selectAll, selectTotal } =
  systemControlAdapter.getSelectors(servicesGroupsFeatureSelector);
