<div class="container" fxLayout="column">
  <com-master-header></com-master-header>
  <div fxLayout="column" class="search-panel-container">
    <div class="search-input-container" fxLayout="row" fxLayoutAlign="center center">
      <input [(ngModel)]="searchString" fxFlex="92" class="search-input" placeholder="Search..." />
      <button *ngIf="searchString.length > 0" class="search-cancel-button" mat-icon-button class="example-fab"
        (click)="closeSearch()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
    <div *ngIf="searchString.length > 0" fxLayout="column" class="search-filters-container" fxLayoutGap="12px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span>Contacts</span>
        <mat-slide-toggle [checked]="searchContacts" [(ngModel)]="searchContacts"></mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span>Conversations</span>
        <mat-slide-toggle [checked]="searchConversations" [(ngModel)]="searchConversations" #item
          (change)="onSearchConversationsChange()"></mat-slide-toggle>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span>Include Archived Conversations</span>
        <mat-slide-toggle [checked]="searchArchivedConversations" [(ngModel)]="searchArchivedConversations" #item
          (change)="onSearchArchivedConversationsChange()"></mat-slide-toggle>
      </div>
    </div>
  </div>
  <com-master-body [hidden]="searchString.length"></com-master-body>
  <com-search-panel fxFlex [searchContacts]="searchContacts" [searchConversations]="searchConversations"
    [searchArchivedConversations]="searchArchivedConversations" [searchString]="searchString"
    [hidden]="!searchString.length" (onConversationSelected)="navigateToSelectedConversation($event)">
  </com-search-panel>
</div>
