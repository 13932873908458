import { Pipe, PipeTransform } from '@angular/core';
import { BrowserControlCommunicationService } from '@applications/sha-services';
@Pipe({
  name: 'codec',
})
export class CodecPipe implements PipeTransform {
  constructor(private browserControlCommunicationService?: BrowserControlCommunicationService) {}
  transform(value: string): unknown {
    let videoUrl: string;

    if (this.browserControlCommunicationService.embeddedInXsellerator) {
      videoUrl = value.substring(0, value.lastIndexOf('.')) + '.webm';
    } else {
      videoUrl = value.substring(0, value.lastIndexOf('.')) + '.mp4';
    }

    return videoUrl;
  }
}
