
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ConversationQueryParameters, DirectMessageTemplateQueryParameters, MessageTransactionMessagePreferenceQueryParameters } from '@quorum/models/xs-query';
import { DirectMessageTemplate, Event, Member, MessageType, MessageTransactionMessagePreference, Template,  } from '@quorum/models/xs-resource';
import { ConversationView } from '../conversation-view.enum';
import { Conversation } from '../conversation.model';

export const ADD_CONVERSATION_FROM_SOCKET_TO_STATE = '[CONVERSATIONS] Add Conversation From Socket To State';
export class AddConversationFromSocketToState implements Action {
  readonly type = ADD_CONVERSATION_FROM_SOCKET_TO_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversation: Conversation }) {}
}

export const ADD_CONVERSATION_TO_STATE = '[CONVERSATIONS] Add Conversation To State';
export class AddConversationToState implements Action {
  readonly type = ADD_CONVERSATION_TO_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversation: Conversation }) {}
}

export const ADD_MEMBER_TO_NEW_CONVERSATION_IN_STATE = '[CONVERSATIONS] Add New Member To New Conversation In State';
export class AddMemberToNewConversationInState implements Action {
  readonly type = ADD_MEMBER_TO_NEW_CONVERSATION_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const ADD_USER_IS_TYPING_TO_STATE = '[CONVERSATIONS] Add User Is Typing To State';
export class AddUserIsTypingToState implements Action {
  readonly type = ADD_USER_IS_TYPING_TO_STATE;
  readonly feature = 'conversations';
  constructor(public payload: Update<Conversation>) {}
}

export const ADD_UNSENT_MESSAGE_TO_STATE = '[CONVERSATIONS] Add Unsent Message To State';
export class AddUnsentMessageToState implements Action {
  readonly type = ADD_UNSENT_MESSAGE_TO_STATE;
  readonly feature = 'conversations';
  constructor(public payload: { conversationId: number; message: string }) {}
}

export const DELETE_CONVERSATION_FROM_STATE = '[CONVERSATIONS] Delete Conversation From State';
export class DeleteConversationFromState implements Action {
  readonly type = DELETE_CONVERSATION_FROM_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { id: number }) {}
}

export const DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER = '[CONVERSATIONS] Delete Direct Message Template From Server';
export class DeleteDirectMessageTemplateFromServer implements Action {
  readonly type = DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: DirectMessageTemplate) {}
}

export const DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_FAILURE =
  '[CONVERSATIONS] Delete Direct Message Template From Server Failure';
export class DeleteDirectMessageTemplateFromServerFailure implements Action {
  readonly type = DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: DirectMessageTemplate; error: any }) {}
}

export const DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_SUCCESS =
  '[CONVERSATIONS] Delete Direct Message Template From Server Success';
export class DeleteDirectMessageTemplateFromServerSuccess implements Action {
  readonly type = DELETE_DIRECT_MESSAGE_TEMPLATE_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: DirectMessageTemplate) {}
}

export const DELETE_MEMBER_FROM_NEW_CONVERSATION_IN_STATE =
  '[CONVERSATIONS] Delete Member From New Conversation In State';
export class DeleteMemberFromNewConversationInState implements Action {
  readonly type = DELETE_MEMBER_FROM_NEW_CONVERSATION_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Member) {}
}

export const DELETE_UNSENT_MESSAGE_FROM_STATE = '[CONVERSATIONS] Delete Unsent Message From State';
export class DeleteUnsentMessageFromState implements Action {
  readonly type = DELETE_UNSENT_MESSAGE_FROM_STATE;
  readonly feature = 'conversations';
  constructor(public payload: { conversationId: number }) {}
}

export const DELETE_USER_IS_TYPING_IN_STATE = '[CONVERSATIONS] Delete User Is Typing In State';
export class DeleteUserIsTypingInState implements Action {
  readonly type = DELETE_USER_IS_TYPING_IN_STATE;
  readonly feature = 'conversations';
  constructor(public payload: Update<Conversation>) {}
}

export const GET_CONVERSATION_FROM_STATE_SERVER = '[CONVERSATIONS] Get Conversation From State Then Server';
export class GetConversationFromStateServer implements Action {
  readonly type = GET_CONVERSATION_FROM_STATE_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: number) {}
}

export const GET_CONVERSATION_FROM_STATE_SERVER_FAILURE =
  '[CONVERSATIONS]  Get Conversation From State Then Server Failure';
export class GetConversationFromStateServerFailure implements Action {
  readonly type = GET_CONVERSATION_FROM_STATE_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { conversationId: number; error: any }) {}
}

export const GET_CONVERSATIONS_FROM_SERVER = '[CONVERSATIONS] Get Conversations From Server';
export class GetConversationsFromServer implements Action {
  readonly type = GET_CONVERSATIONS_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: ConversationQueryParameters; displayNotification?: boolean }) {}
}

export const GET_CONVERSATIONS_FROM_SERVER_FAILURE = '[CONVERSATIONS] Get Conversations From Server Failure';
export class GetConversationsFromServerFailure implements Action {
  readonly type = GET_CONVERSATIONS_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: ConversationQueryParameters; error: any }) {}
}

export const GET_CONVERSATIONS_FROM_SERVER_SUCCESS = '[CONVERSATIONS] Get Conversations From Server Success';
export class GetConversationsFromServerSuccess implements Action {
  readonly type = GET_CONVERSATIONS_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Conversation[]) {}
}

export const GET_CONVERSATIONS_TO_REMOVE_FROM_STATE = '[CONVERSATIONS] Get Conversations To Remove From State';
export class GetConversationsToRemoveFromState implements Action {
  readonly type = GET_CONVERSATIONS_TO_REMOVE_FROM_STATE;
  readonly feature = 'conversations';
  constructor(public payload: ConversationQueryParameters) {}
}

export const GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_SUCCESS =
  '[CONVERSATIONS] Get Conversations To Remove From State Success';
export class GetConversationsToRemoveFromStateSuccess implements Action {
  readonly type = GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_SUCCESS;
  readonly feature = 'conversations';
  constructor(public payload: { conversations: Conversation[] }) {}
}

export const GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_FAILURE =
  '[CONVERSATIONS] Get Conversations To Remove From State Failure';
export class GetConversationsToRemoveFromStateFailure implements Action {
  readonly type = GET_CONVERSATIONS_TO_REMOVE_FROM_STATE_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: ConversationQueryParameters; error: any }) {}
}

export const GET_NEW_CONVERSATION_FROM_SERVER = '[CONVERSATIONS] Get New Conversation From Server';
export class GetNewConversationFromServer implements Action {
  readonly type = GET_NEW_CONVERSATION_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: ConversationQueryParameters) {}
}

export const GET_NEW_CONVERSATION_FROM_SERVER_SUCCESS = '[CONVERSATIONS] Get New Conversation From Server Success';
export class GetNewConversationFromServerSuccess implements Action {
  readonly type = GET_NEW_CONVERSATION_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  constructor(public payload: Conversation) {}
}

export const GET_NEW_CONVERSATION_FROM_SERVER_FAILURE = '[CONVERSATIONS] Get New Conversation From Server Failure';
export class GetNewConversationFromServerFailure implements Action {
  readonly type = GET_NEW_CONVERSATION_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: ConversationQueryParameters; error: any }) {}
}

export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER = '[CONVERSATIONS] Get Direct Message Templates From Server';
export class GetDirectMessageTemplatesFromServer implements Action {
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: DirectMessageTemplateQueryParameters) {}
}

export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_FAILURE =
  '[CONVERSATIONS] Get Direct Message Templates From Server Failure';
export class GetDirectMessageTemplatesFromServerFailure implements Action {
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { queryParameters: DirectMessageTemplateQueryParameters; error: any }) {}
}

export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS =
  '[CONVERSATIONS] Get Direct Message Templates From Server Success';
export class GetDirectMessageTemplatesFromServerSuccess implements Action {
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: DirectMessageTemplate[]) {}
}

export const GET_MESSAGE_TYPES_FROM_SERVER = '[CONVERSATIONS] Get Message Types From Server';
export class GetMessageTypesFromServer implements Action {
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: any) {}
}

export const GET_MESSAGE_TYPES_FROM_SERVER_FAILURE = '[CONVERSATIONS] Get Message Types From Server Failure';
export class GetMessageTypesFromServerFailure implements Action {
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: any) {}
}

export const GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS = '[CONVERSATIONS] Get Message Types From Server Success';
export class GetMessageTypesFromServerSuccess implements Action {
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: MessageType[]) {}
}

export const POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER = '[CONVERSATIONS] Post Archive Messages Of Type To Server';
export class PostArchiveMessagesOfTypeToServer implements Action {
  readonly type = POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: { currentUserId: number; messageTypeId: number }) {}
}

export const POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER_FAILURE =
  '[CONVERSATIONS] Post Archive Messages Of Type To Server Failure';
export class PostArchiveMessagesOfTypeToServerFailure implements Action {
  readonly type = POST_ARCHIVE_MESSAGES_OF_TYPE_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { messageTypeId: number; error: any }) {}
}

export const POST_CONVERSATION_TO_SERVER = '[CONVERSATIONS] Post Conversation To Server';
export class PostConversationToServer implements Action {
  readonly type = POST_CONVERSATION_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: { conversation: Conversation; members: Member[]; event: Event; template?: Template }) {}
}

export const POST_CONVERSATION_TO_SERVER_SUCCESS = '[CONVERSATIONS] Post Conversation To Server Success';
export class PostConversationToServerSuccess implements Action {
  readonly type = POST_CONVERSATION_TO_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversation: Conversation }) {}
}

export const POST_CONVERSATION_TO_SERVER_FAILURE = '[CONVERSATIONS] Post Conversation To Server Failure';
export class PostConversationToServerFailure implements Action {
  readonly type = POST_CONVERSATION_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { conversation: Conversation; error: any }) {}
}

export const POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER = '[CONVERSATIONS] Post Direct Message Template To Server';
export class PostDirectMessageTemplateToServer implements Action {
  readonly type = POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: DirectMessageTemplate) {}
}

export const POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE =
  '[CONVERSATIONS] Post Direct Message Template To Server Failure';
export class PostDirectMessageTemplateToServerFailure implements Action {
  readonly type = POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { template: DirectMessageTemplate; error: any }) {}
}

export const POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS =
  '[CONVERSATIONS] Post Direct Message Template To Server Success';
export class PostDirectMessageTemplateToServerSuccess implements Action {
  readonly type = POST_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: DirectMessageTemplate) {}
}

export const RESET_CONVERSATION_STATE = '[CONVERSATIONS] Reset Conversation State';
export class ResetConversationState implements Action {
  readonly type = RESET_CONVERSATION_STATE;
  readonly feature = 'conversations';
  constructor(public payload: {}) {}
}

export const RESET_CONVERSATION_IN_NEW_CONVERSATION_IN_STATE =
  '[CONVERSATIONS] Reset Conversation In New Conversation In State';
export class ResetConversationInNewConversationInState implements Action {
  readonly type = RESET_CONVERSATION_IN_NEW_CONVERSATION_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: any) {}
}

export const START_NEW_CONVERSATION_IN_STATE = '[CONVERSATIONS] Start New Conversation In State';
export class StartNewConversationInState implements Action {
  readonly type = START_NEW_CONVERSATION_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversation: Conversation }) {}
}

export const TOGGLE_CONVERSATION_INFO_PANEL_UI = '[CONVERSATIONS] Toggle Info Panel Ui';
export class ToggleConversationInfoPanelUi implements Action {
  readonly type = TOGGLE_CONVERSATION_INFO_PANEL_UI;
  readonly feature = 'conversations';
  constructor(public payload: boolean) {}
}

export const TOGGLE_EMOJI_PANEL_UI = '[CONVERSATIONS] Toggle Emoji Panel Ui';
export class ToggleEmojiPanelUi implements Action {
  readonly type = TOGGLE_EMOJI_PANEL_UI;
  readonly feature = 'conversations';
  constructor(public payload: boolean) {}
}

export const UPDATE_CONVERSATION_LAST_EVENT_IN_STATE = '[CONVERSATIONS] Update Conversation Last Event In State';
export class UpdateConversationLastEventInState implements Action {
  readonly type = UPDATE_CONVERSATION_LAST_EVENT_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Conversation>) {}
}

export const UPDATE_CONVERSATION_IS_PINNED_TO_UI_IN_STATE =
  '[CONVERSATIONS] Update Conversation Is Pinned To Ui In State';
export class UpdateConversationIsPinnedToUiInState implements Action {
  readonly type = UPDATE_CONVERSATION_IS_PINNED_TO_UI_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Conversation>) {}
}

export const UPDATE_CONVERSATION_TITLE_ON_SERVER = '[CONVERSATIONS] Update Conversation Title On Server';
export class UpdateConversationTitleOnServer implements Action {
  readonly type = UPDATE_CONVERSATION_TITLE_ON_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: { id: number; title: string }) {}
}

export const UPDATE_CONVERSATION_TITLE_ON_SERVER_FAILURE =
  '[CONVERSATIONS] Update Conversation Title On Server Failure';
export class UpdateConversationTitleOnServerFailure implements Action {
  readonly type = UPDATE_CONVERSATION_TITLE_ON_SERVER_FAILURE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversationId: number; error: any }) {}
}

export const UPDATE_CONVERSATION_TITLE_ON_SERVER_SUCCESS =
  '[CONVERSATIONS] Update Conversation Title On Server Success';
export class UpdateConversationTitleOnServerSuccess implements Action {
  readonly type = UPDATE_CONVERSATION_TITLE_ON_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Conversation>) {}
}

export const UPDATE_CONVERSATION_TITLE_FROM_SOCKET_EVENT_IN_STATE =
  '[CONVERSATIONS] Update Conversation Title From Socket Event In State';
export class UpdateConversationTitleFromSocketEventInState implements Action {
  readonly type = UPDATE_CONVERSATION_TITLE_FROM_SOCKET_EVENT_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Conversation>) {}
}

export const UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER = '[CONVERSATIONS] Update Direct Message Template To Server';
export class UpdateDirectMessageTemplateToServer implements Action {
  readonly type = UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: DirectMessageTemplate) {}
}

export const UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE =
  '[CONVERSATIONS] Update Direct Message Template To Server Failure';
export class UpdateDirectMessageTemplateToServerFailure implements Action {
  readonly type = UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { template: DirectMessageTemplate; error: any }) {}
}

export const UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS =
  '[CONVERSATIONS] Update Direct Message Template To Server Success';
export class UpdateDirectMessageTemplateToServerSuccess implements Action {
  readonly type = UPDATE_DIRECT_MESSAGE_TEMPLATE_TO_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: DirectMessageTemplate) {}
}

export const UPDATE_CONVERSATION_IN_STATE = '[CONVERSATIONS] Update Conversation in State';
export class UpdateConversationInState implements Action {
  readonly type = UPDATE_CONVERSATION_IN_STATE;
  constructor(public payload: Update<Conversation>) {}
}

export const UPDATE_EVENTS_LAST_QUERY_PAGE_IN_STATE = '[CONVERSATIONS] Update Events Last Query Page in State';
export class UpdateEventsLastQueryPageInState implements Action {
  readonly type = UPDATE_EVENTS_LAST_QUERY_PAGE_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversationId: number }) {}
}

export const UPDATE_LAST_QUERY_PAGE_NUMBER_IN_STATE = '[CONVERSATIONS] Update Last Query Page Number in State';
export class UpdateLastQueryPageNumberInState implements Action {
  readonly type = UPDATE_LAST_QUERY_PAGE_NUMBER_IN_STATE;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: { conversationView: ConversationView }) {}
}

export const GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER =
  '[CONVERSATIONS] Get Message Transaction Preference From Server';
export class GetMessageTransactionPreferencesFromServer implements Action {
  readonly type = GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: MessageTransactionMessagePreferenceQueryParameters) {}
}

export const GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER_FAILURE =
  '[CONVERSATIONS] Get Message Transaction Preference From Server Failure';
export class GetMessageTransactionPreferencesFromServerFailure implements Action {
  readonly type = GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { error: any }) {}
}

export const GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER_SUCCESS =
  '[CONVERSATIONS] Get Message Transaction Preference From Server Success';
export class GetMessageTransactionPreferencesFromServerSuccess implements Action {
  readonly type = GET_MESSAGE_TRANSACTION_PREFERENCES_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER =
  '[CONVERSATIONS] Post Message Transaction Preference To Server';
export class PostMessageTransactionPreferenceToServer implements Action {
  readonly type = POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_FAILURE =
  '[CONVERSATIONS] Post Message Transaction Preference To Server Failure';
export class PostMessageTransactionPreferenceToServerFailure implements Action {
  readonly type = POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { error: any }) {}
}

export const POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS =
  '[CONVERSATIONS] Post Message Transaction Preference To Server Success';
export class PostMessageTransactionPreferenceToServerSuccess implements Action {
  readonly type = POST_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER =
  '[CONVERSATIONS] Put Message Transaction Preference To Server';
export class PutMessageTransactionPreferenceToServer implements Action {
  readonly type = PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_FAILURE =
  '[CONVERSATIONS] Put Message Transaction Preference To Server Failure';
export class PutMessageTransactionPreferenceToServerFailure implements Action {
  readonly type = PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { error: any }) {}
}

export const PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS =
  '[CONVERSATIONS] Put Message Transaction Preference To Server Success';
export class PutMessageTransactionPreferenceToServerSuccess implements Action {
  readonly type = PUT_MESSAGE_TRANSACTION_PREFERENCE_TO_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER =
  '[CONVERSATIONS] Delete Message Transaction Preference From Server';
export class DeleteMessageTransactionPreferenceFromServer implements Action {
  readonly type = DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER;
  readonly feature = 'conversations';
  constructor(public payload: MessageTransactionMessagePreference) {}
}

export const DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER_FAILURE =
  '[CONVERSATIONS] Delete Message Transaction Preference From Server Failure';
export class DeleteMessageTransactionPreferenceFromServerFailure implements Action {
  readonly type = DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER_FAILURE;
  readonly feature = 'conversations';
  constructor(public payload: { error: any }) {}
}

export const DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER_SUCCESS =
  '[CONVERSATIONS] Delete Message Transaction Preference From Server Success';
export class DeleteMessageTransactionPreferenceFromServerSuccess implements Action {
  readonly type = DELETE_MESSAGE_TRANSACTION_PREFERENCE_FROM_SERVER_SUCCESS;
  readonly feature = 'conversations';
  readonly triggerStorageSync = true;
  constructor() {}
}

export type ConversationsAction =
  | AddConversationFromSocketToState
  | AddConversationToState
  | AddMemberToNewConversationInState
  | AddUserIsTypingToState
  | AddUnsentMessageToState
  | DeleteConversationFromState
  | DeleteDirectMessageTemplateFromServer
  | DeleteDirectMessageTemplateFromServerFailure
  | DeleteDirectMessageTemplateFromServerSuccess
  | DeleteMemberFromNewConversationInState
  | DeleteUnsentMessageFromState
  | DeleteUserIsTypingInState
  | GetConversationFromStateServer
  | GetConversationFromStateServerFailure
  | GetConversationsFromServer
  | GetConversationsFromServerFailure
  | GetConversationsFromServerSuccess
  | GetDirectMessageTemplatesFromServer
  | GetDirectMessageTemplatesFromServerFailure
  | GetDirectMessageTemplatesFromServerSuccess
  | GetMessageTypesFromServer
  | GetMessageTypesFromServerFailure
  | GetMessageTypesFromServerSuccess
  | GetNewConversationFromServer
  | GetNewConversationFromServerFailure
  | GetNewConversationFromServerSuccess
  | PostArchiveMessagesOfTypeToServer
  | PostArchiveMessagesOfTypeToServerFailure
  | PostConversationToServer
  | PostConversationToServerFailure
  | PostConversationToServerSuccess
  | PostDirectMessageTemplateToServer
  | PostDirectMessageTemplateToServerFailure
  | PostDirectMessageTemplateToServerSuccess
  | GetConversationsToRemoveFromState
  | GetConversationsToRemoveFromStateFailure
  | GetConversationsToRemoveFromStateSuccess
  | ResetConversationState
  | ResetConversationInNewConversationInState
  | StartNewConversationInState
  | ToggleConversationInfoPanelUi
  | ToggleEmojiPanelUi
  | UpdateConversationLastEventInState
  | UpdateConversationIsPinnedToUiInState
  | UpdateConversationTitleFromSocketEventInState
  | UpdateConversationTitleOnServer
  | UpdateConversationTitleOnServerFailure
  | UpdateConversationTitleOnServerSuccess
  | UpdateDirectMessageTemplateToServer
  | UpdateDirectMessageTemplateToServerFailure
  | UpdateDirectMessageTemplateToServerSuccess
  | UpdateEventsLastQueryPageInState
  | UpdateLastQueryPageNumberInState
  | UpdateConversationInState
  | GetMessageTransactionPreferencesFromServer
  | GetMessageTransactionPreferencesFromServerFailure
  | GetMessageTransactionPreferencesFromServerSuccess
  | PostMessageTransactionPreferenceToServer
  | PostMessageTransactionPreferenceToServerFailure
  | PostMessageTransactionPreferenceToServerSuccess
  | PutMessageTransactionPreferenceToServer
  | PutMessageTransactionPreferenceToServerFailure
  | PutMessageTransactionPreferenceToServerSuccess
  | DeleteMessageTransactionPreferenceFromServer
  | DeleteMessageTransactionPreferenceFromServerSuccess
  | DeleteMessageTransactionPreferenceFromServerFailure;
