import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from '@quorum/environments';
import { AuthenticatedUser, Employee } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromAuthentication from './+state/authentication.actions';
import * as fromAuthInterfaces from './+state/authentication.interfaces';
import {
  AuthenticationStages,
  AuthenticationState,
  selectAuthenticationAuthenticatedUser,
  selectAuthenticationAuthenticationStages,
  selectAuthenticationEmployee,
  selectAuthenticationErrors,
  selectAuthenticationIsAuthenticated,
  selectAuthenticationIsAuthenticating,
  selectQuorumApiKey,
  selectQuorumDestinationId,
} from './+state/authentication.interfaces';

@Injectable()
export class AuthenticationStateService {
  constructor(private store: Store<AuthenticationState>, private http: HttpClient) {}

  monitorAuthenticationStages() {
    this.store.dispatch(new fromAuthentication.MonitorAuthenticationStages());
  }

  quorumEmployeeLoginSuccessful(apiKey: string, destinationId: string, redirectUrl: string) {
    this.store.dispatch(
      new fromAuthentication.EmployeeAuthenticationSuccessful({ apiKey, destinationId, redirectUrl })
    );
  }

  quorumEmployeeLoginFailure() {
    this.store.dispatch(new fromAuthentication.EmployeeAuthenticationFailure(null));
  }

  login(username: string, password: string) {
    this.store.dispatch(new fromAuthentication.AuthenticateUser({ username: username, password: password }));
  }

  public authenticationStagesComplete(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuthInterfaces.selectAll),
      filter((stages: any) => {
        return stages.every((stage: AuthenticationStages) => {
          return (
            stage.authenticationStatus === fromAuthInterfaces.AuthenticationStatus.completed ||
            stage.authenticationStatus === fromAuthInterfaces.AuthenticationStatus.optional
          );
        });
      })
    );
  }

  getErrors() {
    return this.store.pipe(select(selectAuthenticationErrors));
  }

  isAuthenticated() {
    return this.store.pipe(
      select(selectAuthenticationIsAuthenticated),
      filter((value) => value != null)
    );
  }

  isAuthenticating() {
    return this.store.pipe(select(selectAuthenticationIsAuthenticating));
  }

  checkCookieForAuthentication() {
    this.store.dispatch(new fromAuthentication.CheckCookieForAuthentication({}));
  }

  selectAuthenticatedUser() {
    return this.store.pipe(select(selectAuthenticationAuthenticatedUser));
  }

  selectAuthenticatedEmployee(): Observable<Employee> {
    return this.store.pipe(select(selectAuthenticationEmployee));
  }

  logout() {
    this.store.dispatch(new fromAuthentication.Logout({}));
  }

  addAuthenticationStages(authenticationStages: AuthenticationStages[]) {
    this.store.dispatch(new fromAuthentication.AddAuthenticationStages(authenticationStages));
  }

  selectAuthenticationStages() {
    return this.store.pipe(select(selectAuthenticationAuthenticationStages));
  }

  selectQuorumApiKey() {
    return this.store.pipe(select(selectQuorumApiKey));
  }

  selectQuorumDestinationId() {
    return this.store.pipe(select(selectQuorumDestinationId));
  }

  getApiCredentialsForEmployee(token: string, redirectUrl: string) {
    this.store.dispatch(new fromAuthentication.GetApiCredentialsForEmployee({ token, redirectUrl }));
  }

  getCurrentStageInProgress(): Observable<AuthenticationStages> {
    return this.store.pipe(
      select(fromAuthInterfaces.selectAll),
      map((stages) => {
        return stages.find((stage) => {
          return stage.authenticationStatus === fromAuthInterfaces.AuthenticationStatus.pending;
        });
      })
    );
  }

  getAuthenticationCompletePercentage(): Observable<number> {
    return this.store.pipe(
      select(fromAuthInterfaces.selectAll),
      map((stages) => {
        return (
          (stages.filter((stage) => {
            return stage.authenticationStatus === fromAuthInterfaces.AuthenticationStatus.completed;
          }).length /
            stages.length) *
          100
        );
      })
    );
  }

  generateJWTTokenForNoticeable(authenticatedUser: AuthenticatedUser) {
    const url = new URL(`${environment.jwtTokenGenerationUrl}`);
    const user = authenticatedUser.user;

    return this.http.post(url.toString(), user);
  }
}
