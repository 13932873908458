import { Store } from '../quorum/store.model';

export class TokenUser {
  apiKey: string;
  avatarUrl: string;
  destination: string;
  email: string;
  errorMessage: string;
  federatedStores: Store[];
  firstName: string;
  id: number;
  isActive: boolean;
  isActiveDirectoryLogin: boolean;
  isAdministrator: boolean;
  isQuorumUser: boolean;
  lastName: string;
  storeId: number;
  storeName: string;
  terminalIdentifier: string;
  username: string;
  xselleratorDatabaseName: string;
  xselleratorDatabaseServer: string;
  xselleratorEmployeeId: string;
  version: string;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class AuthenticatedUser {
  user: TokenUser;
  token: string;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export const tokenUserMock = new TokenUser();
tokenUserMock.apiKey = 'testapikey';
tokenUserMock.avatarUrl = 'localhost/sha-avatar.png';
tokenUserMock.destination = 'quorum';
tokenUserMock.email = 'test@email.com';
tokenUserMock.errorMessage = '';
tokenUserMock.federatedStores = [];
tokenUserMock.firstName = 'John';
tokenUserMock.id = 1000;
tokenUserMock.isActive = true;
tokenUserMock.isActiveDirectoryLogin = false;
tokenUserMock.isAdministrator = false;
tokenUserMock.isQuorumUser = true;
tokenUserMock.lastName = 'Doe';
tokenUserMock.storeId = 1000;
tokenUserMock.storeName = 'XYZ Corp';
tokenUserMock.terminalIdentifier = 'xyzCorp';
tokenUserMock.username = 'doej';
tokenUserMock.version = 'beta';
tokenUserMock.xselleratorDatabaseName = 'dealer';
tokenUserMock.xselleratorDatabaseServer = 'xsw2k12ds';
tokenUserMock.xselleratorEmployeeId = '14';

export const authenticatedUserMock = new AuthenticatedUser({
  user: tokenUserMock,
  token: '1234secret',
});
