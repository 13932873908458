import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { fromConversations } from '@quorum/communicator/state/conversations';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import { Member } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromMembers from './+state/members.actions';
import {
  memberErrorsSelector,
  membersIsLoadingSelector,
  MembersState,
  selectAll,
  selectEntities,
} from './+state/members.interfaces';

@Injectable()
export class MembersStateService {
  constructor(private authenticationStateService: AuthenticationStateService, private store: Store<MembersState>) {}

  addMemberToConversationFromSocketEvent(member: Member) {
    this.store.dispatch(new fromMembers.AddMemberToConversationFromSocketToState({ member: member }));
  }

  addMembersToConversation(members: Member[]) {
    this.store.dispatch(
      new fromMembers.AddMembersToConversation({ conversationId: members[0].conversationId, members: members })
    );
  }

  isGroupConversation(conversationId: number) {
    return this.selectMembers().pipe(
      map((members) => members.filter((member: Member) => member.conversationId === conversationId)),
      map((members) => (members.length > 2 ? true : false))
    );
  }

  removeMemberFromConversation(member: Member, currentUserId: number) {
    this.store.dispatch(new fromMembers.RemoveMemberFromConversationOnServer({ member, currentUserId }));
  }

  removeSelfFromConversation(member: Member) {
    this.store.dispatch(new fromMembers.RemoveSelfFromConversation({ member }));
  }

  removeMemberFromConversationFromSocketEvent(member: Member) {
    this.authenticationStateService.selectAuthenticatedUser().subscribe((currentUser: AuthenticatedUser) => {
      currentUser.user && currentUser.user.id.toString() === member.userId
        ? this.store.dispatch(new fromConversations.DeleteConversationFromState({ id: member.conversationId }))
        : this.store.dispatch(new fromMembers.RemoveMemberFromConversationInState({ id: member.id }));
    });
  }

  selectErrors() {
    return this.store.pipe(select(memberErrorsSelector));
  }

  selectIsLoading() {
    return this.store.pipe(select(membersIsLoadingSelector));
  }

  selectMember(id: number): Observable<Member> {
    return this.store.pipe(
      select(selectEntities),
      map((members: any) => {
        return new DeepCopyPipe().transform(members)[id];
      })
    );
  }

  selectMembers(conversationId?: number): Observable<Member[]> {
    return conversationId
      ? this.store.pipe(
          select(selectAll),
          filter((members) => {
            return members != null && members.length > 0;
          }),
          map((members) => {
            return new DeepCopyPipe().transform(
              members.filter((member) => member !== undefined && member.conversationId === conversationId)
            );
          })
        )
      : this.store.pipe(
          select(selectAll),
          map((members) => {
            return new DeepCopyPipe().transform(members.filter((member) => member !== undefined));
          })
        );
  }

  startSpinner() {
    this.store.dispatch(new fromMembers.StartMembersSpinnerUi(true));
  }

  updateIsArchivedForConversation(member: Member) {
    this.store.dispatch(new fromMembers.UpdateIsArchivedConversationToServer({ member: member }));

    if (!member.isArchived) {
      const queryParameters = new ConversationQueryParameters({
        id: member.conversationId,
        embed: 'lastEvent,messageType',
      });
      this.store.dispatch(new fromConversations.GetConversationsFromServer({ queryParameters }));
    }
  }

  updateIsArchivedForConversationFromSocketEvent(member: Member) {
    this.store.dispatch(new fromMembers.UpdateIsArchivedConversationInState({ member: member }));

    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((authenticatedUser) => {
        if (authenticatedUser.user.id.toString() === member.userId) {
          if (!member.isArchived) {
            const queryParameters = new ConversationQueryParameters({
              id: member.conversationId,
              embed: 'lastEvent,messageType',
            });
            this.store.dispatch(new fromConversations.GetConversationsFromServer({ queryParameters }));
          } else {
            this.store.dispatch(new fromConversations.DeleteConversationFromState({ id: member.conversationId }));
          }
        }
      });
  }

  updateIsReadForConversation(member: Member) {
    this.store.dispatch(new fromMembers.UpdateIsReadConversationOnServer(member));
  }

  updateIsReadForConversationFromSocketEvent(member: Member) {
    // If the Read event was sent from this session then don't do anything as state as already been updated.
    // Only trigger below actions if for different user, or same user but different session.
    this.selectMember(member.id)
      .pipe(take(1))
      .subscribe((m: Member) => {
        if (m && m.isRead != member.isRead) {
          this.store.dispatch(new fromMembers.UpdateIsReadConversationInState(member));
        }
      });
  }

  updateIsPinnedToUiForConversation(member: Member) {
    this.store.dispatch(new fromMembers.UpdateIsPinnedToUiConversationOnServer(member));
    this.store.dispatch(
      new fromConversations.UpdateConversationIsPinnedToUiInState({
        id: member.conversationId,
        changes: { isPinnedToUi: member.isPinnedToUi },
      })
    );
  }

  updateIsPinnedToUiForConversationInState(member: Member) {
    this.store.dispatch(new fromMembers.UpdateIsPinnedToUiConversationInState(member));
    this.store.dispatch(
      new fromConversations.UpdateConversationIsPinnedToUiInState({
        id: member.conversationId,
        changes: { isPinnedToUi: member.isPinnedToUi },
      })
    );
  }

  updateIsPinnedToUiForConversationFromSocketEvent(member: Member) {
    member = {
      ...member,
      isPinnedToUi: typeof member.isPinnedToUi === 'number' && member.isPinnedToUi == 1 ? true : false,
    };
    this.store.dispatch(new fromMembers.UpdateIsPinnedToUiConversationInState(member));
    this.store.dispatch(
      new fromConversations.UpdateConversationIsPinnedToUiInState({
        id: member.conversationId,
        changes: { isPinnedToUi: member.isPinnedToUi },
      })
    );
  }
}
