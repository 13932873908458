<div style="position: fixed; transform: translate(22px, -64px)" fxLayout="row" fxLayoutAlign="start center">
  <div
    *ngFor="let attachment of attachments"
    [matTooltip]="attachment.fileName"
    matTooltipPosition="above"
    style="height: 60px; width: 60px; border-radius: 12px; background-color: #dadce0; margin: 0 4px"
    [ngStyle]="{
      background: 'url(' + attachment.fileName + ')',
      'background-position': 'center',
      'background-repeat': 'no-repeat'
    }"
    fxLayoutAlign="center center"
    fxLayout="column"
  >
    <button
      mat-icon-button
      type="button"
      style="height: 20px; width: 20px; line-height: 20px; transform: translate(1.75rem, -1rem)"
      class="bg-black text-white z-10"
      (click)="removeFile(attachment)"
    >
      <mat-icon style="font-size: 14px; width: 20px; height: 20px; line-height: 20px">close</mat-icon>
    </button>
    <mat-icon
      style="transform: translateY(-0.75rem)"
      [ngStyle]="{
        opacity: !['jpeg', 'jpg', 'png', 'gif'].includes(attachment.fileName) ? 100 : 0
      }"
    >
      attachment
    </mat-icon>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between end">
  <form
    autocomplete="off"
    class="mat-elevation-z3 editor-container"
    (ngSubmit)="sendMessage(message)"
    novalidate
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex
  >
    <div class="input-container" fxFlex fxLayoutAlign="center center">
      <textarea
        cdkTextareaAutosize
        id="textInput"
        class="editor-input"
        (keydown.enter)="sendMessage(message, attachments)"
        [(ngModel)]="message"
        name="message"
        placeholder="{{ placeHolder }}"
        (keydown)="keyDownEvent($event)"
        (keyup)="keyUpEvent($event)"
        (blur)="inputBlurEvent()"
      ></textarea>
    </div>
    <div fxFlexAlign="end" class="option-container" fxLayout="row" fxLayoutAlign="center center">
      <div id="emojiContainer" *ngIf="ui$ | async as ui">
        <button mat-icon-button type="button" (click)="showEmojiPanel()">
          <mat-icon class="secondary-text">tag_faces</mat-icon>
        </button>
        <div>
          <div
            *ngIf="ui.emojiPanelVisible"
            (shaClickOutside)="hideEmojiPanel()"
            [delayClickOutsideInit]="true"
            [excludeBeforeClick]="true"
          >
            <com-emoji-panel (emojiUnicode)="selectedEmoji($event)"></com-emoji-panel>
          </div>
        </div>
      </div>
      <button mat-icon-button type="button" [matMenuTriggerFor]="templates">
        <mat-icon class="secondary-text">message</mat-icon>
      </button>
      <mat-menu #templates="matMenu">
        <button
          mat-menu-item
          (click)="message = template.message"
          *ngFor="let template of directMessageTemplates$ | async"
        >
          {{ template.templateName }}
        </button>
      </mat-menu>
      <button mat-icon-button type="button" (click)="openEmailTemplateDialog()">
        <mat-icon class="secondary-text">table_chart</mat-icon>
      </button>
      <div fxLayoutAlign="center center">
        <mat-spinner style="position: fixed" *ngIf="processingAttachments" diameter="34"></mat-spinner>
        <button
          [disabled]="processingAttachments"
          mat-icon-button
          type="button"
          class="disabled:opacity-30"
          (click)="uploader.click()"
        >
          <mat-icon class="secondary-text">attach_file</mat-icon>
        </button>
      </div>
      <input hidden multiple type="file" id="fileInput" #uploader (change)="uploadFiles($event.target.files)" />
    </div>
  </form>
  <button
    mat-icon-button
    mat-mini-fab
    (click)="sendMessage(message, attachments)"
    [disabled]="message?.length == 0 && attachments?.length == 0"
    class="send-button"
    type="button"
  >
    <mat-icon [class.mat-primary]="message?.length != 0 || attachments?.length > 0">send</mat-icon>
  </button>
</div>
