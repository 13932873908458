import { ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { ContactsStateService } from '@quorum/communicator/state/contacts';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { ContactPresence } from '@quorum/models/xs-misc';
import { CommunicatorContact as Contact, Member } from '@quorum/models/xs-resource';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
@Component({
  selector: 'com-contact-select-dialog',
  templateUrl: './contact-select-dialog.component.html',
  styleUrls: ['./contact-select-dialog.component.css'],
})
export class ContactSelectDialogComponent implements OnInit, OnDestroy {
  contacts$: Observable<Contact[]>;
  errors$: Observable<any>;
  isLoading$: Observable<boolean>;
  contactsPresence: { [key: number]: ContactPresence } = {};
  contactsPresence$: Subscription;
  contactsAuto$: Subscription;
  contacts: Array<Contact> = [];
  contactCtrl = new FormControl();
  filteredContacts$: Observable<Contact[]>;
  selectedMembers: any[] = [];
  contactsList: { contact: Contact; preselected: boolean; selected: boolean }[] = [];

  selectable: boolean = false;
  removable: boolean = true;
  separatorKeysCodes = [ENTER];
  @ViewChild('contactInput') contactInput: any;

  constructor(
    public dialogRef: MatDialogRef<ContactSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { preselectedContacts: Contact[]; title: string },
    private contactsStateService: ContactsStateService,
    private conversationStateService: ConversationStateService
  ) {}

  ngOnInit() {
    this.contactsStateService
      .selectContacts()
      .pipe(take(1))
      .subscribe((contacts) => {
        contacts.map((contact: Contact) => {
          let preselected = this.data.preselectedContacts.find(
            (preselectedContact) => preselectedContact.id == contact.id
          )
            ? true
            : false;

          this.contactsList.push({ contact: contact, preselected: preselected, selected: preselected ? true : false });
        });
      });

    this.errors$ = this.contactsStateService.selectErrors();
    this.isLoading$ = this.contactsStateService.selectIsLoading();
    this.contactsPresence$ = this.contactsStateService
      .selectPresences()
      .subscribe((presences) => (this.contactsPresence = presences));

    this.filteredContacts$ = this.contactCtrl.valueChanges.pipe(
      startWith(''),
      map((contactName: string) => {
        if (typeof contactName === 'string') {
          return contactName ? this.filter(contactName) : this.contacts.slice();
        }
      })
    );

    this.contactsAuto$ = this.contactsStateService.selectContacts().subscribe((contacts) => (this.contacts = contacts));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDone(people: MatListOption[]): void {
    this.selectedMembers = [];
    people.map((p: MatListOption) => this.selectedMembers.push(p.value.contact));
    this.dialogRef.close(this.selectedMembers);
  }

  ngOnDestroy() {
    this.contactsPresence$.unsubscribe();
    this.contactsAuto$.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    if (event.input && typeof event.value === 'object') {
      const contact: Contact = event.value;
      event.input.value = '';

      this.conversationStateService.addMemberToNewConversation(
        new Member({
          userId: contact.id.toString(),
          avatarUrl: contact.avatarUrl,
          firstName: contact.firstName,
          lastName: contact.lastName,
          nickname: contact.nickName,
          isEmployee: contact.isDealershipUser,
        })
      );
    }
  }

  remove(contact: Contact): void {
    this.selectedMembers.splice(this.selectedMembers.indexOf(contact), 1);
    let currentContact = this.contactsList.find((con) => con.contact.id === contact.id);

    if (currentContact) {
      currentContact.selected = false;
    }
  }

  filter(contactName: string) {
    return this.contacts.filter((contact: Contact) => {
      return (
        (contact.firstName.toLowerCase().indexOf(contactName.toLowerCase()) > -1 ||
          contact.lastName.toLowerCase().indexOf(contactName.toLowerCase()) > -1) &&
        !this.selectedMembers.some(function (selectedContact) {
          return selectedContact.id === contact.id;
        })
      );
    });
  }

  autoCompleteSelected(event: MatAutocompleteSelectedEvent): void {
    this.contactInput.nativeElement.value = '';
    let currentContact = this.contactsList.find((contact) => contact.contact.id === event.option.value.id);
    if (currentContact) {
      currentContact.selected = true;
    }

    this.selectedMembers.push(event.option.value);
  }

  onListControlChanged(people: any) {
    if (people.selectedOptions.selected.length == 0) {
      this.selectedMembers = [];
      this.contactsList.forEach((contact) => (contact.selected = false));
    } else {
      people.selectedOptions.selected.map((member: MatListOption) => {
        if (!this.selectedMembers.find((result) => result.id === member.value.contact.id)) {
          this.selectedMembers.push(member.value.contact);
          let currentContact = this.contactsList.find((contact) => contact.contact.id === member.value.contact.id);
          if (currentContact) {
            currentContact.selected = true;
          }
        } else if (people.selectedOptions.selected.length < this.selectedMembers.length) {
          this.contactsList.forEach((contact) => (contact.selected = false));
          this.selectedMembers = [];
          this.selectedMembers.push(member.value.contact);
          let currentContact = this.contactsList.find((contact) => contact.contact.id === member.value.contact.id);
          if (currentContact) {
            currentContact.selected = true;
          }
        }
      });
    }
  }
}
