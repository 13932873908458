import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@quorum/api';
import { ConversationStateService } from '@quorum/communicator/state/conversations';
import { ContactPresence } from '@quorum/models/xs-misc';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import { CommunicatorContact as Contact, Conversation, Member } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
@Component({
  selector: 'com-contacts-list-item',
  templateUrl: './contacts-list-item.component.html',
  styleUrls: ['./contacts-list-item.component.css'],
})
export class ContactsListItemComponent implements OnInit {
  @Input() contact: Contact;
  @Input() contactPresence: ContactPresence;
  @Input() selected: boolean = false;

  contactInitials: string[];

  constructor(
    private apiService: ApiService,
    private conversationStateService: ConversationStateService,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService
  ) {}

  ngOnInit() {
    this.contactInitials = [
      `${this.contact.firstName ? this.contact.firstName.charAt(0) : ''}${
        this.contact.lastName ? this.contact.lastName.charAt(0) : ''
      }`,
    ];
  }

  selectConversationForContacts(contacts: Contact[]) {
    this.conversationStateService
      .selectConversationsForContacts(contacts)
      .pipe(
        take(1),
        map((conversations: Conversation[]) => {
          if (conversations.find((c) => c.dmsMessageTypeId === 0)) {
            this.routeToConversation(
              conversations.find((c) => c.dmsMessageTypeId === 0),
              contacts
            );
          } else {
            this.getConversations(contacts).subscribe((conversations: Conversation[]) => {
              conversations
                ? this.routeToConversation(conversations[0], contacts)
                : this.routeToConversation(null, contacts);
            });
          }
        })
      )
      .subscribe();
  }

  getConversations(contacts: Contact[]) {
    return new Observable((observer) => {
      const params: ConversationQueryParameters = new ConversationQueryParameters({
        isArchived: false,
        isRead: false,
        memberId: contacts.map((c) => c.id).join(','),
        messageTypeId: 0,
        embed: 'messageType,lastEvent',
      });

      this.apiService
        .get<Conversation[]>(`communicator/conversations`, { params })
        .pipe(
          take(1),
          map((conversations: Conversation[]) => observer.next(conversations.length > 0 ? conversations : null))
        )
        .subscribe();
    });
  }

  routeToConversation(conversation: Conversation, contacts?: Contact[]) {
    if (conversation) {
      this.routerStateService.go([{ outlets: { detail: `c/${conversation.id}` } }], { relativeTo: this.route.parent });
    } else {
      this.conversationStateService.startNewConversation(null);
      contacts.map((contact: Contact) =>
        this.conversationStateService.addMemberToNewConversation(
          new Member({
            userId: contact.id.toString(),
            avatarUrl: contact.avatarUrl,
            firstName: contact.firstName,
            lastName: contact.lastName,
            nickname: contact.nickName,
            isEmployee: true,
          })
        )
      );
      this.routerStateService.go([{ outlets: { detail: `new` } }], { relativeTo: this.route.parent });
    }
  }
}
