<div class="flex w-full">
  <div *ngIf="media.mediaType == 'video'"
    class="w-24 h-24 sm:h-32 sm:w-32 rounded-tl-lg relative overflow-hidden cursor-pointer">
    <div #videoDiv (click)="openVideoFullscreen(videoDiv, media, videoElement); playPauseVideo(videoElement)"
      class="h-full w-full absolute">
      <video #videoElement (ended)="videoEnded(videoElement)" style="object-fit: inherit;"
        class="h-full w-full absolute">
        <source [src]="(media.mediaUrl | codec) + '#t=0.001'" type="video/mp4" />
      </video>
      <mat-icon id="play-button"
        class="absolute top-1/2 left-1/2 text-5xl h-12 w-12 text-white transform -translate-x-1/2 -translate-y-1/2"
        [ngClass]="{ 'opacity-0': (hidePlayButton$ | async) === true }">
        play_circle_outline</mat-icon>
    </div>
  </div>

  <div *ngIf="media.mediaType == 'image'">
    <div (click)="clickImage(media.SK)" [ngClass]="{
      'w-24 h-24 sm:h-32 sm:w-32 rounded-tl-lg overflow-hidden': selectedImageSk !== media.SK,
      'fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 place-content-center items-center flex flex-row box-border':
        selectedImageSk === media.SK
    }">
      <img [src]="media.mediaUrl" [ngClass]="{
        'object-cover h-full w-full cursor-pointer': selectedImageSk !== media.SK,
        'max-w-9/10 max-h-9/10 w-auto h-auto transform scale-95': selectedImageSk === media.SK
      }" />
    </div>
  </div>

  <div class="flex flex-col h-24 sm:h-32 w-auto whitespace-nowrap p-1 sm:p-3 overflow-hidden text-xs space-y-1">
    <span class="font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">{{ media?.caption }}</span>
    <span class="font-medium text-black text-opacity-50">{{
      media?.dateAdded | date: 'MMM d, y, h:mm a' }}</span>
    <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"
      *ngIf="media.galleryDescription; else defaultGalleryDescription;">{{media?.galleryDescription}}</span>
    <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis" *ngIf="media.tags.caseSequence">Case:
      {{media.tags.caseSequence}}</span>
    <ng-template #defaultGalleryDescription>
      <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis" *ngIf="media.tags.damageId">Added via
        Body Report</span>
      <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis" *ngIf="media.tags.caseSequence">Added
        via Line Item</span>
      <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"
        *ngIf="media.tags.inspectionTaskId">Added via Inspection Task</span>
      <span class="text-black text-opacity-50 overflow-hidden overflow-ellipsis"
        *ngIf="media.tags.inspectionId && !media.tags.inspectionTaskId && !media.tags.damageId">Added via
        Inspection</span>
    </ng-template>
    <span class="text-black text-opacity-50">{{ media?.customerFacing === true || (media?.customerFacing &&
      media?.customerFacing.toString().toLowerCase() === 'true') ? 'Customer facing' : 'Not customer facing' }}</span>
  </div>
</div>
<mat-divider></mat-divider>

<div class="flex flex-row place-content-between items-center">
  <div class="ml-3" (click)="editMedia(media)">
    <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9512 2.51367H13.9199C13.6387 2.23242 13.1699 2.23242 12.8887 2.51367L11.9512 3.41992L13.4199 4.88867L14.3262 3.95117C14.6074 3.66992 14.6074 3.20117 14.3262 2.88867L13.9512 2.51367ZM6.70117 8.70117C6.60742 8.76367 6.54492 8.88867 6.51367 9.01367L5.98242 10.8262L7.82617 10.3262C7.95117 10.2949 8.04492 10.2324 8.13867 10.1387L12.3574 5.91992L10.9199 4.48242L6.70117 8.70117ZM11.8262 1.45117C12.7012 0.576172 14.1074 0.576172 14.9824 1.45117L15.3887 1.85742C16.2637 2.73242 16.2637 4.13867 15.3887 5.01367L9.20117 11.2012C8.95117 11.4512 8.60742 11.6387 8.23242 11.7637L5.10742 12.6387C4.85742 12.7324 4.57617 12.6699 4.38867 12.4512C4.16992 12.2637 4.10742 11.9824 4.16992 11.7324L5.07617 8.60742C5.16992 8.23242 5.38867 7.88867 5.63867 7.63867L11.8262 1.45117ZM2.91992 2.66992H6.41992C6.82617 2.66992 7.16992 3.01367 7.16992 3.41992C7.16992 3.85742 6.82617 4.16992 6.41992 4.16992H2.91992C2.20117 4.16992 1.66992 4.73242 1.66992 5.41992V13.9199C1.66992 14.6387 2.20117 15.1699 2.91992 15.1699H11.4199C12.1074 15.1699 12.6699 14.6387 12.6699 13.9199V10.4199C12.6699 10.0137 12.9824 9.66992 13.4199 9.66992C13.8262 9.66992 14.1699 10.0137 14.1699 10.4199V13.9199C14.1699 15.4512 12.9199 16.6699 11.4199 16.6699H2.91992C1.38867 16.6699 0.169922 15.4512 0.169922 13.9199V5.41992C0.169922 3.91992 1.38867 2.66992 2.91992 2.66992Z"
        fill="#0054A6" />
    </svg>
  </div>

  <div>
    <button mat-icon-button (click)="deleteMedia(media)">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="downloadMedia(media)">
      <mat-icon>download</mat-icon>
    </button>
  </div>
</div>