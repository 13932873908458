import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { EventComponent } from './event/event.component';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { FileComponent } from './file/file.component';
import { ImagesLoadedDirective } from './images-loaded.directive';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, CommonPipesModule, CommonMaterialComponentsModule, ShaComponentsNgUiModule],
  exports: [EventComponent, FileComponent],
  declarations: [EventComponent, FileComponent, ImagesLoadedDirective],
})
export class CommunicatorUiNgEventsModule {}
