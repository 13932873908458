import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserControlCommunicationService } from './browserControlCommunication.service';
import { CommunicatorService } from './communicator.service';
import { SnackbarService } from './snackbar.service';
import { DataLayerService } from './data-layer.service';
import { VcrService } from './vcr.service';
import { GmSltService } from './gm-slt.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    BrowserControlCommunicationService,
    CommunicatorService,
    DataLayerService,
    VcrService,
    SnackbarService,
    GmSltService,
  ],
})
export class ShaServicesModule {}
