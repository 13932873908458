import { Component, Input, OnInit } from '@angular/core';
import { Event, Member } from '@quorum/models/xs-resource';

@Component({
  selector: 'com-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
})
export class EventComponent implements OnInit {
  @Input() event: Event;
  @Input() index: number = 0;
  @Input() members: Member[];
  @Input() ownEvent: boolean = false;
  @Input() sender: Member;
  @Input() total: number = 0;

  constructor() {}

  ngOnInit() {}

  buildStyle(index: number, total: number, ownEvent: boolean) {
    const style: any = {};

    if (ownEvent) {
      if (total > 1) {
        switch (true) {
          case index < total && index > 1:
            style['border-top-right-radius'] = '0.3em';
            style['border-bottom-right-radius'] = '0.3em';
            break;
          case index === total:
            style['border-top-right-radius'] = '0em';
            break;
          case index === 1:
            style['border-bottom-right-radius'] = '0em';
            break;
        }
      }
    } else {
      if (total > 1) {
        switch (true) {
          case index < total && index > 1:
            style['border-top-left-radius'] = '0.3em';
            style['border-bottom-left-radius'] = '0.3em';
            break;
          case index === total:
            style['border-top-left-radius'] = '0em';
            break;
          case index === 1:
            style['border-bottom-left-radius'] = '0em';
            break;
        }
      }
    }

    return style;
  }

  displayAvatar(total: number, index: number, ownEvent: any) {
    if (ownEvent) {
      return false;
    } else if (total === 1 || (total > 1 && index === total)) {
      return true;
    }

    return false;
  }

  displayNickname(total: number, index: number, ownEvent: boolean, members: Member[]) {
    if (members.length === 2 || ownEvent) return false;
    if (total === 1 || (total > 1 && index === 1)) return true;

    return false;
  }
}
