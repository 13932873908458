import { Injectable } from '@angular/core';
import {
  VcrData,
  QuoteDetail,
  QuoteAdditionalCharge,
  QuoteSalesAdditionalCharge,
  QuoteSalesDetail,
  PartSalesAdditionalCharge,
  PartSalesDetail,
  WorkOrderAdditionalCharge,
  WorkOrderLabour,
  MessageType,
} from '@quorum/models/xs-resource';

@Injectable()
export class VcrService {
  constructor() {}

  getAdditionalChargesForQuoteLine(vcrData: VcrData, quoteLine: QuoteDetail) {
    const additionalCharges = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.quoteAdditionalCharges.forEach((qac: QuoteAdditionalCharge) => {
      if (
        qac.workOrderChargeTypeId !== 'SHO' &&
        qac.workOrderChargeTypeId !== 'LAD' &&
        qac.workOrderChargeTypeId !== 'PAD' &&
        qac.quoteDetailSequence === quoteLine.sequence
      ) {
        /**
         * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
         * If PI flag is '0' (No), the quote additional charges will be charged out
         * above the rate price. If PI flag on the service group rate is '1'
         * (Yes; meaning that all the pricing is included in the rate), we have to further check the
         * PI flag of the specific quote additional charge. If the quote additional charge PI flag is '0' (No),
         * charge out the additional charge above the rate price
         */
        if (quoteLine?.embedded?.serviceGroup?.embedded?.rate?.goodwrenchFlag === '1') {
          if (qac.goodwrenchFlag !== '1') {
            additionalCharges.subTotal += qac.subTotal;
            additionalCharges.tax += qac.gstAmount + qac.pstAmount;
          }
        } else {
          additionalCharges.subTotal += qac.subTotal;
          additionalCharges.tax += qac.gstAmount + qac.pstAmount;
        }
      }
    });

    return additionalCharges;
  }

  getPartSalesForQuoteLine(vcrData: VcrData, quoteLine: QuoteDetail) {
    const additionalCharges = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.quoteSalesDetails.forEach((qsd: QuoteSalesDetail) => {
      /**
       * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
       * If PI flag is '0' (No), the parts will be charged out
       * above the rate price. If PI flag on the service group rate is '1'
       * (Yes; meaning that all the pricing is included in the rate), we have to further check the
       * PI flag of the specific part line. If the part line PI flag is '0' (No),
       * charge out the parts above the rate price
       */
      if (quoteLine?.embedded?.serviceGroup?.embedded?.rate?.goodwrenchFlag === '1') {
        if (qsd.quoteDetailSequence === quoteLine.sequence && qsd.goodwrenchFlag !== '1') {
          additionalCharges.subTotal += qsd.amount;
          additionalCharges.tax += qsd.gstAmount + qsd.pstAmount;
        }
      } else {
        if (qsd.quoteDetailSequence === quoteLine.sequence) {
          additionalCharges.subTotal += qsd.amount;
          additionalCharges.tax += qsd.gstAmount + qsd.pstAmount;
        }
      }
    });

    return additionalCharges;
  }

  getPartSalesAdditionalChargesForQuoteLine(vcrData: VcrData, quoteLine: QuoteDetail) {
    const additionalCharges = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.quoteSalesAdditionalCharges.forEach((qsac: QuoteSalesAdditionalCharge) => {
      /**
       * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
       * If PI flag is '0' (No), the parts additional charges will be charged out
       * above the rate price. If PI flag on the service group rate is '1'
       * (Yes; meaning that all the pricing is included in the rate), we have to further check the
       * PI flag of the specific parts additional charges line. If the parts additional charges line PI flag is '0' (No),
       * charge out the parts additional charges above the rate price
       */
      if (quoteLine?.embedded?.serviceGroup?.embedded?.rate?.goodwrenchFlag === '1') {
        if (qsac.quoteDetailSequence === quoteLine.sequence && qsac.goodwrenchFlag !== '1') {
          additionalCharges.subTotal += qsac.subTotal;
          additionalCharges.tax += qsac.gstAmount + qsac.pstAmount;
        }
      } else {
        if (qsac.quoteDetailSequence === quoteLine.sequence) {
          additionalCharges.subTotal += qsac.subTotal;
          additionalCharges.tax += qsac.gstAmount + qsac.pstAmount;
        }
      }
    });

    return additionalCharges;
  }

  getAllAdditionalChargesForQuoteLine(vcrData: VcrData, quoteLine: QuoteDetail) {
    return {
      subTotal:
        this.getAdditionalChargesForQuoteLine(vcrData, quoteLine).subTotal +
        this.getPartSalesForQuoteLine(vcrData, quoteLine).subTotal +
        this.getPartSalesAdditionalChargesForQuoteLine(vcrData, quoteLine).subTotal,
      tax:
        this.getAdditionalChargesForQuoteLine(vcrData, quoteLine).tax +
        this.getPartSalesForQuoteLine(vcrData, quoteLine).tax +
        this.getPartSalesAdditionalChargesForQuoteLine(vcrData, quoteLine).tax,
    };
  }

  getAdditionalChargesForWorkOrderLabourLine(vcrData: VcrData, labour: WorkOrderLabour) {
    const additionalCharges = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.workOrderAdditionalCharges.forEach((woac: WorkOrderAdditionalCharge) => {
      /**
       * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
       * If PI flag is '0' (No), the WO additional charges will be charged out
       * above the rate price. If PI flag on the service group rate is '1'
       * (Yes; meaning that all the pricing is included in the rate), we have to further check the
       * PI flag of the specific WO additional charge. If the WO additional charge PI flag is '0' (No),
       * charge out the WO additional charge above the rate price
       */
      if (labour?.embedded?.serviceGroup?.serviceGroupRate?.goodwrenchFlag === '1') {
        if (woac.warrantyCaseNumber === labour.warrantyCase && woac.goodwrenchFlag !== '1') {
          additionalCharges.subTotal += woac.subTotal;
          additionalCharges.tax += woac.pstAmount + woac.gstAmount;
        }
      } else {
        if (woac.warrantyCaseNumber === labour.warrantyCase) {
          additionalCharges.subTotal += woac.subTotal;
          additionalCharges.tax += woac.pstAmount + woac.gstAmount;
        }
      }
    });

    return additionalCharges;
  }

  getPartSalesForWorkOrderLabourLine(vcrData: VcrData, labour: WorkOrderLabour) {
    const partSales = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.partSalesDetails.forEach((psd: PartSalesDetail) => {
      /**
       * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
       * If PI flag is '0' (No), the WO parts will be charged out
       * above the rate price. If PI flag on the service group rate is '1'
       * (Yes; meaning that all the pricing is included in the rate), we have to further check the
       * PI flag of the specific part line. If the part line PI flag is '0' (No),
       * charge out the WO parts above the rate price
       */
      if (labour?.embedded?.serviceGroup?.serviceGroupRate?.goodwrenchFlag === '1') {
        if (psd.warrantyCase === labour.warrantyCase && psd.goodwrenchFlag !== '1') {
          partSales.subTotal += psd.amount;
          partSales.tax += psd.pstAmount + psd.gstAmount;
        }
      } else {
        if (psd.warrantyCase === labour.warrantyCase) {
          partSales.subTotal += psd.amount;
          partSales.tax += psd.pstAmount + psd.gstAmount;
        }
      }
    });

    return partSales;
  }

  getPartSalesAdditionalChargesForWorkOrderLabourLine(vcrData: VcrData, labour: WorkOrderLabour) {
    const partSalesAdditionalCharges = {
      subTotal: 0,
      tax: 0,
    };

    vcrData.partSalesAdditionalCharges.forEach((psac: PartSalesAdditionalCharge) => {
      /**
       * Check the PI flag (goodwrenchFlag) on the embedded service group rate.
       * If PI flag is '0' (No), the WO parts additional charges will be charged out
       * above the rate price. If PI flag on the service group rate is '1'
       * (Yes; meaning that all the pricing is included in the rate), we have to further check the
       * PI flag of the specific WO part additional charges line. If the WO part additional charges line PI flag is '0' (No),
       * charge out the WO part additional charges above the rate price
       */
      if (labour?.embedded?.serviceGroup?.serviceGroupRate?.goodwrenchFlag === '1') {
        if (psac.warrantyCaseId === labour.warrantyCase && psac.goodwrenchFlag !== '1') {
          partSalesAdditionalCharges.subTotal += psac.subTotal;
          partSalesAdditionalCharges.tax += psac.pstAmount + psac.gstAmount;
        }
      } else {
        if (psac.warrantyCaseId === labour.warrantyCase) {
          partSalesAdditionalCharges.subTotal += psac.subTotal;
          partSalesAdditionalCharges.tax += psac.pstAmount + psac.gstAmount;
        }
      }
    });

    return partSalesAdditionalCharges;
  }

  getAllAdditionalChargesForWorkOrderLabourLine(vcrData: VcrData, labour: WorkOrderLabour) {
    return {
      subTotal:
        this.getAdditionalChargesForWorkOrderLabourLine(vcrData, labour).subTotal +
        this.getPartSalesForWorkOrderLabourLine(vcrData, labour).subTotal +
        this.getPartSalesAdditionalChargesForWorkOrderLabourLine(vcrData, labour).subTotal,
      tax:
        this.getAdditionalChargesForWorkOrderLabourLine(vcrData, labour).tax +
        this.getPartSalesForWorkOrderLabourLine(vcrData, labour).tax +
        this.getPartSalesAdditionalChargesForWorkOrderLabourLine(vcrData, labour).tax,
    };
  }

  getLineItemRate(quoteLine: QuoteDetail) {
    if (!quoteLine.embedded.serviceGroup) {
      return quoteLine.addedByPowerlane === true || quoteLine.rateType === '1'
        ? quoteLine.labourSubTotal
        : quoteLine.rate;
    } else {
      if (quoteLine.embedded.serviceGroup.embedded.rate && quoteLine.embedded.serviceGroup.embedded.rate !== null) {
        return quoteLine.rateType !== '1' && quoteLine.embedded?.serviceGroup?.embedded?.rate?.goodwrenchFlag === '1'
          ? quoteLine.embedded.serviceGroup.embedded.rate.rate
          : quoteLine.labourSubTotal;
      } else {
        return quoteLine.labourSubTotal;
      }
    }
  }

  determineVcrSendMethod(
    contactFormValues: { messagePreference: number; messagePreferenceContactInfo: string },
    messageTypes: MessageType[],
    currentAssociateMessagePreference: string
  ) {
    if (contactFormValues.messagePreferenceContactInfo !== null) {
      return messageTypes.find((messageType) => messageType.id === contactFormValues.messagePreference).description;
    } else {
      return currentAssociateMessagePreference ? currentAssociateMessagePreference : 'None';
    }
  }
}
