export class ContactPresence {
  id: number;
  idleTimeUtc: number;

  constructor(id: number, idleTimeUtc: number) {
    this.id = id;
    this.idleTimeUtc = idleTimeUtc;
  }
}

export const contactPresenceMock: ContactPresence = new ContactPresence(1000, 0);
export const contactsPresenceMock: any = { 1000: contactPresenceMock, 1001: new ContactPresence(1001, 0) };
