import { Socket } from 'socket.io-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface SocketConnectionInfo {
  readonly isConnected: boolean;
  readonly opts: SocketIOClient.ConnectOpts;
  readonly reconnectFailed: boolean;
  readonly service: string;
  readonly url: string;
}

export interface SocketConnectionInfoState {
  readonly socketConnectionInfos: SocketConnectionInfo[];
}

export const socketFeatureSelector = createFeatureSelector<SocketConnectionInfoState>('sockets');
export const socketConnectionInfosSelector = createSelector(
  socketFeatureSelector,
  (state: SocketConnectionInfoState) => state.socketConnectionInfos
);
