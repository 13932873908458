import { AppointmentCase } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface SelectedAppointmentCasesState extends EntityState<AppointmentCase> {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string;
}

export const appointmentCaseAdapter: EntityAdapter<AppointmentCase> = createEntityAdapter<AppointmentCase>({
  selectId: (AppointmentCase: AppointmentCase) => AppointmentCase.sequence
});
// eslint-disable-next-line max-len
export const initialSelectedAppointmentCaseState: SelectedAppointmentCasesState = appointmentCaseAdapter.getInitialState(
  {
    status: 'idle',
    error: null
  }
);
