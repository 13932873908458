import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  POWERLANEANALYTICS_FEATURE_KEY,
  State,
  PowerlaneAnalyticsPartialState,
  powerlaneAnalyticsAdapter,
} from './powerlane-analytics.reducer';

// Lookup the 'PowerlaneAnalytics' feature state managed by NgRx
export const getPowerlaneAnalyticsState = createFeatureSelector< State>(
  POWERLANEANALYTICS_FEATURE_KEY
);

const { selectAll, selectEntities } = powerlaneAnalyticsAdapter.getSelectors();

export const getPowerlaneAnalyticsLoaded = createSelector(getPowerlaneAnalyticsState, (state: State) => state.loaded);

export const getPowerlaneAnalyticsError = createSelector(getPowerlaneAnalyticsState, (state: State) => state.error);

export const getAllPowerlaneAnalytics = createSelector(getPowerlaneAnalyticsState, (state: State) => selectAll(state));

export const getPowerlaneAnalyticsEntities = createSelector(getPowerlaneAnalyticsState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(getPowerlaneAnalyticsState, (state: State) => state.selectedId);

export const getSelected = createSelector(
  getPowerlaneAnalyticsEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
