import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationModule } from '@quorum/authentication';
import { ContactsModule } from '@quorum/communicator/state/contacts';
import { ConversationsModule } from '@quorum/communicator/state/conversations';
import { CommunicatorStateRootEventsModule } from '@quorum/communicator/state/events';
import { CommunicatorStateRootMembersModule } from '@quorum/communicator/state/members';
import { environment } from '@quorum/environments';
import { CustomSerializer, ShaRouterModule } from '@quorum/sha-router';
import { SocketsModule } from '@quorum/sockets';

import { CommunicatorEffects } from './communicator.effects';
import { UiStateService } from './ui/ui-state-service';
import { uiSettingsReducer } from './ui/ui.reducer';

@NgModule({
  imports: [
    EffectsModule.forRoot([CommunicatorEffects]),
    StoreModule.forRoot(
      { router: routerReducer, ui: uiSettingsReducer },
      { runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false } }
    ),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 100 }) : [],
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    CommonModule,
    ContactsModule,
    ConversationsModule,
    AuthenticationModule,
    CommunicatorStateRootEventsModule,
    CommunicatorStateRootMembersModule,
    SocketsModule,
    ShaRouterModule,
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }, UiStateService],
})
export class CommunicatorStateRootModule {}
