import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/internal/operators/map';
import { FilesStateService } from '../files-state.service';
import * as FilesActions from './files.actions';
import { FileEntity } from './files.models';

@Injectable()
export class FilesEffects {
  loadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesActions.loadFiles),
      fetch({
        id: (action: any) => action.conversationId,
        run: (action: any) => {
          return this.filesStateService
            .getFiles(action.conversationId)
            .pipe(map((files: FileEntity[]) => FilesActions.loadFilesSuccess({ files })));
        },

        onError: (action, error) => {
          console.error('Error within loadFiles effect', error);
          return FilesActions.loadFilesFailure({ error });
        },
      })
    )
  );

  constructor(private actions$: Actions, private filesStateService: FilesStateService) {}
}
