import { Address } from './address.model';
import { Associate } from './associate.model';
import { Department } from './department.model';
import { JobClassification } from './job-classification.model';
import { JobTitle } from './job-title.model';
import { TechnicianGroup } from './technician-group.model';
import { Till } from './till.model';
import { OemRooftopLink } from '../system/oem-rooftop-link.model';

export class Employee {
  active?: string = null;
  allowClearAssign: string = null;
  assignWork: string = null;
  associateId: string = null;
  autovanceDeskRole: number = null;
  autovanceDeskUserId: number = null;
  available: string = null;
  canConfirmSalesAppointment: boolean = null;
  communicatorExtendedWarrantyAlert: string = null;
  communicatorMissedServiceAppointmentReply: string = null;
  communicatorPartsRequestNotifications: boolean = null;
  consoleUrl: string = null;
  customerProspectView: string = null;
  dateLastAuthenticated: Date = null;
  dealerResourceCentreUsername: string = null;
  defaultPurchaseOrderType: number = null;
  departmentId: string = null;
  disableRefresh: boolean = null;
  embedded?: EmployeeEmbed = null;
  eletronicSigningEnabled: boolean = null;
  empowermentAddOperation: string = null;
  empowermentStraightTime: string = null;
  empowermentSubmissionType: string = null;
  enableNewVip: boolean = null;
  hourlyRate: number = null;
  identifixUsername: string = null;
  incentiveView: string = null;
  internalMessagePreferenceId: number = null;
  invoiceFullRetailFlag: string = null;
  jobClassId: number = null;
  jobTitleId: string = null;
  networkUserName: string = null;
  nominalRollCode: string = null;
  oasisUserId: number = null;
  rooftopId: number = null;
  office365IpAddress: string = null;
  onlineApproval: string = null;
  partPercentComission: number = null;
  payrollId: string = null;
  position: string = null;
  salesFollowUpFlag: string = null;
  salespersonCommissionTypeId: string = null;
  salespersonTakeoverNotification: string = null;
  salespersonTypeId: string = null;
  salesPlannerView: number = null;
  salesRegistrationNumber: string = null;
  salesStaff: boolean = null;
  scan123Username: string = null;
  sendGreeting: boolean = null;
  serviceAdvisorNumber: string = null;
  serviceAdvisorNumberUS: string = null;
  serviceGroupSearchDefault: string = null;
  serviceWorkBenchUsername: string = null;
  shopDepartment: string = null;
  showroomFollowUp: string = null;
  technicianCauseCorrectionFlag: string = null;
  technicianGroupId: number = null;
  technicianLicenseNumber: string = null;
  technicianNumber: string = null;
  technicianNumberUS: string = null;
  techTimeAreaPrompt: string = null;
  terminationDate: Date = null;
  tillId: number = null;
  timeType: string = null;
  viewAllPurchaseOrder: string = null;
  vehicleFixedCommission: number = null;
  vehiclePercentCommission: number = null;
  vehicleSalesWorksheetView: string = null;
  whatsNewDismiss: boolean = null;

  constructor(obj?: Employee) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class EmployeeEmbed {
  addresses?: Address[] = null;
  associate?: Associate = null;
  department?: Department = null;
  jobClassification?: JobClassification = null;
  jobTitle?: JobTitle = null;
  oemRooftopLink?: OemRooftopLink = null;
  technicianGroup?: TechnicianGroup = null;
  till?: Till = null;
}

export const authenticatedEmployeeMock: Employee = new Employee({
  active: '1',
  allowClearAssign: '1',
  assignWork: '1',
  associateId: '20893',
  autovanceDeskRole: null,
  autovanceDeskUserId: null,
  available: '1',
  canConfirmSalesAppointment: false,
  communicatorExtendedWarrantyAlert: '1',
  communicatorMissedServiceAppointmentReply: '1',
  communicatorPartsRequestNotifications: true,
  consoleUrl:
    'https://app.powerbi.com/groups/me/apps/8607c235-e271-45c5-b23f-258a02a7fc0a/reports/4f19f0a7-c5df-4a26-acad-cd272d8053fc/ReportSection2a407bc6d5a20aa54267',
  customerProspectView: null,
  dateLastAuthenticated: null,
  dealerResourceCentreUsername: 'millerdzzz509',
  defaultPurchaseOrderType: 2,
  departmentId: 'SHO',
  disableRefresh: null,
  eletronicSigningEnabled: true,
  empowermentAddOperation: null,
  empowermentStraightTime: null,
  empowermentSubmissionType: null,
  enableNewVip: true,
  hourlyRate: 20,
  identifixUsername: null,
  incentiveView: null,
  internalMessagePreferenceId: 2,
  invoiceFullRetailFlag: null,
  jobClassId: 2,
  jobTitleId: 'SAD',
  networkUserName: 'millerdzzz509',
  nominalRollCode: null,
  oasisUserId: null,
  office365IpAddress: 'daniell@luckysofmonticello.onmicrosoft.com',
  onlineApproval: '1',
  partPercentComission: null,
  payrollId: '105       ',
  position: 'Service Manager',
  rooftopId: 1,
  salesFollowUpFlag: '0',
  salespersonCommissionTypeId: null,
  salespersonTakeoverNotification: '0',
  salespersonTypeId: null,
  salesPlannerView: 0,
  salesRegistrationNumber: null,
  salesStaff: false,
  scan123Username: 'xarter@yahoo.com',
  sendGreeting: true,
  serviceAdvisorNumber: '105',
  serviceAdvisorNumberUS: '145755761',
  serviceGroupSearchDefault: 'S',
  serviceWorkBenchUsername: null,
  shopDepartment: '1',
  showroomFollowUp: null,
  technicianCauseCorrectionFlag: '1',
  technicianGroupId: 1,
  technicianLicenseNumber: null,
  technicianNumber: null,
  technicianNumberUS: '145755761',
  techTimeAreaPrompt: '0',
  terminationDate: null,
  tillId: 2,
  timeType: 'S',
  viewAllPurchaseOrder: '0',
  vehicleFixedCommission: null,
  vehiclePercentCommission: null,
  vehicleSalesWorksheetView: null,
  whatsNewDismiss: true,
});
