import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { AppointmentCase, Media, WorkOrderCase } from '@quorum/models/xs-resource';
import { PowerlaneAnalyticsFacade } from '@quorum/power-lane-analytics';
import { filter, map, share, shareReplay, take } from 'rxjs/operators';
import {
  clearMediaState,
  deleteMediaFromServer,
  loadDeltaMedia,
  loadMedia,
  mediaUploaded,
  setMediaModelKey,
  updateMediaOnServer,
  uploadMedia,
  clearUploadingMediaState,
} from './+state/media/media.actions';
import {
  getAllMedia,
  getAllMediaUploading,
  getAppointmentCaseMedia,
  getAppointmentMedia,
  getBodyReportMedia,
  getDeltaMediaData,
  getFilteredVehicleMedia,
  getInspectionMedia,
  getInspectionTaskMedia,
  getMediaLoaded,
  getMediaParamsForKey,
  getSpecificFileMedia,
  getWorkOrderCaseMedia,
  getWorkOrderMedia,
  getAllInspectionTaskMedia,
} from './+state/media/media.selectors';

@Injectable()
export class MediaStateService {
  mediaUploading$ = this.getUploadingMedia().pipe(shareReplay());

  constructor(private store: Store, private powerLaneAnalyticsFacade: PowerlaneAnalyticsFacade) {}

  getAppointmentMedia(appointmentId: number) {
    return this.store.pipe(select(getAppointmentMedia, { appointmentId })).pipe(share());
  }

  deleteMedia(media: Media) {
    this.store.dispatch(deleteMediaFromServer({ media }));
  }

  updateMedia(media: any) {
    this.store.dispatch(updateMediaOnServer({ media }));
  }

  loadMediaForVehicle(vehicleId: number) {
    this.store.dispatch(loadMedia({ vehicleId }));
  }

  getWorkOrderMedia(workOrderId: number) {
    return this.store.pipe(select(getWorkOrderMedia, { workOrderId })).pipe(share());
  }

  getWorkOrderCaseMedia(workOrderCase: WorkOrderCase) {
    return this.store
      .pipe(
        select(getWorkOrderCaseMedia, {
          caseSequence: workOrderCase.sequence,
          workOrderId: workOrderCase.workOrderId,
        })
      )
      .pipe(share());
  }

  getAppointmentCaseMedia(appointmentCase: AppointmentCase) {
    return this.store
      .pipe(
        select(getAppointmentCaseMedia, {
          caseSequence: appointmentCase.sequence,
          appointmentId: appointmentCase.appointmentId,
        })
      )
      .pipe(share());
  }

  getInspectionMedia(inspectionId: number, workOrderId: number) {
    return this.store.pipe(select(getInspectionMedia, { inspectionId, workOrderId })).pipe(share());
  }

  getInspectionTaskMedia(inspectionTaskId: number, workOrderId: number) {
    return this.store.pipe(select(getInspectionTaskMedia, { inspectionTaskId, workOrderId })).pipe(share());
  }

  getAllInspectionTaskMedia(inspectionTypeId: number, workOrderId: number) {
    return this.store.pipe(select(getAllInspectionTaskMedia, { inspectionTypeId, workOrderId })).pipe(share());
  }

  getBodyReportMedia(damageId: string, workOrderId: number) {
    return this.store.pipe(select(getBodyReportMedia, { damageId, workOrderId })).pipe(share());
  }

  setMediaModelKey(mediaParams: any) {
    this.store.dispatch(setMediaModelKey({ mediaParams }));
  }

  getDeltaMedia() {
    this.store
      .pipe(
        select(getDeltaMediaData),
        filter((data) => data.vehicleId != null),
        take(1)
      )
      .subscribe((data) => {
        this.store.dispatch(loadDeltaMedia(data));
      });
  }

  getMediaParamsForKey(key: string) {
    return this.store.pipe(
      select(getMediaParamsForKey, { key }),
      filter((params) => params != null),
      map((params) => {
        let p = new DeepCopyPipe().transform(params);
        return p;
      })
    );
  }

  uploadMedia(file: { fileName: string; fileType: string; fileStatus: string; fileNameToDisplay: string }) {
    this.store.dispatch(uploadMedia({ file: file }));
  }

  getUploadingMedia() {
    return this.store.pipe(select(getAllMediaUploading)).pipe(share());
  }

  mediaUploaded(file: { fileName: string; fileType: string; fileStatus: string }) {
    this.store.dispatch(mediaUploaded({ file: file }));
  }

  clearUploadingMediaState() {
    this.store.dispatch(clearUploadingMediaState());
  }

  clearMediaState() {
    this.store.dispatch(clearMediaState());
  }

  getSpecificFileMedia(fileName: string) {
    return this.store.pipe(select(getSpecificFileMedia, { fileName })).pipe(share());
  }

  getVehicleMedia() {
    return this.store.pipe(select(getAllMedia)).pipe(share());
  }

  getVehicleMediaLoaded() {
    return this.store.pipe(select(getMediaLoaded)).pipe(share());
  }

  getFilteredVehicleMedia(inspectionTaskId: number, workOrderId: number) {
    return this.store.pipe(select(getFilteredVehicleMedia, { inspectionTaskId, workOrderId })).pipe(share());
  }

  buildMediaParams(baseParams: any, additionalParams: any) {
    return { ...baseParams, ...additionalParams };
  }

  createUploadMediaPowerLaneAnalyticEvent(params: any, fileType: string, videoDuration?: number) {
    this.powerLaneAnalyticsFacade.dispatchMediaAddedToWorkOrderFromPowerLane(
      params.workOrderId,
      params.key,
      fileType,
      videoDuration
    );
  }
}
