<div style="height: 100%" *ngIf="authenticatedUser$ | async as authenticatedUser" fxLayout="row">
  <div fxLayout="column" *ngIf="contacts$ | async as contacts"
    style="height: 100%; width: 380px; overflow-y: auto; border-right: 1.5px solid #8080804a">
    <mat-toolbar style="background: #fff" class="mat-toolbar">
      <mat-toolbar-row fxLayoutAlign="center center"><span style="font-weight: 400">Customer Conversations</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <div fxLayout="column" class="search-panel-container">
      <div class="search-input-container" fxLayout="row" fxLayoutAlign="center center">
        <input [(ngModel)]="searchString" fxFlex="92" class="search-input" placeholder="Search..." />
        <button *ngIf="searchString.length > 0" class="search-cancel-button" mat-icon-button class="example-fab"
          (click)="closeSearch()">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
      <div *ngIf="searchString.length > 0" fxLayout="column" class="search-filters-container" fxLayoutGap="12px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Contacts</span>
          <mat-slide-toggle [checked]="searchContacts" [(ngModel)]="searchContacts"></mat-slide-toggle>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Conversations</span>
          <mat-slide-toggle [checked]="searchConversations" [(ngModel)]="searchConversations" #item
            (change)="onSearchConversationsChange()"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Include Archived Conversations</span>
          <mat-slide-toggle [checked]="searchArchivedConversations" [(ngModel)]="searchArchivedConversations" #item
            (change)="onSearchArchivedConversationsChange()"></mat-slide-toggle>
        </div>
      </div>
    </div>
    <com-search-panel style="overflow: auto" [searchString]="searchString" [searchConversations]="searchConversations"
      [searchArchivedConversations]="searchArchivedConversations" [hidden]="!searchString.length"
      [customerAssociateId]="customerAssociateId" (onConversationSelected)="navigateToSelectedConversation($event)">
    </com-search-panel>

    <mat-nav-list fxFlex style="overflow-y: auto" *ngIf="conversationGroups$ | async as conversationGroups"
      [style.display]="searchString.length ? 'none' : 'block'">
      <mat-list-item (click)="startNewConversation()">
        <div mat-list-icon fxLayoutAlign="center center" class="initials-circle">
          <mat-icon class="message-icon">message</mat-icon>
        </div>
        <h4 mat-line class="primary-text">New Conversation</h4>
      </mat-list-item>
      <ng-container *ngIf="members$ | async as members">
        <ng-container *ngFor="
            let conversationGroup of conversationGroups | keyvalue: sortConversationGroups.bind(this);
            let i = index
          ">
          <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="cursor-pointer"
            (click)="conversationListItems.hidden = !conversationListItems.hidden">
            <div fxLayoutAlign="space-between center">
              <div>
                <h3 style="font-weight: 500; padding: 12px 12px 0; margin-bottom: 0">
                  {{ mapTransactionType(conversationGroup.key) }}
                </h3>
              </div>
              <div style="margin-bottom: -11px">
                <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-gray-800"
                  style="background-color: #e5e7eb">{{ conversationGroup.value.length }}</span>
              </div>
            </div>
            <div>
              <div>
                <div style="margin-bottom: -13px">
                  <mat-icon>{{
                    conversationListItems.hidden ? 'keyboard_arrow_left' : 'keyboard_arrow_down'
                    }}</mat-icon>
                </div>
              </div>
            </div>
          </div>
          <div #conversationListItems [hidden]="i === 0 ? false : true">
            <ng-container *ngFor="
                let transactionGroup of conversationGroup.value
                  | groupBy: 'dmsTransactionId'
                  | keyvalue: sortTransactionGroups.bind(this)
              ">
              <span class="mat-subheader">ID {{ transactionGroup.key }}</span>
              <com-conversations-list-item *ngFor="let conversation of transactionGroup.value"
                [authenticatedUser]="authenticatedUser" [conversation]="conversation" [contacts]="contacts"
                [members]="members[conversation.id]" [contactsPresence]="contactsPresence$ | async"
                [selected]="selectedConversation?.id == conversation.id"
                (conversationSelected)="selectConversation($event)">
              </com-conversations-list-item>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </div>

  <div fxFlex style="height: 100%" fxLayout="column">
    <div style="height: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>