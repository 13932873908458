import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ResponseInterceptorOptions as ApiResponseHeaders } from '@quorum/models/xs-misc';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const API_BASE = new InjectionToken('API_BASE');
export const LEGACY_API_BASE = new InjectionToken('LEGACY_API_BASE');
export const LEGACY_AUTH_BASE = new InjectionToken('LEGACY_AUTH_BASE');
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    @Inject(API_BASE) public apiBase: string,
    @Inject(LEGACY_API_BASE) private legacyApiBase: string,
    @Inject(LEGACY_AUTH_BASE) private legacyAuthBase: string
  ) {}

  getWithHeaders<T>(path: string, opts?: any): Observable<any> {
    path = stripSlash(path);

    if (opts) {
      return this.http.get(`${this.apiBase}/${path}`, { ...opts, observe: 'response' }).pipe(
        map((res: any) => {
          const headers = new ApiResponseHeaders({
            etag: res.headers.get('etag') ? res.headers.get('etag') : null,
            total: res.headers.get('x-total') ? parseInt(res.headers.get('x-total')) : null,
            totalPages: res.headers.get('x-total-pages') ? parseInt(res.headers.get('x-total-pages')) : null,
          });
          return { body: res.body, headers: headers };
        }),
        catchError((err: Response) => catcher(err, path))
      );
    } else {
      return this.http.get(`${this.apiBase}/${path}`, { observe: 'response' }).pipe(
        map((res) => {
          const headers = new ApiResponseHeaders({
            etag: res.headers.get('etag') ? res.headers.get('etag') : null,
            total: res.headers.get('x-total') ? parseInt(res.headers.get('x-total')) : null,
            totalPages: res.headers.get('x-total-pages') ? parseInt(res.headers.get('x-total-pages')) : null,
          });
          return { body: res.body, headers: headers };
        }),
        catchError((err: Response) => catcher(err, path))
      );
    }
  }

  getApiUrl() {
    return this.apiBase;
  }

  get<T>(path: string, opts?: any): Observable<any> {
    path = stripSlash(path);
    if (opts) {
      return this.http.get(`${this.apiBase}/${path}`, opts).pipe(
        map((res) => {
          return res;
        }),
        catchError((err: Response) => {
          return catcher(err, path);
        })
      );
    } else {
      return this.http.get(`${this.apiBase}/${path}`).pipe(
        map((res) => res),
        catchError((err: Response) => catcher(err, path))
      );
    }
  }

  head<T>(path: string, opts?: any): Observable<T> {
    path = stripSlash(path);
    opts = Object.assign({}, { observe: 'response', responseType: 'json' }, opts);
    return this.http.head(`${this.apiBase}/${path}`, opts).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: Response) => catcher(err, path))
    );
  }

  patch<T>(path: string, body: any, opts?: any): Observable<any> {
    return this.http.patch(`${this.apiBase}/${path}`, body).pipe(
      map((res) => res),
      catchError((err) => catcher(err, path))
    );
  }

  put<T>(path: string, body: any, opts?: any): Observable<any> {
    if (opts) {
      return this.http.put(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.put(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => catcher(err, path))
      );
    }
  }

  putWithHeaders<T>(path: string, body: any, opts?: any): Observable<any> {
    if (opts) {
      return this.http
        .put(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined, { ...opts, observe: 'response' })
        .pipe(
          map((res: any) => {
            const headers = new ApiResponseHeaders({});
            return { body: res.body, headers: headers };
          }),
          catchError((err) => catcher(err, path))
        );
    } else {
      return this.http
        .put(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined, { observe: 'response' })
        .pipe(
          map((res: any) => {
            const headers = new ApiResponseHeaders({});

            return { body: res, headers: headers };
          }),
          catchError((err) => catcher(err, path))
        );
    }
  }

  post<T>(path: string, body: any, opts?: any): Observable<any> {
    if (opts) {
      return this.http.post(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.post(`${this.apiBase}/${path}`, body ? emptyStringsToNull(body) : undefined).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  legacyPost<T>(path: string, body: any, opts?: any): Observable<any> {
    if (opts) {
      return this.http.post(`${this.legacyApiBase}/${path}`, body, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.post(`${this.legacyApiBase}/${path}`, body).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  legacyPut<T>(path: string, body: any, opts?: any): Observable<any> {
    if (opts) {
      return this.http.put(`${this.legacyApiBase}/${path}`, body, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.put(`${this.legacyApiBase}/${path}`, body).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  legacyGet<T>(path: string, opts?: any): Observable<any> {
    if (opts) {
      return this.http.get(`${this.legacyApiBase}/${path}`, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.get(`${this.legacyApiBase}/${path}`).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  legacyDelete<T>(path: string, opts?: any): Observable<any> {
    if (opts) {
      return this.http.delete(`${this.legacyApiBase}/${path}`, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.delete(`${this.legacyApiBase}/${path}`).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  legacyAuthGet<T>(path: string, opts?: any): Observable<any> {
    if (opts) {
      return this.http.get(`${this.legacyAuthBase}/${path}`, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.get(`${this.legacyAuthBase}/${path}`).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }

  delete<T>(path: string, opts?: any): Observable<any> {
    if (opts) {
      return this.http.delete(`${this.apiBase}/${path}`, opts).pipe(
        map((res: any) => {
          if (opts.observe) return res.body;
          else return res;
        }),
        catchError((err) => catcher(err, path))
      );
    } else {
      return this.http.delete(`${this.apiBase}/${path}`).pipe(
        map((res) => res),
        catchError((err) => catcher(err, path))
      );
    }
  }
}

function catcher(err: any, path: string): any {
  switch (err.status) {
    case 403:
      return throwError({
        reason: `The requested operation is forbidden.`,
      });
    case 404:
      return throwError({
        reason: `Data for /${path} could not be loaded at this time.`,
      });
    case 500:
      return throwError({
        reason: `Internal server internal. ${err.error.error_description}`,
      });
    case 503:
      const body: any = err.error;
      return throwError({
        reason: body.error_description,
      });
    default:
      return throwError({
        reason: `Unknown error loading /${path}.`,
        statusCode: err.status,
      });
  }
}

/**
 * Fix path if it has a leading slash.
 */
function stripSlash(path: string): string {
  return path.replace(/^\//, '');
}

function emptyStringsToNull(body: any) {
  let bodyCopy = new DeepCopyPipe().transform(body);
  Object.keys(bodyCopy).forEach((entry: any) => {
    if (bodyCopy[entry] === '') {
      bodyCopy[entry] = null;
    }
  });
  return bodyCopy;
}
